import React from 'react';
import ListItem from './ListItem';

const List = ({
	data,
	type
}) => {
	return (
		<div className="list">
			<div className="row">
				{data.items.length > 0
					? data.items.map((item, index) => <ListItem key={index} item={item} type={type} />)
					: <div className="col-md-12"><p>No items</p></div>
				}
			</div>
		</div>
	);
}

export default List;