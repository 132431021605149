import ReactGA from 'react-ga';
import * as c from './_constants';

export const isset = (accessor) => {

	try {
		// Note we're seeing if the returned value of our function is not
		// undefined
		return typeof accessor() !== 'undefined';
	} catch (e) {
		// And we're able to catch the Error it would normally throw for
		// referencing a property of undefined
		return false;
	}

}

export const getJsonDate = (date, considerTimezone = false)	=> {

	if (!considerTimezone){
		date = new Date(date.getTime() - (date.getTimezoneOffset() * 60000));
	}

	return date.toJSON().split('.')[0] + "Z";
}

export const getEventAddress = (item, choices)	=> {
	
	let address = {
		string: false,
		mapLink: false
	};

	let stateChoice = choices.state.find(state => parseInt(state.id, 10) === item.state_id);
	let state = stateChoice ? stateChoice.abbreviation : null;

	let countryChoice = choices.country.find(country => parseInt(country.id, 10) === item.country_id);
	let country = countryChoice ? countryChoice.country : null;

	let parts = [];

	if (item.venue){
		parts.push(item.venue)
	}
	if (item.address_line1){
		parts.push(item.address_line1)
	}
	if (item.address_line2){
		parts.push(item.address_line2)
	}
	if (item.address_line3){
		parts.push(item.address_line3)
	}
	if (item.address_line4){
		parts.push(item.address_line4)
	}
	if (item.city){
		parts.push(item.city)
	}
	if (state){
		parts.push(state)
	}
	if (country){
		parts.push(country)
	}

	address.string = parts.join(', ');

	if (address.string){
		address.mapLink = 'https://maps.google.com/?q=' + encodeURI(address.string);
	}

	return address;

	
}

export const getEventRangeDate = (dateStart, dateEnd)	=> {

	let value = ""; 
	if (formatDate(dateStart, 'Ymd') === formatDate(dateEnd, 'Ymd')){
		value += formatDate(dateStart, 'l, j F Y');
		//value += ' - ';
		//value += formatDate(dateStart, 'g:i A');
		//value += ' to ';
		//value += formatDate(dateEnd, 'g:i A');
	}else{
		if (formatDate(dateStart, 'Ym') === formatDate(dateEnd, 'Ym')){
			value += formatDate(dateStart, 'j');
			value += '-';
			value += formatDate(dateEnd, 'j F Y')
		}else{
			value += formatDate(dateStart, 'j F');
			value += '-';
			value += formatDate(dateEnd, 'j F Y')
		}
	}
	return value;
}

export const formatDate = (dateString, format)	=> {

	if (!dateString){
		return false;
	}

	let date = new Date(dateString);

	if (!isValidDate(date)){
		return false;
	}

	let day = date.getDate().toString();
	let weekday = date.getDay().toString();
	let month = (parseInt(date.getMonth(), 10) + 1).toString();
	let year = date.getFullYear().toString();
	let hour = date.getHours().toString();
	let minute = date.getMinutes().toString();
	let second = date.getSeconds().toString();

	let returnValue = '';

	let monthsStrings = getMonthsStrings();
	let daysStrings = getDaysStrings();

	[...format].forEach((char) => {
		let value;
		switch (char) {
			case 'd':
				//Day of the month, 2 digits with leading zeros
				value = day.length < 2 ? '0' + day : day;
				returnValue += value;
				break;
			case 'j':
				//Day of the month without leading zeros
				value = day;
				returnValue += value;
				break;
			case 'l':
				//A full textual represe0ntation of the day of the week
				value = daysStrings[weekday];
				returnValue += value;
				break;
			case 'F':
				//A full textual representation of a month, such as January or March
				value = monthsStrings[month - 1];
				returnValue += value;
				break;
			case 'M':
				//A short textual representation of a month, three letters
				value = monthsStrings[month - 1].substring(0, 3);
				returnValue += value;
				break;
			case 'm':
				//Numeric representation of a month, with leading zeros
				value = month.length < 2 ? '0' + month : month;
				returnValue += value;
				break;
			case 'n':
				//Numeric representation of a month, without leading zeros
				value = month;
				returnValue += value;
				break;
			case 'Y':
				//A full numeric representation of a year, 4 digits
				value = year;
				returnValue += value;
				break;
			case 'a':
				//Lowercase Ante meridiem and Post meridiem
				value = hour >= 12 ? 'am' : 'pm';
				returnValue += value;
				break;
			case 'A':
				//Uppercase Ante meridiem and Post meridiem
				value = hour >= 12 ? 'PM' : 'AM';
				returnValue += value;
				break;
			case 'h':
				//12-hour format of an hour with leading zeros
				value = hour > 12 ? hour - 12 : hour;
				value = value.toString().length < 2 ? '0' + value : value;
				returnValue += value;
				break;
			case 'g':
				//12-hour format of an hour without leading zeros
				value = hour > 12 ? hour - 12 : hour;
				returnValue += value;
				break;
			case 'H':
				//24-hour format of an hour with leading zeros
				value = hour.length < 2 ? '0' + hour : hour;
				returnValue += value;
				break;
			case 'i':
				//Minutes with leading zeros
				value = minute.length < 2 ? '0' + minute : minute;
				returnValue += value;
				break;
			case 's':
				//Seconds, with leading zeros
				value = second.length < 2 ? '0' + second : second;
				returnValue += value;
				break;
			default:
				returnValue += char;
				break;
		}
	});

	return returnValue;

}

export const getSelectDays = () => {

	let days = [];
	for (var index = 1; index <= 31; index++) {
		days.push({ value: index, label: index });
	}

	return days;
};

export const getSelectMonths = () => {

	var monthNames = getMonthsStrings();

	let months = [];

	for (var index = 0; index < monthNames.length; index++) {
		months.push({ value: index + 1, label: monthNames[index] });
	}

	return months;
};

export const getSelectYears = (future = false) => {

	let years = [];

	const currentYear = new Date().getFullYear();

	var index;

	if (future){

		for (index = currentYear - 50; index < currentYear + 10; index++) {
			years.push({ value: index, label: index });
		}

	}else{

		for (index = currentYear; index >= 1900; index--) {
			years.push({ value: index, label: index });
		}
	}


	return years;
};

const isValidDate = (d) => {
	return d instanceof Date && !isNaN(d);
}
const getMonthsStrings = () => {
	return [
		"January",
		"February",
		"March",
		"April",
		"May",
		"June",
		"July",
		"August",
		"September",
		"October",
		"November",
		"December"
	];
}
const getDaysStrings = () => {
	return [
		"Sunday",
		"Monday",
		"Tuesday",
		"Wednesday",
		"Thursday",
		"Friday",
		"Saturday",
	];
}

export const getChoicesById = (
	choices,
	values
)	=> {

	return values.map(id => {
		return choices.find(item => {
			return parseInt(item.id, 10) === id
		});
	}).filter(string => string !== undefined)

}



export const validEmail = (email) => {

	// var filter = /^([a-zA-Z0-9_\.\-\+])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
	var filter = /^([a-zA-Z0-9_.\-+])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;

	if (filter.test(email)) {
		return true;
	} else {
		return false;
	}
}

export const validPassword = (password) => {

	// From Wade Kalandos, email 27/04/2020
	// Passwords must be a minimum length of 6 characters and contain at least 1 lower-case letter, 1 upper-case letter and 1 number

	let passwordtest =  /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,1000}$/;

	if(password.match(passwordtest))
	{
		return true;
	}
	else
	{
		return false;
	}

}


export const validPhone = (phone) => {

	//for the moment, just check if numeric
	var filter = /^([0-9]\d*)$/;

	if (filter.test(phone)) {
		return true;
	} else {
		return false;
	}
}

export const countWords = (string) => {
	let s = string;
	s = s.replace(/(^\s*)|(\s*$)/gi, "");
	s = s.replace(/[ ]{2,}/gi, " ");
	s = s.replace(/\n /, "\n");
	return s.split(' ').length;
}

export const isPositiveInteger = (n) => {
	return 0 === n % (!isNaN(parseFloat(n)) && 0 <= ~~n);
}

export const validUrl = (str) => {
	let regexp = /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
	if (regexp.test(str)) {
		return true;
	}
	else {
		return false;
	}
}


export const handleGaTracking = (action, label) => {

	if(c.GA_CODE){
		ReactGA.event({
			category: 'Engagement',
			action: action,
			label: label
		});
	}
}
