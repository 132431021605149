import React from 'react';

const NonMemberMessage = () => {
	return (
		<div className="content">
			<div className="row">
				<div className="col-md-9">
					<p>This section is for members of The Tax Institute. Find out more or apply for membership here: </p>
					<a rel="noopener noreferrer" target="_blank" href="https://www.taxinstitute.com.au/membership/become-a-member1">https://www.taxinstitute.com.au/membership/become-a-member1</a>
				</div>
			</div>
		</div>
	);
}
export default NonMemberMessage;