import React, { Component } from 'react';

import { actionApiSendData } from '../../../actions/apiData/index';
import * as c from '../../../common/_constants';

class ShowProfile extends Component {
	constructor(props) {
		super(props);

		this.state = {
			showInTaxSpecialistDirectoryValue: this.props.profile.show_in_tax_specialist_directory,
			showInTaxSpecialistDirectoryError: false,
			responseMessage: false,
			dataSaved: false
		}

		this.handleFieldChange = this.handleFieldChange.bind(this);

	}
	handleFieldChange(e) {
		const target = e.target;

		if (target.checked) {
			this.setState({ showInTaxSpecialistDirectoryValue: true });
		} else {
			this.setState({ showInTaxSpecialistDirectoryValue: false });
		}

		let data = {
			show_in_tax_specialist_directory: this.state.showInTaxSpecialistDirectoryValue
		}

		this.props.dispatch(
			actionApiSendData(c.API_DATA.tax_specialist_profile.key, data)
		).then((success) => {
			if (success) {
				this.setState({
					dataSaved: true
				});
				setTimeout(() => {
					this.setState({ dataSaved: false });
				}, 2000);
			} else {
				this.setState({ responseMessage: 'something went wrong' });
			}
			this.setState({ taxProfileLogoValue: data.tax_profile_logo });
		});
	}
	render() {
		return (
			<div className="show-profile">
				<form>
					<div className="row content">
						<div className="col-md-12">
							<div className={"form-check form-group" + (this.state.showInTaxSpecialistDirectoryError ? ' has-error' : '')}>
								<input
									disabled={this.props.saving}
									type="checkbox"
									id="showInTaxSpecialistDirectory"
									name="showInTaxSpecialistDirectory"
									onChange={this.handleFieldChange}
									checked={this.state.showInTaxSpecialistDirectoryValue}
									className="form-check-input"
								/>
								<label className="form-check-label" htmlFor="showInTaxSpecialistDirectory">Show my profile in the <a href={this.props.eportalUrl + "/Directories/Browse.aspx"} rel="noopener noreferrer" target="_blank">Tax Specialist Directory</a></label>
								{this.state.showInTaxSpecialistDirectoryError
									? <p className="form-error">{this.state.showInTaxSpecialistDirectoryError}</p>
									: null
								}
								{this.props.saving
									? <i className="fas fa-spinner fa-spin"></i>
									: null
								}
								{this.state.dataSaved
									? <i className="fas fa-check"></i>
									: null
								}
								{this.state.responseMessage
									? <p className="response-message">{this.state.responseMessage}</p>
									: null
								}
							</div>
							<hr className="mt-5 mb-5" />
						</div>
					</div>
				</form>
			</div>
		)
	}
}

export default ShowProfile;