import React from 'react';

import List from './List';

const Sections = (props) => {

	let autoRenew = false;
	props.data.items.forEach((item) => {
		if (item.auto_renew) {
			autoRenew = true;
		}
	});
	
	return (
		<div className="sections">
			<div className="row">
				<div className="col-md-12">
					{autoRenew
						? 	<p>
								You have opted to auto renew one or more subscription products.
								To manage your renewal preferences please contact the customer
								service department on +61 2 8223 0000 or email{" "}
								<a href="mailto:membership@taxinstitute.com.au">membership@taxinstitute.com.au</a>
							</p>
						: null
					}
					{props.expiredCount > 0
						? <p>You have {props.expiredCount} subscription(s) that have expired.</p>
						: null
					}
					<List {...props} />
				</div>
			</div>
		</div>
	);
}

export default Sections;