import React from 'react';

import TableItem from './TableItem';

const Table = ({
	data
}) => {
	return (
		<div className="orders-table">
			<div className="row">
				<div className="col-md-6">
					<div className="table-title">Order History</div>
				</div>
				{data.pagination.page_count > 1
					? 
						<div className="col-md-6 text-right">
							{data.pagination.total_items} item{data.pagination.total_items !== 1 ? 's ' : ' '}
							in {data.pagination.page_count} page{data.pagination.page_count !== 1 ? 's ' : ' '}
						</div>
					:	null
				}
			</div>
			{data.items.length > 0
				? 
					<div className="table-responsive">
						<table className="table table-striped">
							<thead>
								<tr>
									<th>Order#</th>
									<th>Date</th>
									<th>Product</th>
									{/* <th>Status</th> */}
									<th>Total</th>
								</tr>
							</thead>
							<tbody>
								{data.items.map((item, index) => <TableItem key={index} item={item} />)}
							</tbody>
						</table>
					</div>
				: 
					<p>No items</p>
			}
			
		</div>
	);
}

export default Table;