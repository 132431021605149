import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import * as r from '../../common/_routes';

import TaxVineListItem from './TaxVineListItem';
import Loading from '../common/Loading';

class TaxVineList extends Component {
	
	render() {

		const {
			info,
		} = this.props;

		return (
			<div className="card h-100">
				<div className="card-header">
					<h6>{/* <i className='fas fa-leaf'></i>  */}taxvine newsletter</h6>
				</div>
				<div className="dashboard-tax-vine-list card-body">
					{info
						? info.error
							? <div><p>Sorry, an error occurred</p></div>
							: info.loading
								? <Loading />
								: info.data.content.items
									? info.data.content.items.length > 0
										?
											info.data.content.items.map((item) =>
												<TaxVineListItem 
													key={item.id} 
													item={item}
												/>
											)
										: <div><p>No items</p></div>
									: <Loading />
						: <Loading />
					}
				</div>
				<div className="card-footer">
					<Link to={r.URL_MEMBERSHIP_TAXVINE} className="btn-link">
						View All
	                </Link>
	            </div>
			</div>
		);
	}
}

export default TaxVineList;
