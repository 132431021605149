import * as React from "react";

function SortIcon({ colorUpper, colorLower, ...props }) {
  colorUpper = colorUpper || "#000";
  colorLower = colorLower || "#000";
  return (
    <svg viewBox="0 0 89 128" fill="none" {...props}>
      <path d="M45 2l36.373 58.5H8.627L45 2z" fill={colorUpper} />
      <path d="M45 125L8.627 66.5h72.746L45 125z" fill={colorLower} />
    </svg>
  );
}

export default SortIcon;
