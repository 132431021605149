import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { show, hide } from 'redux-modal';

import ShowProfile from './ShowProfile';
import Social from './Social';
import About from './About';
import PersonalInfo from './PersonalInfo';

const Sections = (props) => {
	return (
		<div className="sections">
			<div className="row">
				<div className="col-md-12">
					<ShowProfile {...props}/>
				</div>
			</div>
			<div className="row">
				<div className="col-md-12">
					<Social {...props} />
				</div>
			</div>
			<div className="row">
				<div className="col-md-6">
					<About {...props} />
				</div>
				<div className="col-md-6">
					<PersonalInfo {...props} />
				</div>
			</div>
		</div>
	);
}

function mapDispatchToProps(dispatch) {
	return {
		...bindActionCreators({ show, hide }, dispatch),
		dispatch
	};
}

export default connect(null, mapDispatchToProps)(Sections);