import React from 'react';

import Loading from '../../common/Loading';
import CategoryList from './CategoryList';

const SearchResult = ({
	loading,
	error,
	result,
	onSelectVideo
}) => {
	return (
		<div className="search-result">
			<div className="row">
				<div className="col-md-12">
					{loading
						? <Loading />
						: null
					}
					{error
						? <p>Sorry, something went wrong</p>
						: null
					}
					{result
						? <CategoryList category={result} onSelectVideo={onSelectVideo} />
						: null
					}
					
				</div>
			</div>
		</div>
	);
}

export default SearchResult;