import React, { Component } from 'react';
import Collapse from 'react-css-collapse';

import { formatDate, handleGaTracking } from '../../../../common/_utility';
import * as c from '../../../../common/_constants';

class ListItem extends Component {
	constructor(props) {
		super(props);

		this.state = {
			showMore: false,
		}

		this.handleCollapse = this.handleCollapse.bind(this);
	}
	handleCollapse() {

		handleGaTracking((this.props.type === 'free' ? 'Complimentary' : 'Purchased') + ' Papers and Presentations page - Click ' + (this.state.showMore ? 'Close' : 'Open') + ' Details', this.props.item.title);

		this.setState({ showMore: !this.state.showMore });
	}
	render() {
		const {
			item,
			type
		} = this.props;
		return (
			<div className="col-md-12">
				<div className="item">
					<div className="row">
						<div className="col-md-8">
							<h4>{item.title}</h4>
							{item.authors
								? <p>Author(s): {item.authors}</p>
								: null
							}
							<p>
								<strong>Published on</strong> {formatDate(item.published_at, 'd M Y')}
								{item.location
									? <span> | <strong>Took Place at</strong> {item.location}</span>
									: null
								}
							</p>
							{item.details.teaser
								?
								<button
									onClick={this.handleCollapse}
									className="btn btn-link"
								>
									{"Details "}
									<i className={"fas " + (this.state.showMore ? 'fa-sort-up' : 'fa-sort-down')}></i>
								</button>
								: null
							}

							<Collapse isOpen={this.state.showMore} transition={c.COLLAPSE_TRANSITION}>
								<div className="materials-extra p-3">
									<h4>{item.details.title}</h4>
									<div dangerouslySetInnerHTML={{ __html: item.details.teaser }}></div>
								</div>
							</Collapse>
						</div>
						<div className="col-md-4">
							<a onClick={() => handleGaTracking((this.props.type === 'free' ? 'Complimentary' : 'Purchased') + ' Papers and Presentations page - Click Download', item.title)} href={item.download_link} className="btn btn-primary mb-3" rel="noopener noreferrer" target="_blank">Download</a>
							{type === 'purchased'
								? <p className="purchased"><strong>Purchased:</strong> {formatDate(item.access_info.purchased_at, 'd M Y')}</p>
								: null
							}
							
						</div>
						<div className="col-md-12 mt-4 mb-4">
							<hr />
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default ListItem;