import React, { Component } from 'react';
import { connect } from 'react-redux';

import { actionGetApiDataKey, actionGetApiDataUrl } from '../../../actions/apiData/index';
import * as c from '../../../common/_constants';
import * as t from '../../../common/_titles';

import Search from './Search';
import Sections from './Sections';
import Loading from '../../common/Loading';

class Main extends Component {
	componentDidUpdate(prevProps) {

		if (this.props.australianTaxForum && !this.props.australianTaxForum.error && !this.props.australianTaxForum.loading) {
			if (this.props.match.params.id !== prevProps.match.params.id) {
				const id = this.props.match.params.id ;
				if (id) {
					this.props.dispatch(
						actionGetApiDataUrl(
							c.API_DATA.membership_australian_tax_forum.key,
							c.API_URL + c.API_DATA.membership_australian_tax_forum.base + id + '/articles?count=all',
							true
						)
					);
					
				} else {
					this.props.dispatch(actionGetApiDataKey(c.API_DATA.membership_australian_tax_forum.key));
				}
			}
		}
	}
	componentDidMount() {

		document.title = t.TITLE_MEMBERSHIP_AUSTRALIAN_TAX_FORUM;

		const id = this.props.match.params.id;

		if(id){

			this.props.dispatch(
				actionGetApiDataUrl(
					c.API_DATA.membership_australian_tax_forum.key,
					c.API_URL + c.API_DATA.membership_australian_tax_forum.base + id + '/articles?count=all',
					true
				)
			);
		}else{
			this.props.dispatch(actionGetApiDataKey(c.API_DATA.membership_australian_tax_forum.key));
		}

		this.props.dispatch(actionGetApiDataKey(c.API_DATA.membership_australian_tax_forum_listing.key));
	}
	render() {
		const {
			australianTaxForum,
			australianTaxForumListing,
			rootLink,
			match
		} = this.props;

		const idNum = match && match.params && match.params.id ? +match.params.id : (australianTaxForum && australianTaxForum.data && australianTaxForum.data.content) ? australianTaxForum.data.content.id : 0;
		const listingDetails = (australianTaxForumListing && australianTaxForumListing.data.content && australianTaxForumListing.data.content.items.find(it => it.id === idNum)) || {};
		const {document_image} = listingDetails;

		if (australianTaxForum && australianTaxForum.data && australianTaxForum.data.content) {
			if (listingDetails.issue && !australianTaxForum.data.content.issue) {
				Object.assign(australianTaxForum.data.content, {issue: listingDetails.issue, issued_at: listingDetails.issued_at})
			}
		}

		
		return (
			<div id="content-membership-australian-tax-forum">
				<div className="row">
					<div className="col-md-12">
						<h1>Australian Tax Forum</h1>
					</div>
				</div>


				{australianTaxForumListing
					? australianTaxForumListing.error
						? <div><p>Sorry, an error occurred</p></div>
						: australianTaxForumListing.loading
							? null
							: <Search data={australianTaxForumListing.data.content} rootLink={rootLink} />
					: null
				}
				{australianTaxForum
					? australianTaxForum.error
						? <div><p>Sorry, an error occurred</p></div>
						: australianTaxForum.loading
							? <Loading />
							: <Sections data={australianTaxForum.data.content} document_image={document_image} />
					: <Loading />
				}
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		australianTaxForum: state.apiData.membership_australian_tax_forum,
		australianTaxForumListing: state.apiData.membership_australian_tax_forum_listing,
		rootLink: state.app.global.global_user.data.content.root_url,
	}
}

export default connect(mapStateToProps)(Main);
