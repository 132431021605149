import React from 'react';

const Total = ({
	data,
	type
}) => {
	return (
		<div className="cpd-total text-right">
			{data.total_cpd_hours
				? 'total ' + type + ' cpd ' + data.total_cpd_hours.toFixed(2)
				: null
			}
			
		</div>
	);
}

export default Total;