import React from 'react';
import { render } from 'react-dom';
import { handleGaTracking } from '../../../common/_utility';

import InformationMissing from '../../common/InformationMissing';
import EditPaymentInformation from './EditPaymentInformation';

const PaymentInformation = (props) => {
	
	const onChange = (p)=> {
		if(!Array.isArray(props.profile.payment_installment)) {
			props.profile.payment_installment = p
		} else {
			props.profile.auto_renewal = p
		}
		const pr = {...props,'onChange' : onChange}
		render(PIElement(pr), document.getElementsByClassName('payment-information card')[0])
	}
	return (	<div className="payment-information card mb-4">
	<PIElement onChange={onChange}  {...props}/>
	</div>);
}

export const PIElement =  ({
	onChange,
	profile,
	choices,
	show,
}) => {
	return (
			<div className="row">
				<div className="col-md-12 mb-4">
					<div className="card-header">
						<h6><i className="fas fa-credit-card"></i> Payment Information</h6>
						<div className="card-header-icon-team">
							<button
								type="button"
								className="btn trigger-edit btn-link"
								onClick={() => {
									handleGaTracking('Profile page - Edit Payment Information', 'Edit Payment Information');
									show(
										'edit-payment-information-details',
										{ content: <EditPaymentInformation update={onChange} />, size: "md" }
									)
								}}
							>
								<i className="fas fa-pen"></i>
							</button>
						</div>
					</div>
					<div className="card-body">
						<div className="row">
							<div className="col-md-12">
								<div className="payment-information">
									<div className="data-label">Card Type</div>
									<p>
										{profile.payment_installment.PaymentType||profile.auto_renewal.PaymentType
											? profile.payment_installment.PaymentType||profile.auto_renewal.PaymentType
											: <InformationMissing />
										}
									</p>
								</div>
							</div>
							<div className="col-md-12">
								<div className="info-detail">
									<div className="data-label">Card number</div>
									<p>
										{profile.payment_installment.CCPartial||profile.auto_renewal.CCPartial
											? profile.payment_installment.CCPartial||profile.auto_renewal.CCPartial
											: <InformationMissing />
										}
									</p>
								</div>
							</div>
							<div className="col-md-4">
								<div className="payment-information">
									<div className="data-label">Exp Mon</div>
									<p>
										{(profile.payment_installment.CCExpireDate||profile.auto_renewal.CCExpireDate)&&
										(profile.payment_installment.CCExpireDate||profile.auto_renewal.CCExpireDate).trim()
										? (profile.payment_installment.CCExpireDate||profile.auto_renewal.CCExpireDate)
										.split(" ")[0].split("/")[1]
										: <InformationMissing />
										}
									</p>
								</div>
							</div>
							<div className="col-md-4">
								<div className="payment-information">
									<div className="data-label">Exp year </div>
									<p>
										{(profile.payment_installment.CCExpireDate||profile.auto_renewal.CCExpireDate)&&
										(profile.payment_installment.CCExpireDate||profile.auto_renewal.CCExpireDate).trim()
										? (profile.payment_installment.CCExpireDate||profile.auto_renewal.CCExpireDate)
										.split(" ")[0].split("/")[2]
										: <InformationMissing />
										}
									</p>
								</div>
							</div>
							<div className="col-md-4">
								<div className="payment-information">
									<div className="data-label">CVV</div>
									<p>
										<InformationMissing />
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
	);
}


export default PaymentInformation;