import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { show, hide } from 'redux-modal';

import TopInfo from './TopInfo';
import MembershipDetails from './MembershipDetails';
import BusinessInformation from './BusinessInformation';
import ProfessionalDetails from './ProfessionalDetails';
import PersonalInfo from './PersonalInfo';
import Interests from './Interests';
import TaxSpecialistDirectory from './TaxSpecialistDirectory';
import TaxAgentDetails from './TaxAgentDetails';
import PaymentInformation from './PaymentInformation';

const Sections = (props) => {
	return (
		<div className="sections">
			<div className="row">
				<div className="col-md-12">
					<TopInfo {...props}/>
				</div>
			</div>
			<div className="row">
				<div className="col-md-12">
					<MembershipDetails {...props} />
				</div>
			</div>
			<div className="row">
				<div className="col-md-6">
					<BusinessInformation {...props} />
					<ProfessionalDetails {...props} />
					<PaymentInformation {...props} />
				</div>
				<div className="col-md-6">
					<PersonalInfo {...props} />
					<Interests {...props} />
					{props.profile.member_type_id !== 1 // Non Member
						? <TaxSpecialistDirectory {...props} />
						: null
					}
					<TaxAgentDetails {...props} />
				</div>
			</div>
		</div>
	);
}

function mapDispatchToProps(dispatch) {
	return {
		...bindActionCreators({ show, hide }, dispatch),
		dispatch
	};
}

export default connect(null, mapDispatchToProps)(Sections);