import React from 'react';

import { getEventRangeDate, getEventAddress } from '../../common/_utility';

const EventsListItem = ({
	item,
	choices
}) => {
	let address = getEventAddress(item, choices);
	return (
		<div className="item">
			<div className="row">
				<div className="col-md-3 event-icon">
					<div className="image-wrapper">
						<img src={item.image} alt={item.title} />
					</div>
				</div>
				<div className="col event-list-item-data">
					<h4 className="m-0">{item.title}</h4>

					{address.string.length > 0
							?
							<div className="location">
								<i className="fas fa-map-marker"></i> {address.string}
							</div>
							: null
					}


					<div className="date">
						<i className="fas fa-calendar-alt"></i>{' '}
						{getEventRangeDate(item.start_at, item.finish_at)}
					</div>
				</div>
				<div className="col-md-2">
					<div className="hours-badge">
						{item.cpd_hours.length > 0
							? item.cpd_hours[0].cpd_hours
							: 0 + ' cpd hours'
						}
					</div>
				</div>
			</div>
			<div className="mb-4 mt-4">
				<hr />
			</div>
		</div>

	);
}

export default EventsListItem;
