import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

import { formatDate, isset, handleGaTracking } from '../../../common/_utility';
import * as r from '../../../common/_routes';

class SubmenuEvents extends Component {
	constructor(props) {
		super(props);

		this.state = {
			activeSlide: 0
		}
	}
	render() {

		const {
			counters,
			events,
			rootUrl
		} = this.props;
		
		var carouselSettings = {
			dots: false,
			infinite: true,
			arrows: false,
			autoplay: true,
			autoplaySpeed: 2000,
			speed: 500,
			slidesToShow: 1,
			slidesToScroll: 1,
			afterChange: (activeSlide) => {
				this.setState({ activeSlide: activeSlide });
			},
		};

		return(
			<div className="submenu" id="submenu-events">
				<div className="submenu-top">
					<h1>Events</h1>
					<ul className="submenu-list">
						<li>
							<NavLink to={r.URL_EVENTS_MY_EVENTS_UPCOMING}>
								My Events
								{isset(() => counters.data.content.my_events)
									? <span className="counter">{counters.data.content.my_events}</span>
									: null
								}
							</NavLink>
						</li>
						<li>
							<NavLink to={r.URL_EVENTS_MY_WEBINARS_UPCOMING}>
								My Webinars
								{isset(() => counters.data.content.my_webinars)
									? <span className="counter">{counters.data.content.my_webinars}</span>
									: null
								}
							</NavLink>
						</li>
					</ul>
				</div>
				<div className="submenu-bottom">
					<div className="text-bold">
						<a onClick={() => handleGaTracking('Menu - Click Explore Upcoming Key Events', 'Click Explore Upcoming Key Events')} href={rootUrl + "/professional-development/key-events"} rel="noopener noreferrer" target="_blank">
							Explore Upcoming Key Events
						</a>
					</div>
					{events.items.length > 0
						?
							<div>
								<span className="gallery-counter">{this.state.activeSlide + 1} of {events.items.length}</span>
								<div className="gallery">
									<Slider {...carouselSettings}>
										{events.items.map((item, index) =>
											<div className="item" key={index}>
												<div className="image-wrapper mb-2 mt-3">
													<a href={item.web_link} rel="noopener noreferrer" target="_blank">
														<img className="img-fluid" src={item.image_uri} alt={item.title} />
													</a>
												</div>
												{item.title}
												<div className="date text-bold text-uppercase small">
													{formatDate(item.start_at, 'F Y')}
												</div>
											</div>
										)}
									</Slider>
								</div>
							</div>
						:	null
					}
					
				</div>
			</div>
		);
	}
}

export default SubmenuEvents;