import React, { Component } from 'react';
import { connect } from 'react-redux';

import { actionGetApiDataKey } from '../../../../actions/apiData/index';

import * as c from '../../../../common/_constants';
import * as t from '../../../../common/_titles';

import Sections from './Sections';
import Links from '../common/Links';
import Loading from '../../../common/Loading';

class Main extends Component {

	componentDidMount() {

		document.title = t.TITLE_RESOURCES_CPD_ON_DEMAND_SELF_PACED_LEARNING;

		this.props.dispatch(actionGetApiDataKey(c.API_DATA.resources_cpd_self_learning.key));

	}
	render() {
		const {
			cpdRecord
		} = this.props;
		return (
			<div id="content-resources-cpd-record-self-paced-learning">
				<div className="row">
					<div className="col-md-12">
						<h1>CPD on Demand</h1>
					</div>
				</div>
				<div className="row">
					<div className="col-md-12">
						
							<Links />
						
					</div>
				</div>
				{cpdRecord
					? cpdRecord.error
						? <div><p>Sorry, an error occurred</p></div>
						: cpdRecord.loading
							? <Loading />
							: <Sections data={cpdRecord.data.content} />
					: <Loading />
				}
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		cpdRecord: state.apiData.resources_cpd_self_learning,
	}
}

export default connect(mapStateToProps)(Main);
