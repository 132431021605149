import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactPlayerLoader from '@brightcove/react-player-loader';

import { refreshGlobalEndpointData } from '../../../actions/app/global';
import { actionApiSendData } from '../../../actions/apiData/index';
import { formatDate, handleGaTracking } from '../../../common/_utility';
import * as c from '../../../common/_constants';

// import { formatDate } from '../../../common/_utility';

class VideoDetails extends Component {

	constructor(props) {
		super(props);

		this.state = {
			showMore: false,
		}

		this.playerOnSuccess = this.playerOnSuccess.bind(this);
	}
	playerOnSuccess(success) {

		var player = success.ref;

		player.on('firstplay', function () {

//			if (!this.props.video.access_info.watched) {

				let data = {
					object_id: this.props.video.media.object_id,
				}
				let url = c.API_URL + c.API_DATA.membership_monthly_tax_update_videowatch.endpoint;
				let method = 'POST';

				this.props.dispatch(
					actionApiSendData(c.API_DATA.membership_monthly_tax_update_videowatch.key, data, url, method)
				).then((success) => {
					refreshGlobalEndpointData('global_user', this.props.dispatch);
				})
//			}
		}.bind(this));

		player.on('play', function () {
			handleGaTracking('Video Library page - Video Play', this.props.video.title);
		}.bind(this));
		player.on('pause', function () {
			handleGaTracking('Video Library page - Video Pause ', this.props.video.title);
		}.bind(this));

	}
	render() {
		const {
			video
		} = this.props;


		let authors = video.authors.map((author) =>
			author.full_name
		);

		authors = authors.filter(function (el) {
			return (el !== null) && (el.trim() !== '');
		});

		return (
			<div className="row">
				<div className="col-md-5">
					<div className="video-info">
						<h4>{video.title}</h4>
						{authors.length > 0
							?
							<div className="authors">
								<strong>Author{authors.length > 1 ? 's' : ''}: </strong>
								{authors.join(', ')}
							</div>
							: ''
						}
						<p>
							{formatDate(video.published_at, 'j F Y')} | Source: {video.source}
						</p>

						{
							video.presentation.teaser
							?
								<div className="details">
									<div className="details-extra mt-3">
										<div dangerouslySetInnerHTML={{ __html: video.presentation.teaser }}></div>
									</div>
								</div>
							: null
						}
						
					</div>
				</div>
				<div className="col-md-7">
					<div className="video-wrapper mb-3">
						<ReactPlayerLoader
							accountId='694915343001'
							videoId={video.media.id}
							playerId="oaG6r7WdV"
							// options={{autoplay: true}} 
						onSuccess={this.playerOnSuccess}
						/>
					</div>
				</div>
			</div>

		);
	}
}

export default connect()(VideoDetails);