import React from 'react';

import ListItem from './ListItem';

const List = ({
	data,
	rootUrl
}) => {
	return (
		<div className="books-list">
			<div className="row">
				<div className="col-md-12">
					{data.items.length > 0
						? data.items.map((item, index) => <ListItem key={index} item={item} />)
						: 
							<div className="col-md-12">
								<a href={rootUrl + '/resources/books-and-journals'} rel="noopener noreferrer" target="_blank">View all Books &amp; Journals.</a>
							</div>
					}
				</div>
			</div>
		</div>
	);
}

export default List;