import React, { Component } from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string';

import { actionGetApiDataKey } from '../../../../actions/apiData/index';
import * as c from '../../../../common/_constants';
import * as t from '../../../../common/_titles';

import Sections from '../Sections';
import InfoBar from '../../../dashboard/Title';
import Links from '../common/Links';
import Loading from '../../../common/Loading';

class Main extends Component {

	componentDidUpdate() {

		if (this.props.orders && !this.props.orders.error && !this.props.orders.loading) {
			const params = queryString.parse(this.props.location.search);
			this.props.dispatch(actionGetApiDataKey(c.API_DATA.order_history_cancelled.key, params));
		}
	}

	componentDidMount() {

		document.title = t.TITLE_PROFILE_ORDER_HISTORY_CANCELLED;

		const params = queryString.parse(this.props.location.search);
		this.props.dispatch(actionGetApiDataKey(c.API_DATA.order_history_cancelled.key, params));

	}
	render() {
		const {
			orders,
		} = this.props;
		
		return (
			<div id="content-profile-order-history-orders">
				
				<div className="row">
					<div className="col-md-12">
						<InfoBar />
					</div>
				</div>
				<div className="row">
					<div className="col-md-12">
						<h1>Order History</h1>
					</div>
				</div>
				<div className="row">
					<div className="col-md-12">
						<Links />
					</div>
				</div>
				{orders
					? orders.error
						? <div><p>Sorry, an error occurred</p></div>
						: orders.loading
							? <Loading />
							: <Sections data={orders.data.content} />
					: <Loading />
				}
				
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		orders: state.apiData.order_history_cancelled,
	}
}

export default connect(mapStateToProps)(Main);
