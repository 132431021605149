import React, { Component } from 'react';
import { connect } from 'react-redux';
import { hide } from 'redux-modal';
import { bindActionCreators } from 'redux';
import Select from 'react-select';
import Tooltip from 'rc-tooltip';

import TooltipEditPersonalInfo from './TooltipEditPersonalInfo';

import { validEmail, formatDate, getSelectDays, getSelectMonths, getSelectYears, getJsonDate, handleGaTracking } from '../../../common/_utility';
import { actionApiSendData } from '../../../actions/apiData/index';
import * as m from '../../../common/_messages';
import * as c from '../../../common/_constants';

class EditPersonalInfo extends Component {
	constructor(props) {
		super(props);

		let profile = this.props.profileUser.data.content;

		this.state = {
			prefixValue: profile.prefix_id,
			prefixError: false,
			firstNameValue: profile.first_name,
			firstNameError: false,
			lastNameValue: profile.last_name,
			lastNameError: false,
			preferredNameValue: profile.preferred_name,
			preferredNameError: false,
			genderValue: profile.gender_id,
			genderError: false,
			DOBDayValue: parseInt(formatDate(profile.date_of_birth_at, 'j'), 10),
			DOBDayError: false,
			DOBMonthValue: parseInt(formatDate(profile.date_of_birth_at, 'n'), 10),
			DOBMonthError: false,
			DOBYearValue: parseInt(formatDate(profile.date_of_birth_at, 'Y'), 10),
			DOBYearError: false,
			emailValue: profile.email,
			emailError: false,
			phoneValue: profile.phone,
			phoneError: false,
			faxValue: profile.fax,
			faxError: false,
			homeAddress1Value: profile.home_street_address_1,
			homeAddress1Error: false,
			homeAddress2Value: profile.home_street_address_2,
			homeAddress2Error: false,
			homeCityValue: profile.home_city,
			homeCityError: false,
			homeStateValue: profile.home_state_id,
			homeStateError: false,
			homePostcodeValue: profile.home_postcode,
			homePostcodeError: false,
			homeCountryValue: profile.home_country_id,
			homeCountryError: false,
			businessAddressValue: profile.street_address,
			businessAddressError: false,
			businessCityValue: profile.city,
			businessCityError: false,
			businessStateValue: profile.state_id,
			businessStateError: false,
			businessPostcodeValue: profile.postcode,
			businessPostcodeError: false,
			businessCountryValue: profile.country_id,
			businessCountryError: false,
			responseMessage: false,
			dataSaved: false,
		}

		this.handleFieldChange = this.handleFieldChange.bind(this);
		this.handleSelectChange = this.handleSelectChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.isFormValid = this.isFormValid.bind(this);

	}
	handleFieldChange(e) {
		const target = e.target;
		const value = target.type === 'checkbox' ? target.checked : target.value;
		const name = target.name;

		this.setState({ [name + 'Value']: value });
	}
	handleSelectChange(optionSelected, { name }) {
		if (name === 'homeCountry') {
			//reset state as well
			this.setState({ homeStateValue: null });
		}
		if (name === 'businessCountry') {
			//reset state as well
			this.setState({ businessStateValue: null });
		}
		optionSelected
			? this.setState({ [name + 'Value']: optionSelected.value })
			: this.setState({ [name + 'Value']: null })
	}
	handleSubmit(e) {

		e.preventDefault();

		//validation
		if (!this.isFormValid()) {
			return;
		}

		var dateOfBirth = new Date(
			this.state.DOBYearValue,
			this.state.DOBMonthValue - 1,
			this.state.DOBDayValue,
		);

		let data = {
			prefix_id: this.state.prefixValue,
			first_name: this.state.firstNameValue,
			last_name: this.state.lastNameValue,
			preferred_name: this.state.preferredNameValue,
			gender_id: this.state.genderValue,
			date_of_birth_at: getJsonDate(dateOfBirth),
			email: this.state.emailValue,
			phone: this.state.phoneValue,
			fax: this.state.faxValue,
			street_address: this.state.businessAddressValue,
			city: this.state.businessCityValue,
			state_id: this.state.businessStateValue,
			postcode: this.state.businessPostcodeValue,
			country_id: this.state.businessCountryValue,
			home_street_address_1: this.state.homeAddress1Value,
			home_street_address_2: this.state.homeAddress2Value,
			home_city: this.state.homeCityValue,
			home_state_id: this.state.homeStateValue,
			home_postcode: this.state.homePostcodeValue,
			home_country_id: this.state.homeCountryValue,
		}

		handleGaTracking('Profile page - Personal Information Save', 'Personal Information Save');

		this.props.dispatch(
			actionApiSendData(c.API_DATA.profile_user.key, data)
		).then((success) => {
			if (success) {
				this.setState({
					responseMessage: 'Saved',
					dataSaved: true
				});
				//close popup after 3 secs
				let hidePopup = this.props.hide;
				setTimeout(
					function () {
						hidePopup('edit-account-details-personal-info')
					}, 2000
				);
			} else {
				this.setState({ responseMessage: 'something went wrong' });
			}
		});
	}
	isFormValid() {

		let formValid = true;

		//reset error states
		this.setState({
			prefixError: false,
			firstNameError: false,
			lastNameError: false,
			preferredNameError: false,
			genderError: false,
			DOBDayError:false,
			DOBMonthError:false,
			DOBYearError:false,
			emailError: false,
			phoneError: false,
			faxError: false,
			homeAddress1Error: false,
			homeAddress2Error: false,
			homeCityError: false,
			homeStateError: false,
			homePostcodeError: false,
			homeCountryError: false,
			businessAddressError: false,
			businessCityError: false,
			businessStateError: false,
			businesspostcodeError: false,
			businessCountryError: false,
			responseMessage: false
		});

		// if (!this.state.prefixValue) {
		// 	this.setState({ prefixError: m.formError.prefix.required });
		// 	formValid = false;
		// }
		// if (!this.state.firstNameValue) {
		// 	this.setState({ firstNameError: m.formError.firstName.required });
		// 	formValid = false;
		// }
		// if (!this.state.lastNameValue) {
		// 	this.setState({ lastNameError: m.formError.lastName.required });
		// 	formValid = false;
		// }
		// if (!this.state.preferredNameValue) {
		// 	this.setState({ preferredNameError: m.formError.preferredName.required });
		// 	formValid = false;
		// }
		if (!this.state.genderValue === '') {
			this.setState({ genderError: m.formError.gender.required });
			formValid = false;
		}
		if (!this.state.DOBDayValue) {
			this.setState({ DOBDayError: m.formError.dob_day.required });
			formValid = false;
		}
		if (!this.state.DOBMonthValue) {
			this.setState({ DOBMonthError: m.formError.dob_month.required });
			formValid = false;
		}
		if (!this.state.DOBYearValue) {
			this.setState({ DOBYearError: m.formError.dob_year.required });
			formValid = false;
		}
		if (!this.state.emailValue) {
			this.setState({ emailError: m.formError.email.required });
			formValid = false;
		} else {
			if (!validEmail(this.state.emailValue)) {
				this.setState({ emailError: m.formError.email.notValid });
				formValid = false;
			}
		}
		// if (!this.state.phoneValue) {
		// 	this.setState({ phoneError: m.formError.phone.required });
		// 	formValid = false;
		// }
		// if (!this.state.faxValue) {
		// 	this.setState({ faxError: m.formError.fax.required });
		// 	formValid = false;
		// }
		// if (!this.state.homeAddress1Value) {
		// 	this.setState({ homeAddress1Error: m.formError.address.required });
		// 	formValid = false;
		// }
		// if (!this.state.homeCityValue) {
		// 	this.setState({ homeCityError: m.formError.city.required });
		// 	formValid = false;
		// }
		// if (!this.state.homePostcodeValue) {
		// 	this.setState({ homePostcodeError: m.formError.postcode.required });
		// 	formValid = false;
		// }
		if (!this.state.homeCountryValue) {
			// this.setState({ homeCountryError: m.formError.country.required });
			// formValid = false;
		} else {
			//check state only if country has states
			let stateList = this.props.profileUser.data.choices.state.filter(
				(item) => parseInt(item.country_id, 10) === this.state.homeCountryValue
			);

			if (stateList.length > 0) {
				if (!this.state.homeStateValue) {
					this.setState({ homeStateError: m.formError.state.required });
					formValid = false;
				}
			}
		}
		// if (!this.state.businessAddressValue) {
		// 	this.setState({ businessAddressError: m.formError.address.required });
		// 	formValid = false;
		// }
		// if (!this.state.businessCityValue) {
		// 	this.setState({ businessCityError: m.formError.city.required });
		// 	formValid = false;
		// }
		// if (!this.state.businessPostcodeValue) {
		// 	this.setState({ businessPostcodeError: m.formError.postcode.required });
		// 	formValid = false;
		// }
		if (!this.state.businessCountryValue) {
			// this.setState({ businessCountryError: m.formError.country.required });
			// formValid = false;
		} else {
			//check state only if country has states
			let stateList = this.props.profileUser.data.choices.state.filter(
				(item) => parseInt(item.country_id, 10) === this.state.businessCountryValue
			);

			if (stateList.length > 0) {
				if (!this.state.businessStateValue) {
					this.setState({ businessStateError: m.formError.state.required });
					formValid = false;
				}
			}
		}

		return formValid;
	}
	render() {
		let choices = this.props.profileUser.data.choices;

		const prefixList = choices.prefix.map((item) => {
			return { value: parseInt(item.id, 10), label: item.prefix }
		});
		const genderList = choices.gender.map((item) => {
			return { value: parseInt(item.id, 10), label: (item.name === 'Unknown' ? 'Other' : item.name) }
		});
		const daysList = getSelectDays();
		const monthsList = getSelectMonths();
		const yearsList = getSelectYears();
		let stateList = choices.state.map((item) => {
			return { value: parseInt(item.id, 10), label: item.full_name, country_id: parseInt(item.country_id, 10) }
		});
		//filter by country 
		let businessStateList = stateList.filter((item) => item.country_id === this.state.businessCountryValue);
		let homeStateList = stateList.filter((item) => item.country_id === this.state.homeCountryValue);
		
		const countryList = choices.country.map((item) => {
			return { value: parseInt(item.id, 10), label: item.country }
		});
		return (
			<div className="edit-modal" id="edit-account-details-professional-details">
				<form onSubmit={this.handleSubmit}>
					<div className="header">
						<div className="row">
							<div className="col-md-12">
								<h4 className="m-0">Edit Personal Information</h4>
							</div>
						</div>
					</div>
					<div className="content">
						<div className="row">
							<div className="col-md-12">
								<div className={"form-group" + (this.state.prefixError ? ' has-error' : '')}>
									<label htmlFor="prefix">Title</label>
									<Select
										id="prefix"
										name="prefix"
										className="custom-react-select"
										value={prefixList.find(item => item.value === this.state.prefixValue || null)}
										options={prefixList}
										onChange={this.handleSelectChange}
										isClearable
									/>
									{this.state.prefixError
										? <p className="form-error">{this.state.prefixError}</p>
										: null
									}
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col-md-6">
								<div className={"form-group" + (this.state.firstNameError ? ' has-error' : '')}>
									<label htmlFor="firstName">First Name</label>
									<input
										type="text"
										id="firstName"
										name="firstName"
										readOnly="readonly"
										value={this.state.firstNameValue}
										onChange={this.handleFieldChange}
										className="form-control"
									/>
									{this.state.firstNameError
										? <p className="form-error">{this.state.firstNameError}</p>
										: null
									}
								</div>
							</div>
							<div className="col-md-6">
								<div className={"form-group" + (this.state.lastNameError ? ' has-error' : '')}>
									<label htmlFor="lastName">Last Name</label>
									<input
										type="text"
										id="lastName"
										name="lastName"
										readOnly="readonly"
										value={this.state.lastNameValue}
										onChange={this.handleFieldChange}
										className="form-control"
									/>
									{this.state.lastNameError
										? <p className="form-error">{this.state.lastNameError}</p>
										: null
									}
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col-md-6">
								<div className={"form-group" + (this.state.preferredNameError ? ' has-error' : '')}>
									<label htmlFor="preferredName">
										{"Preferred Name "}
										<Tooltip
											placement="topLeft"
											overlay={<TooltipEditPersonalInfo />}
										>
											<i className="fas fa-info-circle"></i>
										</Tooltip>
									</label>
									<input
										type="text"
										id="preferredName"
										name="preferredName"
										value={this.state.preferredNameValue}
										onChange={this.handleFieldChange}
										className="form-control"
									/>
									{this.state.preferredNameError
										? <p className="form-error">{this.state.preferredNameError}</p>
										: null
									}
								</div>
							</div>
							<div className="col-md-6">
								<div className={"form-group" + (this.state.genderError ? ' has-error' : '')}>
									<div><label>Gender</label></div>
									<div className="btn-group btn-group-toggle">
										{genderList.map((option, index) => 
											<label key={index} className={"btn btn-secondary" + (parseInt(this.state.genderValue, 10) === option.value ? ' active' : '')}>
												<input
													type="radio"
													name="gender"
													id={"gender-" + option.label }
													autoComplete="off"
													onChange={this.handleFieldChange}
													value={option.value}
													checked={parseInt(this.state.genderValue, 10) === option.value}
												/>
												{option.label}
											</label>
										)}
									</div>
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col-md-12">
								<h4>Birthday</h4>
							</div>
						</div>
						<div className="row">
							<div className="col-md-4">
								<div className={"form-group" + (this.state.DOBDayError ? ' has-error' : '')}>
									<label htmlFor="DOBDay">Day</label>
									<Select
										id="DOBDay"
										name="DOBDay"
										className="custom-react-select"
										value={daysList.find(item => item.value === this.state.DOBDayValue || null)}
										options={daysList}
										onChange={this.handleSelectChange}
										isClearable
										isDisabled={this.props.profileUser.data.content.date_of_birth_at ? true : false}
									/>
									{this.state.DOBDayError
										? <p className="form-error">{this.state.DOBDayError}</p>
										: null
									}
								</div>
							</div>
							<div className="col-md-4">
								<div className={"form-group" + (this.state.DOBMonthError ? ' has-error' : '')}>
									<label htmlFor="DOBMonth">Month</label>
									<Select
										id="DOBMonth"
										name="DOBMonth"
										className="custom-react-select"
										value={monthsList.find(item => item.value === this.state.DOBMonthValue || null)}
										options={monthsList}
										onChange={this.handleSelectChange}
										isClearable
										isDisabled={this.props.profileUser.data.content.date_of_birth_at ? true : false}
									/>
									{this.state.DOBMonthError
										? <p className="form-error">{this.state.DOBMonthError}</p>
										: null
									}
								</div>
							</div>
							<div className="col-md-4">
								<div className={"form-group" + (this.state.DOBYearError ? ' has-error' : '')}>
									<label htmlFor="DOBYear">Year</label>
									<Select
										id="DOBYear"
										name="DOBYear"
										className="custom-react-select"
										value={yearsList.find(item => item.value === this.state.DOBYearValue || null)}
										options={yearsList}
										onChange={this.handleSelectChange}
										isClearable
										isDisabled={this.props.profileUser.data.content.date_of_birth_at ? true : false}
									/>
									{this.state.DOBYearError
										? <p className="form-error">{this.state.DOBYearError}</p>
										: null
									}
								</div>
							</div>
							{this.props.profileUser.data.content.date_of_birth_at
								?
									<div className="col-md-12 mb-3">
									<small>To update your birth date,  please contact the customer service department on <a href="tel:1300829338">1300 829 338</a> or email <a href="mailto:membership@taxinstitute.com.au">membership@taxinstitute.com.au</a></small>
									</div>
								:	null
							}
						</div>
						<div className="row">
							<div className="col-md-12">
								<div className={"form-group" + (this.state.emailError ? ' has-error' : '')}>
									<label htmlFor="email">Email</label>
									<input
										type="text"
										id="email"
										name="email"
										value={this.state.emailValue}
										onChange={this.handleFieldChange}
										className="form-control"
									/>
									{this.state.emailError
										? <p className="form-error">{this.state.emailError}</p>
										: null
									}
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col-md-6">
								<div className={"form-group" + (this.state.phoneError ? ' has-error' : '')}>
									<label htmlFor="phone">Phone</label>
									<input
										type="text"
										id="phone"
										name="phone"
										value={this.state.phoneValue}
										onChange={this.handleFieldChange}
										className="form-control"
									/>
									{this.state.phoneError
										? <p className="form-error">{this.state.phoneError}</p>
										: null
									}
								</div>
							</div>
							<div className="col-md-6">
								<div className={"form-group" + (this.state.faxError ? ' has-error' : '')}>
									<label htmlFor="fax">Fax</label>
									<input
										type="text"
										id="fax"
										name="fax"
										value={this.state.faxValue}
										onChange={this.handleFieldChange}
										className="form-control"
									/>
									{this.state.faxError
										? <p className="form-error">{this.state.faxError}</p>
										: null
									}
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col-md-12">
								<h4>Home Address</h4>
							</div>
						</div>
						<div className="row">
							<div className="col-md-12">
								<div className={"form-group" + (this.state.homeAddress1Error ? ' has-error' : '')}>
									<label htmlFor="homeAddress1">Address 1</label>
									<input
										type="text"
										id="homeAddress1"
										name="homeAddress1"
										value={this.state.homeAddress1Value}
										onChange={this.handleFieldChange}
										className="form-control"
									/>
									{this.state.homeAddress1Error
										? <p className="form-error">{this.state.homeAddress1Error}</p>
										: null
									}
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col-md-12">
								<div className={"form-group" + (this.state.homeAddress2Error ? ' has-error' : '')}>
									<label htmlFor="homeAddress2">Address 2</label>
									<input
										type="text"
										id="homeAddress2"
										name="homeAddress2"
										value={this.state.homeAddress2Value}
										onChange={this.handleFieldChange}
										className="form-control"
									/>
									{this.state.homeAddress2Error
										? <p className="form-error">{this.state.homeAddress2Error}</p>
										: null
									}
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col-md-6">
								<div className={"form-group" + (this.state.homeCityError ? ' has-error' : '')}>
									<label htmlFor="homeCity">City</label>
									<input
										type="text"
										id="homeCity"
										name="homeCity"
										value={this.state.homeCityValue}
										onChange={this.handleFieldChange}
										className="form-control"
									/>
									{this.state.homeCityError
										? <p className="form-error">{this.state.homeCityError}</p>
										: null
									}
								</div>
							</div>
							<div className="col-md-6">
								<div className={"form-group" + (this.state.homePostcodeError ? ' has-error' : '')}>
									<label htmlFor="homePostcode">Postcode</label>
									<input
										type="text"
										id="homePostcode"
										name="homePostcode"
										value={this.state.homePostcodeValue}
										onChange={this.handleFieldChange}
										className="form-control"
									/>
									{this.state.homePostcodeError
										? <p className="form-error">{this.state.homePostcodeError}</p>
										: null
									}
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col-md-6">
								<div className={"form-group" + (this.state.homeStateError ? ' has-error' : '')}>
									<label htmlFor="homeState">State</label>
									<Select
										id="homeState"
										name="homeState"
										className="custom-react-select"
										value={homeStateList.find(item => item.value === this.state.homeStateValue) || null}
										options={homeStateList}
										onChange={this.handleSelectChange}
										isClearable
										isDisabled={homeStateList.length > 0 ? false : true}
									/>
									{this.state.homeStateError
										? <p className="form-error">{this.state.homeStateError}</p>
										: null
									}
								</div>
							</div>
							<div className="col-md-6">
								<div className={"form-group" + (this.state.homeCountryError ? ' has-error' : '')}>
									<label htmlFor="homeCountry">Country</label>
									<Select
										id="homeCountry"
										name="homeCountry"
										className="custom-react-select"
										defaultValue={countryList.find(item => item.value === this.state.homeCountryValue) || null}
										options={countryList}
										onChange={this.handleSelectChange}
										isClearable
									/>
									{this.state.homeCountryError
										? <p className="form-error">{this.state.homeCountryError}</p>
										: null
									}
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col-md-12">
								<h4>Business Address</h4>
							</div>
						</div>
						<div className="row">
							<div className="col-md-12">
								<div className={"form-group" + (this.state.businessAddressError ? ' has-error' : '')}>
									<label htmlFor="businessAddress">Address</label>
									<input
										type="text"
										id="businessAddress"
										name="businessAddress"
										value={this.state.businessAddressValue}
										onChange={this.handleFieldChange}
										className="form-control"
									/>
									{this.state.businessAddressError
										? <p className="form-error">{this.state.businessAddressError}</p>
										: null
									}
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col-md-6">
								<div className={"form-group" + (this.state.businessCityError ? ' has-error' : '')}>
									<label htmlFor="businessCity">City</label>
									<input
										type="text"
										id="businessCity"
										name="businessCity"
										value={this.state.businessCityValue}
										onChange={this.handleFieldChange}
										className="form-control"
									/>
									{this.state.businessCityError
										? <p className="form-error">{this.state.businessCityError}</p>
										: null
									}
								</div>
							</div>
							<div className="col-md-6">
								<div className={"form-group" + (this.state.businessPostcodeError ? ' has-error' : '')}>
									<label htmlFor="businessPostcode">Postcode</label>
									<input
										type="text"
										id="businessPostcode"
										name="businessPostcode"
										value={this.state.businessPostcodeValue}
										onChange={this.handleFieldChange}
										className="form-control"
									/>
									{this.state.businessPostcodeError
										? <p className="form-error">{this.state.businessPostcodeError}</p>
										: null
									}
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col-md-6">
								<div className={"form-group" + (this.state.businessStateError ? ' has-error' : '')}>
									<label htmlFor="businessState">State</label>
									<Select
										id="businessState"
										name="businessState"
										className="custom-react-select"
										value={businessStateList.find(item => item.value === this.state.businessStateValue) || null}
										options={businessStateList}
										onChange={this.handleSelectChange}
										isClearable
										isDisabled={businessStateList.length > 0 ? false : true}
									/>
									{this.state.businessStateError
										? <p className="form-error">{this.state.businessStateError}</p>
										: null
									}
								</div>
							</div>
							<div className="col-md-6">
								<div className={"form-group" + (this.state.businessCountryError ? ' has-error' : '')}>
									<label htmlFor="businessCountry">Country</label>
									<Select
										id="businessCountry"
										name="businessCountry"
										className="custom-react-select"
										defaultValue={countryList.find(item => item.value === this.state.businessCountryValue) || null}
										options={countryList}
										onChange={this.handleSelectChange}
										isClearable
									/>
									{this.state.businessCountryError
										? <p className="form-error">{this.state.businessCountryError}</p>
										: null
									}
								</div>
							</div>
						</div>
						
					</div>
					<div className="footer">
						<div className="row">
							<div className="col-md-12">
								{this.props.profileUser.saving
									?
										<span>Saving <i className="fas fa-spinner fa-spin"></i></span>
									:
										<span>
											{!this.state.dataSaved
												?
													<span>
														<button
														disabled={this.props.profileUser.saving}
															type="button" 
															onClick={() => this.props.hide('edit-account-details-personal-info')}
															className="btn btn-link-secondary"
														>
															Cancel
														</button>
														<button
															disabled={this.props.profileUser.saving}
															type="submit"
															value="Save"
															className="btn btn-link"
														>
															Save
														</button>
													</span>
												: null
											}
											{this.state.responseMessage
												? <p className="response-message">{this.state.responseMessage}</p>
												: null
											}
										</span>
								}
							</div>
						</div>
					</div>
				</form>
			</div>
		)
	}
}
function mapStateToProps(state) {
	return {
		profileUser: state.apiData.profile_user,
	}
}
function mapDispatchToProps(dispatch) {
	return {
		...bindActionCreators({ hide }, dispatch),
		dispatch
	};
}
export default connect(mapStateToProps, mapDispatchToProps)(EditPersonalInfo);