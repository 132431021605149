import React, { Component } from 'react';
import { connect } from 'react-redux';
import { hide } from 'redux-modal';
import { bindActionCreators } from 'redux';
import Select from 'react-select';

import { actionApiSendData } from '../../../actions/apiData/index';
// import * as m from '../../../common/_messages';
import * as c from '../../../common/_constants';
import { handleGaTracking } from '../../../common/_utility';

class EditInterests extends Component {
	constructor(props) {
		super(props);

		let profile = this.props.profileUser.data.content;

		this.state = {
			interestsValue: profile.interests,
			interestsError: false,
			responseMessage: false,
			dataSaved: false,
		}

		this.handleSelectMultiChange = this.handleSelectMultiChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.isFormValid = this.isFormValid.bind(this);

	}
	handleSelectMultiChange(optionSelected, { name }) {
		optionSelected
			? this.setState({ [name + 'Value']: optionSelected.map(item => item.value) })
			: this.setState({ [name + 'Value']: null })
	}
	handleSubmit(e) {

		e.preventDefault();

		//validation
		if (!this.isFormValid()) {
			return;
		}

		let data = {
			interests: this.state.interestsValue
		}

		handleGaTracking('Profile page - Interests Save', 'Interests Save');

		this.props.dispatch(
			actionApiSendData(c.API_DATA.profile_user.key, data)
		).then((success) => {
			if (success) {
				this.setState({
					responseMessage: 'Saved',
					dataSaved: true
				});
				//close popup after 3 secs
				let hidePopup = this.props.hide;
				setTimeout(
					function () {
						hidePopup('edit-account-details-interests')
					}, 2000
				);
			} else {
				this.setState({ responseMessage: 'something went wrong' });
			}
		});
	}
	isFormValid() {

		let formValid = true;

		//reset error states
		this.setState({
			interestsError: false,
			responseMessage: false
		});

		// if (!this.state.interestsValue.length) {
		// 	this.setState({ interestsError: m.formError.interests.required });
		// 	formValid = false;
		// }

		return formValid;
	}
	render() {
		let choices = this.props.profileUser.data.choices;

		const interestsList = choices.interests.map((item) => {
			return { value: parseInt(item.id, 10), label: item.name }
		});
		return (
			<div className="edit-modal" id="edit-account-details-interests">
				<form onSubmit={this.handleSubmit}>
					<div className="header">
						<div className="row">
							<div className="col-md-12">
								<h4 className="m-0">Edit Interests</h4>
							</div>
						</div>
					</div>
					<div className="content">
						<div className="row">
							<div className="col-md-12">
								<div className={"form-group" + (this.state.interestsError ? ' has-error' : '')}>
									<label htmlFor="interests">Interests</label>
									<Select
										id="interests"
										className="custom-react-select multiple-select"
										name="interests"
										value={this.state.interestsValue.map(option => interestsList.find(item => item.value === option))}
										options={interestsList}
										onChange={this.handleSelectMultiChange}
										isClearable
										isMulti
									/>
									{this.state.interestsError
										? <p className="form-error">{this.state.interestsError}</p>
										: null
									}
								</div>
							</div>
						</div>
					</div>
					<div className="footer">
						<div className="row">
							<div className="col-md-12">
								{this.props.profileUser.saving
									?
										<span>Saving <i className="fas fa-spinner fa-spin"></i></span>
									:
										<span>
											{!this.state.dataSaved
												?
													<span>
														<button
														disabled={this.props.profileUser.saving}
															className="btn btn-link-secondary"
															type="button"
															onClick={() => this.props.hide('edit-account-details-interests')}
														>
															Cancel
														</button>
														<button
															disabled={this.props.profileUser.saving}
															className="btn btn-link"
															type="submit"
															value="Save"
														>
															Save
														</button>
													</span>
												: null
											}
											{this.state.responseMessage
												? <p className="response-message">{this.state.responseMessage}</p>
												: null
											}
										</span>
								}
							</div>
						</div>
					</div>
				</form>
			</div>
		)
	}
}
function mapStateToProps(state) {
	return {
		profileUser: state.apiData.profile_user,
	}
}
function mapDispatchToProps(dispatch) {
	return {
		...bindActionCreators({ hide }, dispatch),
		dispatch
	};
}
export default connect(mapStateToProps, mapDispatchToProps)(EditInterests);