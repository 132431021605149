import React, { Component } from 'react';
// import { LinkedIn } from 'react-linkedin-login-oauth2';
import { connect } from 'react-redux';

// import * as c from '../../../common/_constants';
import { retrieveApiData } from '../../../actions/apiData/index';

import UserProfilePicture from '../../common/UserProfilePicture';
import DoughnutChart from '../../common/DoughnutChart';
// import EditAccountImage from './EditAccountImage';

class TopInfo extends Component {

	constructor(props) {
		super(props);

		this.state = {
			
		}

		this.handleSuccess = this.handleSuccess.bind(this);
		this.handleFailure = this.handleFailure.bind(this);

	}
	handleSuccess(data) {
		// console.log('handleSuccess');
		// console.log(data.code);

		if(data.code){
			let url = 'http://tti-member-portal.localdev/api/v1/user/linked_in/?code=' + data.code;

			retrieveApiData(this.props.dispatch, url).then((response) => {
				// console.log(response);
			})
		}

	}
	handleFailure(error) {
		// console.log('handleFailure');
		// console.log(error);
	}
	render() {
		const {
			profile,
			choices,
			// show,
			showCpdWheel,
			globalUser
		} = this.props;

		let prefixChoice = choices.prefix.find(item => parseInt(item.id, 10) === profile.prefix_id);
		let prefix = prefixChoice ? prefixChoice.prefix : null;

		let stateChoice = choices.state.find(item => parseInt(item.id, 10) === profile.state_id);
		let state = stateChoice ? stateChoice.abbreviation : null;

		let countryChoice = choices.country.find(item => parseInt(item.id, 10) === profile.country_id);
		let country = countryChoice ? countryChoice.country : null;

		let fullAddress;

		fullAddress = [profile.city, state].join(', ');
		fullAddress = [fullAddress, country].join(' ');

		// let percentageCPD = (globalUser.cpd_hours * 100) / globalUser.cpd_hours_required;

		// if (percentageCPD > 100) {
		// 	percentageCPD = 100;
		// }
		// if (percentageCPD < 0) {
		// 	percentageCPD = 0;
		// }

		return (
			<div className="top-info">
				<div className="row">
					<div className="col-md-2">
						<UserProfilePicture />

						{/* <button
							type="button"
							className="btn-linkedin"
							onClick={() =>
								show(
									'edit-account-details-profile-image',
									{ content: <EditAccountImage /> }
								)
							}
						>
							Add your Profile Picture
						</button> */}

						{/* TODO 
						{profile.avatar
							? 'Remove'
							:
							<LinkedIn
								clientId={c.LINKEDIN_CLIENT_ID}
								onFailure={this.handleFailure}
								onSuccess={this.handleSuccess}
								redirectUri={c.LINKEDIN_REDIRECT_URL}
	              scope="r_liteprofile"
							>
								Add your LinkedIn Profile Picture
							</LinkedIn>
						}
						*/}

					</div>
					<div className="col-md-8 account-details-top">
						<h3 className="name mb-0">
							{[
								prefix,
								profile.full_name
							].join(' ')}
						</h3>
						<h4 className="role text-grey-dark mb-0">{profile.position_title}</h4>
						<div className="location mb-0">
							{fullAddress}
						</div>
					</div>
					{showCpdWheel
						?
							<div className="col-md-2 text-center">
								<h6 className="mb-1">CPD Hours</h6>
								<div className="wheel-wrapper">
									<DoughnutChart
										cpdHoursTotal={globalUser.cpd_hours.total}
										cpdHoursStructured={globalUser.cpd_hours.structured}
										cpdHoursUnStructured={globalUser.cpd_hours.unstructured}
										cpdHoursRequired={globalUser.cpd_hours_required}
										stroke={11}
										wheelBgColor={'#ffffff'}
										structuredColor={'#ffc400'}
										unstructuredColor={'#b8a463'}
										transitionDurationInSeconds={1.9}
										fullLegend={false}
									>
									</DoughnutChart>
								</div>
							</div>
						:	null
					}
				</div>
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		showCpdWheel: state.app.global.global_user.data.content.membership_data.show_cpd_wheel,
		globalUser: state.app.global.global_user.data.content,
	}
}

export default connect(mapStateToProps)(TopInfo);