import React from 'react';
import { CircularProgressbarWithChildren, CircularProgressbar, buildStyles } from 'react-circular-progressbar';

import ProgressProvider from './ProgressProvider';

const DoughnutChart = ({
	cpdHoursTotal,
	cpdHoursStructured,
	cpdHoursUnStructured,
	cpdHoursRequired,
	wheelBgColor,
	structuredColor,
	unstructuredColor,
	stroke,
	transitionDurationInSeconds,
	fullLegend
}) => {

	let percentageTotal = (cpdHoursTotal * 100) / cpdHoursRequired;

	if (percentageTotal > 100) {
		percentageTotal = 100;
	}
	if (percentageTotal < 0) {
		percentageTotal = 0;
	}

	let ratio = percentageTotal / 100;
	let percentageUnstructured = ((cpdHoursUnStructured * 100) / cpdHoursTotal) * ratio;

	return (
		<div>
			<ProgressProvider valueStart={0} valueEnd={percentageTotal - percentageUnstructured}>
				{(value) =>
					<CircularProgressbarWithChildren
						value={value + percentageUnstructured}
						strokeWidth={stroke}
						styles={buildStyles({
							pathColor: unstructuredColor,
							trailColor: wheelBgColor,
							strokeLinecap: 'butt',
							pathTransitionDuration: transitionDurationInSeconds,
						})}
					>
						{/* Foreground path */}
						<CircularProgressbar
							value={value}
							strokeWidth={stroke}
							styles={buildStyles({
								trailColor: 'transparent',
								pathColor: structuredColor,
								strokeLinecap: 'butt',
								pathTransitionDuration: transitionDurationInSeconds,
							})}
						/>
					</CircularProgressbarWithChildren>
				}
			</ProgressProvider>
			<div className="cpd-wheel-container">
				<div className="cpd-wheel1">CPD</div>
				<div className="cpd-wheel2">{cpdHoursTotal}</div>
				{fullLegend
					? <div className="cpd-wheel3">out of {cpdHoursRequired}</div>
					: null	
				}
				{fullLegend && cpdHoursUnStructured
					?
						<div className="color-legend">
						<p><span style={{ backgroundColor: structuredColor }} className="color"></span>Structured ({cpdHoursStructured} hr{cpdHoursStructured > 1 ? 's' : null})</p>
						<p><span style={{ backgroundColor: unstructuredColor }} className="color"></span>Unstructured ({cpdHoursUnStructured} hr{cpdHoursUnStructured > 1 ? 's' : null})</p>
						</div>
					:	null
				}
			</div>
		</div>
		
	);
}

export default DoughnutChart;