import React, { Component } from 'react';
import { handleGaTracking } from '../../common/_utility';

class VideoListItem extends Component {
	
	render() {
		const {
			item
		} = this.props;
		return (
			<div className="item" style={{ display: "inline-block", padding: "0 5px" }} key={item.id}>
				<a onClick={() => handleGaTracking('Dashboard - Click Video Item', item.title)} href={item.media.link} rel="noopener noreferrer" target="_blank">
					<img className="img-fluid" src={item.media.image_links.poster} alt="" />
				</a>
			</div>
		);
	}
}

export default VideoListItem;
