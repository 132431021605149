import React, { Component } from 'react';
import { connect } from 'react-redux';
import { hide } from 'redux-modal';
import { bindActionCreators } from 'redux';
import Select from 'react-select';
import AsyncCreatableSelect from 'react-select/lib/AsyncCreatable';

import { actionApiSendData } from '../../../actions/apiData/index';
// import * as m from '../../../common/_messages';
import * as c from '../../../common/_constants';
import { handleGaTracking } from '../../../common/_utility';

class EditBusinessInformation extends Component {
	constructor(props) {
		super(props);

		let profile = this.props.profileUser.data.content;

		this.state = {
			companyNameValue: profile.company_name,
			companyNameError: false,
			positionTitleValue: profile.position_title,
			positionTitleError: false,
			specialisationValue: profile.specialisation,
			specialisationError: false,
			primaryJobValue: profile.primary_job_function_id,
			primaryJobError: false,
			responseMessage: false,
			dataSaved: false,
		}

		this.handleFieldChange = this.handleFieldChange.bind(this);
		this.handleSelectMultiChange = this.handleSelectMultiChange.bind(this);
		this.handleSelectChange = this.handleSelectChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.isFormValid = this.isFormValid.bind(this);

	}
	handleFieldChange(e) {
		const target = e.target;
		const value = target.type === 'checkbox' ? target.checked : target.value;
		const name = target.name;

		this.setState({ [name + 'Value']: value });
	}
	handleSelectMultiChange(optionSelected, { name }) {
		optionSelected
			? this.setState({ [name + 'Value']: optionSelected.map(item => item.value) })
			: this.setState({ [name + 'Value']: null })
	}
	handleSelectChange(optionSelected, { name }) {
		optionSelected
			? this.setState({ [name + 'Value']: optionSelected.value })
			: this.setState({ [name + 'Value']: null })
	}
	handleSubmit(e) {

		e.preventDefault();

		//validation
		if (!this.isFormValid()) {
			return;
		}

		let company = '';
		if (this.state.companyNameValue && this.state.companyNameValue !== null){
			company = this.state.companyNameValue
		}

		let data = {
			company_name: company,
			position_title: this.state.positionTitleValue,
			specialisation: this.state.specialisationValue,
			primary_job_function_id: this.state.primaryJobValue,
		}

		handleGaTracking('Profile page - Business Information Save', 'Business Information Save');
		
		this.props.dispatch(
			actionApiSendData(c.API_DATA.profile_user.key, data)
		).then((success) => {
			if (success){
				this.setState({ 
					responseMessage: 'Saved' ,
					dataSaved : true
				});
				//close popup after 3 secs
				let hidePopup = this.props.hide;
				setTimeout(
					function () { 
						hidePopup('edit-account-details-business-information')
					}, 2000
				);
			}else{
				this.setState({ responseMessage: 'something went wrong' });
			}
		});
	}
	isFormValid() {

		let formValid = true;

		//reset error states
		this.setState({
			companyNameError: false,
			positionTitleError: false,
			specialisationError: false,
			primaryJobError: false,
			responseMessage: false
		});

		// if (!this.state.companyNameValue) {
		// 	this.setState({ companyNameError: m.formError.companyName.required });
		// 	formValid = false;
		// }
		// if (!this.state.positionTitleValue) {
		// 	this.setState({ positionTitleError: m.formError.positionTitle.required });
		// 	formValid = false;
		// }
		// if (!this.state.specialisationValue.length) {
		// 	this.setState({ specialisationError: m.formError.specialisation.required });
		// 	formValid = false;
		// }
		// if (!this.state.primaryJobValue) {
		// 	this.setState({ primaryJobError: m.formError.primaryJobFunction.required });
		// 	formValid = false;
		// }

		return formValid;
	}
	getOptions = (inputValue, callback) => {
		if (!inputValue) {
			return callback([]);
		}

		const fetchURL = c.API_BASE_URL + 'companySearch.php?term=' + encodeURI(inputValue);

		fetch(fetchURL)
		.then(response => response.json())
		.then(response => {
			callback(response);
		})
		.catch(err => {
			callback([]);
		});
	};
	handleCreate = (inputValue) => {
		this.setState({
			companyNameValue: inputValue
		});
	};
	render() {
		let choices = this.props.profileUser.data.choices;

		const specialisationList = choices.specialisation.map((item) => {
			return { value: parseInt(item.id, 10), label: item.name }
		});
		const primaryJobList = choices.primary_job_function.map((item) => {
			return { value: parseInt(item.id, 10), label: item.name }
		});

		return (
			<div className="edit-modal" id="edit-account-details-business-information">
				<form onSubmit={this.handleSubmit}>
					<div className="header">
						<div className="row">
							<div className="col-md-12">
								<h4 className="m-0">Edit Business Information</h4>
							</div>
						</div>
					</div>
					<div className="content">
						{/* <div className="row">
							<div className="col-md-12">
								<div className={"form-group" + (this.state.companyNameAutocompleteError ? ' has-error' : '')}>
									<label htmlFor="companyNameAutocomplete">Company Autocomplete</label>
									<AsyncCreatableSelect
										id="companyNameAutocomplete"
										className="custom-react-select multiple-select"
										name="companyNameAutocomplete"
										loadOptions={this.getOptions}
										onChange={this.handleSelectChange}
										isClearable
										cacheOptions
										defaultOptions
									/>
									{this.state.companyNameAutocompleteError
										? <p className="form-error">{this.state.companyNameAutocompleteError}</p>
										: null
									}
								</div>
							</div>
						</div> */}
						<div className="row">
							<div className="col-md-6">
								<div className={"form-group" + (this.state.companyNameError ? ' has-error' : '')}>
									<label htmlFor="companyName">Company</label>
									<AsyncCreatableSelect
										id="companyName"
										name="companyName"
										className="custom-react-select multiple-select"
										loadOptions={this.getOptions}
										onChange={this.handleSelectChange}
										value={{ value: this.state.companyNameValue, label: this.state.companyNameValue }}
										onCreateOption={this.handleCreate}
										isClearable
										cacheOptions
										defaultOptions
									/>
									{this.state.companyNameError
										? <p className="form-error">{this.state.companyNameError}</p>
										: null
									}
								</div>
							</div>
							<div className="col-md-6">
								<div className={"form-group" + (this.state.positionTitleError ? ' has-error' : '')}>
									<label htmlFor="positionTitle">Position Title</label>
									<input
										type="text"
										id="positionTitle"
										name="positionTitle"
										value={this.state.positionTitleValue}
										onChange={this.handleFieldChange}
										className="form-control"
									/>
									{this.state.positionTitleError
										? <p className="form-error">{this.state.positionTitleError}</p>
										: null
									}
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col-md-6">
								<div className={"form-group" + (this.state.specialisationError ? ' has-error' : '')}>
									<label htmlFor="specialisation">Specialisations</label>
									<Select
										id="specialisation"
										className="custom-react-select multiple-select"
										name="specialisation"
										value={this.state.specialisationValue.map(option => specialisationList.find(item => item.value === option))}
										options={specialisationList}
										onChange={this.handleSelectMultiChange}
										isClearable
										isMulti
									/>
									{this.state.specialisationError
										? <p className="form-error">{this.state.specialisationError}</p>
										: null
									}
								</div>
							</div>
							<div className="col-md-6">
								<div className={"form-group" + (this.state.primaryJobError ? ' has-error' : '')}>
									<label htmlFor="primaryJob">Primary Job Function</label>
									<div className="input-select">
										<Select
											id="primaryJob"
											name="primaryJob"
											className="custom-react-select"
											value={primaryJobList.find(item => item.value === this.state.primaryJobValue) || null}
											options={primaryJobList}
											onChange={this.handleSelectChange}
											isClearable
										/>
									</div>
									{this.state.primaryJobError
										? <p className="form-error">{this.state.primaryJobError}</p>
										: null
									}
								</div>
							</div>
						</div>
					</div>
					<div className="footer">
						<div className="row">
							<div className="col-md-12">
								{this.props.profileUser.saving
									? 
										<span>Saving <i className="fas fa-spinner fa-spin"></i></span>
									: 
										<span>
											{!this.state.dataSaved
												?
													<span>
														<button
															disabled={this.props.profileUser.saving}
															className="btn btn-link-secondary"
															type="button"
															onClick={() => this.props.hide('edit-account-details-business-information')}
														>
															Cancel
														</button>
														<button
															disabled={this.props.profileUser.saving}
															className="btn btn-link"
															type="submit"
															value="Save"
														>
															Save
														</button>
													</span>
												:
													null
											}

											{this.state.responseMessage
												? <p className="response-message">{this.state.responseMessage}</p>
												: null
											}

										</span>

								}
								
							</div>
						</div>
					</div>
				</form>
			</div>
		)
	}
}
function mapStateToProps(state) {
	return {
		profileUser: state.apiData.profile_user,
	}
}
function mapDispatchToProps(dispatch) {
	return {
		...bindActionCreators({ hide }, dispatch),
		dispatch
	};
}
export default connect(mapStateToProps, mapDispatchToProps)(EditBusinessInformation);