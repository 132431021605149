import React from 'react';

import EditAbout from './EditAbout';
import InformationMissing from '../../common/InformationMissing';

import { handleGaTracking } from '../../../common/_utility';

const About = ({
	profile,
	show,
}) => {
	return (
		<div className="social">
			<div className="row">
				<div className="col-md-12">
					<div className="tax-specialist-about mt-5 p-4">
						<div className="row">
							<div className="col-md-8">
								<h4>About</h4>
							</div>
							<div className="col-md-4 text-right">
								<button
									type="button"
									className="btn btn-link text-base-color trigger-edit"
									onClick={() => {
										handleGaTracking('Tax Specialist Profile page - Edit About', 'Edit About');
										show(
											'edit-tax-specialist-profile-about',
											{ content: <EditAbout /> }
										)
									}}
								>
									<i className="fas fa-pen"></i>
								</button>
							</div>
						</div>
						<p className="tax-specialist-about-details">
							{profile.about
								? profile.about
								: <InformationMissing />
							}
						</p>
					</div>
				</div>
			</div>
		</div>
	);
}

export default About;