import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { formatDate } from '../../common/_utility';
import * as r from '../../common/_routes';
class ActivityListItem extends Component {
	
	render() {
		const {
			item
		} = this.props;

		let label;
		let url;
		let urlType;

		if (!item.items)
		{
			return "";
		}
		
		switch (item.type) {
			case 'order':
				if (item.items.length > 1){
					label = 'Purchased ' + item.items.length + ' products'
				}else{
					label = 'Purchased ' + item.items[0].description
				}
				url = r.URL_PROFILE_ORDER_DETAILS + item.item_id;
				urlType = 'internal';
				break;
			case 'download':
				if (item.items.length > 1){
					label = item.items.length + ' items downloaded'
				}else{
					label = item.items[0].description + ' download'
				}
				url = item.items[0].item_link;
				urlType = 'external';
				break;
		
			default:
				break;
		}

		return (
			<div className="item">
				<div className="row">
					<div className="col-md-8">
						{urlType === 'internal'
							? 
								<Link to={url}>
									{label}
								</Link>
							: 
								<a href={url} rel="noopener noreferrer" target="_blank">
									{label}
								</a>
						}
						
					</div>
					<div className="col-md-4 text-right">
						{formatDate(item.actioned_at, 'j F, Y')}
					</div>
				</div>
				<hr className="m-0" />
			</div>
		);
	}
}

export default ActivityListItem;
