import React from 'react';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

import ListItem from './ListItem';
// import CategoryList from './CategoryList';
// import { isset } from '../../../common/_utility';

const CategoryList = ({
	category,
	onSelectVideo
}) => {
	var carouselSettings = {
		dots: false,
		infinite: false,
		speed: 500,
		slidesToShow: 4,
		slidesToScroll: 4,
		// afterChange: (activeSlide) => {
		// 	this.setState({ activeSlide: activeSlide });
		// },
	};
	return (
		<div className="video-category-gallery">
			<div className="row">
				<div className="col-md-12">
					<h6>{category.name} ({category.count})</h6>
					<div className="video-carousel">
						{Object.keys(category.items).length > 0
							? 	
								<Slider {...carouselSettings}>
									{Object.keys(category.items).map((keyName, index) =>
										<ListItem key={index} item={category.items[keyName]} onSelectVideo={onSelectVideo} />
									)}
								</Slider>
							: <p>No items</p>
						}	
					</div>
				</div>
			</div>
		</div>
	);
}

export default CategoryList;