import React from 'react';

import { getChoicesById, handleGaTracking } from '../../../common/_utility';

import InformationMissing from '../../common/InformationMissing';
import EditBusinessInformation from './EditBusinessInformation';

const BusinessInformation = ({
	profile,
	choices,
	show,
}) => {
	let specialisation = getChoicesById(
		choices.specialisation,
		profile.specialisation
	);
	let primaryJobFunctionChoice = choices.primary_job_function.find(item => parseInt(item.id, 10) === profile.primary_job_function_id);
	let primaryJobFunction = primaryJobFunctionChoice ? primaryJobFunctionChoice.name : null;
	return (
		<div className="business-information card mb-4">
			<div className="card-header">
				<h6><i className="fas fa-building"></i> Business Information</h6>
				<div className="card-header-icon-team">
					<button 
						type="button"
						className="btn trigger-edit btn-link"
						onClick={() => {
							handleGaTracking('Profile page - Edit Business Information', 'Edit Business Information');
							show(
								'edit-account-details-business-information', 
								{ content: <EditBusinessInformation />,  size:"md" }
							);
						}}
					>
						<i className="fas fa-pen"></i>
					</button>
				</div>
			</div>
			
			<div className="card-body">
				<div className="row">
					<div className="col-md-6">
						<div className="info-detail">
							<div className="data-label">Company</div>
							<p>
								{profile.company_name
									? profile.company_name
									: <InformationMissing />
								}
							</p>
						</div>
						<div className="info-detail">
							<div className="data-label">Specialisation</div>
							<p>
								{specialisation.length
									? specialisation.map(item => item.name).join(', ')
									: <InformationMissing />
								}
							</p>
						</div>
					</div>
					<div className="col-md-6">
						<div className="info-detail">
							<div className="data-label">Position Title</div>
							<p>
								{profile.position_title
									? profile.position_title
									: <InformationMissing />
								}
							</p>
						</div>
						<div className="info-detail">
							<div className="data-label">Primary Job Function</div>
							<p>
								{primaryJobFunction
									? primaryJobFunction
									: <InformationMissing />
								}
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default BusinessInformation;