import React from 'react';

// import { formatDate } from '../../../common/_utility';

const ListItem = ({
	item,
	onSelectVideo
}) => {
	return (
		<div className="item" onClick={() => onSelectVideo(item)}>
			<div className="image-container">
				<img src={item.media.image_links.poster} alt=""/>
			</div>
			<div className="text-container">
				{item.title} ({item.access_info.watched ? 'Watched' : 'NOT Watched'})
			</div>
		</div>
		
			
		
	);
}

export default ListItem;