import React from 'react';
import { Route, Switch, withRouter, Redirect } from 'react-router-dom';
import { LinkedInPopUp } from 'react-linkedin-login-oauth2';

import * as r from '../common/_routes';
import * as c from '../common/_constants';

import PrivateOnlyRoute from './common/PrivateOnlyRoute';
import Header from './header/Main';
import LoginForm from './login/Form';
import ForgotPasswordForm from './login/ForgotPassword';
import PageNotFound from './common/PageNotFound';
import TODO from './temp/TODO';
import ModalBox from './common/ModalBox';

import MainDashboard from './dashboard/Main';

import MainEventsUpcoming from './events/events/upcoming/Main';
import MainEventsPast from './events/events/past/Main';
import MainWebinarsUpcoming from './events/webinars/upcoming/Main';
import MainWebinarsPast from './events/webinars/past/Main';

import MainResourcesBooks from './resources/books/Main';
import MainResourcesPapersAndPresentationsPurchased from './resources/papersAndPresentations/purchased/Main';
import MainResourcesPapersAndPresentationsComplimentary from './resources/papersAndPresentations/complimentary/Main';
import MainResourcesPodcasts from './resources/podcasts/Main';
import MainResourcesVideos from './resources/videos/Main';
import MainResourcesCpdOnDemandSelfPacedLearning from './resources/cpdOnDemand/selfPacedLearning/Main';
import MainResourcesCpdOnDemandBundles from './resources/cpdOnDemand/bundles/Main';

// import MainMembershipPaperOfTheMonth from './membership/paperOfTheMonth/Main';
// import MainMembershipSpecialTopic from './membership/specialTopic/Main';
import MainMembershipMentoring from './membership/mentoring/Main';
// import MainMembershipTaxRatesTable from './membership/taxRatesTable/Main';
import MainMembershipTaxVine from './membership/taxvine/Main';
import MainMembershipTaxationInAustralia from './membership/taxationInAustralia/Main';
import MainMembershipTheTaxSpecialist from './membership/theTaxSpecialist/Main';
import MainMembershipAustralianTaxForum from './membership/australianTaxForum/Main';
// import MainMembershipMonthlyTaxUpdate from './membership/monthlyTaxUpdate/Main';
import MainMembershipTaxwiseNewsletter from './membership/taxwiseNewsletter/Main';

import MainMyEnrollment from './education/myEnrollment/Main';

import MainProfileAccountDetails from './profile/accountDetails/Main';
import MainProfileCpdRecordStructured from './profile/cpdRecord/structured/Main';
import MainProfileCpdRecordUnstructured from './profile/cpdRecord/unstructured/Main';
import MainProfileCpdRecordEdit from './profile/cpdRecord/edit/Main';
import MainProfileCpdRecordAdd from './profile/cpdRecord/add/Main';
import MainProfileCpdRecordPrint from './profile/cpdRecord/print/Main';
import MainProfileOrderHistoryOrders from './profile/orderHistory/orders/Main';
import MainProfileOrderHistoryCancelled from './profile/orderHistory/cancelled/Main';
import MainProfileOrderDetails from './profile/orderHistory/details/Main';
import MainProfileRenewMembership from './profile/renewMembership/Main';
import MainProfileSubscriptionsSubscribed from './profile/subscriptions/subscribed/Main';
import MainProfileSubscriptionsExpired from './profile/subscriptions/expired/Main';
import MainProfileTaxSpecialistProfile from './profile/taxSpecialistProfile/Main';
import MainProfileLogos from './profile/logos/Main';
import MainProfileLogout from './profile/logout/Main';

import ReactGA from 'react-ga';

const Main = ({
	auth,
	location
}) => {

	if (c.GA_CODE) {
		ReactGA.pageview(window.location.pathname + window.location.search);
	}

	return (
		<div>
			{auth
				? <header><Header /></header>
				: null
			}
			<div id="main-content" className={((location.pathname.replace(/\//g, "") === r.URL_LOGIN.replace(/\//g, "") ) || (location.pathname.replace(/\//g, "") === r.URL_FORGOTPASSWORD.replace(/\//g, "") ))? 'login-page' : ''}>
				<div className={((location.pathname.replace(/\//g, "") === r.URL_LOGIN.replace(/\//g, "") ) || (location.pathname.replace(/\//g, "") === r.URL_FORGOTPASSWORD.replace(/\//g, "") )) ? 'container-fluid' : 'container'}>
					<div className="row">
						<div className="col-md-12">
							<div className="main-content-wrapper">
								<Switch>

									<Route exact path="/">
										<Redirect to={r.URL_HOME} />
									</Route>

									{/* dashboard */}
									<PrivateOnlyRoute exact path={r.URL_HOME} component={MainDashboard} />
									<PrivateOnlyRoute exact path={r.URL_DASHBOARD} component={MainDashboard} />

									{/* events */}
									<PrivateOnlyRoute exact path={r.URL_EVENTS_MY_EVENTS_UPCOMING} component={MainEventsUpcoming} />
									<PrivateOnlyRoute exact path={r.URL_EVENTS_MY_EVENTS_PAST} component={MainEventsPast} />
									<PrivateOnlyRoute exact path={r.URL_EVENTS_MY_WEBINARS_UPCOMING} component={MainWebinarsUpcoming} />
									<PrivateOnlyRoute exact path={r.URL_EVENTS_MY_WEBINARS_PAST} component={MainWebinarsPast} />

									{/* resources */}
									<PrivateOnlyRoute exact path={r.URL_RESOURCES_BOOKS} component={MainResourcesBooks} />
									<PrivateOnlyRoute exact path={r.URL_RESOURCES_PAPER_AND_PRESENTATIONS_PURCHASED} component={MainResourcesPapersAndPresentationsPurchased} />
									<PrivateOnlyRoute exact path={r.URL_RESOURCES_PAPER_AND_PRESENTATIONS_COMPLIMENTARY} component={MainResourcesPapersAndPresentationsComplimentary} />
									<PrivateOnlyRoute exact path={r.URL_RESOURCES_PODCASTS} component={MainResourcesPodcasts} />
									<PrivateOnlyRoute exact path={r.URL_RESOURCES_VIDEOS} component={MainResourcesVideos} />
									<PrivateOnlyRoute exact path={r.URL_RESOURCES_CPD_ON_DEMAND_SELF_PACED_LEARNING} component={MainResourcesCpdOnDemandSelfPacedLearning} />
									<PrivateOnlyRoute exact path={r.URL_RESOURCES_CPD_ON_DEMAND_BUNDLES} component={MainResourcesCpdOnDemandBundles} />
									

									{/* membership */}
									<PrivateOnlyRoute exact path={r.URL_MEMBERSHIP_MENTORING} component={MainMembershipMentoring} />
									{/* <PrivateOnlyRoute exact path={r.URL_MEMBERSHIP_PAPER_OF_THE_MONTH + ':id?'} component={MainMembershipPaperOfTheMonth} /> */}
									{/* <PrivateOnlyRoute exact path={r.URL_MEMBERSHIP_SPECIAL_TOPIC + ':id?'} component={MainMembershipSpecialTopic} /> */}
									{/* <PrivateOnlyRoute exact path={r.URL_MEMBERSHIP_TAX_RATES_TABLE} component={MainMembershipTaxRatesTable} /> */}
									<PrivateOnlyRoute exact path={r.URL_MEMBERSHIP_TAXVINE + ':id(\\d+)?'} component={MainMembershipTaxVine} />
									<PrivateOnlyRoute exact path={r.URL_MEMBERSHIP_TAXATION_IN_AUSTRALIA + ':id?'} component={MainMembershipTaxationInAustralia} />
									<PrivateOnlyRoute exact path={r.URL_MEMBERSHIP_THE_TAX_SPECIALIST + ':id?'} component={MainMembershipTheTaxSpecialist} />
									<PrivateOnlyRoute exact path={r.URL_MEMBERSHIP_AUSTRALIAN_TAX_FORUM + ':id?'} component={MainMembershipAustralianTaxForum} />
									{/* <PrivateOnlyRoute exact path={r.URL_MEMBERSHIP_MONTHLY_TAX_UPDATE + ':id?'} component={MainMembershipMonthlyTaxUpdate} /> */}
									<PrivateOnlyRoute exact path={r.URL_MEMBERSHIP_TAXWISE_NEWSLETTER + ':id?'} component={MainMembershipTaxwiseNewsletter} />
									{/* education */}
									<PrivateOnlyRoute exact path={r.URL_EDUCATION_MY_ENROLLMENT} component={MainMyEnrollment} />

									{/* profile */}
									<PrivateOnlyRoute exact path={r.URL_PROFILE_ACCOUNT_DETAILS} component={MainProfileAccountDetails} />
									<PrivateOnlyRoute exact path={r.URL_PROFILE_CPD_RECORD_STRUCTURED} component={MainProfileCpdRecordStructured} />
									<PrivateOnlyRoute exact path={r.URL_PROFILE_CPD_RECORD_UNSTRUCTURED} component={MainProfileCpdRecordUnstructured} />
									<PrivateOnlyRoute exact path={r.URL_PROFILE_CPD_RECORD_EDIT + ':id(\\d+)'} component={MainProfileCpdRecordEdit} />
									<PrivateOnlyRoute exact path={r.URL_PROFILE_CPD_RECORD_ADD} component={MainProfileCpdRecordAdd} />
									<PrivateOnlyRoute exact path={r.URL_PROFILE_CPD_RECORD_PRINT} component={MainProfileCpdRecordPrint} />
									<PrivateOnlyRoute exact path={r.URL_PROFILE_ORDER_HISTORY_ORDERS} component={MainProfileOrderHistoryOrders} />
									<PrivateOnlyRoute exact path={r.URL_PROFILE_ORDER_HISTORY_CANCELLED} component={MainProfileOrderHistoryCancelled} />
									<PrivateOnlyRoute exact path={r.URL_PROFILE_ORDER_DETAILS + ':id(\\d+)'} component={MainProfileOrderDetails} />
									<PrivateOnlyRoute exact path={r.URL_PROFILE_RENEW_MEMBERSHIP} component={MainProfileRenewMembership} />
									<PrivateOnlyRoute exact path={r.URL_PROFILE_SUBSCRIPTIONS_SUBSCRIBED} component={MainProfileSubscriptionsSubscribed} />
									<PrivateOnlyRoute exact path={r.URL_PROFILE_SUBSCRIPTIONS_EXPIRED} component={MainProfileSubscriptionsExpired} />
									<PrivateOnlyRoute exact path={r.URL_PROFILE_TAX_SPECIALIST_PROFILE} component={MainProfileTaxSpecialistProfile} />
									<PrivateOnlyRoute exact path={r.URL_PROFILE_LOGOS} component={MainProfileLogos} />
									<PrivateOnlyRoute exact path={r.URL_PROFILE_LOGOUT} component={MainProfileLogout} />

									{/* temp TODO */}
									<PrivateOnlyRoute exact path="/TODO" component={TODO} />

									{/* linkeIn */}
									<Route exact path="/linkedin" component={LinkedInPopUp} />

									{/* public */}
									<Route exact path={r.URL_LOGIN} component={LoginForm} />
									<Route exact path={r.URL_FORGOTPASSWORD} component={ForgotPasswordForm} />

									{/* 404 */}
									<Route component={PageNotFound} />

								</Switch>

								{/* <ModalBox name="edit-account-details-profile-image" /> */}
								<ModalBox name="edit-account-details-business-information" />
								<ModalBox name="edit-account-details-professional-details" />
								<ModalBox name="edit-account-details-personal-info" />
								<ModalBox name="edit-change-password" />
								<ModalBox name="edit-account-details-interests" />
								<ModalBox name="edit-tax-specialist-profile-social" />
								<ModalBox name="edit-tax-specialist-profile-about" />
								<ModalBox name="edit-tax-specialist-profile-personal-info" />
								<ModalBox name="edit-tax-agent-details" />
								<ModalBox name="delete-cpd-record" />
								<ModalBox name="send-cpd-record" />
								<ModalBox name="send-order-invoice" />
								<ModalBox name="help-what-is-ePub" />
								<ModalBox name="edit-payment-information-details" />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default withRouter(Main);