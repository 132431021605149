import React from 'react';

const TooltipTaxSpecialistDirectory = () => {
	return (
		<div>
			<div className="text-bold">Tax Specialist Directory</div>
			<p>
				Allow website users to find your professional details 
				and contact information in The Tax Institutes Tax Specialist Directory
			</p>
		</div>
	);
}

export default TooltipTaxSpecialistDirectory;