import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { show } from 'redux-modal';

// import * as c from '../../../common/_constants';
// import HelpEPub from '../../common/HelpEPub';

const Item = ({
	data,
	show,
	rootLink
}) => {

	return (
		<div className="item">
			{/* {data.epub_uri
				?
					<button
						type="button"
						className="btn btn-link"
						onClick={() =>
							show(
								'help-what-is-ePub',
								{ content: <HelpEPub /> }
							)
						}
					>
						What is an ePub
					</button>
				: null
			} */}
			<img width="200" src="https://tticdn.blob.core.windows.net/tti-images/images/dmImage/StandardImage/w02_Taxwise_image_201205.gif" alt="Taxwise Newsletter" />
			<p>{data.title}</p>
			<center>
			<a href={data.docFilename} rel="noopener noreferrer" target="_blank">DOC</a>
			&nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp; 
			<a href={data.pdfFilename} rel="noopener noreferrer" target="_blank">PDF</a>
			</center>
			
		</div>
	);
}


function mapDispatchToProps(dispatch) {
	return {
		...bindActionCreators({ show }, dispatch),
		dispatch
	};
}
function mapStateToProps(state) {
	return {
		rootLink: state.app.global.global_user.data.content.root_url,
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Item);