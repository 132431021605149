import React from 'react';

import { getChoicesById, handleGaTracking } from '../../../common/_utility';

import InformationMissing from '../../common/InformationMissing';
import EditProfessionalDetails from './EditProfessionalDetails';

const ProfessionalDetails = ({
	profile,
	choices,
	show,
}) => {
	let segmentation_decision = getChoicesById(
		choices.segmentation_decision,
		profile.segmentation_decision
	);
	let membership_of_other_organisation = getChoicesById(
		choices.membership_of_other_organisation, 
		profile.membership_of_other_organisation 
	);
	let client_industry = getChoicesById(
		choices.client_industry, 
		profile.client_industry 
	);
	let corporate_industry = getChoicesById(
		choices.corporate_industry, 
		profile.corporate_industry 
	);
	let interest_in_volunteering = getChoicesById(
		choices.interest_in_volunteering, 
		profile.interest_in_volunteering 
	);
	let stageInCareerChoice = choices.stage_in_career.find(item => parseInt(item.id, 10) === profile.stage_in_career_id);
	let stageInCareer = stageInCareerChoice ? stageInCareerChoice.name : null;

	return (
		<div className="professional-details card mb-4">
			<div className="row">
				<div className="col-md-12">
					<div className="card-header">
						<h6><i className="fas fa-user"></i> Professional Details</h6>
						<div className="card-header-icon-team">
							<button
							type="button"
							className="btn trigger-edit btn-link"
							onClick={() => {
								handleGaTracking('Profile page - Edit Professional Details', 'Edit Professional Details');
								show(
									'edit-account-details-professional-details',
									{ content: <EditProfessionalDetails /> }
								)
							}}
						>
							<i className="fas fa-pen"></i>
							</button>
						</div>
					</div>

					<div className="card-body">
					
						<div className="info-detail">
							<div className="data-label">Do you make any decisions on the following?</div>
							<p>
								{segmentation_decision.length
									? segmentation_decision.map(item => item.name).join(', ')
									: <InformationMissing />
								}
							</p>
						</div>
						<div className="info-detail">
							<div className="data-label">Are you a member in other organisations?</div>
							<p>
								{membership_of_other_organisation.length
									? membership_of_other_organisation.map(item => item.name).join(', ')
									: <InformationMissing />
								}
							</p>
						</div>
						<div className="info-detail">
							<div className="data-label">Are you currently studying?</div>
							<p>
								{profile.is_currently_studying === true
									? 'Yes'
									: profile.is_currently_studying === false
										? 'No'
										: <InformationMissing />
								}
							</p>
						</div>
						<div className="info-detail">
							<div className="data-label">What is your client Industry?</div>
							<p>
								{client_industry.length
									? client_industry.map(item => item.name).join(', ')
									: <InformationMissing />
								}
							</p>
						</div>
						<div className="info-detail">
							<div className="data-label">What is your corporate Industry?</div>
							<p>
								{corporate_industry.length
									? corporate_industry.map(item => item.name).join(', ')
									: <InformationMissing />
								}
							</p>
						</div>
						<div className="info-detail">
							<div className="data-label">What stage are you in your career?</div>
							<p>
								{stageInCareer
									? stageInCareer
									: <InformationMissing />
								}
							</p>
						</div>
						<div className="info-detail">
							<div className="data-label">What year did you start in the tax industry?</div>
							<p>
								{profile.started_career_in_tax_at
									? profile.started_career_in_tax_at
									: <InformationMissing />
								}
							</p>
						</div>
						<div className="info-detail">
							<div className="data-label">Are you interested in Volunteering.</div>
							<p>
								{interest_in_volunteering.length
									? interest_in_volunteering.map(item => item.name).join(', ')
									: <InformationMissing />
								}
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default ProfessionalDetails;