import React, { Component } from 'react';
import { connect } from 'react-redux';

import NonMemberMessage from '../../common/NonMemberMessage';

import * as t from '../../../common/_titles';

class Main extends Component {

	componentDidMount() {

		document.title = t.TITLE_MEMBERSHIP_MENTORING;
	}

	render() {
		return (
			<div id="content-membership-mentoring">
				<div className="row">
					<div className="col-md-12">
						<h1>Mentoring</h1>
					</div>
				</div>
				{this.props.fullMember
					? 
						<div className="content">
							<div className="row">
								<div className="col-md-9">
									<h2>Young Practitioners Mentoring Program</h2>
									<p>This formalised program provides participants with the greatest chance of success by matching mentees with mentors who align with focus skills as well as providing participants with online training, support, webinars and content.</p>
									<p>The Young practitioners Mentoring program is open to members (Affiliate, Associate, Fellows and CTAs) of The Tax Institute only.</p>
								<a href={this.props.rootUrl + "/membership/benefits/develop-your-career/mentoring-program"} className="btn btn-primary" rel="noopener noreferrer" target="_blank">Become a mentor</a>
								</div>
							</div>
						</div>
					:	<NonMemberMessage />
				}
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		rootUrl: state.app.global.global_user.data.content.root_url,
		fullMember: state.app.global.global_user.data.content.membership_data.full_member,
	}
}

export default connect(mapStateToProps)(Main);
