//Page Titles
let TITLE_SUFFIX = ' | The tax institute'
export const TITLE_LOGIN = 'Login' + TITLE_SUFFIX;
export const TITLE_FORGOTPASSWORD = 'Forgot Password' + TITLE_SUFFIX;
export const TITLE_DASHBOARD = 'Dashboard' + TITLE_SUFFIX;

export const TITLE_EVENTS_MY_EVENTS_UPCOMING = 'Events | My events | Upcoming' + TITLE_SUFFIX;
export const TITLE_EVENTS_MY_EVENTS_PAST = 'Events | My events | Past' + TITLE_SUFFIX;
export const TITLE_EVENTS_MY_WEBINARS_UPCOMING = 'Events | My webinars | Upcoming' + TITLE_SUFFIX;
export const TITLE_EVENTS_MY_WEBINARS_PAST = 'Events | My webinars | Past' + TITLE_SUFFIX;

export const TITLE_RESOURCES_VIDEOS = 'Resources | Videos' + TITLE_SUFFIX;
export const TITLE_RESOURCES_BOOKS = 'Resources | Books' + TITLE_SUFFIX;
export const TITLE_RESOURCES_PAPER_AND_PRESENTATIONS_PURCHASED = 'Resources | Papers and presentations | Purchased' + TITLE_SUFFIX;
export const TITLE_RESOURCES_PAPER_AND_PRESENTATIONS_COMPLIMENTARY = 'Resources | Papers and presentations | Complimentary' + TITLE_SUFFIX;
export const TITLE_RESOURCES_PODCASTS = 'Resources | Podcasts' + TITLE_SUFFIX;
export const TITLE_RESOURCES_CPD_ON_DEMAND_SELF_PACED_LEARNING = 'Resources | CPD on demand | Self paced learning' + TITLE_SUFFIX;
export const TITLE_RESOURCES_CPD_ON_DEMAND_BUNDLES = 'Resources | CPD on demand | Bundles' + TITLE_SUFFIX;

export const TITLE_MEMBERSHIP_MENTORING = 'Membership | Mentoring' + TITLE_SUFFIX;
export const TITLE_MEMBERSHIP_PAPER_OF_THE_MONTH = 'Membership | Paper of the month' + TITLE_SUFFIX;
export const TITLE_MEMBERSHIP_SPECIAL_TOPIC = 'Membership | Special Topic Presentation' + TITLE_SUFFIX;
export const TITLE_MEMBERSHIP_TAX_RATES_TABLE = 'Membership | Tax rates table' + TITLE_SUFFIX;
export const TITLE_MEMBERSHIP_TAXVINE = 'Membership | Taxvine' + TITLE_SUFFIX;
export const TITLE_MEMBERSHIP_MONTHLY_TAX_UPDATE = 'Membership | Tax Insights ' + TITLE_SUFFIX;
export const TITLE_MEMBERSHIP_TAXATION_IN_AUSTRALIA = 'Membership | Taxation in Australia' + TITLE_SUFFIX;
export const TITLE_MEMBERSHIP_THE_TAX_SPECIALIST = 'Membership | The tax specialist' + TITLE_SUFFIX;
export const TITLE_MEMBERSHIP_AUSTRALIAN_TAX_FORUM = 'Membership | Australian tax forum' + TITLE_SUFFIX;
export const TITLE_MEMBERSHIP_TAXWISE_NEWSLETTER = 'Membership | Taxwise newsletter' + TITLE_SUFFIX;

export const TITLE_EDUCATION_MY_ENROLLMENT = 'Education | My Enrollment' + TITLE_SUFFIX;

export const TITLE_PROFILE_ACCOUNT_DETAILS = 'Profile | Account details' + TITLE_SUFFIX;
export const TITLE_PROFILE_CPD_RECORD_STRUCTURED = 'Profile | CPD records | Structured' + TITLE_SUFFIX;
export const TITLE_PROFILE_CPD_RECORD_UNSTRUCTURED = 'Profile | CPD records | Unstructured' + TITLE_SUFFIX;
export const TITLE_PROFILE_CPD_RECORD_ADD = 'Profile | CPD records | Add' + TITLE_SUFFIX;
export const TITLE_PROFILE_CPD_RECORD_EDIT = 'Profile | CPD records | Edit' + TITLE_SUFFIX;
export const TITLE_PROFILE_CPD_RECORD_PRINT = 'Profile | CPD records | Print' + TITLE_SUFFIX;
export const TITLE_PROFILE_ORDER_HISTORY_ORDERS = 'Profile | Order history' + TITLE_SUFFIX;
export const TITLE_PROFILE_ORDER_HISTORY_CANCELLED = 'Profile | Order history | Cancelled' + TITLE_SUFFIX;
export const TITLE_PROFILE_ORDER_DETAILS = 'Profile | Order history | Details' + TITLE_SUFFIX;
export const TITLE_PROFILE_RENEW_MEMBERSHIP = 'Profile | Renew membership' + TITLE_SUFFIX;
export const TITLE_PROFILE_LOGOS = 'Profile | Logos' + TITLE_SUFFIX;
export const TITLE_PROFILE_SUBSCRIPTIONS_SUBSCRIBED = 'Profile | Subscriptions | Subscribed' + TITLE_SUFFIX;
export const TITLE_PROFILE_SUBSCRIPTIONS_EXPIRED = 'Profile | Subscriptions | Expired' + TITLE_SUFFIX;
export const TITLE_PROFILE_TAX_SPECIALIST_PROFILE = 'Profile | Tax specialist profile' + TITLE_SUFFIX;
export const TITLE_PROFILE_LOGOUT = 'Logout' + TITLE_SUFFIX;