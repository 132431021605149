import React from 'react';
import Tooltip from 'rc-tooltip';

import { getChoicesById, handleGaTracking } from '../../../common/_utility';

import TooltipInterests from './TooltipInterests';
import EditInterests from './EditInterests';

const Interests = ({
	profile,
	choices,
	show,
}) => {

	let interests = getChoicesById(
		choices.interests,
		profile.interests
	);
	return (
		<div className="interest card mb-4">
			<div className="row">
				<div className="col-md-12">
					<div className="card-header">
						<h6><i className="fas fa-check-circle"></i> Interests</h6>
						<div className="card-header-icon-team">
							<button
								type="button"
								className="btn trigger-edit btn-link"
								onClick={() => {
									handleGaTracking('Profile page - Edit Interests', 'Edit Interests');
									show(
										'edit-account-details-interests',
										{ content: <EditInterests /> }
									)
								}}
							>
								<i className="fas fa-pen"></i>
							</button>
							<Tooltip
								placement="topLeft"
								overlay={<TooltipInterests />}
							>
								<i className="fas fa-info-circle"></i>
							</Tooltip>
						</div>
					</div>

					<div className="card-body">
						<div className="interest-list">
							{interests.length
								? 	interests.map(item => <span className="badge badge-primary" key={item.id}>{item.name}</span>)
								: 	<button
									type="button"
									className="btn trigger-edit btn-link"
									onClick={() => {
										handleGaTracking('Profile page - Edit Interests', 'Edit Interests');
										show(
											'edit-account-details-interests',
											{ content: <EditInterests /> }
										)
									}}
									>
										Enter your interests
									</button>
							}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Interests;