import React, { Component } from 'react';
import { connect } from 'react-redux';
import { hide } from 'redux-modal';
import { bindActionCreators } from 'redux';
import Select from 'react-select';

import { actionApiSendData } from '../../../actions/apiData/index';
// import * as m from '../../../common/_messages';
import * as c from '../../../common/_constants';
import { handleGaTracking } from '../../../common/_utility';

class EditProfessionalDetails extends Component {
	constructor(props) {
		super(props);

		let profile = this.props.profileUser.data.content;

		this.state = {
			segmentationDecisionValue: profile.segmentation_decision,
			segmentationDecisionError: false,
			membershipOtherOrgValue: profile.membership_of_other_organisation,
			membershipOtherOrgError: false,
			currentlyStudyingValue: profile.is_currently_studying  ? "yes" : "no",
			currentlyStudyingError: false,
			clientIndustryValue: profile.client_industry,
			clientIndustryError: false,
			corporateIndustryValue: profile.corporate_industry,
			corporateIndustryError: false,
			stageInCareerValue: profile.stage_in_career_id,
			stageInCareerError: false,
			startedCareerInTaxYearValue: profile.started_career_in_tax_at,
			startedCareerInTaxYearError: false,
			interestVolunteeringValue: profile.interest_in_volunteering,
			interestVolunteeringError: false,
			responseMessage: false,
			dataSaved: false,
		}

		this.handleFieldChange = this.handleFieldChange.bind(this);
		this.handleSelectChange = this.handleSelectChange.bind(this);
		this.handleSelectMultiChange = this.handleSelectMultiChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.isFormValid = this.isFormValid.bind(this);

	}
	handleFieldChange(e) {
		const target = e.target;
		const value = target.type === 'checkbox' ? target.checked : target.value;
		const name = target.name;

		this.setState({ [name + 'Value']: value });
	}
	handleSelectChange(optionSelected, { name }) {
		optionSelected
			? this.setState({ [name + 'Value']: optionSelected.value })
			: this.setState({ [name + 'Value']: null })
	}
	handleSelectMultiChange(optionSelected, {name}) {
		optionSelected
			? this.setState({ [name + 'Value']: optionSelected.map(item => item.value) })
			: this.setState({ [name + 'Value']: null })
	}
	handleSubmit(e) {

		e.preventDefault();

		//validation
		if (!this.isFormValid()) {
			return;
		}

		let data = {
			segmentation_decision: this.state.segmentationDecisionValue,
			membership_of_other_organisation: this.state.membershipOtherOrgValue,
			is_currently_studying: this.state.currentlyStudyingValue === 'yes' ? true : false,
			client_industry: this.state.clientIndustryValue,
			corporate_industry: this.state.corporateIndustryValue,
			stage_in_career_id: this.state.stageInCareerValue,
			started_career_in_tax_at: this.state.startedCareerInTaxYearValue,
			interest_in_volunteering: this.state.interestVolunteeringValue,
		}

		handleGaTracking('Profile page - Professional Details Save', 'Professional Details Save');

		this.props.dispatch(
			actionApiSendData(c.API_DATA.profile_user.key, data)
		).then((success) => {
			if (success) {
				this.setState({
					responseMessage: 'Saved',
					dataSaved: true
				});
				//close popup after 3 secs
				let hidePopup = this.props.hide;
				setTimeout(
					function () {
						hidePopup('edit-account-details-professional-details')
					}, 2000
				);
			} else {
				this.setState({ responseMessage: 'something went wrong' });
			}
		});
	}
	isFormValid() {

		let formValid = true;

		//reset error states
		this.setState({
			segmentationDecisionError: false,
			membershipOtherOrgError: false,
			currentlyStudyingError: false,
			clientIndustryError: false,
			corporateIndustryError: false,
			stageInCareerError: false,
			startedCareerInTaxYearError: false,
			interestVolunteeringError: false,
			responseMessage: false
		});

		// if (!this.state.segmentationDecisionValue.length) {
		// 	this.setState({ segmentationDecisionError: m.formError.segmentationDecision.required });
		// 	formValid = false;
		// }
		// if (!this.state.membershipOtherOrgValue.length) {
		// 	this.setState({ membershipOtherOrgError: m.formError.membershipOtherOrg.required });
		// 	formValid = false;
		// }
		// if (!this.state.clientIndustryValue.length) {
		// 	this.setState({ clientIndustryError: m.formError.clientIndustry.required });
		// 	formValid = false;
		// }
		// if (!this.state.corporateIndustryValue.length) {
		// 	this.setState({ corporateIndustryError: m.formError.corporateIndustry.required });
		// 	formValid = false;
		// }
		// if (!this.state.stageInCareerValue) {
		// 	this.setState({ stageInCareerError: m.formError.stageInCareer.required });
		// 	formValid = false;
		// }
		// if (!this.state.startedCareerInTaxYearValue) {
		// 	this.setState({ startedCareerInTaxYearError: m.formError.startedCareerInTaxYear.required });
		// 	formValid = false;
		// }
		// if (!this.state.interestVolunteeringValue.length) {
		// 	this.setState({ interestVolunteeringError: m.formError.interestVolunteering.required });
		// 	formValid = false;
		// }
		
		return formValid;
	}
	render() {
		let choices = this.props.profileUser.data.choices;

		const segmentationDecisionList = choices.segmentation_decision.map((item) => {
			return { value: parseInt(item.id, 10), label: item.name }
		});
		const membershipOtherOrgList = choices.membership_of_other_organisation.map((item) => {
			return { value: parseInt(item.id, 10), label: item.name }
		});
		const clientIndustryList = choices.client_industry.map((item) => {
			return { value: parseInt(item.id, 10), label: item.name }
		});
		const corporateIndustryList = choices.corporate_industry.map((item) => {
			return { value: parseInt(item.id, 10), label: item.name }
		});
		const stageInCareerList = choices.stage_in_career.map((item) => {
			return { value: parseInt(item.id, 10), label: item.name }
		});
		const startedCareerInTaxYearList = [];
		for (var index = new Date().getFullYear(); index >= 1900; index--) {
			startedCareerInTaxYearList.push({ value: index, label: index });
		}
		const interestVolunteeringList = choices.interest_in_volunteering.map((item) => {
			return { value: parseInt(item.id, 10), label: item.name }
		});

		return (
			<div className="edit-modal" id="edit-account-details-professional-details">
				<form onSubmit={this.handleSubmit}>
					<div className="header">
						<div className="row">
							<div className="col-md-12">
								<h4 className="m-0">Edit Professional Details</h4>
							</div>
						</div>
					</div>
					<div className="content">

						<div className="row">
							<div className="col-md-12">
								<div className={"form-group" + (this.state.segmentationDecisionError ? ' has-error' : '')}>
									<label htmlFor="segmentationDecision">Do you make any decisions on the following?</label>
									<Select
										id="segmentationDecision"
										className="custom-react-select multiple-select"
										name="segmentationDecision"
										value={this.state.segmentationDecisionValue.map(option => segmentationDecisionList.find(item => item.value === option))}
										options={segmentationDecisionList}
										onChange={this.handleSelectMultiChange}
										isClearable
										isMulti
									/>
									{this.state.segmentationDecisionError
										? <p className="form-error">{this.state.segmentationDecisionError}</p>
										: null
									}
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col-md-12">
								<div className={"form-group" + (this.state.membershipOtherOrgError ? ' has-error' : '')}>
									<label htmlFor="membershipOtherOrg">Are you a member in other organisations?</label>
									<Select
										id="membershipOtherOrg"
										className="custom-react-select multiple-select"
										name="membershipOtherOrg"
										value={this.state.membershipOtherOrgValue.map(option => membershipOtherOrgList.find(item => item.value === option))}
										options={membershipOtherOrgList}
										onChange={this.handleSelectMultiChange}
										isClearable
										isMulti
									/>
									{this.state.membershipOtherOrgError
										? <p className="form-error">{this.state.membershipOtherOrgError}</p>
										: null
									}
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col-md-12">
								<div className={"form-group" + (this.state.currentlyStudyingError ? ' has-error' : '')}>
									<div><label>Are you currently studying?</label></div>
									<div className="btn-group btn-group-toggle">
										<label className={"btn btn-secondary" + (this.state.currentlyStudyingValue === "yes" ? ' active' : '')}>
											<input 
												type="radio" 
												name="currentlyStudying" 
												id="currentlyStudying-yes" 
												autoComplete="off"
												onChange={this.handleFieldChange}
												value="yes"
												checked={this.state.currentlyStudyingValue === "yes"}
											/>
											Yes
										</label>
										<label className={"btn btn-secondary" + (this.state.currentlyStudyingValue === "no" ? ' active' : '')}>
											<input 
												type="radio" 
												name="currentlyStudying" 
												id="currentlyStudying-no" 
												autoComplete="off"
												onChange={this.handleFieldChange}
												value="no"
												checked={this.state.currentlyStudyingValue === "no"}
											/>
											No
										</label>
									</div>
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col-md-12">
								<div className={"form-group" + (this.state.clientIndustryError ? ' has-error' : '')}>
									<label htmlFor="clientIndustry">What is your client Industry?</label>
									<Select
										id="clientIndustry"
										className="custom-react-select multiple-select"
										name="clientIndustry"
										value={this.state.clientIndustryValue.map(option => clientIndustryList.find(item => item.value === option))}
										options={clientIndustryList}
										onChange={this.handleSelectMultiChange}
										isClearable
										isMulti
									/>
									{this.state.clientIndustryError
										? <p className="form-error">{this.state.clientIndustryError}</p>
										: null
									}
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col-md-12">
								<div className={"form-group" + (this.state.corporateIndustryError ? ' has-error' : '')}>
									<label htmlFor="corporateIndustry">What is your corporate Industry?</label>
									<Select
										id="corporateIndustry"
										className="custom-react-select multiple-select"
										name="corporateIndustry"
										value={this.state.corporateIndustryValue.map(option => corporateIndustryList.find(item => item.value === option))}
										options={corporateIndustryList}
										onChange={this.handleSelectMultiChange}
										isClearable
										isMulti
									/>
									{this.state.corporateIndustryError
										? <p className="form-error">{this.state.corporateIndustryError}</p>
										: null
									}
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col-md-12">
								<div className={"form-group" + (this.state.stageInCareerError ? ' has-error' : '')}>
									<label htmlFor="stageInCareer">What stage are you in your career?</label>
									<Select
										id="stageInCareer"
										name="stageInCareer"
										className="custom-react-select"
										value={stageInCareerList.find(item => item.value === this.state.stageInCareerValue || null)}
										options={stageInCareerList}
										onChange={this.handleSelectChange}
										isClearable
									/>
									{this.state.stageInCareerError
										? <p className="form-error">{this.state.stageInCareerError}</p>
										: null
									}
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col-md-12">
								<div className={"form-group" + (this.state.startedCareerInTaxYearError ? ' has-error' : '')}>
									<label htmlFor="startedCareerInTaxYear">What year did you start in the tax industry?</label>
									<Select
										id="startedCareerInTaxYear"
										name="startedCareerInTaxYear"
										className="custom-react-select"
										value={startedCareerInTaxYearList.find(item => item.value === this.state.startedCareerInTaxYearValue || null)}
										options={startedCareerInTaxYearList}
										onChange={this.handleSelectChange}
										isClearable
									/>
									{this.state.startedCareerInTaxYearError
										? <p className="form-error">{this.state.startedCareerInTaxYearError}</p>
										: null
									}
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col-md-12">
								<div className={"form-group" + (this.state.interestVolunteeringError ? ' has-error' : '')}>
									<label htmlFor="interestVolunteering">Are you interested in Volunteering.</label>
									<Select
										id="interestVolunteering"
										className="custom-react-select multiple-select"
										name="interestVolunteering"
										value={this.state.interestVolunteeringValue.map(option => interestVolunteeringList.find(item => item.value === option))}
										options={interestVolunteeringList}
										onChange={this.handleSelectMultiChange}
										isClearable
										isMulti
									/>
									{this.state.interestVolunteeringError
										? <p className="form-error">{this.state.interestVolunteeringError}</p>
										: null
									}
								</div>
							</div>
						</div>
					</div>
					<div className="footer">
						<div className="row">
							<div className="col-md-12">
								{this.props.profileUser.saving
									?
										<span>Saving <i className="fas fa-spinner fa-spin"></i></span>
									:
										<span>
											{!this.state.dataSaved
												?
													<span>
														<button
														disabled={this.props.profileUser.saving}
															type="button" 
															onClick={() => this.props.hide('edit-account-details-professional-details')}
															className="btn btn-link-secondary"
														>
															Cancel
														</button>
														<button
															disabled={this.props.profileUser.saving}
															type="submit"
															value="Save"
															className="btn btn-link"
														>
															Save
														</button>
													</span>
												: null
											}
											{this.state.responseMessage
												? <p className="response-message">{this.state.responseMessage}</p>
												: null
											}
										</span>
								}
							</div>
						</div>
					</div>
				</form>
			</div>
		)
	}
}
function mapStateToProps(state) {
	return {
		profileUser: state.apiData.profile_user,
	}
}
function mapDispatchToProps(dispatch) {
	return {
		...bindActionCreators({ hide }, dispatch),
		dispatch
	};
}
export default connect(mapStateToProps, mapDispatchToProps)(EditProfessionalDetails);