import React, { Component } from "react";
import { connect } from "react-redux";
import queryString from "query-string";
import parse from "date-fns/parse"; //included as a dependency of react-datepicker

import { actionGetApiDataKey } from "../../../actions/apiData";

import * as c from "../../../common/_constants";
import * as t from "../../../common/_titles";

import FilterView from "./FilterView";
import Loading from "../../common/Loading";
import Pagination from "../../common/Pagination";
import SortIcon from "../../common/SortIcon";

const COLOR_GREEN = "rgba(210, 238, 213, 1)";
const COLOR_RED = "rgba(240, 205, 206, 1)";
const COLOR_WHITE = "rgb(255,255,255)";
const COLOR_BLACK = "rgb(0,0,0)";
const COLOR_GRAY = "#828282";
class Main extends Component {
	constructor(props) {
		super(props);
		this.state = {
			colSortOrder: {
				date_completed: 0,
			},
			sortedRowData: [],
		};
	}

	componentDidUpdate(prevProps) {
		if ( prevProps.location.search !== this.props.location.search) {
			const params = queryString.parse(this.props.location.search);

			//remove parameter type as it is only internal
			delete params.query_type;

			this.props.dispatch(
				actionGetApiDataKey(
					c.API_DATA.education_my_enrollment_details.key,
					params
				)
			);
		}
		if (
			prevProps.rowData !== this.props.rowData
		) {
			this.sortAndUpdate([...this.props.rowData]);
		}
	}

	componentDidMount() {
		document.title = t.TITLE_EDUCATION_MY_ENROLLMENT;

		const params = queryString.parse(this.props.location.search);

		//remove parameter type as it is only internal
		delete params.query_type;

		this.props.dispatch(
			actionGetApiDataKey(
				c.API_DATA.education_my_enrollment_details.key,
				params
			)
		);
		this.sortAndUpdate([...this.props.rowData]);
	}

	sortAndUpdate = (rowData) => {
		const sortOrder = this.state.colSortOrder.date_completed;
		if (sortOrder !== 0) {
			rowData.sort(
				(a, b) => (a._date_completed - b._date_completed) * sortOrder
			);
		} else {
			rowData = [...this.props.rowData];
		}
		this.setState({
			sortedRowData: rowData,
		});
	};

	updateSortOrder = () => {
		let sortOrder = this.state.colSortOrder.date_completed;
		switch (sortOrder) {
			case 0:
				sortOrder = 1;
				break;
			case 1:
				sortOrder = -1;
				break;
			case -1:
				sortOrder = 0;
				break;
			default:
				sortOrder = 0;
		}
		this.setState(
			{
				colSortOrder: { date_completed: sortOrder },
			},
			() => {
				this.sortAndUpdate(this.state.sortedRowData);
			}
		);
	};

	render() {
		const { columnDefs, loading, ok, pagination } = this.props;
		//prepare params for links
		const params = queryString.parse(this.props.location.search);
		delete params.page;

		return (
			<div id="content-membership-taxation-in-australia">
				<div className="row">
					<div className="col-md-12">
						<h1>My Enrolment History</h1>
					</div>
				</div>
				<div className="row">
					<div className="col-md-12">
						{loading ? (
							<Loading />
						) : !ok ? (
							<div>
								<p>Sorry, an error occurred</p>
							</div>
						) : (
							<div className="sections">
								<div className="row">
									<div className="col-md-12">
										<FilterView />
									</div>
								</div>
								<table className="table table-striped table-bordered cpd-table-info">
									<thead>
										<tr>
											{columnDefs.map(({ name, field, bgcolor, textColor }) => (
												<th
													key={field}
													style={{
														verticalAlign: "middle",
														backgroundColor: COLOR_BLACK,
														color: COLOR_WHITE,
														position: "relative",
														paddingRight: "16px",
													}}
												>
													{name}
													{field === "date_completed" ? (
														<div
															style={{
																cursor: "pointer",
																position: "absolute",
																bottom: "4px",
																right: "4px",
																height: "16px",
															}}
															onClick={this.updateSortOrder}
															role="button"
														>
															<SortIcon
																style={{
																	height: "16px",
																}}
																colorUpper={
																	this.state.colSortOrder.date_completed === -1
																		? COLOR_GRAY
																		: COLOR_WHITE
																}
																colorLower={
																	this.state.colSortOrder.date_completed === 1
																		? COLOR_GRAY
																		: COLOR_WHITE
																}
															/>
														</div>
													) : null}
												</th>
											))}
										</tr>
									</thead>
									<tbody>
										{this.state.sortedRowData &&
											this.state.sortedRowData.map((rowD) => {
												const backgroundColor =
													rowD.enrolment_status === "Passed"
														? COLOR_GREEN
														: rowD.enrolment_status === "Failed" ||
															rowD.enrolment_status === "Fail"
														? COLOR_RED
														: "";

												return (
													<tr key={rowD.order_id}>
														{columnDefs.map(({ field, viewFn }) => {
															let style = {};
															if (
																field === "enrolment_status" ||
																field === "percent_score"
															) {
																style = { backgroundColor };
															}
															return (
																<td key={field} style={style} className="mr-0">
																	{viewFn ? viewFn(rowD[field]) : rowD[field]}
																</td>
															);
														})}
													</tr>
												);
											})}
									</tbody>
								</table>
								<div className="row">
									<div className="col-md-12">
										<Pagination data={pagination} />
									</div>
								</div>
							</div>
						)}
					</div>
				</div>
			</div>
		);
	}
}

function mapStateToProps(state) {
	const columnDefs = [
		{
			name: "Class Tittle",
			field: "class_title",
			bgcolor: COLOR_BLACK,
			textColor: COLOR_WHITE,
		},
		{
			name: "Enrolment Type",
			field: "column2",
		},
		{
			name: "Status",
			field: "enrolment_status",
		},
		{
			name: "Score",
			field: "score",
		},
		{
			name: "Percent Score",
			field: "percent_score",
		},
		/*{
			name: "Date registered",
			field: "date_registered",
			viewFn: (txt) => txt && txt.split(" ")[0],
		},*/
		{
			name: "Date Completed",
			field: "date_completed",
			// viewFn: (txt) => txt && txt.split(" ")[0],
		},
	];

	const myEnrolmentDetails =
		state.apiData.education_my_enrollment_details || {};
	const loading =
		myEnrolmentDetails.loading === undefined
			? true
			: myEnrolmentDetails.loading;
	const ok =
		!!myEnrolmentDetails.data && myEnrolmentDetails.data.status === 200;
	const rowData = ok ? myEnrolmentDetails.data.content.items : [];
	const pagination = ok ? myEnrolmentDetails.data.content.pagination : {};
	const _date = new Date();
	rowData.forEach((row) => {
		row._date_completed = parse(
			row.date_completed,
			"d/MM/yyyy",
			_date
		);
	});
	return {
		columnDefs,
		loading,
		ok,
		rowData,
		pagination,
	};
}

export default connect(mapStateToProps)(Main);
