import * as c from '../../common/_constants';
import queryString from 'query-string';

import { actionAuthError, actionAuthSuccess } from '../app/auth';

export const actionResetApiData = () => {
	return {
		type: c.ACTION_RESET_API_DATA,
	}
}
const actionGetApiDataInit = (key) => {
	return {
		type: c.ACTION_GET_API_DATA_INIT,
		key
	}
}
const actionGetApiDataLoad = (key, data, params) => {
	return {
		type: c.ACTION_GET_API_DATA_LOAD,
		key,
		data,
		params
	}
}
const actionGetApiDataError = (key) => {
	return {
		type: c.ACTION_GET_API_DATA_ERROR,
		key
	}
}
export const actionGetApiDataUrl = (key, url, force = false, params={}) => (dispatch, getState) => {

	let state = getState();

	let update = false;
	if (force || !state.apiData[key] || Date.now() > state.apiData[key].expiry) {
		//update if expired
		update = true;
	}

	if (update) {
		dispatch(actionGetApiDataInit(key));

		retrieveApiData(dispatch, url)
		.then(response => {
			switch (response.status) {
				case 200:
					dispatch(actionGetApiDataLoad(key, response, params));
					break;
				case 401:
					localStorage.removeItem('authToken');
					dispatch(actionAuthError());
					break;

				default:
					dispatch(actionGetApiDataError(key));
					break;
			}
		})
		.catch(err => {
			console.log(err);
			dispatch(actionGetApiDataError(key));
		});
	}
}

export const actionGetApiDataKey = (key, params = {}) => (dispatch, getState) => {

	let state = getState();

	let update = false;

  // Only allow these URL parameters to be sent to the API, ignore extras such as analytics codes.
	let allowparams = [
			'count',
			'end_time',
			'page',
			'sort_by',
			'keyword',
			'email',
			'sort_order',
			'start_time',
			'enrolment_status',
			'year_completed'
	]

	let validparams = {};

	const keys = Object.keys(params)
	for (const key of keys) {
		if(allowparams.includes(key))
		{
			validparams[key] = params[key];
		}
	}

	//merge endpoint parameters with given parameters
	const mergedParams = { ...c.API_DATA[key].endpointParams, ...validparams };


	if (
		!state.apiData[key] || 
		Date.now() > state.apiData[key].expiry || 
		JSON.stringify(state.apiData[key].params) !== JSON.stringify(mergedParams)
	) {

		//update or different page
		update = true;
	}

	if (update) {
		dispatch(actionGetApiDataInit(key));

		let paramString = queryString.stringify(mergedParams);

		let url = c.API_URL + c.API_DATA[key].endpoint + (paramString ? '?' + paramString : '');

		retrieveApiData(dispatch, url )
		.then(response => {

			switch (response.status) {
				case 200:
					dispatch(actionGetApiDataLoad(key, response, mergedParams));
					break;
				case 401:
					localStorage.removeItem('authToken');
					dispatch(actionAuthError());
					break;

				default:
					dispatch(actionGetApiDataError(key));
					break;
			}

		})
		.catch(err => {
			console.log(err);
			dispatch(actionGetApiDataError(key));
		});
	}
}

export const retrieveApiData = (dispatch, url) => {

	return fetch(url, {
		method: 'GET',
		headers: {
			"Content-Type": "application/json",
			"Authorization": "Bearer " + localStorage.getItem('authToken'),
			"Accept-Type": "application/json",
		}
	})
	.then(response => response.json())
	.then(response => {

		switch (response.status) {
			case 200:
				if(localStorage.getItem('authToken')){
					dispatch(actionAuthSuccess());
				}
				break;
			case 401:
				localStorage.removeItem('authToken');
				dispatch(actionAuthError());
				break;
		
			default:
				//dispatch(actionGetApiDataError(key));
				//dispatch(actionAppStatusLoadError());
				break;
		}
		
		return response;
	});

}

const actionApiSendDataInit = (key) => {
	return {
		type: c.ACTION_API_SEND_DATA_INIT,
		key
	}
}
const actionApiSendDataLoad = (key, data) => {
	// console.log(data);
	return {
		type: c.ACTION_API_SEND_DATA_LOAD,
		key,
		data,
	}
}
const actionApiSendDataError = (key, data) => {

	// RS global var will do for now
	window.apidataerror = data;

	return {
		type: c.ACTION_API_SEND_DATA_ERROR,
		key,
		data
	}
}

export const actionApiSendData = (key, data, url = null, method = 'POST') => (dispatch, getState) => {

	dispatch(actionApiSendDataInit(key));

	let body;

	if (data){

		body = Object.keys(data).map((key) => {
			let value = data[key];
			if (Array.isArray(data[key])){
				value = '[' + data[key].join(', ') + ']'
			}
			return encodeURIComponent(key) + '=' + encodeURIComponent(value);
		}).join('&');
	}

	if (!url){
		url = c.API_URL + c.API_DATA[key].endpoint;
	}

	return fetch(url, {
		method: method,
		body: body,
		headers: {
			"Authorization": "Bearer " + localStorage.getItem('authToken'),
			"Accept-Type": "application/json",
			"Content-Type": "application/x-www-form-urlencoded"
		}
	})
	.then(response => response.json())
	.then(response => {
		let success;
		switch (response.status) {
			case 200:
				dispatch(actionApiSendDataLoad(key, response));
				success = true;
				break;
			case 401:
				localStorage.removeItem('authToken');
				dispatch(actionAuthError());
				success = false;
				break;

			default:
				dispatch(actionApiSendDataError(key, response));
				success = false;
				break;
		}
		return success;
	})
	.catch(err => {
		console.log(err)
		dispatch(actionApiSendDataError(key));
		return false;
	});

}
