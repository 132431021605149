import React, { Component } from 'react';
import Select from 'react-select';
import { withRouter } from 'react-router';

import { formatDate } from '../../../common/_utility';
import * as r from '../../../common/_routes';
class Search extends Component {
	constructor(props) {
		super(props);

		this.state = {
			issueYearValue: null,
			issueYearError: false,
			issueNumberValue: null,
			issueNumberError: false,
			responseMessage: false,
		}

		this.handleSelectChange = this.handleSelectChange.bind(this);

	}
	handleSelectChange(optionSelected, { name }) {
		optionSelected
			? this.setState({ [name + 'Value']: optionSelected.value })
			: this.setState({ [name + 'Value']: null })

		if (optionSelected && name === 'issueNumber'){
			this.props.history.push(r.URL_MEMBERSHIP_AUSTRALIAN_TAX_FORUM + optionSelected.value );
		}
			
	}
	render() {

		let items = this.props.data.items;
		
		let issueNumberList = [];
		let issueYearList = [];

		items.forEach((item) => {
			issueNumberList.push({
				value: parseInt(item.id, 10), 
				label: item.issue + " - " + formatDate(item.issued_at, 'd M Y'),
				year: formatDate(item.issued_at, 'Y')
			})
			if(!issueYearList.find(year => year.value === formatDate(item.issued_at, 'Y'))){
				issueYearList.push({
					value: formatDate(item.issued_at, 'Y'),
					label: formatDate(item.issued_at, 'Y')
				})
			}
		});

		//filter by year 
		issueNumberList = issueNumberList.filter((item) => item.year === this.state.issueYearValue);

		return (
			<div className="search">
				<form>
					<div className="row">
						<div className="col-md-3">
							<div className={"form-group" + (this.state.issueYearError ? ' has-error' : '')}>
								<label htmlFor="issueYear">Select Issue Year</label>
								<Select
									id="issueYear"
									name="issueYear"
									className="custom-react-select"
									value={issueYearList.find(item => item.value === this.state.issueYearValue) || null}
									options={issueYearList}
									onChange={this.handleSelectChange}
									isClearable
								/>
								{this.state.issueYearError
									? <p>{this.state.issueYearError}</p>
									: null
								}
							</div>
						</div>
						<div className="col-md-3">
							{this.state.issueYearValue
								?
									<div className={"form-group" + (this.state.issueNumberError ? ' has-error' : '')}>
										<label htmlFor="issueNumber">Select Issue Number</label>
										<Select
											id="issueNumber"
											name="issueNumber"
											className="custom-react-select"
											value={issueNumberList.find(item => item.value === this.state.issueNumberValue) || null}
											options={issueNumberList}
											onChange={this.handleSelectChange}
											isClearable
										/>
										{this.state.issueNumberError
											? <p>{this.state.issueNumberError}</p>
											: null
										}
									</div>
								:	null
							}
							
						</div>
						<div className="col-md-6 text-right">
							<a href={this.props.rootLink + '/resources/books-and-journals/australian-tax-forum'} rel="noopener noreferrer" target="_blank"><i className="fas fa-search"></i> Search The Australian Tax Forum</a>
						</div>
					</div>
				</form>
			</div>
		);
	}
}

export default withRouter(Search);