import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import reducers from '../reducers/index';
import { initializeStore } from '../api/index';


const configureStore = () => {
    
    const middlewares = [thunk];
    
    if(process.env.NODE_ENV !== 'production'){
    
    }
    
    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
    
    const store =  createStore(
            reducers,
            composeEnhancers(
                applyMiddleware(...middlewares)
            )
    );
    
    //load data from DB
    initializeStore(store);
    
    return store;
};

export default configureStore;
