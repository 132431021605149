import React from 'react';

// import ListItem from './ListItem';
import CategoryList from './CategoryList';
import { isset } from '../../../common/_utility';

const Lists = ({
	data,
	rootUrl,
	onSelectVideo
}) => {
	let categories = isset(() => data.items[0].resources) ? data.items[0].resources : false;
	return (
		<div className="videos-categories-lists">
			{categories
				? Object.keys(categories).map((keyName, index) => <CategoryList key={index} category={categories[keyName]} onSelectVideo={onSelectVideo} />)
				: null
			}
		</div>
	);
}

export default Lists;