import * as c from '../../common/_constants';
import { isset } from '../../common/_utility';

function reducerGlobal(state = {}, action) {

	let expiryInteval = 0;

	switch (action.type) {
		case c.ACTION_REFRESH_GLOBAL_ENDPOINT_DATA:
			return {
				...state,
				[action.key] : {
					...state[action.key],
					data: {
						...state[action.key].data,
						content: action.content
					}
				}
			};
		case c.ACTION_GET_API_GLOBAL_INIT:
			return {
				...state,
				[action.key]: {
					loading: true,
					error: false,
					expiry: false,
					data: []
				}
			};
		case c.ACTION_GET_API_GLOBAL_ERROR:
			return {
				...state,
				[action.key]: {
					loading: false,
					error: true,
					expiry: false,
					data: []
				}
			};
		case c.ACTION_GET_API_GLOBAL_LOAD:
			expiryInteval =
				isset(() => c.API_DATA[action.key].expiryInterval)
					? c.API_DATA[action.key].expiryInterval
					: c.EXPIRY_INTERVAL_DEFAULT
			//add 1 second to avoid infinite loop
			expiryInteval += 1000;

			return {
				...state,
				[action.key]: {
					loading: false,
					error: false,
					expiry: Date.now() + expiryInteval,
					data: action.data
				}
			};
		default:
			return state;
	}
}

export default reducerGlobal;