import * as c from '../../common/_constants';

import { actionAppStatusLoad, actionAppStatusLoadError } from './appStatus';

export const actionSetAuth = (status) => {
	return {
		type: c.ACTION_SET_AUTH,
		status
	}
}

const actionAuthRequest = () => {
	return {
		type: c.ACTION_AUTH_REQUEST,
	}
}

// const actionForgotPasswordRequest = () => {
// 	return {
// 		type: c.ACTION_FORGOTPASSWORD_REQUEST,
// 	}
// }

export const actionAuthSuccess = () => {
	return {
		type: c.ACTION_AUTH_SUCCESS,
	}
}

export const actionForgotpasswordSuccess = () => {
	return {
		type: c.ACTION_FORGOTPASSWORD_SUCCESS,
	}
}

export const actionForgotpasswordFinaliseSuccess = () => {
	return {
		type: c.ACTION_FORGOTPASSWORDFINALISE_SUCCESS,
	}
}



export const actionAuthError = (message) => {
	return {
		type: c.ACTION_AUTH_ERROR,
		message
	}
}

export const actionGetAuth = (email, password, rememberMe) => (dispatch, getState) => {


	dispatch(actionAuthRequest());

	//localStorage.removeItem('authToken');

	// var body_content = 'grant_type=client_credentials';

	return fetch(c.AUTH_URL + c.API_DATA['auth'].endpoint, {
		method: 'POST',
		body: 'grant_type=client_credentials',
		headers: {
			"Content-Type": "application/x-www-form-urlencoded",
			'Authorization': 'Basic ' + btoa(email + ":" + password),
		}
	})
	.then(handleResponse)
	// .then(data => console.log(data))
	.then(response => {
		switch (response.status) {
			case 200:
				localStorage.setItem('authToken', response.access_token);
				document.cookie = "rememberAccess=false;max-age=2592000;Path=/;";

				let cookieValue = "currentMPSession="+password;

				if (window.location.hostname !== "localhost") {
					cookieValue = cookieValue + ";domain=taxinstitute.com.au";
				}

				document.cookie = cookieValue;

				if (rememberMe) {
					document.cookie = "rememberAccess=true;max-age=2592000;Path=/;";
				}

				// if (
				// 		document.referrer &&
				// 		!(document.referrer.includes("forgot-password")) &&
				// 		!(document.referrer.includes("membership.taxinstitute.com.au")) &&
				// 		!(document.referrer.includes("become-a-member")) &&
				// 		!(window.location.search.indexOf('noredirect=')>=0)
				// )
				// {
				// 	window.location.href = document.referrer;
				// }
				// else {

					dispatch(actionAppStatusLoad());
					dispatch(actionAuthSuccess());

					// setTimeout(function () {
					//
					// 	var oldFrm = document.getElementById("loginform");
					// 	if (oldFrm) {
					// 		oldFrm.parentNode.removeChild(oldFrm);
					// 	}
					//
					// 	var ifrm = document.createElement('iframe');
					// 	ifrm.setAttribute('id', 'loginform');
					// 	document.body.appendChild(ifrm);
					//
					// 	let urlBase = 'https://www.taxinstitute.com.au/';
					// 	if (window.location.href.includes("dashboard-test")) {
					// 		urlBase = 'http://www-test.taxinstitute.com.au/';
					// 	}
					// 	ifrm.setAttribute('src', urlBase);
					//
					// }, 3000);

				// }

				break;
			case 401:
				localStorage.removeItem('authToken');
				//dispatch(actionAuthError('wrong credentials'));
				console.log("401 error on auth attempt");
				//window.location.href = process.env.REACT_APP_TTI_LOGIN_REDIRECT_URL;
				break;

			default:
				dispatch(actionAppStatusLoadError('Error 002 - response status error code '.response.status));
				break;
		}


	})
	.catch(err => {
		dispatch(actionAppStatusLoadError(err));
	});

	function handleResponse(response) {

		let contentType = response.headers.get('content-type')
		if (contentType.includes('application/json')) {
			return handleJSONResponse(response)
		} else if (contentType.includes('text/html')) {
			return handleTextResponse(response)
		} else {
			// Other response types as necessary. I haven't found a need for them yet though.
			// throw new Error(`Sorry, content-type ${contentType} not supported`)
			return Promise.reject({
				status: response.status,
				statusText: response.statusText,
				url: response.url,
				err: `Sorry, content-type ${contentType} not supported`
			})
		}
	}
	function handleJSONResponse(response) {
		return response.json()
		// .then(json => {
		// 	// if (response.ok) {
		// 		return json
		// 	// } else {
		// 	// 	return Promise.reject(Object.assign({}, json, {
		// 	// 		status: response.status,
		// 	// 		statusText: response.statusText,
		// 	// 		url: response.url,
		// 	// 		err: ''
		// 	// 	}))
		// 	// }
		// })
	}
	function handleTextResponse(response) {
		return response.text()
		.then(text => {
			return Promise.reject({
				status: response.status,
				statusText: response.statusText,
				url: response.url,
				err: text
			})
		})
	}


}

export const actionResetPassword = (userid, emailaddress) => (dispatch, getState) => {

	dispatch(actionAuthRequest());

	//localStorage.removeItem('authToken');

	let forgotPasswordURL = c.FORGOTPASSWORD_URL;

	forgotPasswordURL += "?";
	forgotPasswordURL += "UserName=" + userid + "&";
	forgotPasswordURL += "email=" + emailaddress;

	// no-cors means we don't get the response back - but we dont need it.
//crossDomain:true

	return fetch(c.API_BASE_URL + "cproxy.php", {
		crossDomain:true,
		method: 'POST',
		body: '',
		headers: {
			"Content-Type": "application/json; charset=utf-8",
			"X-Proxy-URL": forgotPasswordURL,
		}
	})
			.then(response => {


				switch (response.status) {
					case 200:

						dispatch(actionForgotpasswordSuccess());

						break;
					case 401:
						localStorage.removeItem('authToken');
						dispatch(actionAuthError('wrong credentials'));
						break;

					default:
						dispatch(actionAppStatusLoadError('Error 003 - response status error code '.response.status));
						break;
				}
			})
			.catch(err => {
				dispatch(actionAppStatusLoadError('Error on "actionResetPassword" ajax call'));
			});

}


export const actionResetPasswordFinalise = (userid, password, token) => (dispatch, getState) => {

	dispatch(actionAuthRequest());

	const params = new URLSearchParams({
		Username: userid,
		Password: password,
		Token: token,
	});

	let forgotPasswordURL = c.FORGOTPASSWORDFINALISE_URL;

	return fetch(c.API_BASE_URL + "cproxy.php", {
		method: 'POST',
		body: params,
		headers: {
			"Content-Type": "application/x-www-form-urlencoded; charset=utf-8",
			"X-Proxy-URL": forgotPasswordURL,
		}
	})
			.then(response => {

				switch (response.status) {
					case 200:

						dispatch(actionForgotpasswordFinaliseSuccess());

						break;
					case 401:
						localStorage.removeItem('authToken');
						dispatch(actionAuthError('wrong credentials'));
						break;

					default:
						dispatch(actionAppStatusLoadError('Error 004 - response status error code '.response.status));
						break;
				}
			})
			.catch(err => {
				dispatch(actionAppStatusLoadError('Error on "actionResetPasswordFinalise" ajax call'));
			});

}
