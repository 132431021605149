import React from 'react';
import queryString from 'query-string';

import { formatDate } from '../../../../common/_utility';
import * as c from '../../../../common/_constants';

const Title = ({
	search
}) => {
	const params = queryString.parse(search);
	return (
		<div className="mt-3">
			<div className="title">
				<div className="row">
					<div className="col-md-12">
						<h1>CPD Print Preview</h1>
					</div>
				</div>
			</div>
			<div>
				<div className="row justify-content-md-center">
					<div className="col-md-6 text-center mt-3">
						<p><img class="print-logo" src={c.URL_IMAGES_BASE + 'logo.png'} alt="" /></p>
						<h3 className="text-uppercase">
							{!params.start_time && !params.end_time
								? 'Total '
								: null
							}
							Cpd hours accumulated{' '}
							{params.start_time && params.end_time
								? 'between ' + formatDate(params.start_time, 'j F Y') + ' - ' + formatDate(params.end_time, 'j F Y')
								: null
							}
							{params.start_time && !params.end_time
								? 'from ' + formatDate(params.start_time, 'j F Y')
								: null
							}
							{!params.start_time && params.end_time
								? 'until ' + formatDate(params.end_time, 'j F Y')
								: null
							}
						</h3>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Title;