export const URL_BASE = '/portal';

export const URL_HOME = URL_BASE;
export const URL_LOGIN = URL_BASE + '/login/';
export const URL_FORGOTPASSWORD = URL_BASE + '/forgot-password/';
export const URL_DASHBOARD = URL_BASE + '/dashboard/';

export const URL_EVENTS_MY_EVENTS_ALL = URL_BASE + '/events/my-events/all/';
export const URL_EVENTS_MY_EVENTS_UPCOMING = URL_BASE + '/events/my-events/upcoming/';
export const URL_EVENTS_MY_EVENTS_PAST = URL_BASE + '/events/my-events/past/';
export const URL_EVENTS_MY_WEBINARS_UPCOMING = URL_BASE + '/events/my-webinars/upcoming/';
export const URL_EVENTS_MY_WEBINARS_PAST = URL_BASE + '/events/my-webinars/past/';

export const URL_RESOURCES_VIDEOS = URL_BASE + '/resources/videos/';
export const URL_RESOURCES_BOOKS = URL_BASE + '/resources/books/';
export const URL_RESOURCES_PAPER_AND_PRESENTATIONS_PURCHASED = URL_BASE + '/resources/papers-and-presentations/purchased/';
export const URL_RESOURCES_PAPER_AND_PRESENTATIONS_COMPLIMENTARY = URL_BASE + '/resources/papers-and-presentations/complimentary/';
export const URL_RESOURCES_PODCASTS = URL_BASE + '/resources/podcasts/';
export const URL_RESOURCES_CPD_ON_DEMAND_SELF_PACED_LEARNING = URL_BASE + '/resources/cpd-on-demand/self-paced-learning/';
export const URL_RESOURCES_CPD_ON_DEMAND_BUNDLES = URL_BASE + '/resources/cpd-on-demand/bundles/';

export const URL_MEMBERSHIP_BUSINESS_ALLIANCE_PROGRAM = 'https://www.taxinstitute.com.au/membership/benefits/access-to-services/business-alliance-partners-offers/partner-benefits-and-loyalty-program';
export const URL_MEMBERSHIP_MENTORING = URL_BASE + '/membership/mentoring/';
export const URL_MEMBERSHIP_PAPER_OF_THE_MONTH = URL_BASE + '/membership/tax-update-paper/';
export const URL_MEMBERSHIP_SPECIAL_TOPIC = URL_BASE + '/membership/special-topic-presentation/';
export const URL_MEMBERSHIP_SNAPSHOT_SERIES = 'https://www.taxinstitute.com.au/resources/books-and-journals/snapshot-series/snapshot-series';
export const URL_MEMBERSHIP_TAX_RATES_TABLE = 'https://www.taxinstitute.com.au/resources/member-benefits/tax-rates-table';
export const URL_MEMBERSHIP_TAXVINE = URL_BASE + '/membership/taxvine/';
// export const URL_MEMBERSHIP_MONTHLY_TAX_UPDATE = URL_BASE + '/membership/tax-insights/';
export const URL_MEMBERSHIP_MONTHLY_TAX_UPDATE = 'https://www.taxinstitute.com.au/resources/member-benefits/monthly-tax-insight';
export const URL_MEMBERSHIP_TAXATION_IN_AUSTRALIA = 'https://www.taxinstitute.com.au/resources/journals/taxation-in-australia/tia-access';//URL_BASE + '/membership/taxation-in-australia/';
export const URL_MEMBERSHIP_THE_TAX_SPECIALIST = URL_BASE + '/membership/the-tax-specialist/';
export const URL_MEMBERSHIP_AUSTRALIAN_TAX_FORUM = URL_BASE + '/membership/australian-tax-forum/';
export const URL_MEMBERSHIP_FEEDBACK =  'https://www.surveymonkey.com/r/TTIMEMBER';
export const URL_MEMBERSHIP_TAXWISE_NEWSLETTER = URL_BASE + '/membership/taxwise-newsletter/';

export const URL_EDUCATION_MY_ENROLLMENT = URL_BASE + '/education/my-enrollment';
export const URL_EDUCATION_LOGIN = 'https://taxinstitute.instructure.com/';
export const URL_EDUCATION_VIEW_COURSES = 'http://www.taxinstitute.com.au/education';

export const URL_PROFILE_ACCOUNT_DETAILS = URL_BASE + '/profile/account-details/';
export const URL_PROFILE_CPD_RECORD_STRUCTURED = URL_BASE + '/profile/cpd-record/structured/';
export const URL_PROFILE_CPD_RECORD_UNSTRUCTURED = URL_BASE + '/profile/cpd-record/unstructured/';
export const URL_PROFILE_CPD_RECORD_ADD = URL_BASE + '/profile/cpd-record/add/';
export const URL_PROFILE_CPD_RECORD_EDIT = URL_BASE + '/profile/cpd-record/edit/';
export const URL_PROFILE_CPD_RECORD_DELETE = URL_BASE + '/profile/cpd-record/delete/';
export const URL_PROFILE_CPD_RECORD_PRINT = URL_BASE + '/profile/cpd-record/print/';
export const URL_PROFILE_ORDER_HISTORY_ORDERS = URL_BASE + '/profile/order-history/orders/';
export const URL_PROFILE_ORDER_HISTORY_CANCELLED = URL_BASE + '/profile/order-history/cancelled/';
export const URL_PROFILE_ORDER_DETAILS = URL_BASE + '/profile/order-history/';
export const URL_PROFILE_RENEW_MEMBERSHIP = URL_BASE + '/profile/renew-membership/';
export const URL_PROFILE_SUBSCRIPTIONS_SUBSCRIBED = URL_BASE + '/profile/subscriptions/subscribed/';
export const URL_PROFILE_SUBSCRIPTIONS_EXPIRED = URL_BASE + '/profile/subscriptions/expired/';
export const URL_PROFILE_TAX_SPECIALIST_PROFILE = URL_BASE + '/profile/tax-specialist-profile/';
export const URL_PROFILE_LOGOS = URL_BASE + '/profile/logos/';
// export const URL_PROFILE_GIVE_FEEDBACK = URL_BASE + '/TODO/';
export const URL_PROFILE_LOGOUT = URL_BASE + '/profile/logout/';

export const MENU_EVENTS = [
	URL_EVENTS_MY_EVENTS_UPCOMING,
	URL_EVENTS_MY_EVENTS_PAST,
	URL_EVENTS_MY_WEBINARS_UPCOMING,
	URL_EVENTS_MY_WEBINARS_PAST,
];
export const MENU_RESOURCES = [
	URL_RESOURCES_VIDEOS,
	URL_RESOURCES_BOOKS,
	URL_RESOURCES_PAPER_AND_PRESENTATIONS_PURCHASED,
	URL_RESOURCES_PAPER_AND_PRESENTATIONS_COMPLIMENTARY,
	URL_RESOURCES_PODCASTS,
	URL_RESOURCES_CPD_ON_DEMAND_SELF_PACED_LEARNING,
	URL_RESOURCES_CPD_ON_DEMAND_BUNDLES,
];
export const MENU_MEMBERSHIP = [
	URL_MEMBERSHIP_BUSINESS_ALLIANCE_PROGRAM,
	URL_MEMBERSHIP_MENTORING,
	URL_MEMBERSHIP_PAPER_OF_THE_MONTH,
	URL_MEMBERSHIP_SNAPSHOT_SERIES,
	URL_MEMBERSHIP_TAX_RATES_TABLE,
	URL_MEMBERSHIP_TAXVINE,
	URL_MEMBERSHIP_TAXATION_IN_AUSTRALIA,
	URL_MEMBERSHIP_THE_TAX_SPECIALIST,
	URL_MEMBERSHIP_AUSTRALIAN_TAX_FORUM,
	URL_MEMBERSHIP_TAXWISE_NEWSLETTER,
];
export const MENU_EDUCATION = [
	URL_EDUCATION_MY_ENROLLMENT,
	URL_EDUCATION_LOGIN,
	URL_EDUCATION_VIEW_COURSES,
];
export const MENU_PROFILE = [
	URL_PROFILE_ACCOUNT_DETAILS,
	URL_PROFILE_CPD_RECORD_STRUCTURED,
	URL_PROFILE_CPD_RECORD_UNSTRUCTURED,
	URL_PROFILE_CPD_RECORD_ADD,
	URL_PROFILE_CPD_RECORD_EDIT,
	URL_PROFILE_CPD_RECORD_PRINT,
	URL_PROFILE_ORDER_HISTORY_ORDERS,
	URL_PROFILE_ORDER_HISTORY_CANCELLED,
	URL_PROFILE_ORDER_DETAILS,
	URL_PROFILE_RENEW_MEMBERSHIP,
	URL_PROFILE_SUBSCRIPTIONS_SUBSCRIBED,
	URL_PROFILE_SUBSCRIPTIONS_EXPIRED,
	URL_PROFILE_TAX_SPECIALIST_PROFILE,
	// URL_PROFILE_GIVE_FEEDBACK,
	URL_PROFILE_LOGOUT,
];
