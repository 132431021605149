import React, { Component } from 'react';
import { Element, scroller } from 'react-scroll';

class Articles extends Component {
	constructor(props) {
		super(props);

		this.state = {
			articleSelected: false
		}

		this.handleSelectArticle = this.handleSelectArticle.bind(this);

	}
	handleSelectArticle(article) {
		this.setState({ articleSelected: article });
		scroller.scrollTo('scrollTarget', {
			duration: 600,
			delay: 100,
			smooth: true,
			offset: 0,
		});
	}
	render() {
		const {
			data
		} = this.props;
		return(
			<div className="articles">
				<div className="row">
					<div className="col-md-12">
						<h3>This week in tax</h3>
						<p>Click on the links below to view this week's tax news:</p>
						<ul className="articles-list">
							{data.articles.items.map((item, index) => {
								return (
									<li key={index}>
										<button
											onClick={() => this.handleSelectArticle(item)}
											className="btn btn-link"
										>
											{item.title}
										</button>
									</li>
								);
							})}
						</ul>
					</div>
				</div>
				<Element name="scrollTarget">
					{this.state.articleSelected
						?
							<div className="row">
								<div className="col-md-12 article-content">
									<h3>{this.state.articleSelected.title}</h3>
									<div dangerouslySetInnerHTML={{ __html: this.state.articleSelected.body }}></div>
								</div>
							</div>
						: null
					}
				</Element>
			</div>
		);
	}
}

export default Articles;