import React from 'react';
import { NavLink } from 'react-router-dom';

import * as r from '../../../common/_routes';

const SubmenuMembership = ({
    voting_member,full_Member
}) => (
    <div className="submenu" id="submenu-membership">
        <h1>Membership Benefits</h1>
        <div className="row">
            <div className="col-md-12">
                <ul className="submenu-list">
                    <li>
                        <a href={r.URL_MEMBERSHIP_TAXATION_IN_AUSTRALIA} target="_blank" rel="noopener noreferrer">
                            Taxation in Australia journal
                        </a>
                    </li>
                    <li>
                        <NavLink to={r.URL_MEMBERSHIP_TAXVINE}>
                            TaxVine newsletter
                        </NavLink>
                    </li>
                    <li>
                        <a href={r.URL_MEMBERSHIP_MONTHLY_TAX_UPDATE} target="_blank" rel="noopener noreferrer">
                            Tax Insights
                        </a>
                    </li>
                    {/* <li>
                        <NavLink to={r.URL_MEMBERSHIP_MONTHLY_TAX_UPDATE}>
                            Tax Insights
                        </NavLink>
                    </li> */}
                    {/* <li>
                        <NavLink to={r.URL_MEMBERSHIP_PAPER_OF_THE_MONTH}>
                            Tax update paper
                        </NavLink>
                    </li> */}
                    {/* <li>
                        <NavLink to={r.URL_MEMBERSHIP_SPECIAL_TOPIC}>
                            Special topic presentation
                        </NavLink>
                    </li> */}

                    <li>
                        <a href={r.URL_MEMBERSHIP_TAX_RATES_TABLE} target="_blank" rel="noopener noreferrer">
                            Tax rates tables
                        </a>
                    </li>
                    { full_Member ?
                    <li>
                        <a href={r.URL_MEMBERSHIP_FEEDBACK} target="_blank" rel="noopener noreferrer">
                            Give Feedback  
                        </a>
                    </li> :	null
                    }
                    {/*<li>*/}
                    {/*    <NavLink to={r.URL_MEMBERSHIP_MENTORING}>*/}
                    {/*        Mentoring*/}
                    {/*    </NavLink>*/}
                    {/*</li>*/}
                    {/*<li>*/}
                    {/*    <a href={r.URL_MEMBERSHIP_SNAPSHOT_SERIES} rel="noopener noreferrer" target="_blank">*/}
                    {/*        Snapshot Series*/}
                    {/*    </a>*/}
                    {/*</li>*/}
                    {/* <li>*/}
                    {/*    <a href={r.URL_MEMBERSHIP_BUSINESS_ALLIANCE_PROGRAM} rel="noopener noreferrer" target="_blank">*/}
                    {/*        Business Alliance Program*/}
                    {/*    </a>*/}
                    {/*</li>*/}
                </ul>
            </div>
        </div>
    </div>
);

export default SubmenuMembership;