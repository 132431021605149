import React, { Component } from 'react';
import { connect } from 'react-redux';
import { hide } from 'redux-modal';
import { bindActionCreators } from 'redux';

// import { actionApiSendData } from '../../../../actions/apiData/index';
import { retrieveApiData } from '../../../../actions/apiData/index';
import { validEmail, handleGaTracking } from '../../../../common/_utility';
import * as m from '../../../../common/_messages';
import * as c from '../../../../common/_constants';

class FormSendInvoice extends Component {
	constructor(props) {
		super(props);

		this.state = {
			emailValue: '',
			emailError: false,
			sending: false,
			// responseMessage: false,
			responseMessage: false,
			dataSaved: false,
		}

		this.handleFieldChange = this.handleFieldChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.isFormValid = this.isFormValid.bind(this);

	}
	handleFieldChange(e) {
		const target = e.target;
		const value = target.type === 'checkbox' ? target.checked : target.value;
		const name = target.name;

		this.setState({ [name + 'Value']: value });
	}
	handleSubmit(e) {

		e.preventDefault();

		//validation
		if (!this.isFormValid()) {
			return;
		}

		this.setState({ sending : true });


		let url = c.API_BASE_URL + 'api/v1/ordersemail/' + this.props.data.id + '?email=' + this.state.emailValue;

		handleGaTracking('Order Details page - Email Order', 'Email Order');

		retrieveApiData(this.props.dispatch, (url)).then((success) => {
			this.setState({ sending: false });
			if (success) {
				this.setState({
					responseMessage: 'Email sent',
					dataSaved: true
				});
				//close popup after 3 secs
				let hidePopup = this.props.hide;
				setTimeout(
					function () {
						hidePopup('send-order-invoice')
					}, 2000
				);
			} else {
				this.setState({ responseMessage: 'something went wrong' });
			}
		})
	}
	isFormValid() {

		let formValid = true;

		//reset error states
		this.setState({
			emailError: false,
			responseMessage: false
		});

		if (!this.state.emailValue) {
			this.setState({ emailError: m.formError.email.required });
			formValid = false;
		} else {
			let emails = this.state.emailValue.split(',');

			let counter = 0;
			emails.forEach((email) => {

				if (!validEmail(email.trim())) {
					this.setState({ emailError: m.formError.email.notValidMultiple });
					formValid = false;
				}
				counter++;
			})

			if (counter > 5){
				this.setState({ emailError: m.formError.email.notValidMultipleTooMany });
				formValid = false;
			}
		}

		return formValid;
	}
	render() {
		return (
			<div className="edit-modal" id="send-order-invoice">
				<form onSubmit={this.handleSubmit}>
					<div className="header">
						<div className="row">
							<div className="col-md-12">
								<h2>Email Invoice</h2>
							</div>
						</div>
					</div>
					<div className="content">
						<div className="row">
							<div className="col-md-12">
								<p>To email this Order Confirmation, enter the email address below and click the button.</p>
								<p>(Multiple email addresses should be separated by commas, max 5)</p>
							</div>
						</div>
						<div className="row">
							<div className="col-md-12">
								<div className={"form-group" + (this.state.emailError ? ' has-error' : '')}>
									<label htmlFor="email">Email</label>
									<input
										type="text"
										id="email"
										name="email"
										value={this.state.emailValue}
										onChange={this.handleFieldChange}
										className="form-control"
									/>
									{this.state.emailError
										? <p className="form-error">{this.state.emailError}</p>
										: null
									}
								</div>
							</div>
						</div>
					</div>
					<div className="footer">
						<div className="row">
							<div className="col-md-12">
								{this.state.sending
									?
										<span>Sending <i className="fas fa-spinner fa-spin"></i></span>
									:
										<span>
											{!this.state.dataSaved
												?
													<span>
														<button
															className="btn btn-link-secondary"
															type="button"
															onClick={() => this.props.hide('send-order-invoice')}
														>
															Cancel
														</button>
														<button
															disabled={this.state.sending}
															className="btn btn-link"
															type="submit"
															value="Send"
														>
															Send
														</button>
													</span>
												:	null
											}
											{this.state.responseMessage
												? <p className="response-message">{this.state.responseMessage}</p>
												: null
											}
										</span>
								}
							</div>
						</div>
					</div>
				</form>
			</div>
		)
	}
}

function mapDispatchToProps(dispatch) {
	return {
		...bindActionCreators({ hide }, dispatch),
		dispatch
	};
}
export default connect(null, mapDispatchToProps)(FormSendInvoice);