import React, { Component } from "react";
import { connect } from "react-redux";
import queryString from "query-string";

import { actionGetApiDataKey } from "../../../../actions/apiData/index";
import * as c from "../../../../common/_constants";
import * as t from "../../../../common/_titles";

import Sorting from "../../../common/Sorting";
import Loading from "../../../common/Loading";

import Sections from "./Sections";
import Links from "../common/Links";

class Main extends Component {
  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.location.search !== prevProps.location.search
    ) {
      const params = queryString.parse(this.props.location.search);
      this.props.dispatch(
        actionGetApiDataKey(c.API_DATA.events_past.key, params)
      );
    }
  }

  componentDidMount() {
    document.title = t.TITLE_EVENTS_MY_EVENTS_PAST;

    const params = queryString.parse(this.props.location.search);
    this.props.dispatch(
      actionGetApiDataKey(c.API_DATA.events_past.key, params)
    );
  }

  render() {
    const { events, ePortalLink } = this.props;
    return (
      <div id="content-events-past">
        <div className="row">
          <div className="col-md-10">
            <h1>My Events</h1>
          </div>
          <div className="col-md-2 text-right">
            <ul className="title-actions mb-0">
              <li>
                <Sorting
                  size="large"
                  options={[
                    {
                      name: "End Date Ascending",
                      sortBy: "finish_at",
                      sortOrder: "asc",
                    },
                    {
                      name: "End Date Descending",
                      sortBy: "finish_at",
                      sortOrder: "desc",
                    },
                  ]}
                />
              </li>
            </ul>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <Links />
          </div>
        </div>
        {events ? (
          events.error ? (
            <div>
              <p>Sorry, an error occurred</p>
            </div>
          ) : events.loading ? (
            <Loading />
          ) : (
            <Sections data={events.data} ePortalLink={ePortalLink} />
          )
        ) : (
          <Loading />
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    events: state.apiData.events_past,
    ePortalLink: state.app.global.global_user.data.content.root_eportalurl,
  };
}

export default connect(mapStateToProps)(Main);
