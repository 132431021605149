import React from 'react';

import Table from './Table';
import OrderDetails from './OrderDetails';
import BillingDetails from './BillingDetails';

const Sections = (props) => {
	return (
		<div className="sections">
			<div className="row">
				<div className="col-md-12">
					<Table {...props} />
				</div>
			</div>
			<div className="row order-details-and-billing">
				<div className="col-md-6">
					<OrderDetails {...props} />
				</div>
				<div className="col-md-6">
					<BillingDetails {...props} />
				</div>
			</div>
		</div>
	);
}

export default Sections;