import React from 'react';

const TableItem = ({
	item
}) => {
	return (
		<tr>
			<td>{item.name}</td>
			<td>{item.description}</td>
			<td>{item.quantity}</td>
			<td>${item.unit_price}</td>
			<td>${item.total_price}</td>
		</tr>
	);
}

export default TableItem;