import React, { Component } from "react";
import { connect } from "react-redux";
import queryString from "query-string";

import { actionGetApiDataKey } from "../../../../actions/apiData/index";
import * as c from "../../../../common/_constants";
import * as t from "../../../../common/_titles";

import Sorting from "../../../common/Sorting";
import Loading from "../../../common/Loading";
import Links from "../common/Links";
import Sections from "./Sections";

class Main extends Component {


  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.location.search !== prevProps.location.search
    ) {
      const params = queryString.parse(this.props.location.search);
      this.props.dispatch(
        actionGetApiDataKey(c.API_DATA.webinars_upcoming.key, params)
      );
    }
  }

  componentDidMount() {
    document.title = t.TITLE_EVENTS_MY_WEBINARS_UPCOMING;

    const params = queryString.parse(this.props.location.search);
    this.props.dispatch(
      actionGetApiDataKey(c.API_DATA.webinars_upcoming.key, params)
    );
  }
  
  render() {
    const { webinars, ePortalLink } = this.props;

    return (
      <div id="content-webinars-upcoming">
        <div className="row">
          <div className="col-md-10">
            <h1>My Webinars</h1>
          </div>
          <div className="col-md-2 text-right">
            <ul className="title-actions mb-0">
              <li>
              <Sorting
                  size="large"
                  options={[
                    {
                      name: "End Date Ascending",
                      sortBy: "finish_at",
                      sortOrder: "asc",
                    },
                    {
                      name: "End Date Descending",
                      sortBy: "finish_at",
                      sortOrder: "desc",
                    },
                  ]}
                />
              </li>
            </ul>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <Links />
          </div>
        </div>
        {webinars ? (
          webinars.error ? (
            <div>
              <p>Sorry, an error occurred</p>
            </div>
          ) : webinars.loading ? (
            <Loading />
          ) : (
            <Sections data={webinars.data.content} ePortalLink={ePortalLink} />
          )
        ) : (
          <Loading />
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    webinars: state.apiData.webinars_upcoming,
    ePortalLink: state.app.global.global_user.data.content.root_eportalurl,
  };
}

export default connect(mapStateToProps)(Main);
