import React from 'react';
import { connect } from 'react-redux';
import { withRouter, NavLink } from 'react-router-dom';

import * as r from '../../common/_routes';

import SubmenuEvents from './menuPortal/SubmenuEvents';
import SubmenuResources from './menuPortal/SubmenuResources';
import SubmenuMembership from './menuPortal/SubmenuMembership';
import SubmenuEducation from './menuPortal/SubmenuEducation';

const MenuPortal = ({
	counters,
	events,
	location,
	TKE_access,
	TSJ_access,
	ATF_access,
	voting_member,
	rootUrl,
	full_Member
}) => {
	return (
		<div className="header-menu">
			<div className="row">
				<div className="col-md-12">
					<div id="app-menu" className="clearfix">
						<ul className="nav justify-content-center">
							<li className="nav-item">
								<NavLink to={r.URL_DASHBOARD} className="nav-link btn btn-menu">
									{/* <i className="fas fa-tachometer-alt"></i> */}
									<span>Dashboard</span>
								</NavLink>
							</li>
							<li className="nav-item dropdown">
								<button
									className={"nav-link btn btn-menu" + (r.MENU_MEMBERSHIP.includes(location.pathname) ? ' active' : '')}
									data-toggle="dropdown"
									type="button"
								>
									{/* <i className="fas fa-medal"></i> */}
									<span>Membership</span>
								</button>
								<div className="dropdown-menu">
									<SubmenuMembership voting_member={voting_member} full_Member={full_Member}/>
								</div>
							</li>
							<li className="nav-item dropdown">
								<button
									className={"nav-link btn btn-menu" + (r.MENU_EDUCATION.includes(location.pathname) ? ' active' : '')}
									data-toggle="dropdown"
									type="button"
								>
									{/* <i className="fas fa-graduation-cap"></i> */}
									<span>Education</span>
								</button>
								<div className="dropdown-menu">
									<SubmenuEducation />
								</div>
							</li>

							<li className="nav-item dropdown">
								<button
									className={"nav-link btn btn-menu" + (r.MENU_EVENTS.includes(location.pathname) ? ' active' : '')}
									data-toggle="dropdown" 
									type="button"
								>
									{/* <i className="fas fa-calendar-alt"></i> */}
									<span>Events</span>
								</button>
								<div className="dropdown-menu dropdown-menu-right">
									<SubmenuEvents 
										counters={counters} 
										events={events} 
										rootUrl={rootUrl}
									/>
								</div>
							</li>
							<li className="nav-item dropdown">
								<button
									className={"nav-link btn btn-menu" + (r.MENU_RESOURCES.includes(location.pathname) ? ' active' : '')}
									data-toggle="dropdown"
									type="button"
								>
									{/* <i className="fas fa-book"></i> */}
									<span>resources</span>
								</button>
								<div className="dropdown-menu dropdown-menu-right">
									<SubmenuResources counters={counters} TKE_access={TKE_access} TSJ_access={TSJ_access} ATF_access={ATF_access} />
								</div>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	)
}

function mapStateToProps(state) {
	return {
		rootUrl: state.app.global.global_user.data.content.root_url,
		counters: state.app.global.global_counters,
		events: state.app.global.global_key_events.data.content,
		TKE_access: state.app.global.global_user.data.content.membership_data.subscribeto_tke,
		TSJ_access: state.app.global.global_user.data.content.membership_data.subscribeto_taxspecialistjournal,
		ATF_access: state.app.global.global_user.data.content.membership_data.subscribeto_australiantaxforum,
		voting_member: state.app.global.global_user.data.content.membership_data.voting_member
	}
}

export default withRouter(connect(mapStateToProps, null)(MenuPortal))

