import { Component } from 'react';

class ProgressProvider extends Component {
	constructor(props) {
		super(props);

		this.state = {
			value: this.props.valueStart,
		}
	}

	componentDidMount() {
		this.timeout = window.setTimeout(() => {
			this.setState({
				value: this.props.valueEnd,
			});
		}, 200);
	}

	componentWillUnmount() {
		window.clearTimeout(this.timeout);
	}

	render() {
		return this.props.children(this.state.value);
	}
}

export default ProgressProvider;