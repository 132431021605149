import React from 'react';

import queryString from 'query-string';

import PathNavLink from '../../../common/PathNavLink';
import * as r from '../../../../common/_routes';

const Links = ({
	params
}) => {
	return (
		<ul className="menu-tabs">
			<li>
				<PathNavLink to={r.URL_PROFILE_CPD_RECORD_STRUCTURED + '?' + queryString.stringify(params)}>Structured</PathNavLink>
			</li>
			<li>
				<PathNavLink to={r.URL_PROFILE_CPD_RECORD_UNSTRUCTURED + '?' + queryString.stringify(params)}>Unstructured</PathNavLink>
			</li>
		</ul>
	);
}

export default Links;