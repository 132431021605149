import React, { Component } from 'react';
import { connect } from 'react-redux';

import { actionGetApiDataKey } from '../../../actions/apiData/index';
import * as c from '../../../common/_constants';
import * as t from '../../../common/_titles';

import Title from '../../dashboard/Title';
import Sections from './Sections';
import Loading from '../../common/Loading';

class Main extends Component {

	componentDidMount() {

		document.title = t.TITLE_PROFILE_ACCOUNT_DETAILS;

		this.props.dispatch(actionGetApiDataKey(c.API_DATA.profile_user.key));

	}
	render() {
		const {
			user,
			ePortalLink,
			profileData
		} = this.props;
		return (
			<div id="content-profile-account-details">
				<div className="row">
					<div className="col-md-12">
						<Title />
					</div>
				</div>
				{profileData
					? profileData.error
						? <div><p>Sorry, an error occurred</p></div>
						: profileData.loading
							? <Loading />
							: <Sections profile={profileData.data.content} choices={profileData.data.choices} ePortalLink={ePortalLink} user={user} />
					: <Loading />
				}
				
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		user: state.app.global.global_user.data,
		ePortalLink: state.app.global.global_user.data.content.root_eportalurl,
		profileData: state.apiData.profile_user,
	}
}

export default connect(mapStateToProps)(Main);
