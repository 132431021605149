import React, { Component } from 'react';
import { connect } from 'react-redux';

import { actionGetApiDataKey } from '../../../../actions/apiData/index';
import * as c from '../../../../common/_constants';
import * as t from '../../../../common/_titles';

import Title from '../common/Title';
import InfoBar from '../../../dashboard/Title';
import Intro from './Intro';
import Form from '../common/Form';

import PageNotFound from '../../../common/PageNotFound';
import Loading from '../../../common/Loading';

class Main extends Component {

	componentDidMount() {

		document.title = t.TITLE_PROFILE_CPD_RECORD_ADD;

		this.props.dispatch(actionGetApiDataKey(c.API_DATA.cpd_record_add.key));
	}
	render() {
		const {
			cpdRecord,
			user
		} = this.props;

		/* 404 if no permission */
		if (!user.content.membership_data.permissionto_addcpdrecord) {
			return <PageNotFound />;
		}
		
		return (
			<div id="content-profile-cpd-record-add">
				<div className="row">
					<div className="col-md-12">
						<Title user={user} />
					</div>
				</div>
				<div className="row">
					<div className="col-md-12">
						<InfoBar />
					</div>
				</div>
				<div className="row">
					<div className="col-md-12">
						<Intro />
					</div>
				</div>
				{cpdRecord
					? cpdRecord.error
						? <div><p>Sorry, an error occurred</p></div>
						: cpdRecord.loading
							? <Loading />
							: 
								<Form
									cpdRecord={cpdRecord}
									type="add"
								/>
					: <Loading />
				} 
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		cpdRecord: state.apiData.cpd_record_add,
		user: state.app.global.global_user.data,
	}
}

export default connect(mapStateToProps)(Main);
