import * as c from '../../common/_constants';

import {actionAuthError, actionGetAuth} from './auth';
import { actionLoadGlobal } from '../app/global';

const actionAppStatusLoadRequest = () => {
	return {
		type: c.APP_STATUS_LOAD_REQUEST
	}
}

const actionAppStatusLoadSuccess = () => {
	return {
		type: c.APP_STATUS_LOAD_SUCCESS
	}
}

export const actionAppStatusLoadError = (error) => {
	return {
		type: c.APP_STATUS_LOAD_ERROR,
		error
	}
}

function getCookie(cname) {
	var name = cname + "=";
	var decodedCookie = decodeURIComponent(document.cookie);
	var ca = decodedCookie.split(';');
	for (var i = 0; i < ca.length; i++) {
		var c = ca[i];
		while (c.charAt(0) === ' ') {
			c = c.substring(1);
		}
		if (c.indexOf(name) === 0) {
			return c.substring(name.length, c.length);
		}
	}
	return "";
}

 export const actionAppStatusLoad = () => (dispatch, getState) => {

	let token;

	var cookieSession = getCookie('currentMPSession');

	var AuthCookie = '';
	if (window.location.hostname === "localhost") {

		/*
		To log in from localhost:
			Go here:
			https://www.taxinstitute.com.au/
			Login with:
			username : Gsiswati
			password : Taxation123

			Then open a cookie manager and copy the entire value of the TTIAuth Cookie below.
			It is in the taxinstitute.com.au domain

			Then open the portal here:
			http://localhost:3000/portal/dashboard

			If it doesn't work - clear all cookies and try again
		 */
		AuthCookie = 'fetchforlocalhostdev';//getCookie('TTIAuth');
	}
	else
	{
		AuthCookie = getCookie('TTIAuth');
	}

	if ((cookieSession.length > 0) && (cookieSession === AuthCookie)){
		token = localStorage.getItem('authToken')
	} else {
		if (AuthCookie)
		{
			// user has logged in via TTI portal
			dispatch(actionGetAuth('authcookie', AuthCookie, 1));
		}
		else
		{
			window.location.href = process.env.REACT_APP_TTI_LOGIN_REDIRECT_URL;
		}
	}

	if(!token){
		dispatch(actionAuthError());
//		window.location.href = process.env.REACT_APP_TTI_LOGIN_REDIRECT_URL;
		return;
	}

	//dispatch(actionAuthSuccess());

	dispatch(actionAppStatusLoadRequest());


	getAppLoadData(dispatch)
	.then(() => {

		let state  = getState();

		if (!state.app.auth.granted){
			dispatch(actionAppStatusLoadSuccess());
			localStorage.removeItem('authToken');
			dispatch(actionAuthError());
		}else{

			let statusLoaded = true;

			//check everything has been loaded and I'm ready to start the app
			if (state.app.global.global_user.loading || state.app.global.global_user.error) {
				statusLoaded = false;
			}

			if (state.app.global.global_key_events.loading || state.app.global.global_key_events.error) {
				statusLoaded = false;
			}

			if (statusLoaded) {
				dispatch(actionAppStatusLoadSuccess());
				//load the counters here 
				actionLoadGlobal('global_counters', dispatch);
			} else {
				dispatch(actionAppStatusLoadError('Error 001 - Status not loaded'));
			}
		}


	})
	.catch(err => {
		dispatch(actionAppStatusLoadError('Error on "actionAppStatusLoad" ajax call'));
	});



}

const getAppLoadData = (dispatch) => {

	return Promise.all([
		actionLoadGlobal('global_user', dispatch ),
		actionLoadGlobal('global_key_events', dispatch ),
	])
}
