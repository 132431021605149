import React, { Component } from 'react';
import { connect } from 'react-redux';
import { hide } from 'redux-modal';
import { bindActionCreators } from 'redux';

import { validUrl, handleGaTracking } from '../../../common/_utility';
import { actionApiSendData } from '../../../actions/apiData/index';
import * as m from '../../../common/_messages';
import * as c from '../../../common/_constants';

class EditSocial extends Component {
	constructor(props) {
		super(props);

		let profile = this.props.taxSpecialistData.data.content;

		this.state = {
			taxProfileLogoValue: profile.tax_profile_logo,
			taxProfileLogoDeleteValue: false,
			taxProfileLogoError: false,
			facebookValue: profile.facebook,
			facebookError: false,
			twitterValue: profile.twitter,
			twitterError: false,
			linkedInValue: profile.linked_in,
			linkedInError: false,
			responseMessage: false,
			dataSaved: false,

			pictures: []
		}

		this.handleFieldChange = this.handleFieldChange.bind(this);
		this.handleSelectChange = this.handleSelectChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.isFormValid = this.isFormValid.bind(this);
		this.handleFieldUploadChange = this.handleFieldUploadChange.bind(this);
		this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
		// this.onDrop = this.onDrop.bind(this);

	}
	// onDrop(picture) {

	// 	console.log(picture);

	// 	this.setState({
	// 		pictures: this.state.pictures.concat(picture),
	// 	});
	// }
	handleFieldUploadChange(e) {

		let reader = new FileReader();
		let file = e.target.files[0];

		reader.onloadend = () => {
			this.setState({
				taxProfileLogoValue: reader.result
			});
		}

		reader.readAsDataURL(file)

	}
	handleFieldChange(e) {
		const target = e.target;
		const value = target.type === 'checkbox' ? target.checked : target.value;
		const name = target.name;

		this.setState({ [name + 'Value']: value });
	}
	handleCheckboxChange(e) {
		const target = e.target;

		if (target.checked) {
			this.setState({ taxProfileLogoDeleteValue: true });
		} else {
			this.setState({ taxProfileLogoDeleteValue: false });
		}
	}
	handleSelectChange(optionSelected, { name }) {
		optionSelected
			? this.setState({ [name + 'Value']: optionSelected.value })
			: this.setState({ [name + 'Value']: null })
	}
	handleSubmit(e) {

		e.preventDefault();

		//validation
		if (!this.isFormValid()) {
			return;
		}

		let data = {
			tax_profile_logo: this.state.taxProfileLogoDeleteValue ? '' : this.state.taxProfileLogoValue,
			facebook: this.state.facebookValue,
			twitter: this.state.twitterValue,
			linked_in: this.state.linkedInValue,
		}

		handleGaTracking('Tax Specialist Profile page - Social Save', 'Social Save');

		this.props.dispatch(
			actionApiSendData(c.API_DATA.tax_specialist_profile.key, data)
		).then((success) => {
			if (success) {
				this.setState({
					responseMessage: 'Saved',
					dataSaved: true
				});
				//close popup after 3 secs
				let hidePopup = this.props.hide;
				setTimeout(
					function () {
						hidePopup('edit-tax-specialist-profile-social')
					}, 2000
				);
			} else {
				this.setState({ responseMessage: 'something went wrong' });
			}
			this.setState({ taxProfileLogoValue: data.tax_profile_logo });
		});
	}
	isFormValid() {

		let formValid = true;

		//reset error states
		this.setState({
			facebookError: false,
			twitterError: false,
			linkedInError: false,
			responseMessage: false
		});

		if (!this.state.facebookValue) {
			// this.setState({ facebookError: m.formError.facebook.required });
			// formValid = false;
		}else{
			if (!validUrl(this.state.facebookValue)){
				this.setState({ facebookError: m.formError.url.notValid });
				formValid = false;
			}
		}
		if (!this.state.twitterValue) {
			// this.setState({ twitterError: m.formError.twitter.required });
			// formValid = false;
		} else {
			if (!validUrl(this.state.twitterValue)) {
				this.setState({ twitterError: m.formError.url.notValid });
				formValid = false;
			}
		}
		if (!this.state.linkedInValue) {
			// this.setState({ linkedInError: m.formError.linkedIn.required });
			// formValid = false;
		} else {
			if (!validUrl(this.state.linkedInValue)) {
				this.setState({ linkedInError: m.formError.url.notValid });
				formValid = false;
			}
		}

		return formValid;
	}
	render() {
		return (
			<div className="edit-modal" id="edit-tax-specialist-profile-social">
				<form onSubmit={this.handleSubmit}>
					<div className="header">
						<div className="row">
							<div className="col-md-12">
								<h4>Edit Tax Specialist Profile Information</h4>
							</div>
						</div>
					</div>
					<div className="content">
						<div className="row">
							<div className="col-md-12">
								<div className={"form-group" + (this.state.taxProfileLogoError ? ' has-error' : '')}>
									<label htmlFor="taxProfileLogo">Tax Profile Logo</label>
									<input
										type="file"
										id="taxProfileLogo"
										name="taxProfileLogo"
										// value={this.state.taxProfileLogoValue}
										onChange={this.handleFieldUploadChange}
										className="form-control-file"
									/>
									<div className="preview">
										{this.state.taxProfileLogoValue
											? 
												<div>
													<img src={this.state.taxProfileLogoValue} alt="" />
													<div className="form-check form-group">
														<input
															type="checkbox"
															id="taxProfileLogoDelete"
															name="taxProfileLogoDelete"
															onChange={this.handleCheckboxChange}
															checked={this.state.taxProfileLogoDeleteValue}
															className="form-check-input"
														/>
														<label className="form-check-label" htmlFor="taxProfileLogoDelete">Delete image</label>
													</div>
												</div>
											: null
										}
									</div>
									{this.state.taxProfileLogoError
										? <p className="form-error">{this.state.taxProfileLogoError}</p>
										: null
									}
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col-md-12">
								<div className={"form-group" + (this.state.facebookError ? ' has-error' : '')}>
									<label htmlFor="facebook">Facebook URL</label>
									<input
										type="text"
										id="facebook"
										name="facebook"
										value={this.state.facebookValue}
										onChange={this.handleFieldChange}
										className="form-control"
									/>
									{this.state.facebookError
										? <p className="form-error">{this.state.facebookError}</p>
										: null
									}
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col-md-12">
								<div className={"form-group" + (this.state.twitterError ? ' has-error' : '')}>
									<label htmlFor="twitter">Twitter URL</label>
									<input
										type="text"
										id="twitter"
										name="twitter"
										value={this.state.twitterValue}
										onChange={this.handleFieldChange}
										className="form-control"
									/>
									{this.state.twitterError
										? <p className="form-error">{this.state.twitterError}</p>
										: null
									}
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col-md-12">
								<div className={"form-group" + (this.state.linkedInError ? ' has-error' : '')}>
									<label htmlFor="linkedIn">LinkedIn URL</label>
									<input
										type="text"
										id="linkedIn"
										name="linkedIn"
										value={this.state.linkedInValue}
										onChange={this.handleFieldChange}
										className="form-control"
									/>
									{this.state.linkedInError
										? <p className="form-error">{this.state.linkedInError}</p>
										: null
									}
								</div>
							</div>
						</div>
					</div>

					<div className="footer">
						<div className="row">
							<div className="col-md-12">
								{this.props.taxSpecialistData.saving
									?
										<span>Saving <i className="fas fa-spinner fa-spin"></i></span>
									:
										<span>
											{!this.state.dataSaved
												?
													<span>
														<button
														disabled={this.props.taxSpecialistData.saving}
															className="btn btn-link-secondary"
															type="button"
															onClick={() => this.props.hide('edit-tax-specialist-profile-social')}
														>
															Cancel
														</button>
														<button
															disabled={this.props.taxSpecialistData.saving}
															className="btn btn-link"
															type="submit"
															value="Save"
														>
															Save
														</button>
													</span>
												: null
											}
											{this.state.responseMessage
												? <p className="response-message">{this.state.responseMessage}</p>
												: null
											}
										</span>
								}
							</div>
						</div>
					</div>
				</form>
			</div>
		)
	}
}

function mapStateToProps(state) {
	return {
		taxSpecialistData: state.apiData.tax_specialist_profile,
	}
}
function mapDispatchToProps(dispatch) {
	return {
		...bindActionCreators({ hide }, dispatch),
		dispatch
	};
}
export default connect(mapStateToProps, mapDispatchToProps)(EditSocial);