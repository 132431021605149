import React, { Component } from 'react';
import { connect } from 'react-redux';
import { hide } from 'redux-modal';
import { bindActionCreators } from 'redux';

import { refreshGlobalEndpointData } from '../../../../actions/app/global';
import { actionApiSendData, actionGetApiDataUrl } from '../../../../actions/apiData/index';
import * as c from '../../../../common/_constants';

class FormDelete extends Component {
	constructor(props) {
		super(props);

		this.state = {
			saving: false,
			responseMessage: false,
			deletedSuccess: false
		}

		this.handleSubmit = this.handleSubmit.bind(this);

	}
	handleSubmit(e) {

		e.preventDefault();

		this.setState({ saving: true });

		let url = c.API_URL + c.API_DATA.cpd_record_edit.endpoint + this.props.item.id;

		this.props.dispatch(
			actionApiSendData(c.API_DATA.cpd_record_edit.key, null, url, 'DELETE')
		).then((success) => {
			this.setState({ saving: false });
			if (success) {
				this.setState({ deletedSuccess: true });

				//refresh based on the deleted record type
				if (this.props.item.training_type_id === 1) {
					this.props.dispatch(
						//refresh structured listing
						actionGetApiDataUrl(
							c.API_DATA.cpd_record_structured.key,
							c.API_URL + c.API_DATA.cpd_record_structured.endpoint,
							true
						)
					);
				}
				if (this.props.item.training_type_id === 2) {
					this.props.dispatch(
						//refresh unstructured listing
						actionGetApiDataUrl(
							c.API_DATA.cpd_record_unstructured.key,
							c.API_URL + c.API_DATA.cpd_record_unstructured.endpoint,
							true
						)
					);
				}
				//also get user data again, to fix cpd Hours
				refreshGlobalEndpointData('global_user', this.props.dispatch);

				setTimeout(() => {
					this.props.hide('delete-cpd-record');
				}, 2000);
			} else {
				this.setState({ responseMessage: 'something went wrong' });
			}
		});
	}
	render() {
		return (
			<div className="edit-modal" id="delete-cpd-record">
				<form onSubmit={this.handleSubmit}>
					<div className="header">
						<div className="row">
							<div className="col-md-12">
								<h2>Delete CPD Record</h2>
							</div>
						</div>
					</div>
					<div className="content">
						<div className="row">
							<div className="col-md-12">
								{this.state.deletedSuccess
									? <p>Record deleted succesfully</p>
									: <p>Are you sure?</p>
								}
							</div>
						</div>
					</div>
					<div className="footer">
						<div className="row">
							<div className="col-md-12">
								{this.state.saving
									? <i className="fas fa-spinner fa-spin"></i>
									: 
										<span>

										{this.state.deletedSuccess
											? null
											:
												<span>
													<button
														className="btn btn-link"
														type="button"
														onClick={() => this.props.hide('delete-cpd-record')}
													>
														{this.state.deletedSuccess ? 'Close' : 'Cancel'}
													</button>
													<button
														disabled={this.state.saving}
														className="btn btn-primary"
														type="submit"
														value="Save"
													>
														Confirm Delete
													</button>
												</span>
											
										}
										</span>
								}
								
								{this.state.responseMessage
									? <p className="response-message">{this.state.responseMessage}</p>
									: null
								}
							</div>
						</div>
					</div>
				</form>
			</div>
		)
	}
}
function mapStateToProps(state) {
	return {
		profileUser: state.apiData.profile_user,
	}
}
function mapDispatchToProps(dispatch) {
	return {
		...bindActionCreators({ hide }, dispatch),
		dispatch
	};
}
export default connect(mapStateToProps, mapDispatchToProps)(FormDelete);