import React from 'react';
import { Link } from 'react-router-dom';
import queryString from 'query-string';

import FormSendCpdHours from './FormSendCpdHours';
import * as r from '../../../../common/_routes';

const Title = ({
	params,
	user,
	show
}) => {
	return (
		<div className="title">
			<div className="row">
				<div className="col-md-10">
					<h1 className="mb-0">CPD Record</h1>
				</div>
				<div className="col-md-2 text-right">
					<ul className="title-actions mb-0">
						{user.content.membership_data.permissionto_addcpdrecord
							?
								<li>
									<Link to={r.URL_PROFILE_CPD_RECORD_ADD}><i className="fas fa-plus-circle"></i></Link>
								</li>
							:	null
						}
						<li>
							<Link to={r.URL_PROFILE_CPD_RECORD_PRINT + '?' + queryString.stringify(params)}><i className="fas fa-print"></i></Link>
						</li>
						<li>
							<button
								className="btn p-0"
								onClick={() =>
									show(
										'send-cpd-record',
										{ content: <FormSendCpdHours params={params} /> }
									)
								}
							>
								<i className="far fa-envelope"></i>
							</button>
						</li>
					</ul>
				</div>
			</div>
		</div>
	);
}

export default Title;