import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { formatDate } from '../../common/_utility';
import * as r from '../../common/_routes';

class GeneralListItem extends Component {
	
	render() {
		const {
			item
		} = this.props;
		return (
			<div className="item">
				<Link to={r.URL_MEMBERSHIP_TAXVINE + item.id}>
					<div className="row">
						<div className="col-md-6">
							Issue {item.issue}
						</div>
						<div className="col-md-6 text-right">
							{formatDate(item.issued_at, 'd F Y')}
						</div>
					</div>
				</Link>
				<div className="col-md-12">
					<hr className="m-0" />
				</div>
			</div>
		);
	}
}

export default GeneralListItem;
