import React, { Component } from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string';

import { actionGetApiDataKey } from '../../../actions/apiData/index';

import * as c from '../../../common/_constants';
import * as t from '../../../common/_titles';

import Sections from './Sections';
import Loading from '../../common/Loading';

class Main extends Component {

	componentDidUpdate(){

		if (this.props.books && !this.props.books.error && !this.props.books.loading){
			const params = queryString.parse(this.props.location.search);
			this.props.dispatch(actionGetApiDataKey(c.API_DATA.resources_books.key, params));
		}
	}
	
	componentDidMount() {

		document.title = t.TITLE_RESOURCES_BOOKS;

		const params = queryString.parse(this.props.location.search);
		this.props.dispatch(actionGetApiDataKey(c.API_DATA.resources_books.key, params));

	}
	render() {
		const {
			books,
			rootUrl
		} = this.props;
		return (
			<div id="content-resources-books">
				<div className="row">
					<div className="col-md-12">
						<h1>My Books</h1>
					</div>
				</div>
				{books
					? books.error
						? <div><p>Sorry, an error occurred</p></div>
						: books.loading
							? <Loading />
							: <Sections data={books.data.content} rootUrl={rootUrl} />
					: <Loading />
				}
				
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		books: state.apiData.resources_books,
		rootUrl: state.app.global.global_user.data.content.root_url,
	}
}

export default connect(mapStateToProps)(Main);
