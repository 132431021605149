import React from 'react';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import queryString from 'query-string';

import * as c from '../../common/_constants';

const Pagination = ({
	data,
	location
}) => {

	const totalPages = data.page_count;
	const totalItems = data.total_items;

	const countOptions = c.DEFAULT_PAGINATION_OPTIONS;
	
	let query = queryString.parse(location.search);
	const currentPage = query.page ? parseInt(query.page, 10) : 1;
	const currentCount = query.count ? parseInt(query.count, 10) : countOptions[0] ;

	//base existing query for items count
	let queryCount = queryString.parse(location.search);
	//remove page parameter because on selecting items count we always start from first page
	delete queryCount.page;
	//also remove items count parameters
	delete queryCount.count;
	
	//base existing query for page
	let querypage = queryString.parse(location.search);
	delete querypage.page;


	const pages = [];
	for (let x = 1; x <= totalPages; x++) {
		pages.push(x);
	}

	//parameters links pagination
	//first page
	const paramsFirstPage = queryString.stringify(querypage);

	//prev page
	delete querypage.page;
	if (currentPage > 2){
		querypage.page = currentPage - 1;
	}
	const paramsPrevPage = (queryString.stringify(querypage));
	
	//next page
	querypage.page = currentPage + 1;
	const paramsNextPage = (queryString.stringify(querypage));

	//last page
	querypage.page = totalPages;
	const paramsLastPage = (queryString.stringify(querypage));


	return (
		<div className="pagination-section">
			{totalItems > c.DEFAULT_PAGINATION_COUNT
				?
					<div className="row">
						<div className="col-md-2">
							<div className="dropdown pagination-items-counter">
								<button className="btn btn-light dropdown-toggle" data-toggle="dropdown" >
									{currentCount}
								</button>
								<div className="dropdown-menu pagination-items-counter-list">
									{countOptions.map((item) => {
										queryCount.count = item;
										let current = item === currentCount;
										return (
											<Link
												key={item}
												className={"dropdown-item" + (current ? ' active' : '')}
												to={location.pathname + '?' + queryString.stringify(queryCount)}
											>
												{item}
											</Link>
										)
									})}
								</div>
							</div>
						</div>
						<div className="col-md-10">
							<div className="pagination-list">
								<ul className="pagination justify-content-end">

									{/* first page */}
									<li className={"page-item" + (currentPage === 1 ? ' disabled' : '')}>
										<Link
											className="page-link"
											to={location.pathname + (paramsFirstPage ? '?' + paramsFirstPage : '')}
										>
											<i className="fas fa-fast-backward"></i>
										</Link>
									</li>

									{/* prev page */}
									<li className={"page-item" + (currentPage === 1 ? ' disabled' : '')}>
										<Link
											className="page-link"
											to={location.pathname + (paramsPrevPage ? '?' + paramsPrevPage : '')}
										>
											<i className="fas fa-step-backward"></i>
										</Link>
									</li>

									{pages.map((counter) => {

										delete querypage.page;
										if(counter !== 1){
											querypage.page = counter;
										}
										let params = queryString.stringify(querypage);


										let url = location.pathname + (params ? '?' + params : '');
										let link = <Link to={url} className="page-link">{counter}</Link>

										return (
											<li
												className={"page-item" + (currentPage === counter ? ' disabled' : '')}
												key={counter}
											>
												{link}
											</li>
										)
									})}

									{/* next page */}
									<li className={"page-item" + (currentPage === totalPages ? ' disabled' : '')}>
										<Link
											className="page-link"
											to={location.pathname + (paramsNextPage ? '?' + paramsNextPage : '')}
										>
											<i className="fas fa-step-forward"></i>
										</Link>
									</li>

									{/* last page */}
									<li className={"page-item" + (currentPage === totalPages ? ' disabled' : '')}>
										<Link
											className="page-link"
											to={location.pathname + (paramsLastPage ? '?' + paramsLastPage : '')}
										>
											<i className="fas fa-fast-forward"></i>
										</Link>
									</li>
								</ul>
							</div>
						</div>
					</div>
				:	null
			}
		</div>
	);
}

export default withRouter(Pagination);