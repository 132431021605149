import React from 'react';
import { NavLink } from 'react-router-dom';

import * as r from '../../../../common/_routes';

const Links = (props) => {
	return (
		<ul className="menu-tabs">
			<li>
				<NavLink to={r.URL_RESOURCES_PAPER_AND_PRESENTATIONS_PURCHASED}>Purchased papers</NavLink>
			</li>
			<li>
				<NavLink to={r.URL_RESOURCES_PAPER_AND_PRESENTATIONS_COMPLIMENTARY}>Complimentary papers</NavLink>
			</li>
		</ul>
	);
}

export default Links;