import React, { Component } from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string';
import { bindActionCreators } from 'redux';
import { show } from 'redux-modal';

import { actionGetApiDataKey } from '../../../../actions/apiData/index';
import * as c from '../../../../common/_constants';
import * as t from '../../../../common/_titles';

import Title from '../common/Title';
import Sections from '../common/Sections';
import InfoBar from '../../../dashboard/Title';
import IntroListing from '../common/IntroListing';
import View from '../common/View';
import Links from '../common/Links';
import Loading from '../../../common/Loading';

class Main extends Component {

	componentDidUpdate() {

		if (this.props.cpdRecord && !this.props.cpdRecord.error && !this.props.cpdRecord.loading) {
			const params = queryString.parse(this.props.location.search);
			
			//remove parameter type as it is only internal
			delete params.query_type;

			this.props.dispatch(actionGetApiDataKey(c.API_DATA.cpd_record_unstructured.key, params));
		}
	}

	componentDidMount() {

		document.title = t.TITLE_PROFILE_CPD_RECORD_UNSTRUCTURED;

		const params = queryString.parse(this.props.location.search);
		
		//remove parameter type as it is only internal
		delete params.query_type;

		this.props.dispatch(actionGetApiDataKey(c.API_DATA.cpd_record_unstructured.key, params));

	}
	render() {
		const {
			cpdRecord, 
			user,
			show
		} = this.props;

		//prepare params for structured/unstructured links
		const params = queryString.parse(this.props.location.search);
		delete params.page;

		return (
			<div id="content-profile-cpd-record-unstructured">
				<div className="row">
					<div className="col-md-12">
						<Title 
							show={show} 
							params={params} 
							user={user} 
							/>
					</div>
				</div>
				<div className="row">
					<div className="col-md-12">
						<InfoBar />
					</div>
				</div>
				<div className="row">
					<div className="col-md-12">
						<IntroListing user={user} />
					</div>
				</div>
				<div className="row">
					<div className="col-md-12">
						<View />
					</div>
				</div>
				<div className="row">
					<div className="col-md-12">
						<Links params={params} />
					</div>
				</div>
				{cpdRecord
					? cpdRecord.error
						? <div><p>Sorry, an error occurred</p></div>
						: cpdRecord.loading
							? <Loading />
							: <Sections data={cpdRecord.data.content} choices={cpdRecord.data.choices} type="unstructured" />
					: <Loading />
				}
				
			</div>
		);
	}
}

function mapDispatchToProps(dispatch) {
	return {
		...bindActionCreators({ show }, dispatch),
		dispatch
	};
}

function mapStateToProps(state) {
	return {
		cpdRecord: state.apiData.cpd_record_unstructured,
		user: state.app.global.global_user.data,
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Main);
