import React, { Component } from 'react';
import Collapse from 'react-css-collapse';

import * as c from '../../../../common/_constants';

class ListItem extends Component {
	constructor(props) {
		super(props);

		this.state = {
			showMore: false,
		}

		this.handleCollapse = this.handleCollapse.bind(this);
	}
	handleCollapse() {
		this.setState({ showMore: !this.state.showMore });
	}
	render() {
		const {
			item,
		} = this.props;
		return (
			<div className="row item">
				<div className="col-md-12">
					<div className="accordion" onClick={this.handleCollapse}>
						<div className="accordion-header p-3">
							<i className={"fas " + (this.state.showMore ? 'fa-sort-up' : 'fa-sort-down')}></i>
							<span className="pl-4">{item.title}</span>
						</div>
						<Collapse isOpen={this.state.showMore} transition={c.COLLAPSE_TRANSITION}>
							<div className="accordion-body p-3">
								<p>{item.teaser}</p>
								<a href={item.module_uri} rel="noopener noreferrer" target="_blank">Access Module</a>
							</div>
						</Collapse>
					</div>
				</div>
			</div>
		);
	}
}

export default ListItem;