import React, { Component } from 'react';
import Collapse from 'react-css-collapse';
import { formatDate, handleGaTracking } from '../../../common/_utility';
import * as c from '../../../common/_constants';

class ListItem extends Component {
	constructor(props) {
		super(props);

		this.state = {
			showMore: false,
		}

		this.handleCollapse = this.handleCollapse.bind(this);
	}
	handleCollapse() {

		handleGaTracking('Podcast page - Click ' + (this.state.showMore ? 'Close' : 'Open') + ' Details', this.props.item.title);

		this.setState({ showMore: !this.state.showMore });
	}
	render() {
		const {
			item,
		} = this.props;
		return (
			<div className="row item">
				<div className="col-md-2">
					<div className="image-wrapper mb-3 text-center">
						<img src={c.URL_IMAGES_BASE + 'icon-podcast.svg'} alt=""/>
					</div>
					<div className="expires text-small text-center mt-3">
						<span className="text-bold">Expires:</span> {formatDate(item.access_info.subscribed_until, 'd/m/Y')}
					</div>
				</div>
				<div className="col-md-6">
					<h4 className="m-0">{item.title}</h4>
						{formatDate(item.published_at, 'j F Y')} | Source: {item.source}
						<br />
						Presented at: <a href={item.presentation.link} rel="noopener noreferrer" target="_blank">{item.presentation.title}</a>
					<div className="topics">
						<button
							onClick={this.handleCollapse}
							className="btn btn-link"
						>
							{"This podcast covers "}
							<i className={"fas " + (this.state.showMore ? 'fa-sort-up' : 'fa-sort-down')}></i>
						</button>
						<Collapse isOpen={this.state.showMore} transition={c.COLLAPSE_TRANSITION}>

							<div className="materials-extra p-3">
								<div dangerouslySetInnerHTML={{ __html: item.presentation.teaser }}></div>
							</div>
							
						</Collapse>
					</div>
				</div>
				<div className="col-md-4">
					{item.media.link
						? <a onClick={() => handleGaTracking('Podcast page - Click Listen', item.title)} href={item.media.link} rel="noopener noreferrer" target="_blank" className="btn btn-primary mb-3">Listen</a>
						: null
					}
					<div className="purchased">
						<span className="text-bold">Purchased:</span> {formatDate(item.access_info.subscribed_at, 'd/m/Y')}
					</div>
				</div>
				<div className="mb-4 mt-4">
					<hr />
				</div>
			</div>
		);
	}
}

export default ListItem;