//APP CONSTANTS

export const WWW_BASE_URL = process.env.REACT_APP_TTI_URL;

export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
export const URL_IMAGES_BASE = process.env.REACT_APP_URL_IMAGES_BASE;
export const URL_CDNIMAGES_BASE = "https://tticdn.blob.core.windows.net/tti-images";
export const URL_EPORTAL_BASE = process.env.REACT_APP_EPORTAL_BASE_URL;
export const URL_CRM_BASE = process.env.REACT_APP_CRM_BASE_URL;
export const GA_CODE = process.env.REACT_APP_GA_CODE;
export const API_TYPE = 'api/';
export const AUTH_TYPE = 'oauth2/';
export const API_VERSION = 'v1/';
export const API_URL = API_BASE_URL + API_TYPE + API_VERSION;
export const AUTH_URL = API_BASE_URL + AUTH_TYPE + API_VERSION;
export const FORGOTPASSWORD_URL = URL_CRM_BASE + "AptifyServicesAPI/services/Authentication/PasswordResetRequest/Web";
export const FORGOTPASSWORDFINALISE_URL = URL_CRM_BASE + "AptifyServicesAPI/services/Authentication/PasswordReset/Web";
export const LINKEDIN_CLIENT_ID = "86g6bntjuvfl7y";
export const LINKEDIN_REDIRECT_URL = process.env.REACT_APP_LINKEDIN_REDIRECT_URL;
export const DEFAULT_PAGINATION_COUNT = 25;
export const DEFAULT_PAGINATION_OPTIONS = [25,50,75];

export const APP_STATUS_LOAD_REQUEST = 'APP_STATUS_LOAD_REQUEST';
export const APP_STATUS_LOAD_SUCCESS = 'APP_STATUS_LOAD_SUCCESS';
export const APP_STATUS_LOAD_ERROR = 'APP_STATUS_LOAD_ERROR';

export const ACTION_GET_API_GLOBAL_INIT = 'ACTION_GET_API_GLOBAL_INIT';
export const ACTION_GET_API_GLOBAL_LOAD = 'ACTION_GET_API_GLOBAL_LOAD';
export const ACTION_GET_API_GLOBAL_ERROR = 'ACTION_GET_API_GLOBAL_ERROR';

export const ACTION_RESET_API_DATA = 'ACTION_RESET_API_DATA';
export const ACTION_GET_API_DATA_INIT = 'ACTION_GET_API_DATA_INIT';
export const ACTION_GET_API_DATA_LOAD = 'ACTION_GET_API_DATA_LOAD';
export const ACTION_GET_API_DATA_ERROR = 'ACTION_GET_API_DATA_ERROR';

export const ACTION_API_SEND_DATA_INIT = 'ACTION_API_SEND_DATA_INIT';
export const ACTION_API_SEND_DATA_LOAD = 'ACTION_API_SEND_DATA_LOAD';
export const ACTION_API_SEND_DATA_ERROR = 'ACTION_API_SEND_DATA_ERROR';

export const ACTION_AUTH_REQUEST = 'ACTION_AUTH_REQUEST';
export const ACTION_AUTH_SUCCESS = 'ACTION_AUTH_SUCCESS';
export const ACTION_AUTH_ERROR = 'ACTION_AUTH_ERROR';
export const ACTION_SET_AUTH = 'ACTION_SET_AUTH';

export const ACTION_FORGOTPASSWORD_REQUEST = 'ACTION_FORGOTPASSWORD_REQUEST';
export const ACTION_FORGOTPASSWORD_SUCCESS = 'ACTION_FORGOTPASSWORD_SUCCESS';
export const ACTION_FORGOTPASSWORDFINALISE_SUCCESS = 'ACTION_FORGOTPASSWORDFINALISE_SUCCESS';

export const ACTION_REFRESH_GLOBAL_ENDPOINT_DATA = 'ACTION_REFRESH_GLOBAL_ENDPOINT_DATA';

export const EXPIRY_INTERVAL_DEFAULT = 1000 * 60 * 5; // 5 minutes (in milliseconds)
export const COLLAPSE_TRANSITION = "height 250ms cubic-bezier(.4, 0, .2, 1)"; //CSS transition
export const MONTHS_OPTION = ['', 'Jan','Feb','Mar','Apr','May','Jun', 'Jul','Aug', 'Sep','Oct', 'Nov','Dec']

export const API_DATA =  {
	auth: {
		key: 'auth',
		endpoint: 'token',
	},
	global_user: {
		key: 'global_user',
		endpoint: 'user/me/',
	},
	global_counters: {
		key: 'global_counters',
		endpoint: 'portal/counter/',
	},
	global_key_events: {
		key: 'global_key_events',
		endpoint: 'events/key/upcoming',
		endpointParams: {
			count: '3', 
			sort_by: 'start_at',
			sort_order: 'asc',
		},
	},
	dashboard_events_upcoming: {
		key: 'dashboard_events_upcoming',
		endpoint: 'events/all',
		endpointParams: {
			count: '3',
			sort_by: 'start_at',
			sort_order: 'asc',
		},
	},
	dashboard_taxation_in_australia: {
		key: 'dashboard_taxation_in_australia',
		endpoint: 'publication/taxation-in-australia/',
		endpointParams: {
			count: '4',
			sort_by: 'issued_at',
			sort_order: 'desc',
		},
	},
	dashboard_tax_specialist: {
		key: 'dashboard_tax_specialist',
		endpoint: 'publication/tax-specialist/',
		endpointParams: {
			count: '4',
			sort_by: 'issued_at',
			sort_order: 'desc',
		},
	},
	dashboard_australian_tax_forum: {
		key: 'dashboard_australian_tax_forum',
		endpoint: 'publication/australian-tax-forum/',
		endpointParams: {
			count: '4',
			sort_by: 'issued_at',
			sort_order: 'desc',
		},
	},
	dashboard_videos: {
		key: 'dashboard_videos',
		endpoint: 'media/videos/',
		endpointParams: {
			count: '10',
			sort_by: 'published_at',
			sort_order: 'desc',
		},
	},
	dashboard_taxvine: {
		key: 'dashboard_taxvine',
		endpoint: 'publication/taxvine/',
		endpointParams: {
			count: '4',
			sort_by: 'issued_at',
			sort_order: 'desc',
		},
	},
	dashboard_recent_activity: {
		key: 'dashboard_recent_activity',
		endpoint: 'user/recent-activity/',
		endpointParams: {
			count: '10',
			sort_by: 'actioned_at',
			sort_order: 'desc',
		},
	},
	events_upcoming: {
		key: 'events_upcoming',
		endpoint: 'events/upcoming',
		endpointParams: {
			sort_by: 'start_at',
			sort_order: 'asc',
		},
	},
	events_past: {
		key: 'events_past',
		endpoint: 'events/past',
		endpointParams: {
			sort_by: 'start_at',
			sort_order: 'desc',
		},
	},
	webinars_upcoming: {
		key: 'webinars_upcoming',
		endpoint: 'webinars/upcoming/',
		endpointParams: {
			sort_by: 'start_at',
			sort_order: 'asc',
		},
	},
	webinars_past: {
		key: 'webinars_past',
		endpoint: 'webinars/past/',
		endpointParams: {
			sort_by: 'start_at',
			sort_order: 'desc',
		},
	},
	resources_books: {
		key: 'resources_books',
		endpoint: 'media/books/',
		endpointParams: {
			sort_by: 'title',
			sort_order: 'asc',
		},
	},
	resources_podcasts: {
		key: 'resources_podcasts',
		endpoint: 'media/podcasts/',
		endpointParams: {
			sort_by: 'published_at',
			sort_order: 'desc',
		},
		expiryInterval: 0 //this override the default expiry Interval
	},
	resources_videos: {
		key: 'resources_videos',
		endpoint: 'media/resources/videos/',
		endpointParams: {
			sort_by: 'date',
			sort_order: 'desc',
		},
	},
	resources_videos_search: {
		key: 'resources_videos_search',
		endpoint: 'media/resources/videos/',
		endpointParams: {
			sort_by: 'date',
			sort_order: 'desc',
		},
		expiryInterval: 0 //this override the default expiry Interval
	},
	resources_videos_purchased: {
		key: 'resources_videos_purchased',
		endpoint: 'media/videos/',
		endpointParams: {
			sort_by: 'published_at',
			sort_order: 'desc',
		},
	},
	resources_videos_complimentary: {
		key: 'resources_videos_complimentary',
		endpoint: 'media/videos_free/',
		endpointParams: {
			sort_by: 'published_at',
			sort_order: 'desc',
		},
	},
	resources_cpd_self_learning: {
		key: 'resources_cpd_self_learning',
		endpoint: 'cpd/on-demand/self-paced-learning',
	},
	resources_cpd_bundles: {
		key: 'resources_cpd_bundles',
		endpoint: 'cpd/on-demand/bundles',
	},
	resources_papers_and_presentation_purchased: {
		key: 'resources_papers_and_presentation_purchased',
		endpoint: 'media/papers-and-presentations/',
	},
	resources_papers_and_presentation_complimentary: {
		key: 'resources_papers_and_presentation_complimentary',
		endpoint: 'media/papers-and-presentations_free/',
	},
	// membership_paper_of_the_month: {
	// 	key: 'membership_paper_of_the_month',
	// 	endpoint: 'media/paper-of-the-month/',
	// 	endpointParams: {
	// 		count: 'all',
	// 	},
	// },
	membership_paper_of_the_month: {
		key: 'membership_paper_of_the_month',
		endpoint: 'media/paper-of-the-month/latest/',
		expiryInterval: 0 //this override the default expiry Interval
	},
	membership_paper_of_the_month_listing: {
		key: 'membership_paper_of_the_month_listing',
		endpoint: 'media/paper-of-the-month/',
		endpointParams: {
			count: 'all',
		},
	},
	// membership_special_topic: {
	// 	key: 'membership_special_topic',
	// 	endpoint: 'media/specialtopic/',
	// },
	membership_special_topic: {
		key: 'membership_special_topic',
		endpoint: 'media/specialtopic/altlatest/',
		expiryInterval: 0 //this override the default expiry Interval
	},
	membership_special_topic_listing: {
		key: 'membership_special_topic_listing',
		endpoint: 'media/specialtopic/',
		endpointParams: {
			count: 'all',
		},
	},
	membership_tax_rates_table: {
		key: 'membership_tax_rates_table',
		endpoint: 'media/tax-rates-table/latest/',
	},
	membership_monthly_tax_update: {
		key: 'membership_monthly_tax_update',
		endpoint: 'media/monthlytaxupdate/altlatest/',
		expiryInterval: 0 //this override the default expiry Interval
	},
	membership_monthly_tax_update_listing: {
		key: 'membership_monthly_tax_update_listing',
		endpoint: 'media/monthlytaxupdate/',
		endpointParams: {
			count: 'all',
		},
	},
	membership_monthly_tax_update_videowatch: {
		key: 'membership_monthly_tax_update_videowatch',
		endpoint: 'media/videowatch/',
	},
	membership_taxvine: {
		key: 'membership_taxvine',
		endpoint: 'publication/taxvine/latest/articles/',
		base: 'publication/taxvine/',
		expiryInterval: 0, //this override the default expiry Interval
		endpointParams: {
			count: 'all',
		},
	},
	membership_taxvine_listing: {
		key: 'membership_taxvine_listing',
		endpoint: 'publication/taxvine/',
		endpointParams: {
			count: 'all',
			sort_by: 'issued_at',
			sort_order: 'desc',
		},
	},
	membership_taxation_in_australia: {
		key: 'membership_taxation_in_australia',
		endpoint: 'publication/taxation-in-australia/latest/',
		expiryInterval: 0 //this override the default expiry Interval
	},
	membership_taxation_in_australia_listing: {
		key: 'membership_taxation_in_australia_listing',
		endpoint: 'publication/taxation-in-australia/',
		endpointParams: {
			// count: '4',
			sort_by: 'issued_at',
			sort_order: 'desc',
		},
	},
	membership_the_tax_specialist: {
		key: 'membership_the_tax_specialist',
		endpoint: 'publication/tax-specialist/latest/articles/',
		base: 'publication/tax-specialist/',
		expiryInterval: 0, //this override the default expiry Interval
		endpointParams: {
			count: 'all',
		},
	},
	membership_the_tax_specialist_listing: {
		key: 'membership_the_tax_specialist_listing',
		endpoint: 'publication/tax-specialist/',
		endpointParams: {
			count: 'all',
			sort_by: 'issued_at',
			sort_order: 'desc',
		},
	},
	membership_australian_tax_forum: {
		key: 'membership_australian_tax_forum',
		endpoint: 'publication/australian-tax-forum/latest/articles/',
		base: 'publication/australian-tax-forum/',
		endpointParams: {
			count: 'all',
		},
	},
	membership_australian_tax_forum_listing: {
		key: 'membership_australian_tax_forum_listing',
		endpoint: 'publication/australian-tax-forum/',
		endpointParams: {
			count: 'all',
			sort_by: 'issued_at',
			sort_order: 'desc',
		},
	},
	membership_taxwise_newsletter: {
		key: 'membership_taxwise_newsletter',
		endpoint: 'publication/taxwise/',
		expiryInterval: 0, //this override the default expiry Interval
	},
	membership_taxwise_newsletterlist: {
		key: 'membership_taxwise_newsletterlist',
		endpoint: 'publication/taxwise/',
		endpointParams: {
			count: 'all',
			sort_by: 'issued_at',
			sort_order: 'desc',
		},
	},
	education_my_enrollment_details: {
		key: 'education_my_enrollment_details',
		endpoint: 'user/educationresults',
	},
	profile_user: {
		key: 'profile_user',
		endpoint: 'user/account/',
	},
	profile_password: {
		key: 'profile_password',
		endpoint: 'user/password/',
	},
	cpd_record_structured: {
		key: 'cpd_record_structured',
		endpoint: 'cpd/record/structured/',
		endpointParams: {
			sort_by: 'earned_at',
			sort_order: 'desc',
		},
	},
	cpd_record_unstructured: {
		key: 'cpd_record_unstructured',
		endpoint: 'cpd/record/unstructured/',
		endpointParams: {
			sort_by: 'earned_at',
			sort_order: 'desc',
		},
	},
	cpd_record_structured_print: {
		key: 'cpd_record_structured_print',
		endpoint: 'cpd/record/structured/',
		endpointParams: {
			count: 'all',
			sort_by: 'earned_at',
			sort_order: 'desc',
		},
	},
	cpd_record_unstructured_print: {
		key: 'cpd_record_unstructured_print',
		endpoint: 'cpd/record/unstructured/',
		endpointParams: {
			count: 'all',
			sort_by: 'earned_at',
			sort_order: 'desc',
		},
	},
	cpd_record_structured_email: {
		key: 'cpd_record_structured_email',
		endpoint: 'cpd/record/structured/',
		expiryInterval: 0, //this override the default expiry Interval
		endpointParams: {
			count: 'all',
			sort_by: 'earned_at',
			sort_order: 'desc',
		},
	},
	cpd_record_unstructured_email: {
		key: 'cpd_record_unstructured_email',
		endpoint: 'cpd/record/unstructured/',
		expiryInterval: 0, //this override the default expiry Interval
		endpointParams: {
			count: 'all',
			sort_by: 'earned_at',
			sort_order: 'desc',
		},
	},
	cpd_record_edit: {
		key: 'cpd_record_edit',
		endpoint: 'cpd/record/',
	},
	cpd_record_add: {
		key: 'cpd_record_add',
		endpoint: 'cpd/record/structured/',
	},
	order_history_orders: {
		key: 'order_history_orders',
		endpoint: 'orders/current/',
		endpointParams: {
			sort_by: 'ordered_at',
			sort_order: 'desc',
		},
	},
	order_history_cancelled: {
		key: 'order_history_cancelled',
		endpoint: 'orders/cancelled/',
		endpointParams: {
			sort_by: 'ordered_at',
			sort_order: 'desc',
		},
	},
	order_history_details: {
		key: 'order_history_details',
		endpoint: 'orders/',
	},
	subscriptions_current: {
		key: 'subscriptions_current',
		endpoint: 'subscriptions/current/',
	},
	subscriptions_expired: {
		key: 'subscriptions_expired',
		endpoint: 'subscriptions/expired/',
	},
	tax_specialist_profile: {
		key: 'tax_specialist_profile',
		endpoint: 'user/tax-specialist-profile/',
	},
	save_card_details: {
		key:'save_card_details',
		endpoint:'user/cc/'
	}
}