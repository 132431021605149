import * as c from '../../common/_constants';

function reducerAppStatus(state = {}, action) {

	switch (action.type) {

		case c.APP_STATUS_LOAD_REQUEST:
			return {
				...state,
				isLoading: true,
				loadingError: false,
				error: false,
			};
		case c.APP_STATUS_LOAD_SUCCESS:
			return {
				...state,
				isLoading: false,
				loadingError: false,
				error: false,
			};
		case c.APP_STATUS_LOAD_ERROR:
			return {
				...state,
				isLoading: true,
				loadingError: true,
				error: action.error,
			};
		default:
			return state;
	}
}

export default reducerAppStatus;