import React, { Component } from 'react';
import { connect } from 'react-redux';
import { hide } from 'redux-modal';
import { bindActionCreators } from 'redux';
import Select from 'react-select';

import { formatDate, getJsonDate, getSelectDays, getSelectMonths, getSelectYears, handleGaTracking } from '../../../common/_utility';
import { actionApiSendData } from '../../../actions/apiData/index';
import * as m from '../../../common/_messages';
import * as c from '../../../common/_constants';

class EditTaxAgentDetails extends Component {
	constructor(props) {
		super(props);

		let profile = this.props.profileUser.data.content;

		this.state = {
			statusValue: parseInt(profile.tax_agent_status_id, 10),
			statusError: false,
			rtaNumberValue: profile.tax_agent_rta_number,
			rtaNumberError: false,
			rtaExpiryDayValue: parseInt(formatDate(profile.tax_agent_rta_expiry_date, 'j'), 10),
			rtaExpiryDayError: false,
			rtaExpiryMonthValue: parseInt(formatDate(profile.tax_agent_rta_expiry_date, 'n'), 10),
			rtaExpiryMonthError: false,
			rtaExpiryYearValue: parseInt(formatDate(profile.tax_agent_rta_expiry_date, 'Y'), 10),
			rtaExpiryYearError: false,
			responseMessage: false,
			dataSaved: false,
		}

		this.handleFieldChange = this.handleFieldChange.bind(this);
		this.handleSelectChange = this.handleSelectChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.isFormValid = this.isFormValid.bind(this);

	}
	handleFieldChange(e) {
		const target = e.target;
		const value = target.type === 'checkbox' ? target.checked : target.value;
		const name = target.name;

		this.setState({ [name + 'Value']: value });
	}
	handleSelectChange(optionSelected, { name }) {
		optionSelected
			? this.setState({ [name + 'Value']: optionSelected.value })
			: this.setState({ [name + 'Value']: null })
	}
	handleSubmit(e) {

		e.preventDefault();

		//validation
		if (!this.isFormValid()) {
			return;
		}

		let rtaExpiryDate  = null;
		if (this.state.rtaExpiryDayValue){

			rtaExpiryDate = new Date(
				this.state.rtaExpiryYearValue,
				this.state.rtaExpiryMonthValue - 1,
				this.state.rtaExpiryDayValue,
			);
			rtaExpiryDate = getJsonDate(rtaExpiryDate);
		}

		

		let data = {
			tax_agent_status_id: this.state.statusValue,
			tax_agent_rta_number: this.state.rtaNumberValue,
			tax_agent_rta_expiry_date: rtaExpiryDate,
		}

		handleGaTracking('Profile page - Tax Agent Details Save', 'Tax Agent Details Save');

		this.props.dispatch(
			actionApiSendData(c.API_DATA.profile_user.key, data)
		).then((success) => {
			if (success){
				this.setState({ 
					responseMessage: 'Saved' ,
					dataSaved : true
				});
				//close popup after 3 secs
				let hidePopup = this.props.hide;
				setTimeout(
					function () { 
						hidePopup('edit-tax-agent-details')
					}, 2000
				);
			}else{
				this.setState({ responseMessage: 'something went wrong' });
			}
		});
	}
	isFormValid() {

		let formValid = true;

		//reset error states
		this.setState({
			statusError: false,
			rtaNumberError: false,
			rtaExpiryDayError: false,
			rtaExpiryMonthError: false,
			rtaExpiryYearError: false,
		});

		if (this.state.rtaExpiryDayValue || this.state.rtaExpiryMonthValue || this.state.rtaExpiryYearValue) {
			if (!this.state.rtaExpiryDayValue) {
				this.setState({ rtaExpiryDayError: m.formError.rta_expiry_day.required });
				formValid = false;
			}
			if (!this.state.rtaExpiryMonthValue) {
				this.setState({ rtaExpiryMonthError: m.formError.rta_expiry_month.required });
				formValid = false;
			}
			if (!this.state.rtaExpiryYearValue) {
				this.setState({ rtaExpiryYearError: m.formError.rta_expiry_year.required });
				formValid = false;
			}
		}

		return formValid;
	}
	render() {
		let choices = this.props.profileUser.data.choices;
		const daysList = getSelectDays();
		const monthsList = getSelectMonths();
		const yearsList = getSelectYears(true);


		const statusList = choices.tax_agent_status.map((item) => {
			return { value: parseInt(item.id, 10), label: item.name }
		});
		

		return (
			<div className="edit-modal" id="edit-tax-agent-details">
				<form onSubmit={this.handleSubmit}>
					<div className="header">
						<div className="row">
							<div className="col-md-12">
								<h4 className="m-0">Edit Tax Agent</h4>
							</div>
						</div>
					</div>
					<div className="content">
						<div className="row">
							<div className="col-md-12">
								<div className={"form-group" + (this.state.statusError ? ' has-error' : '')}>
									<label htmlFor="status">Status</label>
									<div className="input-select">
										<Select
											id="status"
											name="status"
											className="custom-react-select"
											value={statusList.find(item => item.value === this.state.statusValue) || null}
											options={statusList}
											onChange={this.handleSelectChange}
											isClearable
										/>
									</div>
									{this.state.statusError
										? <p className="form-error">{this.state.statusError}</p>
										: null
									}
								</div>
								{/* <div className={"form-group" + (this.state.statusError ? ' has-error' : '')}>
									<label htmlFor="status">Status</label>
									<input
										type="text"
										id="status"
										name="status"
										value={this.state.statusValue}
										onChange={this.handleFieldChange}
										className="form-control"
									/>
									{this.state.statusError
										? <p className="form-error">{this.state.statusError}</p>
										: null
									}
								</div> */}
							</div>
						</div>
						<div className="row">
							<div className="col-md-12">
								<div className={"form-group" + (this.state.rtaNumberError ? ' has-error' : '')}>
									<label htmlFor="rtaNumber">RTA Number</label>
									<input
										type="text"
										id="rtaNumber"
										name="rtaNumber"
										value={this.state.rtaNumberValue}
										onChange={this.handleFieldChange}
										className="form-control"
									/>
									{this.state.rtaNumberError
										? <p className="form-error">{this.state.rtaNumberError}</p>
										: null
									}
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col-md-12">
								<h4>RTA Expiry Date</h4>
							</div>
						</div>
						<div className="row">
							<div className="col-md-4">
								<div className={"form-group" + (this.state.rtaExpiryDayError ? ' has-error' : '')}>
									<label htmlFor="rtaExpiryDay">Day</label>
									<Select
										id="rtaExpiryDay"
										name="rtaExpiryDay"
										className="custom-react-select"
										value={daysList.find(item => item.value === this.state.rtaExpiryDayValue || null)}
										options={daysList}
										onChange={this.handleSelectChange}
										isClearable
									/>
									{this.state.rtaExpiryDayError
										? <p className="form-error">{this.state.rtaExpiryDayError}</p>
										: null
									}
								</div>
							</div>
							<div className="col-md-4">
								<div className={"form-group" + (this.state.rtaExpiryMonthError ? ' has-error' : '')}>
									<label htmlFor="rtaExpiryMonth">Month</label>
									<Select
										id="rtaExpiryMonth"
										name="rtaExpiryMonth"
										className="custom-react-select"
										value={monthsList.find(item => item.value === this.state.rtaExpiryMonthValue || null)}
										options={monthsList}
										onChange={this.handleSelectChange}
										isClearable
									/>
									{this.state.rtaExpiryMonthError
										? <p className="form-error">{this.state.rtaExpiryMonthError}</p>
										: null
									}
								</div>
							</div>
							<div className="col-md-4">
								<div className={"form-group" + (this.state.rtaExpiryYearError ? ' has-error' : '')}>
									<label htmlFor="rtaExpiryYear">Year</label>
									<Select
										id="rtaExpiryYear"
										name="rtaExpiryYear"
										className="custom-react-select"
										value={yearsList.find(item => item.value === this.state.rtaExpiryYearValue || null)}
										options={yearsList}
										onChange={this.handleSelectChange}
										isClearable
									/>
									{this.state.rtaExpiryYearError
										? <p className="form-error">{this.state.rtaExpiryYearError}</p>
										: null
									}
								</div>
							</div>
						</div>
					</div>
					<div className="footer">
						<div className="row">
							<div className="col-md-12">
								{this.props.profileUser.saving
									? 
										<span>Saving <i className="fas fa-spinner fa-spin"></i></span>
									: 
										<span>
											{!this.state.dataSaved
												?
													<span>
														<button
															disabled={this.props.profileUser.saving}
															className="btn btn-link-secondary"
															type="button"
															onClick={() => this.props.hide('edit-tax-agent-details')}
														>
															Cancel
														</button>
														<button
															disabled={this.props.profileUser.saving}
															className="btn btn-link"
															type="submit"
															value="Save"
														>
															Save
														</button>
													</span>
												:
													null
											}

											{this.state.responseMessage
												? <p className="response-message">{this.state.responseMessage}</p>
												: null
											}
										</span>
								}
							</div>
						</div>
					</div>
				</form>
			</div>
		)
	}
}
function mapStateToProps(state) {
	return {
		profileUser: state.apiData.profile_user,
	}
}
function mapDispatchToProps(dispatch) {
	return {
		...bindActionCreators({ hide }, dispatch),
		dispatch
	};
}
export default connect(mapStateToProps, mapDispatchToProps)(EditTaxAgentDetails);