import React, { Component } from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string';

import { actionGetApiDataKey } from '../../../../actions/apiData/index';

import * as c from '../../../../common/_constants';
import * as t from '../../../../common/_titles';

import Sorting from '../../../common/Sorting';
import Sections from '../common/Sections';
import Links from '../common/Links';
import Loading from '../../../common/Loading';

class Main extends Component {

	componentDidUpdate() {

		document.title = t.TITLE_RESOURCES_PAPER_AND_PRESENTATIONS_PURCHASED;

		if (this.props.papersPresentations && !this.props.papersPresentations.error && !this.props.papersPresentations.loading) {
			const params = queryString.parse(this.props.location.search);
			this.props.dispatch(actionGetApiDataKey(c.API_DATA.resources_papers_and_presentation_purchased.key, params));
		}
	}

	componentDidMount() {

		const params = queryString.parse(this.props.location.search);
		this.props.dispatch(actionGetApiDataKey(c.API_DATA.resources_papers_and_presentation_purchased.key, params));

	}
	render() {
		const {
			papersPresentations
		} = this.props;
		return (
			<div id="content-resources-papers-and-presentations-purchased">
				<div className="row">
					<div className="col-md-10">
						<h1>Purchased papers</h1>

					</div>
					<div className="col-md-2 text-right">
						<ul className="title-actions mb-0">
							<li>
								<Sorting
									options={[
										{ name: "Alphabetical (A - Z)", sortBy: 'group_title', sortOrder: 'asc' },
										{ name: "Alphabetical (Z - A)", sortBy: 'group_title', sortOrder: 'desc' },
										{ name: "Purchase Date Ascending", sortBy: 'access_info|purchased_at', sortOrder: 'asc' },
										{ name: "Purchase Date Descending", sortBy: 'access_info|purchased_at', sortOrder: 'desc' },
									]}
								/>
							</li>
						</ul>
					</div>
				</div>
				<div className="row">
					<div className="col-md-12">
						<Links />
					</div>
				</div>
				{papersPresentations
					? papersPresentations.error
						? <div><p>Sorry, an error occurred</p></div>
						: papersPresentations.loading
							? <Loading />
							: <Sections data={papersPresentations.data.content} type="purchased" />
					: <Loading />
				}
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		papersPresentations: state.apiData.resources_papers_and_presentation_purchased,
	}
}

export default connect(mapStateToProps)(Main);
