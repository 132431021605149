import React from 'react';

import EditSocial from './EditSocial';

import { handleGaTracking } from '../../../common/_utility';

const Social = ({
	profile,
	choices,
	show,
  eportalUrl,
	hide
}) => {
	
	let prefixChoice = choices.prefix.find(item => parseInt(item.id, 10) === profile.prefix_id);
	let prefix = prefixChoice ? prefixChoice.prefix : null;
	return (
		<div className="social">
			<p>Below is how your Tax Profile will appear in the <a href={eportalUrl + "/Directories/Browse.aspx"} rel="noopener noreferrer" target="_blank">Tax Specialist Directory</a></p>
			<div className="row">
				<div className="col-md-2">
					{profile.tax_profile_logo
						? 
							<div className="tax-specialist-image-wrapper"><img src={profile.tax_profile_logo} alt="profile logo" /><div className="text-small text-grey-dark">150px x 150px</div></div>
						: 
							<button
								type="button"
								className="btn trigger-edit"
								onClick={() => {
									handleGaTracking('Tax Specialist Profile page - Edit Social', 'Edit Social');
									show(
										'edit-tax-specialist-profile-social',
										{ content: <EditSocial /> }
									)
								}
								}
							>
								Add Image (e.g. profile pic, company logo)
							</button>
					}
				</div>
				<div className="col-md-10">
					<h6>
						{[prefix, profile.first_name, profile.last_name ].join(' ')}
					</h6>

					<ul className="social-icons d-inline m-0 p-0">
						
						<li className={"facebook mr-2" + (profile.facebook ? ' active' : '')}>
							{profile.facebook
								? <a rel="noopener noreferrer" target="_blank" href={profile.facebook}><i className="fab fa-facebook-f"></i></a>
								: <span><i className="fab fa-facebook-f"></i></span> 
							}
						</li>

						<li className={"twitter mr-2" + (profile.twitter ? ' active' : '')}>
							{profile.twitter
								? <a rel="noopener noreferrer" target="_blank" href={profile.twitter}><i className="fab fa-twitter"></i></a>
								: <span><i className="fab fa-twitter"></i></span>
							}
						</li>

						<li className={"linkedin mr-2" + (profile.linked_in ? ' active' : '')}>
							{profile.linked_in
								? <a rel="noopener noreferrer" target="_blank" href={profile.linked_in}><i className="fab fa-linkedin-in"></i></a>
								: <span><i className="fab fa-linkedin-in"></i></span>
							}
						</li>
						<li>
							<button
							type="button"
							className="btn btn-link text-base-color trigger-edit"
							onClick={() => {
								handleGaTracking('Tax Specialist Profile page - Edit Social', 'Edit Social');
								show(
									'edit-tax-specialist-profile-social',
									{ content: <EditSocial /> }
								)
							}
							}
							>
							<i className="fas fa-pen"></i>
							</button>

						</li>

					</ul>
					

				</div>
			</div>
		</div>
	);
}

export default Social;