import React, { Component } from 'react';
import Collapse from 'react-css-collapse';

import { getEventRangeDate, getEventAddress, isset, handleGaTracking } from '../../../../common/_utility';
import * as c from '../../../../common/_constants';

class ListItem extends Component {
	constructor(props) {
		super(props);

		this.state = {
			showMoreDetails: false,
			showMoreAttachments: false,
		}

		this.handleCollapseDetails = this.handleCollapseDetails.bind(this);
		this.handleCollapseAttachments = this.handleCollapseAttachments.bind(this);
	}
	handleCollapseDetails() {

		handleGaTracking('Event Upcoming page - Click ' + (this.state.showMoreDetails ? 'Close' : 'Open') + ' Details', this.props.item.title);

		this.setState({ showMoreDetails: !this.state.showMoreDetails });
	}
	handleCollapseAttachments() {

		handleGaTracking('Event Upcoming page - Click ' + (this.state.showMoreAttachments ? 'Close' : 'Open') + ' Attachments', this.props.item.title);

		this.setState({ showMoreAttachments: !this.state.showMoreAttachments });
	}
	render() {
		const {
			item,
			choices,
			ePortalLink
		} = this.props;
		let address = getEventAddress(item, choices);
		return (
			<div className="col-md-12 item">
				<div className="row">
					<div className="col-md-2">
						<div className="image-wrapper mb-3">
							<img src={c.URL_IMAGES_BASE + 'calendar.png'} alt={item.title} />
						</div>
					</div>
					<div className="col-md-8">
						<h4 className="m-0">{item.title}</h4>
						<div className="location">
							<i className="fas fa-map-marker"></i>{' '}
							{address.string}
							{address.mapLink
								?
								<span>
									{' | '}
									<a href={address.mapLink} rel="noopener noreferrer" target="_blank">Map</a>
								</span>
								: null
							}
						</div>
						<div className="details mb-4 mt-4">
							<button
								onClick={this.handleCollapseDetails}
								className="btn btn-link"
							>
								{"Event Details "}
								<i className={"fas " + (this.state.showMoreDetails ? 'fa-sort-up' : 'fa-sort-down')}></i>
							</button>
							<Collapse isOpen={this.state.showMoreDetails} transition={c.COLLAPSE_TRANSITION}>
								<div className="details-extra p-3">
									<p>{item.description}</p>
									<p><strong>Event Coordinator: </strong>{item.coordinator} (<a href={"mailto:" + item.coordinator_email}>{item.coordinator_email}</a>)</p>
									<a onClick={() => handleGaTracking('Event Upcoming page - Click More Details', item.title)} href={ePortalLink + '/Meetings/Meeting.aspx?ID=' + item.product_id} rel="noopener noreferrer" target="_blank">Click for more details</a>
								</div>
							</Collapse>
						</div>
						{item.attachments.length
							?
								<div className="attachments mb-4 mt-4">
									<button
										onClick={this.handleCollapseAttachments}
										className="btn btn-link"
									>
										{"Attachments "}
										<i className={"fas " + (this.state.showMoreAttachments ? 'fa-sort-up' : 'fa-sort-down')}></i>
									</button>
									<Collapse isOpen={this.state.showMoreAttachments} transition={c.COLLAPSE_TRANSITION}>
										<div className="attachments-extra p-3">
											<ul>
												{item.attachments.map((attachment, index) => {

													return <li key={index}><a onClick={() => handleGaTracking('Event Upcoming page - Click Attachment', item.title + ' / ' + attachment.title)} href={attachment.url} target="_blank" rel="noopener noreferrer">{attachment.title}</a></li>
												})

												}
											</ul>
										</div>
									</Collapse>
								</div>
							:	null
						}
						
						<div className="date">
							<i className="fas fa-calendar-alt"></i>{' '}
							{getEventRangeDate(item.start_at, item.finish_at)}
						</div>
					</div>
					{isset(() => item.cpd_hours[0].cpd_hours)
						?
						<div className="col-md-2">
							<div className="hours-badge">
								{item.cpd_hours[0].cpd_hours}
							</div>
						</div>
						: null
					}

				</div>
				<div className="col-md-12 mb-4 mt-4">
					<hr />
				</div>
			</div>
		);
	}
}

export default ListItem;