import React from 'react';

import ListItem from './ListItem';

const List = ({
	data
}) => {
	return (
		<div className="podcasts-list">
			<div className="row">
				<div className="col-md-12">
					{data.items.length > 0
						? data.items.map((item, index) => <ListItem key={index} item={item} />)
						: <div className="col-md-12"><p>No items</p></div>
					}
				</div>
			</div>
		</div>
	);
}

export default List;