import React from 'react';
import { NavLink } from 'react-router-dom';
import { withRouter } from 'react-router';

const PathNavLink = ({
	to,
	staticContext,
	match,
	history, 
	location,
	...rest
}) => {
	return (
		<NavLink isActive={() => to.includes(location.pathname)} to={to} {...rest}/>
	);
}

export default withRouter(PathNavLink);