import React, { Component } from 'react';
import { formatDate } from '../../common/_utility';
import { connect } from 'react-redux';

import * as c from '../../common/_constants';

class Title extends Component {
	render() {
		const data = this.props.user.content;
		const choices = this.props.user.choices;
		const lifetime_membership = this.props.user.content.membership_data.lifetime_membership;
		const access_level = this.props.user.content.membership_data.access_level;
		const nonfinancial_member = this.props.user.content.membership_data.nonfinancial_member;
		const deferred_member = this.props.user.content.membership_data.deferred_member;
		let memberTypeChoice = choices.member_type.find(item => parseInt(item.id, 10) === data.member_type_id);
		let memberType = memberTypeChoice ? memberTypeChoice.name : null;

		return (
			<div className={"main-title-bar access-" + access_level}>
				<div className="row">
					<div className="col-xl-10 col-md-8">
						<div className="main-title-bar-l py-4 py-md-0">
							<h1>{memberType}{nonfinancial_member ? ' - Not yet renewed' : ''}</h1>
							{lifetime_membership
								? <span className="badge badge-life">life</span>
								: ''
							}
						</div>
					</div>
					<div className="col-xl-2 col-md-4 text-right">
						<div className={"main-title-bar-r" + (!data.membership_since_interval.length ? ' no-bg' : '' )}>
							{access_level === 'nonmember'
								? 
									deferred_member || nonfinancial_member
										?
									<a style={{ backgroundImage: "url(" + c.URL_IMAGES_BASE + "logo-title-bar.png)", margin: '10px' }} rel="noopener noreferrer" target="_blank" href={this.props.ePortalLink + "/customerservice/renewals.aspx"}>
												Renew
											</a>
										:
									<a style={{ backgroundImage: "url(" + c.URL_IMAGES_BASE + "logo-title-bar.png)", margin: '10px' }} rel="noopener noreferrer" target="_blank" href={this.props.rootUrl + "/membership/become-a-member1"}>
												Become a member
											</a>
								:	access_level !== 'student'
										?
											<div>
												{data.membership_since_interval.length
													?
														<div>
															<p>member since<br />{formatDate(data.membership_commenced_at, 'd F Y')}</p>
															<span className="member-since">
																{data.membership_since_interval.length < 4
																	? '(less than a day)'
																	: '(' + data.membership_since_interval[0] + ' ' + data.membership_since_interval[1] + ')'
																}
															</span>
														</div>
													:	null
												}
											</div>
										:	null
							}
							
							
							
						</div>
					</div>
				</div>
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		user: state.app.global.global_user.data,
		rootUrl: state.app.global.global_user.data.content.root_url,
		ePortalLink: state.app.global.global_user.data.content.root_eportalurl,
	}
}

export default connect(mapStateToProps)(Title);