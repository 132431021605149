import React from 'react';

import { formatDate } from '../../../common/_utility';

const MembershipDetails = ({
	user,
	profile,
	choices
}) => {
	const nonfinancial_member = user.content.membership_data.nonfinancial_member;
	let memberTypeChoice = choices.member_type.find(item => parseInt(item.id, 10) === profile.member_type_id);
	let memberType = memberTypeChoice ? memberTypeChoice.name : null;
	return (
		<div className="membership-details">
			<div className="row">
				<div className="col-md-12">
					<h5><i className="fas fa-calendar-alt"></i> Membership Details</h5>
					<div className="row">
						<div className="col-md-4">
							<div className="info-detail">
								<div className="data-label">Membership Type</div>
								<p>{memberType}{nonfinancial_member ? ' - Not yet renewed' : ''}</p>
							</div>
							<div className="info-detail">
								<div className="data-label">User ID</div>
								<p>{profile.user_name}</p>
							</div>
						</div>
						{user.content.membership_data.full_member
							?
								<div className="col-md-4">
									<div className="info-detail">
										<div className="data-label">Membership</div>
										<p>{formatDate(profile.membership_commenced_at, 'd M Y')} - {formatDate(profile.membership_renewal_at, 'd M Y')}</p>
									</div>
								</div>
							: null
						}
						{user.content.membership_data.full_member
							?
								<div className="col-md-4">
									<div className="info-detail">
										<div className="data-label">Membership Renewal</div>
										<p>{formatDate(profile.membership_renewal_at, 'd M Y')}</p>
									</div>
								</div>
							: null
						}
					</div>
				</div>
			</div>
		</div>
	);
}

export default MembershipDetails;