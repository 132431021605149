import React, { Component } from 'react';
import { connect } from 'react-redux';
import { hide } from 'redux-modal';
import { bindActionCreators } from 'redux';
import Select from 'react-select';

import { validEmail, handleGaTracking } from '../../../common/_utility';
import { actionApiSendData } from '../../../actions/apiData/index';
import * as m from '../../../common/_messages';
import * as c from '../../../common/_constants';

class EditPersonalInfo extends Component {
	constructor(props) {
		super(props);

		let profile = this.props.taxSpecialistData.data.content;

		this.state = {
			companyNameValue: profile.company_name,
			companyNameError: false,
			positionTitleValue: profile.position_title,
			positionTitleError: false,
			addressValue: profile.address_line1,
			addressError: false,
			cityValue: profile.city,
			cityError: false,
			stateValue: profile.state_id,
			stateError: false,
			postcodeValue: profile.postcode,
			postcodeError: false,
			countryValue: profile.country_id,
			countryError: false,
			emailValue: profile.email,
			emailError: false,
			phoneValue: profile.phone,
			phoneError: false,
			faxValue: profile.fax,
			faxError: false,
			responseMessage: false,
			dataSaved: false,
		}

		this.handleFieldChange = this.handleFieldChange.bind(this);
		this.handleSelectChange = this.handleSelectChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.isFormValid = this.isFormValid.bind(this);

	}
	handleFieldChange(e) {
		const target = e.target;
		const value = target.type === 'checkbox' ? target.checked : target.value;
		const name = target.name;

		this.setState({ [name + 'Value']: value });
	}
	handleSelectChange(optionSelected, { name }) {
		if(name === 'country'){
			//reset state as well
			this.setState({ stateValue: null });
		}
		optionSelected
			? this.setState({ [name + 'Value']: optionSelected.value })
			: this.setState({ [name + 'Value']: null })
	}
	handleSubmit(e) {

		e.preventDefault();

		//validation
		if (!this.isFormValid()) {
			return;
		}

		let data = {
			company_name: this.state.companyNameValue,
			position_title: this.state.positionTitleValue,
			address_line1: this.state.addressValue,
			city: this.state.cityValue,
			state_id: this.state.stateValue,
			postcode: this.state.postcodeValue,
			country_id: this.state.countryValue,
			email: this.state.emailValue,
			phone: this.state.phoneValue,
			fax: this.state.faxValue,
		}

		handleGaTracking('Tax Specialist Profile page - Personal Information Save', 'Personal Information Save');

		this.props.dispatch(
			actionApiSendData(c.API_DATA.tax_specialist_profile.key, data)
		).then((success) => {
			if (success) {
				this.setState({
					responseMessage: 'Saved',
					dataSaved: true
				});
				//close popup after 3 secs
				let hidePopup = this.props.hide;
				setTimeout(
					function () {
						hidePopup('edit-tax-specialist-profile-personal-info')
					}, 2000
				);
			} else {
				this.setState({ responseMessage: 'something went wrong' });
			}
		});
		
	}
	isFormValid() {

		let formValid = true;

		//reset error states
		this.setState({
			companyNameError: false,
			positionTitleError: false,
			addressError: false,
			cityError: false,
			stateError: false,
			postcodeError: false,
			countryError: false,
			emailError: false,
			phoneError: false,
			faxError: false,
			responseMessage: false
		});

		if (!this.state.companyNameValue) {
			this.setState({ companyNameError: m.formError.companyName.required });
			formValid = false;
		}
		if (!this.state.positionTitleValue) {
			this.setState({ positionTitleError: m.formError.positionTitle.required });
			formValid = false;
		}
		if (!this.state.addressValue) {
			this.setState({ addressError: m.formError.address.required });
			formValid = false;
		}
		if (!this.state.cityValue) {
			this.setState({ cityError: m.formError.city.required });
			formValid = false;
		}
		if (!this.state.postcodeValue) {
			this.setState({ postcodeError: m.formError.postcode.required });
			formValid = false;
		}
		if (!this.state.countryValue) {
			this.setState({ countryError: m.formError.country.required });
			formValid = false;
		}else{
			//check state only if country has states
			let stateList = this.props.taxSpecialistData.data.choices.state.filter(
				(item) => parseInt(item.country_id, 10) === this.state.countryValue
			);

			if (stateList.length > 0){
				if (!this.state.stateValue) {
					this.setState({ stateError: m.formError.state.required });
					formValid = false;
				}
			}
		}
		
		if (!this.state.emailValue) {
			this.setState({ emailError: m.formError.email.required });
			formValid = false;
		}else{
			if (!validEmail(this.state.emailValue)) {
				this.setState({ emailError: m.formError.email.notValid });
				formValid = false;
			}
		}
		if (!this.state.phoneValue) {
			this.setState({ phoneError: m.formError.phone.required });
			formValid = false;
		}
		// if (!this.state.faxValue) {
		// 	this.setState({ faxError: m.formError.fax.required });
		// 	formValid = false;
		// }

		return formValid;
	}
	render() {

		let choices = this.props.taxSpecialistData.data.choices;

		let stateList = choices.state.map((item) => {
			return { value: parseInt(item.id, 10), label: item.full_name, country_id: parseInt(item.country_id, 10) }
		});
		//filter by country 
		stateList = stateList.filter((item) => item.country_id === this.state.countryValue);
		const countryList = choices.country.map((item) => {
			return { value: parseInt(item.id, 10), label: item.country }
		});
		return (
			<div className="edit-modal" id="edit-tax-specialist-profile-personal-info">
				<form onSubmit={this.handleSubmit}>
					<div className="header">
						<div className="row">
							<div className="col-md-12">
								<h4>Edit Tax Specialist Profile Information</h4>
							</div>
						</div>
					</div>
					<div className="content">
						<div className="row">
							<div className="col-md-12">
								<h4>Company</h4>
							</div>
						</div>
						<div className="row">
							<div className="col-md-6">
								<div className={"form-group" + (this.state.companyNameError ? ' has-error' : '')}>
									<label htmlFor="companyName">Company Name</label>
									<input
										type="text"
										id="companyName"
										name="companyName"
										value={this.state.companyNameValue}
										onChange={this.handleFieldChange}
										className="form-control"
									/>
									{this.state.companyNameError
										? <p className="form-error">{this.state.companyNameError}</p>
										: null
									}
								</div>
							</div>
							<div className="col-md-6">
								<div className={"form-group" + (this.state.positionTitleError ? ' has-error' : '')}>
									<label htmlFor="positionTitle">Position Title</label>
									<input
										type="text"
										id="positionTitle"
										name="positionTitle"
										value={this.state.positionTitleValue}
										onChange={this.handleFieldChange}
										className="form-control"
									/>
									{this.state.positionTitleError
										? <p className="form-error">{this.state.positionTitleError}</p>
										: null
									}
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col-md-12">
								<h4>Address</h4>
							</div>
						</div>
						<div className="row">
							<div className="col-md-12">
								<div className={"form-group" + (this.state.addressError ? ' has-error' : '')}>
									<label htmlFor="address">Address</label>
									<input
										type="text"
										id="address"
										name="address"
										value={this.state.addressValue}
										onChange={this.handleFieldChange}
										className="form-control"
									/>
									{this.state.addressError
										? <p className="form-error">{this.state.addressError}</p>
										: null
									}
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col-md-6">
								<div className={"form-group" + (this.state.cityError ? ' has-error' : '')}>
									<label htmlFor="city">City</label>
									<input
										type="text"
										id="city"
										name="city"
										value={this.state.cityValue}
										onChange={this.handleFieldChange}
										className="form-control"
									/>
									{this.state.cityError
										? <p className="form-error">{this.state.cityError}</p>
										: null
									}
								</div>
							</div>
							<div className="col-md-6">
								<div className={"form-group" + (this.state.postcodeError ? ' has-error' : '')}>
									<label htmlFor="postcode">Postcode</label>
									<input
										type="text"
										id="postcode"
										name="postcode"
										value={this.state.postcodeValue}
										onChange={this.handleFieldChange}
										className="form-control"
									/>
									{this.state.postcodeError
										? <p className="form-error">{this.state.postcodeError}</p>
										: null
									}
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col-md-6">
								<div className={"form-group" + (this.state.stateError ? ' has-error' : '')}>
									<label htmlFor="state">State</label>
									<Select
										id="state"
										name="state"
										className="custom-react-select"
										value={stateList.find(item => item.value === this.state.stateValue) || null}
										options={stateList}
										onChange={this.handleSelectChange}
										isClearable
										isDisabled={stateList.length > 0 ? false : true}
									/>
									{this.state.stateError
										? <p className="form-error">{this.state.stateError}</p>
										: null
									}
								</div>
							</div>
							<div className="col-md-6">
								<div className={"form-group" + (this.state.countryError ? ' has-error' : '')}>
									<label htmlFor="country">Country</label>
									<Select
										id="country"
										name="country"
										className="custom-react-select"
										defaultValue={countryList.find(item => item.value === this.state.countryValue) || null}
										options={countryList}
										onChange={this.handleSelectChange}
										isClearable
									/>
									{this.state.countryError
										? <p className="form-error">{this.state.countryError}</p>
										: null
									}
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col-md-12">
								<h4>Contact</h4>
							</div>
						</div>
						<div className="row">
							<div className="col-md-12">
								<div className={"form-group" + (this.state.emailError ? ' has-error' : '')}>
									<label htmlFor="email">Email</label>
									<input
										type="text"
										id="email"
										name="email"
										value={this.state.emailValue}
										onChange={this.handleFieldChange}
										className="form-control"
									/>
									{this.state.emailError
										? <p className="form-error">{this.state.emailError}</p>
										: null
									}
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col-md-6">
								<div className={"form-group" + (this.state.phoneError ? ' has-error' : '')}>
									<label htmlFor="phone">Phone</label>
									<input
										type="text"
										id="phone"
										name="phone"
										value={this.state.phoneValue}
										onChange={this.handleFieldChange}
										className="form-control"
									/>
									{this.state.phoneError
										? <p className="form-error">{this.state.phoneError}</p>
										: null
									}
								</div>
							</div>
							<div className="col-md-6">
								<div className={"form-group" + (this.state.faxError ? ' has-error' : '')}>
									<label htmlFor="fax">Fax</label>
									<input
										type="text"
										id="fax"
										name="fax"
										value={this.state.faxValue}
										onChange={this.handleFieldChange}
										className="form-control"
									/>
									{this.state.faxError
										? <p className="form-error">{this.state.faxError}</p>
										: null
									}
								</div>
							</div>
						</div>
					</div>
					<div className="footer">
						<div className="row">
							<div className="col-md-12">
								{this.props.taxSpecialistData.saving
									?
										<span>Saving <i className="fas fa-spinner fa-spin"></i></span>
									:
										<span>
											{!this.state.dataSaved
												?
													<span>
														<button
															disabled={this.props.taxSpecialistData.saving}
															type="button"
															className="btn btn-link-secondary"
															onClick={() => this.props.hide('edit-tax-specialist-profile-personal-info')}
														>
															Cancel
														</button>
														<button
															disabled={this.props.taxSpecialistData.saving}
															className="btn btn-link"
															type="submit"
															value="Save"
														>
															Save
														</button>
													</span>
												: null
											}
											{this.state.responseMessage
												? <p className="response-message">{this.state.responseMessage}</p>
												: null
											}
										</span>
								}
							</div>
						</div>
					</div>
				</form>
			</div>
		)
	}
}

function mapStateToProps(state) {
	return {
		taxSpecialistData: state.apiData.tax_specialist_profile,
	}
}
function mapDispatchToProps(dispatch) {
	return {
		...bindActionCreators({ hide }, dispatch),
		dispatch
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(EditPersonalInfo);