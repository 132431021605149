import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import * as r from '../../common/_routes';

const PrivateOnlyRoute  = ({
	component: Component,
	auth,
	...rest
}) => (
		<Route {...rest} render={props => (
			auth
				? <Component {...props} />
				: <Redirect to={{ pathname: r.URL_LOGIN, state: { from: props.location } }} />
		)} />
);

function mapStateToProps(state) {
	return {
		auth: state.app.auth.granted
	}
}

export default connect(mapStateToProps)(PrivateOnlyRoute);