import React from 'react';

import { formatDate } from '../../../../common/_utility';

import ListItem from './ListItem';

const List = ({
	data,
	ePortalLink
}) => {
	let year = false;
	let yearSection = false;
	return (
		<div className="events-list">
			<div className="row">
				{data.content.items.length > 0
					? 
						data.content.items.map((item, index) => {

							year = false;

							if (yearSection !== formatDate(item.start_at, 'Y')){
								yearSection = formatDate(item.start_at, 'Y');
								year = yearSection;
							}

							return <ListItem key={index} item={item} year={year} choices={data.choices} ePortalLink={ePortalLink} />
						})
					:	<div className="col-md-12"><p>No items</p></div>
				}
			</div>
		</div>
	);
}

export default List;