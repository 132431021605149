import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import Sections from './Sections';
import InfoBar from '../../../dashboard/Title';
import Loading from '../../../common/Loading';

import { actionGetApiDataUrl } from '../../../../actions/apiData/index';
import * as c from '../../../../common/_constants';
import * as r from '../../../../common/_routes';
import * as t from '../../../../common/_titles';


class Main extends Component {

	componentDidMount() {

		document.title = t.TITLE_PROFILE_ORDER_DETAILS;

		const id = this.props.match.params.id;
		this.props.dispatch(
			actionGetApiDataUrl(
				c.API_DATA.order_history_details.key,
				c.API_URL + c.API_DATA.order_history_details.endpoint + id,
				true
			)
		);
	}
	render() {
		const {
			order,
		} = this.props;
		
		return (
			<div id="content-profile-order-history-details">
				
				<div className="row">
					<div className="col-md-12">
						<InfoBar />
					</div>
				</div>
				<div className="row">
					<div className="col-md-12">
						<h1>Order Details</h1>
					</div>
				</div>
				<div className="row">
					<div className="col-md-12">
						<Link className="btn btn-primary" to={r.URL_PROFILE_ORDER_HISTORY_ORDERS}>
							<i className="fas fa-chevron-left"></i>&nbsp;&nbsp;Back
						</Link>
					</div>
				</div>
				{order
					? order.error
						? <div><p>Sorry, an error occurred</p></div>
						: order.loading
							? <Loading />
							: <Sections data={order.data.content} choices={order.data.choices} />
					: <Loading />
				}
				
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		order: state.apiData.order_history_details,
	}
}

export default connect(mapStateToProps)(Main);
