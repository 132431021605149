import React from 'react';

const VideoCounter = ({
	items,
	gallerySize,
	activeSlide
}) => {
	let totalSlides = items.length;
	let lastVisibleSlide = activeSlide + gallerySize;
	if (lastVisibleSlide > totalSlides) {
		lastVisibleSlide = totalSlides
	}
	let firstVisibleSlide = lastVisibleSlide - gallerySize + 1;
	if (firstVisibleSlide < 1) {
		firstVisibleSlide = 1;
	}
	return (
		<div className="videos-counter">
			{firstVisibleSlide + "-" + lastVisibleSlide + " / " + totalSlides}
		</div>
	);
}

export default VideoCounter;
