import React, { Component } from "react";
import Collapse from "react-css-collapse";
import ReactPlayerLoader from "@brightcove/react-player-loader";

import {
  getEventRangeDate,
  isset,
  handleGaTracking,
} from "../../../../common/_utility";
import * as c from "../../../../common/_constants";

class ListItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showMoreDetails: false,
      showMoreAttachments: false,
    };

    this.handleCollapseDetails = this.handleCollapseDetails.bind(this);
    this.handleCollapseAttachments = this.handleCollapseAttachments.bind(this);
  }
  handleCollapseDetails() {
    handleGaTracking(
      "Webinars Past page - Click " +
        (this.state.showMoreDetails ? "Close" : "Open") +
        " Details",
      this.props.item.title
    );

    this.setState({ showMoreDetails: !this.state.showMoreDetails });
  }
  handleCollapseAttachments() {
    handleGaTracking(
      "Webinars Past page - Click " +
        (this.state.showMoreAttachments ? "Close" : "Open") +
        " Attachments",
      this.props.item.title
    );

    this.setState({ showMoreAttachments: !this.state.showMoreAttachments });
  }

  handlePodcastLink(e) {
    if (!e.target.href) {
      e.preventDefault();
      return false;
    }
  }

  render() {
    const { item, year, ePortalLink } = this.props;
    const { showMoreDetails } = this.state;
    return (
      <div className="col-md-12 item">
        <h4 className="past-event-year">{year}</h4>
        <div className="row">
          <div className="col-md-2">
            <div className="image-wrapper">
              <img src={item.image} alt={item.title} />
            </div>
          </div>
          <div className="col-md-7">
            <h4 className="m-0">{item.title}</h4>
            <div className="details mb-4 mt-4">
              <button
                onClick={this.handleCollapseDetails}
                className="btn btn-link"
              >
                {"Event Details "}
                <i
                  className={
                    "fas " +
                    (this.state.showMoreDetails ? "fa-sort-up" : "fa-sort-down")
                  }
                ></i>
              </button>
              <Collapse
                isOpen={this.state.showMoreDetails}
                transition={c.COLLAPSE_TRANSITION}
              >
                <div className="details-extra p-3">
                  {isset(() => item.video_id) ? (
                    <div className="video-wrapper mb-3">
                      <ReactPlayerLoader
                        accountId="694915343001"
                        videoId={item.video_id}
                        playerId="oaG6r7WdV"
                        // onSuccess={this.playerOnSuccess}
                      />
                    </div>
                  ) : null}
                  <p>{item.description}</p>
                  <p>
                    <strong>Event Coordinator: </strong>
                    {item.coordinator} (
                    <a href={"mailto:" + item.coordinator_email}>
                      {item.coordinator_email}
                    </a>
                    )
                  </p>
                  <a
                    onClick={() =>
                      handleGaTracking(
                        "Webinar Past page - Click More Details",
                        item.title
                      )
                    }
                    href={
                      ePortalLink +
                      "/Meetings/Meeting.aspx?ID=" +
                      item.product_id
                    }
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Click for more details
                  </a>
                </div>
              </Collapse>
            </div>
            <div className="date">
              <i className="fas fa-calendar-alt"></i>{' '}
              {getEventRangeDate(item.start_at, item.finish_at)}
            </div>
            {item.attachments.length ? (
              <div className="attachments mb-4 mt-4">
                <button
                  onClick={this.handleCollapseAttachments}
                  className="btn btn-link"
                >
                  {"Attachments "}
                  <i
                    className={
                      "fas " +
                      (this.state.showMoreAttachments
                        ? "fa-sort-up"
                        : "fa-sort-down")
                    }
                  ></i>
                </button>
                <Collapse
                  isOpen={this.state.showMoreAttachments}
                  transition={c.COLLAPSE_TRANSITION}
                >
                  <div className="attachments-extra p-3">
                    <ul>
                      {item.attachments.map((attachment, index) => {
                        return (
                          <li key={index}>
                            <a
                              onClick={() =>
                                handleGaTracking(
                                  "Webinar Past page - Click Attachment",
                                  item.title + " / " + attachment.title
                                )
                              }
                              href={attachment.url}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {attachment.title}
                            </a>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </Collapse>
              </div>
            ) : null}
          </div>
          <div className="col-md-3 text-right">
            {isset(() => item.video_id) ? (
              <div>
                {/* <a className="btn btn-primary mb-3" href={ePortalLink + '/Meetings/Meeting.aspx?ID=' + item.product_id} rel="noopener noreferrer" target="_blank">view webinar</a> */}
                <button
                  className={
                    "btn btn-primary mb-3" +
                    (showMoreDetails ? " disabled" : "")
                  }
                  disabled={showMoreDetails}
                  onClick={this.handleCollapseDetails}
                >
                  view webinar
                </button>
                <span>
                  <a
                    href={item.podcastFilename}
                    onClick={this.handlePodcastLink}
                    className="btn btn-primary mb-3"
                    title={
                      !item.podcastFilename
                        ? "Podcast not available"
                        : "Open Podcast"
                    }
                    rel="noopener noreferrer"
                    role="button"
                    target="_blank"
                    style={{
                      visibility: !item.podcastFilename ? "hidden" : "visible",
                    }}
                  >
                    Listen To The Audio
                  </a>
                </span>
              </div>
            ) : null}
            {isset(() => item.cpd_hours_values[0].EducationUnits) ? (
              <div className="hours-badge mr-0">
                {parseFloat(item.cpd_hours_values[0].EducationUnits, 10)}
              </div>
            ) : null}
          </div>
        </div>
        <div className="mb-4 mt-4">
          <hr />
        </div>
      </div>
    );
  }
}

export default ListItem;
