import React from 'react';
import { Link } from 'react-router-dom';

import { formatDate } from '../../../common/_utility';
import * as r from '../../../common/_routes';

const TableItem = ({
	item
}) => {
	return (
		<tr>
			<td>
				<Link className="btn btn-link" to={r.URL_PROFILE_ORDER_DETAILS + item.id }>
					{item.id}
				</Link>
			</td>
			<td>{formatDate(item.ordered_at, 'd/m/Y')}</td>
			<td>
				{item.products.length > 1
					? <span>{item.products.length} items</span>
					: <span>{item.products[0].name}</span>
				}
			</td>
			{/* <td>{item.shipping.status}</td> */}
			<td>{item.grant_total}</td>
		</tr>
	);
}

export default TableItem;