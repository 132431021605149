import React from 'react';

const ErrorLoading = ({
	error
}) => {

	console.log(error);
	return (
		// <div className="loading"><i className="fas fa-spinner"></i></div>
		<div>
			{typeof error === 'object' && error !== null
				?
					<div>
						<h3>An error occurred</h3>
						<p> URL	: {error.url}</p>
						<p> Status : {error.status}</p>
						<p> Message	: {error.statusText}</p>

						<div dangerouslySetInnerHTML={{ __html: error.err }}></div>
					</div>
				:
					<div>
						<h3>An error occurred</h3>
						<div dangerouslySetInnerHTML={{ __html: error }}></div>
					</div>
			}
				
		</div>
	);
}

export default ErrorLoading;