import React from 'react';
import { connect } from 'react-redux';
import { hide } from 'redux-modal';
import { bindActionCreators } from 'redux';

const HelpEPub = ({
	hide
}) => {
	return (
		<div className="edit-modal" id="help-what-is-ePub">
			<div className="header">
				<div className="row">
					<div className="col-md-12">
						<h2>What is an ePub</h2>
					</div>
				</div>
			</div>
			<div className="content">
				<div className="row">
					<div className="col-md-12">
						<p>
							EPUB files can store words, images, stylesheets, fonts, metadata details,
							and tables of content. They are considered layouts agnostics, meaning
							that screen size doesn't affect the formatting - EPUBS files can display
							content on screens as small as 3.5". This and the fact it's a freely 
							available standard is why a majority of eReaders support EPUB files.
						</p>
						<p>
							<strong>How Do I Open One?</strong><br/>
							Because of their widespread use, more hardware eReaders support EPUB files than
							any other eBook file format. You can open an EPUB file whether you're using a 
							Kobo, Barnes &amp; Noble Nook, or even on your desktop using one of the many
							free programs, such as Calibre or Stanza Desktop. The notable exception here 
							is Kindle. You cannot read an EPUB file direcly on a Kindle, but there are ways
							to convert one to something the Kindle can use.
						</p>
					</div>
				</div>
			</div>
			<div className="footer">
				<div className="row">
					<div className="col-md-12">
						<button
							className="btn btn-link"
							type="button"
							onClick={() => hide('help-what-is-ePub')}
						>
							OK
						</button>
					</div>
				</div>
			</div>
		</div>
	);
}

function mapDispatchToProps(dispatch) {
	return {
		...bindActionCreators({ hide }, dispatch),
		dispatch
	};
}
export default connect(null, mapDispatchToProps)(HelpEPub);