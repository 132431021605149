import { retrieveApiData } from '../apiData/index';
import queryString from 'query-string';

import * as c from '../../common/_constants';

import { actionAuthError } from './auth';
import { isset } from '../../common/_utility';

const actionGetApiGlobalInit = (key) => {
	return {
		type: c.ACTION_GET_API_GLOBAL_INIT,
		key
	}
}

const actionGetApiGlobalError = (key) => {
	return {
		type: c.ACTION_GET_API_GLOBAL_ERROR,
		key
	}
}

const actionGetApiGlobalLoad = (key, data) => {
	return {
		type: c.ACTION_GET_API_GLOBAL_LOAD,
		key,
		data
	}
}
const actionRefreshGlobalEndpointData = (key, content) => {
	return {
		type: c.ACTION_REFRESH_GLOBAL_ENDPOINT_DATA,
		key,
		content
	}
}

export const refreshGlobalEndpointData = (key, dispatch) => {

	let paramString = queryString.stringify(c.API_DATA[key].endpointParams);

	let url = c.API_URL + c.API_DATA[key].endpoint + (paramString ? '?' + paramString : '');

	retrieveApiData(dispatch, url)
	.then(response => {
		if (isset(() => response.content)){
			dispatch(actionRefreshGlobalEndpointData(key, response.content));
		}
	});



}

export const actionLoadGlobal = (key, dispatch) => {

	dispatch(actionGetApiGlobalInit(key));

	let paramString = queryString.stringify(c.API_DATA[key].endpointParams);

	let url = c.API_URL + c.API_DATA[key].endpoint + (paramString ? '?' + paramString : '');

	return retrieveApiData(dispatch, url)
	.then(response => {

		switch (response.status) {
			case 200:
				dispatch(actionGetApiGlobalLoad(key, response));
				break;
			case 401:
				localStorage.removeItem('authToken');
				dispatch(actionAuthError());
				break;

			default:
				dispatch(actionGetApiGlobalError(key));
				break;
		}

	})
	.catch(err => {
		console.log(err);
		dispatch(actionGetApiGlobalError(key));
	});

}

