import React from 'react';
import Tooltip from 'rc-tooltip';
import { Link } from 'react-router-dom';

import * as r from '../../../common/_routes';
import * as c from '../../../common/_constants';
import TooltipTaxSpecialistDirectory from './TooltipTaxSpecialistDirectory';

const TaxSpecialistDirectory = ({
	profile
}) => {
	return (
		<div className="tax-specialist-directory card mb-4">
			<div className="row">
				<div className="col-md-12 mb-4">
					<div className="card-header">
						<h6><img src={c.URL_IMAGES_BASE + 'icon-tti.svg'} alt="" className="tax-specialist-directory-icon-tti"/>  Tax Specialist Directory</h6>
						<div className="card-header-icon-team">
							<Tooltip
								placement="topLeft"
								overlay={<TooltipTaxSpecialistDirectory />}
							>
								<i className="fas fa-info-circle"></i>
							</Tooltip>
						</div>
					</div>
					<div className="card-body">
						{!profile.show_in_tax_specialist_directory
							?	
								<p><i className="fas fa-check"></i> Listed in the Tax Specialist Directory</p>
							:
								<div>
									<p>You are not listed in the Tax Institute's Specialist Directory.</p>
									<p className="notification">
										Membership is required to be listed in the Tax Institute's Directory.
										<Link to={r.URL_PROFILE_TAX_SPECIALIST_PROFILE}>Become a member.</Link>
									</p>
								</div>

						}
					</div>
				</div>
			</div>
		</div>
	);
}

export default TaxSpecialistDirectory;