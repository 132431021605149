import React from 'react';
// import * as c from '../../../../common/_constants';

const CustomerInfo = ({
	user,
	choices
}) => {

	let prefixChoice = choices.prefix.find(item => parseInt(item.id, 10) === user.prefix_id);
	let prefix = prefixChoice ? prefixChoice.prefix : null;
	return (
		<div className="customer-info">
			<div className="row">
				<div className="col-md-12">
					<div>
						<table className="table table-striped table-bordered cpd-table-info">
							<tbody>
								<tr>
									<td colspan="4">
										<h4 className="mb-0">Customer Information</h4>
									</td>
								</tr>
								<tr>
									<td>
										<div className="text-bold">Member Name</div>
										<p>{prefix} {user.full_name}</p>
									</td>
									<td>
										<div className="text-bold">Member Number</div>
										<p>{user.member_number}</p>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
	);
}

export default CustomerInfo;