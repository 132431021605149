import React from 'react';
import { NavLink } from 'react-router-dom';

import * as r from '../../../../common/_routes';

const Links = (props) => {
	return (
		<ul className="menu-tabs">
			<li>
				<NavLink to={r.URL_RESOURCES_CPD_ON_DEMAND_SELF_PACED_LEARNING}>Self Paced Learning</NavLink>
			</li>
			<li>
				<NavLink to={r.URL_RESOURCES_CPD_ON_DEMAND_BUNDLES}>Bundles</NavLink>
			</li>
		</ul>
	);
}

export default Links;