import React, { Component } from 'react';
import Collapse from 'react-css-collapse';

import * as c from '../../../../common/_constants';
import { formatDate, isset } from '../../../../common/_utility';

class ListItem extends Component {
	constructor(props) {
		super(props);

		this.state = {
			showMore: false,
		}

		this.handleCollapse = this.handleCollapse.bind(this);
	}
	handleCollapse() {
		this.setState({ showMore: !this.state.showMore });
	}
	render() {
		const {
			item,
		} = this.props;
		return (
			<div className="row item">
				<div className="col-md-2">
					<div className="image-holder text-center">
					{isset(() => item.media.image_links.poster)
						? <img className="img-fluid" src={item.media.image_links.poster} alt={item.title} />
						: <img src={c.URL_IMAGES_BASE + 'icon-cpd-bundle.svg'} alt="" />
					}
					</div>
					<div className="expires text-small text-center mt-3">
						<span className="text-bold">Expires:</span> {formatDate(item.access_until, 'd/m/Y')}
					</div>
				</div>
				<div className="col-md-6">
					<h4 className="m-0">{item.title}</h4>
					{isset(() => item.presentation.teaser)
						?
							<div className="details">
								<button
									onClick={this.handleCollapse}
									className="btn btn-link"
								>
									{"Bundle details "}
									<i className={"fas " + (this.state.showMore ? 'fa-sort-up' : 'fa-sort-down')}></i>
								</button>
								<Collapse isOpen={this.state.showMore} transition={c.COLLAPSE_TRANSITION}>
									<div className="materials-extra p-3">
										{isset(() => item.presentation.title)
											? <h5>{item.presentation.title}</h5>
											: null
										}
										<div dangerouslySetInnerHTML={{ __html: item.presentation.teaser }}>
										</div>
									</div>
								</Collapse>
							</div>
						: null
					}
				</div>
				<div className="col-md-4">
					{isset(() => item.media.link)
						? <a href={item.media.link} rel="noopener noreferrer" target="_blank" className="btn btn-primary mb-3">Access Bundle</a>
						: null
					}
					{isset(() => item.access_info.purchased_at)
						?
							<div className="purchased">
								<strong>Purchased:</strong> {formatDate(item.access_info.purchased_at, 'd/m/Y')}
							</div>
						:	null
					}
				</div>
				<div className="col-md-12 mb-4 mt-4">
					<hr />
				</div>
			</div>
		);
	}
}

export default ListItem;