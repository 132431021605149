import React from 'react';

import List from './List';

const Sections = (props) => {
	return (
		<div className="sections">
			<div className="row">
				<div className="col-md-12">
					{props.expiredCount > 0
						? <p>You have {props.expiredCount} subscription(s) that have expired and could be missing out on some great content.</p>
						: null
					}
					<List {...props} />
				</div>
			</div>
		</div>
	);
}

export default Sections;