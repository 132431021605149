import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';

import { actionGetApiDataKey } from '../../../../actions/apiData/index';
import * as r from '../../../../common/_routes';
import * as c from '../../../../common/_constants';
import * as t from '../../../../common/_titles';

import Loading from '../../../common/Loading';
import Sections from './Sections';
import { isset } from '../../../../common/_utility';

class Main extends Component {

	componentDidMount() {

		document.title = t.TITLE_PROFILE_SUBSCRIPTIONS_SUBSCRIBED;

		this.props.dispatch(actionGetApiDataKey(c.API_DATA.subscriptions_current.key));
		this.props.dispatch(actionGetApiDataKey(c.API_DATA.subscriptions_expired.key));

	}
	render() {
		const {
			subscriptionsCurrent,
			subscriptionsExpired
		} = this.props;
		return (
			<div id="content-profile-subscriptions-current">
				<div className="row">
					<div className="col-md-12">
						<h1>Subscriptions</h1>
					</div>
				</div>
				<div className="row">
					<div className="col-md-12">
						<ul className="menu-tabs">
							<li>
								<NavLink to={r.URL_PROFILE_SUBSCRIPTIONS_SUBSCRIBED}>
									Subscribed
									{isset(() => subscriptionsCurrent.data.content.pagination.total_items)
										? " (" + subscriptionsCurrent.data.content.pagination.total_items + ")"
										: null
									}
								</NavLink>
							</li>
							<li>
								<NavLink to={r.URL_PROFILE_SUBSCRIPTIONS_EXPIRED}>
									Expired
									{isset(() => subscriptionsExpired.data.content.pagination.total_items)
										? " (" + subscriptionsExpired.data.content.pagination.total_items + ")"
										: null
									}
								</NavLink>
							</li>
						</ul>
					</div>
				</div>
				{subscriptionsCurrent
					? subscriptionsCurrent.error
						? <div><p>Sorry, an error occurred</p></div>
						: subscriptionsCurrent.loading
							?	<Loading />
							: 
								<Sections
									data={subscriptionsCurrent.data.content}
									expiredCount={
										isset(() => subscriptionsExpired.data.content.pagination.total_items)
											? subscriptionsExpired.data.content.pagination.total_items
											: null
									}
								/>
					: <Loading />
				}
				
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		subscriptionsCurrent: state.apiData.subscriptions_current,
		subscriptionsExpired: state.apiData.subscriptions_expired,
	}
}

export default connect(mapStateToProps)(Main);
