import React from 'react';

import Title from './Title';
import Articles from './Articles';

const Sections = (props) => {
	return (
		<div className="sections mt-4">
			<div className="row">
				<div className="col-md-12">
					<Title {...props} />
				</div>
				<div className="col-md-12">
					<Articles {...props} />
				</div>
			</div>
		</div>
	);
}

export default Sections;