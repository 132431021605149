import React, { Component } from 'react';
// import ActivityListItem from './ActivityListItem';
import ActivityListScroller from './ActivityListScroller';
import Loading from '../common/Loading';

class ActivityList extends Component {
	
	render() {

		const {
			info,
		} = this.props;

		return (
			<div className="card h-100">
				<div className="card-header">
					<h6>{/* <i className='fas fa-list-ul'></i>*/}recent activity</h6> 
				</div>
				<div className="dashboard-activity-list card-body">
					
					{info
						? info.error
							? <div><p>Sorry, an error occurred</p></div>
							: info.loading
								? <Loading />
								: info.data.content.items
									? info.data.content.items.length > 0
										? 
											<ActivityListScroller items={info.data.content.items} />
											// info.data.content.items.map((item) =>
											// 	<ActivityListItem key={item.item_id} item={item} />
											// )
											
										: <div><p>No items</p></div>
									: <Loading />
						: <Loading />
					}
				</div>
			</div>
		);
	}
}

export default ActivityList;
