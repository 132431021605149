import React from 'react';
import { NavLink } from 'react-router-dom';

import * as r from "../../../common/_routes";
// import { handleGaTracking } from "../../../common/_utility";

const SubmenuEducation = () => (
    <div className="submenu" id="submenu-education">
        <h1>Education</h1>
        <div className="row">
            <div className="col-md-12">
                <ul className="submenu-list">
                    <li>
                        <NavLink to={r.URL_EDUCATION_MY_ENROLLMENT}>
                            My Enrolment
                        </NavLink>
                    </li>
                    <li>
                        <a href={r.URL_EDUCATION_LOGIN}  rel="noopener noreferrer" target="_blank">
                            Current Students: Log in
                        </a>
                    </li>
                    <li>
                        <a href={r.URL_EDUCATION_VIEW_COURSES}  rel="noopener noreferrer" target="_blank">
                            Not a student? View Courses
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
);

export default SubmenuEducation;
