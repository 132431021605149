import React, { Component } from 'react'
import Modal from 'react-bootstrap/Modal';
import { connectModal } from 'redux-modal';

class ModalBoxModal extends Component {

	render() {
		const { show, handleHide, content, size } = this.props;
		return (
			<Modal 
				animation={false}
				size={size ? size : null}
				keyboard={true}
				show={show}
				backdrop={true}
				onHide={handleHide}
				backdropClassName="show"
			>
				<div className="container">
					{content}
				</div>
			</Modal>
		);
	}
}

export default class ModalBox extends Component {
	render() {
		const { name } = this.props;
		const WrappedMyModal = connectModal({ name })(ModalBoxModal)
		return <WrappedMyModal />
	}
}
