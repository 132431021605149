import React from 'react';
import { connect } from 'react-redux';
import { NavLink, Link } from 'react-router-dom';

import * as r from '../../../common/_routes';
import * as c from '../../../common/_constants';

import { formatDate, handleGaTracking } from '../../../common/_utility'
import UserProfilePicture from '../../common/UserProfilePicture';

const SubmenuProfile = ({
	user,
	hideSubmenu
}) => (
	<div className="submenu-dropdown account-dropdown" id="submenu-profile">
		<div className="submenu-top">
			<div className="row">
				<div className="col-md-3">
					<UserProfilePicture />
				</div>
				<div className="col-md-9 text-left d-flex account-dropdown-name">
					<h4 className="mb-0">{user.full_name}</h4>
					<span className="email text-grey-dark text-bold">{user.email}</span>
				</div>
			</div>
			<i className="fas fa-times" onClick={() => hideSubmenu()}></i>
		</div>
		<div className="submenu-bottom">
			{user.membership_data.full_member
				? 
					<p className="renewal">
						<span className="data-label">Membership Renewal: </span>
						<span>{formatDate(user.membership_renewal_at, 'd M Y')}</span>
					</p>
				: null
			}
			<div className="row">
				<div className="col-sm-6">
					<span className="small grey-dark">Member: #{user.member_number}</span>
				</div>
				<div className="col-sm-6 text-right">
					<span className="small grey-dark">UserID: {user.user_name}</span>
				</div>
			</div>

			<div className="account-my-dashboard">
				<Link onClick={() => hideSubmenu()} to={r.URL_DASHBOARD}>
					My Dashboard
				</Link>
			</div>

			<ul className="submenu-list">
				<li>
					<NavLink onClick={() => hideSubmenu()} to={r.URL_PROFILE_ACCOUNT_DETAILS}>
						<i className="fas fa-user"></i> Account Details
					</NavLink>
				</li>
				<li>
					<NavLink onClick={() => hideSubmenu()} to={r.URL_PROFILE_CPD_RECORD_STRUCTURED}>
						<i className="fas fa-chart-pie"></i> CPD Record
					</NavLink>
				</li>
				<li>
					<NavLink onClick={() => hideSubmenu()} to={r.URL_PROFILE_ORDER_HISTORY_ORDERS}>
						<i className="fas fa-credit-card"></i> Order History
					</NavLink>
				</li>
				<li>
					<NavLink onClick={() => hideSubmenu()} to={r.URL_PROFILE_RENEW_MEMBERSHIP}>
						<i className="fas fa-clock"></i> Renew Membership
					</NavLink>
				</li>
				<li>
					<NavLink onClick={() => hideSubmenu()} to={r.URL_PROFILE_SUBSCRIPTIONS_SUBSCRIBED}>
						<i className="fas fa-check-square"></i> Subscriptions
					</NavLink>
				</li>
				<li>
					<NavLink onClick={() => hideSubmenu()} to={r.URL_PROFILE_TAX_SPECIALIST_PROFILE}>
						<img src={c.URL_IMAGES_BASE + 'icon-tti.svg'} alt="" className="submenu-profile-icon-tti"/> Tax Specialist Profile
					</NavLink>
				</li>
				{/* <li>
					<NavLink onClick={() => hideSubmenu()} to={r.URL_PROFILE_GIVE_FEEDBACK}>
						<i className="fas fa-comment"></i> Give Feedback
					</NavLink>
				</li> */}
				{user.membership_data.logos.access
					?
					<li>
						<NavLink onClick={() => hideSubmenu()} to={r.URL_PROFILE_LOGOS}>
							<i className="fas fa-pencil-alt"></i> Logos
						</NavLink>
					</li>
					: null
				}
				
				<li>
					<NavLink onClick={() => { handleGaTracking('Menu - Logout', 'Logout'); hideSubmenu()}} to={r.URL_PROFILE_LOGOUT}>
						<i className="fas fa-sign-out-alt"></i> Logout
					</NavLink>
				</li>
			</ul>
		</div>
	</div>
);

function mapStateToProps(state) {
	return {
		user: state.app.global.global_user.data.content,
	}
}

export default connect(mapStateToProps)(SubmenuProfile);