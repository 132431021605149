import React, { Component } from 'react';
import { connect } from 'react-redux';
import { hide } from 'redux-modal';
import { bindActionCreators } from 'redux';


import { validPassword, handleGaTracking} from '../../../common/_utility';


import { actionApiSendData } from '../../../actions/apiData/index';
import * as m from '../../../common/_messages';
import * as c from '../../../common/_constants';


class ChangePassword extends Component {
	constructor(props) {
		super(props);

		this.state = {
			saving: false,
			currentPasswordError: false,
			newPasswordError: false,
			confirmPasswordError: false,
			responseMessage: '',
			currentPasswordValue: '',
			newPasswordValue: '',
			confirmPasswordValue: '',
		};

		this.isFormValid = this.isFormValid.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleFieldChange = this.handleFieldChange.bind(this);

	}

	handleFieldChange(e) {
		const target = e.target;
		const value = target.type === 'checkbox' ? target.checked : target.value;
		const name = target.name;

		this.setState({ [name + 'Value']: value });
	}


	handleSubmit(e) {

		e.preventDefault();

		//validation
		if (!this.isFormValid()) {
			return;
		}

		this.setState({ 'saving': true });
		let profile = this.props.profileUser.data.content;

		let data = {
			 "userID": profile.user_name,
			 "Password" : this.state.currentPasswordValue,
			 "New_password" : this.state.newPasswordValue
		}
		window.apidataerror = false;

		handleGaTracking('Profile page - Change Password Save', 'Change Password Save');

		this.props.dispatch(
				actionApiSendData(c.API_DATA.profile_password.key, data)
		).then((success) => {
			if (success) {
				document.getElementById("api-return-error").innerText = '';

				this.setState({
					responseMessage: 'Saved',
					dataSaved: true
				});
				//close popup after 3 secs
				let hidePopup = this.props.hide;
				setTimeout(
						function () {
							hidePopup('edit-change-password')
						}, 2000
				);
			} else {
				if (window.apidataerror)
				{
					document.getElementById("api-return-error").innerText = window.apidataerror.Error;
				}
				else
				{
					this.setState({ responseMessage: 'something went wrong' });
				}
			}

			this.setState({ 'saving': false });

		});
	}

	isFormValid() {

		let formValid = true;

		//reset error states
		this.setState({
			currentPasswordError: false,
			newPasswordError: false,
			confirmPasswordError: false
		});

		if (this.state.currentPasswordValue === '') {
			this.setState({ currentPasswordError: m.formError.currentpassword.required });
			formValid = false;
		}

		if (this.state.newPasswordValue === '') {
			this.setState({ newPasswordError: m.formError.newpassword.required });
			formValid = false;
		} else {
			if (!validPassword(this.state.newPasswordValue)) {
				this.setState({ newPasswordError: m.formError.newpassword.notValid });
				formValid = false;
			}
		}

		if (this.state.confirmPasswordValue === '') {
			this.setState({ confirmPasswordError: m.formError.confirmpassword.required });
			formValid = false;
		} else {
			if (this.state.newPasswordValue !== this.state.confirmPasswordValue) {
				this.setState({ confirmPasswordError: m.formError.confirmpassword.notValid });
				formValid = false;
			}
		}

		return formValid;
	}

	render() {

		return (
				<div className="edit-modal" id="edit-change-password">
					<form onSubmit={this.handleSubmit}>
						<div className="header">
							<div className="row">
								<div className="col-md-12">
									<h4 className="m-0">Change your Member Portal pasword</h4>
								</div>
							</div>
						</div>
						<div className="content">

							<div className={"form-group"}>
								<p className="form-error" id={"api-return-error"}></p>
							</div>

								<div
										className={"form-group" + (this.state.currentPasswordError ? ' has-error' : '')}>
									<label htmlFor="firstName">Current Password</label>
									<small>Please enter your current member portal password</small>
									<input
											type="password"
											id="currentPassword"
											name="currentPassword"
											defaultValue={''}
											onChange={this.handleFieldChange}
											className="form-control"
									/>
									{this.state.currentPasswordError
											?
											<p className="form-error">{this.state.currentPasswordError}</p>
											: null
									}
									<hr />
									<label htmlFor="firstName">New Password</label>
									<small>Please enter the new password you would like to use. At least 6 characters, and containing upper and lower case letters and a number</small>
									<input
											type="password"
											id="newPassword"
											name="newPassword"
											defaultValue={''}
											onChange={this.handleFieldChange}
											className="form-control"
									/>
									{this.state.newPasswordError
											?
											<p className="form-error">{this.state.newPasswordError}</p>
											: null
									}
									<label htmlFor="firstName">Confirm Password</label>
									<small>Please re-enter the new password you would like to use to confirm that it is correct</small>
									<input
											type="password"
											id="confirmPassword"
											name="confirmPassword"
											defaultValue={''}
											onChange={this.handleFieldChange}
											className="form-control"
									/>
									{this.state.confirmPasswordError
											?
											<p className="form-error">{this.state.confirmPasswordError}</p>
											: null
									}


								</div>


								<small>
									If you have trouble changing your password please
									contact the customer service department on <a
										href="tel:1300829338">1300 829 338</a> or email{" "}
									<a href="mailto:membership@taxinstitute.com.au">membership@taxinstitute.com.au</a></small>

						</div>
						<div className="footer">
							<div className="row">
								<div className="col-md-12">
									{this.state.saving
											?
											<span>Saving <i className="fas fa-spinner fa-spin"></i></span>
											:
											<span>
											{!this.state.dataSaved
													?
													<span>
														<button
																disabled={this.state.saving}
																type="button"
																onClick={() => this.props.hide('edit-change-password')}
																className="btn btn-link-secondary"
														>
															Cancel
														</button>
														<button
																disabled={this.state.saving}
																type="submit"
																value="Save"
																className="btn btn-link"
														>
															Save
														</button>
													</span>
													: null
											}
												{this.state.responseMessage
														? <p className="response-message">{this.state.responseMessage}</p>
														: null
												}
										</span>
									}
								</div>
							</div>
						</div>
					</form>
					</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		profileUser: state.apiData.profile_user,
	}
}
function mapDispatchToProps(dispatch) {
	return {
		...bindActionCreators({ hide }, dispatch),
		dispatch
	};
}
export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);

