import React from 'react';

import { formatDate } from '../../../../common/_utility';


const OrderDetails = ({
	data
}) => {
	return (
		<div className="order-details card">
			<div className="card-header">
				<h6><i className="fas fa-truck"></i> Order Details</h6>
			</div>

			<div className="card-body">

				<p className="shipping-status">{data.shipping.status}</p>
				<p>
					<strong>Order Number:</strong> {data.id}
				</p>
				<p>
					<strong>Order Type:</strong> {data.type}
				</p>
				<p>
					<strong>Customer Number:</strong> {data.shipping.customer_number}
				</p>
				<p>
					<strong>Shipment Method:</strong> {data.shipping.type}
				</p>
				<p>
					<strong>Tracking:</strong> {data.shipping.tracking_number ? data.shipping.tracking_number : 'Not Available'}
				</p>
				<p>
					<strong>Date Shipped:</strong> {formatDate(data.shipping.shipped_at, 'd/m/Y g:i:s A')}
				</p>
			</div>
		</div>
	);
}

export default OrderDetails;