import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { show } from 'redux-modal';

import * as c from '../../../common/_constants';
import HelpEPub from '../../common/HelpEPub';

const Item = ({
	data,
	show,
	rootLink
}) => {

	return (
		<div className="item">
			{data.epub_uri
				?
					<button
						type="button"
						className="btn btn-link"
						onClick={() =>
							show(
								'help-what-is-ePub',
								{ content: <HelpEPub /> }
							)
						}
					>
						What is an ePub
					</button>
				: null
			}
			<img width="200" src={c.URL_CDNIMAGES_BASE + data.image} alt="Taxation In Australia" />
			<p>{data.issue} {data.volume} Edition</p>
			<a href={rootLink + '/redirectfile.cfm?docFilename=' + data.document_uri} rel="noopener noreferrer" target="_blank">PDF</a>
			{data.epub_uri
				? <span>{" | "}<a href={rootLink + '/redirectfile.cfm?docFilename=' + data.epub_uri} rel="noopener noreferrer" target="_blank">EPUB</a></span>
				: null
			}
		</div>
	);
}


function mapDispatchToProps(dispatch) {
	return {
		...bindActionCreators({ show }, dispatch),
		dispatch
	};
}
function mapStateToProps(state) {
	return {
		rootLink: state.app.global.global_user.data.content.root_url,
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Item);