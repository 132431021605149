import { combineReducers } from 'redux';

// import driver from './driver';
import appStatus from './appStatus';
import global from './global';
import auth from './auth';
// import splitAccount from './splitAccount';
// import accountMaster from './accountMaster';
// import users from './users';
// import accountsConnected from './accountsConnected';
// import wordpressContent from './wordpressContent';
// import coreLogicCount from './coreLogicCount';

const rootReducer = combineReducers({
	appStatus,
	global,
	auth,
	// splitAccount,
	// accountMaster,
	// driver,
	// accountsConnected,
	// wordpressContent,
	// coreLogicCount
});

export default rootReducer;