import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { show } from 'redux-modal';

import TableItem from './TableItem';

const Table = ({
	choices,
	data,
	from,
	show,
	user
}) => {
	return (
		<div className="cpd-table">
			<div className="table-responsive">
				<table className="table table-striped">
					<thead>
						<tr>
							<th>Resource</th>
							<th>Provider</th>
							<th>Date</th>
							<th>CPD hours</th>
							{from !== 'print' && user.content.membership_data.permissionto_addcpdrecord
								? <th></th>
								: null
							}
							
						</tr>
					</thead>
					<tbody>
						{data.items.length > 0
							?
								data.items.map((item, index) =>
									<TableItem
										key={index}
										item={item}
										from={from}
										show={show}
										choices={choices}
										user={user}
									/>
								)
							:	<tr><td colspan="4">No records</td></tr>
						}
					</tbody>
				</table>
			</div> 
		</div>
	);
}

function mapDispatchToProps(dispatch) {
	return {
		...bindActionCreators({ show }, dispatch),
		dispatch
	};
}

function mapStateToProps(state) {
	return {
		user: state.app.global.global_user.data,
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Table);