import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { show } from 'redux-modal';

import TableItem from './TableItem';
import FormSendInvoice from './FormSendInvoice';
import { handleGaTracking } from '../../../../common/_utility';

const Table = ({
	data,
	show
}) => {
	const print = () => {
		handleGaTracking('Order Details page - Print Order', 'Print Order');
		window.print();
	}
	return (
		<div className="orders-table mt-5">
			<div className="row">
				<div className="col-md-6">
					<div className="table-title">Order History</div>
				</div>
				<div className="col-md-6 text-right">
					<button
						className="btn"
						onClick={() =>
							show(
								'send-order-invoice',
								{ content: <FormSendInvoice data={data} /> }
							)
						}
					>
						<i className="far fa-envelope"></i>
					</button>
					<button
						className="btn"
						onClick={print}
					>
						<i className="fas fa-print"></i>
					</button>
				</div>
			</div>
			{data.products.length > 0
				?
					<div className="table-responsive">
						<table className="table table-striped">
							<thead>
								<tr>
									<th>Product</th>
									<th>Description</th>
									<th>Quantity</th>
									<th>Unit Price</th>
									<th>Total</th>
								</tr>
							</thead>
							<tbody>
								{data.products.map((item, index) => <TableItem key={index} item={item} />)}
							</tbody>
							<tfoot>
								<tr>
									<td className="text-right subtotal" colSpan="5">Sub-Total: ${data.subtotal}</td>
								</tr>
								<tr>
									<td className="text-right shipping" colSpan="5">Shipping/Handling: ${data.shipping_handling}</td>
								</tr>
								<tr>
									<td className="text-right gst" colSpan="5">GST: ${data.gst}</td>
								</tr>
								<tr>
									<td className="text-right table-double-line m-0 p-0" colSpan="5">&nbsp;</td>
								</tr>
								<tr>
									<td className="text-right total" colSpan="5"><strong>Grand Total: ${data.grant_total}</strong></td>
								</tr>
								<tr>
									<td className="text-right payments" colSpan="5">Payments: ${data.payment}</td>
								</tr>
							</tfoot>
						</table>
					</div> 
				: 
					<p>No items</p>
			}
			
		</div>
	);
}

function mapDispatchToProps(dispatch) {
	return {
		...bindActionCreators({ show }, dispatch),
		dispatch
	};
}

export default connect(null, mapDispatchToProps)(Table);