import React from 'react';

import InformationMissing from '../../common/InformationMissing';
import EditPersonalInfo from './EditPersonalInfo';

import { handleGaTracking } from '../../../common/_utility';

const PersonalInfo = ({
	profile,
	choices,
	show,
}) => {
	let prefixChoice = choices.prefix.find(item => parseInt(item.id, 10) === profile.prefix_id);
	let prefix = prefixChoice ? prefixChoice.prefix : null;

	let stateChoice = choices.state.find(item => parseInt(item.id, 10) === profile.state_id);
	let state = stateChoice ? stateChoice.abbreviation : null;

	let countryChoice = choices.country.find(item => parseInt(item.id, 10) === profile.country_id);
	let country = countryChoice ? countryChoice.country : null;

	let fullAddress;

	fullAddress = [profile.city, state].join(', ');
	fullAddress = [fullAddress, profile.postcode].join(' ');
	fullAddress = [profile.address_line1, fullAddress, country].join('<br />');
	return (
		<div className="personal-info rhs-pullout mt-5">
			
			<div className="row">
				<div className="col-md-8">
					<div className="info-detail">
						<h4>
							{prefix || profile.first_name || profile.last_name
								? [prefix, profile.first_name, profile.last_name].join(' ')
								: <InformationMissing />
							}
						</h4>
					</div>
				</div>
				<div className="col-md-4 text-right">
					<button 
					type="button"
					className="btn btn-link text-base-color trigger-edit"
					onClick={() => {
						handleGaTracking('Tax Specialist Profile page - Edit Personal Information', 'Edit Personal Information');
						show(
							'edit-tax-specialist-profile-personal-info', 
							{ content: <EditPersonalInfo  /> }
						)
					}}
					>
					<i className="fas fa-pen"></i>
					</button>
				</div>
				<div className="col-md-12">
					<div className="info-detail">
						<h6>Company</h6>
						<p>
							{profile.company_name
								? profile.company_name
								: <InformationMissing />
							}
							<br/>
							<span className="strong">Title: </span>
							{profile.position_title
								? profile.position_title
								: <InformationMissing />
							}
						</p>
					</div>
				</div>
				<div className="col-md-12">
					<div className="info-detail">
						<h6>Address</h6>
						{fullAddress
							? <p dangerouslySetInnerHTML={{ __html: fullAddress }}></p>
							: <InformationMissing />
						}
					</div>
				</div>
				<div className="col-md-12">
					<div className="info-detail">
						<h6>Contact</h6>
						<p>
							<span>Email: </span>
							{profile.email
								? profile.email
								: <InformationMissing />
							}
							<br/>
							<span>Phone: </span>
							{profile.phone
								? profile.phone
								: <InformationMissing />
							}
							<br/>
							<span>Fax: </span>
							{profile.fax
								? profile.fax
								: <InformationMissing />
							}
						</p>
					</div>
				</div>
				
			</div>
		</div>
	);
}

export default PersonalInfo;