import React, { Component } from 'react';
import { connect } from 'react-redux';

import { actionGetApiDataKey, actionGetApiDataUrl } from '../../../actions/apiData/index';
import * as c from '../../../common/_constants';
import * as t from '../../../common/_titles';

import NonMemberMessage from '../../common/NonMemberMessage';
import Loading from '../../common/Loading';
import Search from './Search';
import Sections from './Sections';

class Main extends Component {
	componentDidUpdate(prevProps) {

		if (this.props.fullMember){
			if (this.props.taxvine && !this.props.taxvine.error && !this.props.taxvine.loading) {
				if (this.props.match.params.id !== prevProps.match.params.id) {
					const id = this.props.match.params.id ;
					if(id){
						this.props.dispatch(
							actionGetApiDataUrl(
								c.API_DATA.membership_taxvine.key,
								c.API_URL + c.API_DATA.membership_taxvine.base + id + '/articles?count=all',
								true
							)
						);
					}else{
						this.props.dispatch(actionGetApiDataKey(c.API_DATA.membership_taxvine.key));
					}
				}
			}
		}
	}
	componentDidMount() {

		document.title = t.TITLE_MEMBERSHIP_TAXVINE;

		if (this.props.fullMember) {
			const id = this.props.match.params.id;

			if(id){

				this.props.dispatch(
					actionGetApiDataUrl(
						c.API_DATA.membership_taxvine.key,
						c.API_URL + c.API_DATA.membership_taxvine.base + id + '/articles?count=all',
						true
					)
				);
			}else{
				this.props.dispatch(actionGetApiDataKey(c.API_DATA.membership_taxvine.key));
			}

			this.props.dispatch(actionGetApiDataKey(c.API_DATA.membership_taxvine_listing.key));
		}
	}
	render() {
		const {
			taxvine,
			taxvineListing,
			fullMember,
			rootLink
		} = this.props;
		return (
			<div id="content-membership-taxvine">
				<div className="row">
					<div className="col-md-12">
						<h1>Taxvine</h1>
					</div>
				</div>

				{fullMember
					?	
						<div>
							{taxvineListing
								? taxvineListing.error
									? <div><p>Sorry, an error occurred</p></div>
									: taxvineListing.loading
										? null
									: <Search data={taxvineListing.data.content} rootLink={rootLink} />
								: null
							}
							{taxvine
								? taxvine.error
									? <div><p>Sorry, an error occurred</p></div>
									: taxvine.loading
										? <Loading/>
										: <Sections data={taxvine.data.content} />
								: <Loading/>
							}
						</div>

					:	<NonMemberMessage />
				}

			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		fullMember: state.app.global.global_user.data.content.membership_data.full_member,
		taxvine: state.apiData.membership_taxvine,
		taxvineListing: state.apiData.membership_taxvine_listing,
		rootLink: state.app.global.global_user.data.content.root_url,
	}
}

export default connect(mapStateToProps)(Main);
