import React from 'react';
import { formatDate, handleGaTracking } from '../../../common/_utility';

import EditTaxAgentDetails from './EditTaxAgentDetails';
import InformationMissing from '../../common/InformationMissing';

const TaxAgentDetails = ({
	profile,
	choices,
	show,
}) => {
	let statusChoice = choices.tax_agent_status.find(item => parseInt(item.id, 10) === parseInt(profile.tax_agent_status_id, 10));
	let status = statusChoice ? statusChoice.name : null;
	return (
		<div className="tax-agent-details card mb-4">
			<div className="row">
				<div className="col-md-12 mb-4">
					<div className="card-header">
						<h6><i className="fas fa-id-card"></i> Tax Agent Details</h6>
						<div className="card-header-icon-team">
							<button
								type="button"
								className="btn trigger-edit btn-link"
								onClick={() => {
									handleGaTracking('Profile page - Edit Tax Agent Details', 'Edit Tax Agent Details');
									show(
										'edit-tax-agent-details',
										{ content: <EditTaxAgentDetails />, size: "lg" }
									)
								}}
							>
								<i className="fas fa-pen"></i>
							</button>
						</div>
					</div>
					<div className="card-body">
						<div className="row">
							<div className="col-md-12">
								<div className="info-detail">
									<div className="data-label">Status</div>
									<p>
										{status
											? status
											: <InformationMissing />
										}
									</p>
								</div>
							</div>
							<div className="col-md-12">
								<div className="info-detail">
									<div className="data-label">RTA Number</div>
									<p>
										{profile.tax_agent_rta_number
											? profile.tax_agent_rta_number
											: <InformationMissing />
										}
									</p>
								</div>
							</div>
							<div className="col-md-12">
								<div className="info-detail">
									<div className="data-label">RTA Expiry Date</div>
									<p>
										{profile.tax_agent_rta_expiry_date
											? formatDate(profile.tax_agent_rta_expiry_date, 'd/m/Y')
											: <InformationMissing />
										}
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default TaxAgentDetails;