import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import * as r from '../../../common/_routes';

import SubmenuProfile from '../menuPortal/SubmenuProfile';
import UserProfilePicture from '../../common/UserProfilePicture';


class Profile extends Component {
	constructor(props) {
		super(props);

		this.state = {
			showSubmenu: false,
		}

		this.showSubmenu = this.showSubmenu.bind(this);
		this.hideSubmenu = this.hideSubmenu.bind(this);

	}
	showSubmenu() {
		this.setState({ showSubmenu: true });
	}
	hideSubmenu() {
		this.setState({ showSubmenu: false });
	}
	render() {
		const{
			location
		} = this.props;
		return (
			<div className="d-inline-block">
				<button
					type="button"
					className={"btn profile-picture-wrapper" + (r.MENU_PROFILE.includes(location.pathname) ? ' active' : '')}
					onClick={() => this.showSubmenu() }
				>
					<UserProfilePicture />
				</button>
				{this.state.showSubmenu
					? <SubmenuProfile hideSubmenu={this.hideSubmenu} />
					: null
				}
			</div>
		)
	}
}

export default withRouter(Profile);

