import React from 'react';
import { NavLink } from 'react-router-dom';

import * as r from '../../../../common/_routes';

const Links = (props) => {
	return (
		<ul className="menu-tabs">
			<li>
				<NavLink to={r.URL_EVENTS_MY_EVENTS_UPCOMING}>upcoming</NavLink>
			</li>
			<li>
				<NavLink to={r.URL_EVENTS_MY_EVENTS_PAST}>Past</NavLink>
			</li>
		</ul>
	);
}

export default Links;