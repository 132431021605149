import React, { Component } from 'react';
import { connect } from 'react-redux';

import { actionGetApiDataKey } from '../../../actions/apiData/index';

import Sections from './Sections';
import Loading from '../../common/Loading';
import * as c from '../../../common/_constants';
import * as t from '../../../common/_titles';

class Main extends Component {

	componentDidMount() {

		document.title = t.TITLE_PROFILE_TAX_SPECIALIST_PROFILE;

		this.props.dispatch(actionGetApiDataKey(c.API_DATA.tax_specialist_profile.key));

	}
	render() {
		const {
			taxSpecialistData,
      eportalUrl
		} = this.props;
		return (
			<div id="content-profile-tax-specialist-profile">
				<div className="row">
					<div className="col-md-12">
						<h1>Tax Specialist Profile</h1>
					</div>
				</div>
				{taxSpecialistData
					? taxSpecialistData.error
						? <div><p>Sorry, an error occurred</p></div>
						: taxSpecialistData.loading
							?	<Loading />
							: 	
								<Sections
									eportalUrl={eportalUrl}
									profile={taxSpecialistData.data.content}
									choices={taxSpecialistData.data.choices}
									saving={taxSpecialistData.saving}
								/>
					: <Loading />
				}
				
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		taxSpecialistData: state.apiData.tax_specialist_profile,
		eportalUrl: state.app.global.global_user.data.content.root_eportalurl,
	}
}

export default connect(mapStateToProps)(Main);
