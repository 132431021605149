import React from 'react';

const Intro = () => {
	return (
		<div className="intro">
			<div className="row">
				<div className="col-md-12">
					<p>
						Edit the information below
					</p>
				</div>
			</div>
		</div>
	);
}

export default Intro;