import React from 'react';

const BillingDetails = ({
	data,
	choices
}) => {
	let stateChoice = choices.state.find(state => parseInt(state.id, 10) === data.billing.state_id);
	let state = stateChoice ? stateChoice.abbreviation : null;

	let countryChoice = choices.country.find(country => parseInt(country.id, 10) === data.billing.country_id);
	let country = countryChoice ? countryChoice.country : null;

	let partsAddress1 = [];
	let partsAddress2 = [];

	if (data.billing.address_line1) {
		partsAddress1.push(data.billing.address_line1);
	}
	if (data.billing.address_line2) {
		partsAddress1.push(data.billing.address_line2);
	}
	if (data.billing.address_line3) {
		partsAddress1.push(data.billing.address_line3);
	}
	if (data.billing.city) {
		partsAddress2.push(data.billing.city);
	}
	if (state) {
		partsAddress2.push(state);
	}
	if (data.billing.postcode) {
		partsAddress2.push(data.billing.postcode);
	}

	return (
		<div className="billing-details card">
			<div className="card-header">
				<h6><i className="fas fa-credit-card"></i> Billing Details</h6>
			</div>

			<div className="card-body">

				<p>
					<strong>Payment Method:</strong> {data.billing.payment_method}
				</p>
				<p>
					<strong>Bill To:</strong>
					<br/>
					{data.billing.customer_name}
					<br />
					{partsAddress1.join(', ')}
				</p>
				<p>
					<strong>Ship To:</strong>
					<br/>
					{partsAddress2.join(' ')}
					<br/>
					{country}
				</p>
			</div>
		</div>
	);
}

export default BillingDetails;