import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import 'react-circular-progressbar/dist/styles.css';

import * as r from '../../common/_routes';

import DoughnutChart from '../common/DoughnutChart';


class CPDWheel extends Component {

	render() {
		const data = this.props.user.content;
		return (
			<div className="dashboard-cdp-wheel card-inverse h-100">
				<Link
					className="text-white"
					to={r.URL_PROFILE_CPD_RECORD_STRUCTURED}
				>
					<div>
						<h6>CPD Hours</h6>
						<div className="wheel-wrapper">
							<DoughnutChart
								cpdHoursTotal={data.cpd_hours.total}
								cpdHoursStructured={data.cpd_hours.structured}
								cpdHoursUnStructured={data.cpd_hours.unstructured}
								cpdHoursRequired={data.cpd_hours_required}
								stroke={11}
								wheelBgColor={'#18AFF5'}
								structuredColor={'#ffffff'}
								unstructuredColor={'#b5b4b4'}
								transitionDurationInSeconds={1.9}
								fullLegend={true}
							>
							</DoughnutChart>
						</div>
						
						<p className="legend">
							Associate, Fellow, and CTA require completion of 30 CPD hours.
							At least 15 must be structured for Associate and Fellow. All 30
							must be structured for CTA
						</p>
					</div>
				</Link>
			</div>
		);
	}
}

export default CPDWheel;
