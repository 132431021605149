import React from 'react';

import { formatDate } from '../../../../common/_utility';

import ListItem from './ListItem';

const List = ({
	data,
	ePortalLink
}) => {
	let year = false;
	let yearSection = false;
	return (
		<div className="webinars-list">
			<div className="row">
				{data.items.length > 0
					? data.items.map((item, index) => {
						year = false;

						if (yearSection !== formatDate(item.start_at, 'Y')){
							yearSection = formatDate(item.start_at, 'Y');
							year = yearSection;
						}
						return <ListItem key={index} item={item} year={year} ePortalLink={ePortalLink} />})
					: <div className="col-md-12"><p>No items</p></div>
				}
			</div>
		</div>
	);
}

export default List;