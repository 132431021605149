export const formError = {
	email: {
		required: 'Email is required',
		notValid: 'Please enter a valid email',
		notValidMultiple: 'Please enter a valid emails (max 5)',
		notValidMultipleTooMany: 'Please enter max 5 emails',
	},
	password: {
		required: 'Password is required',
	},

	userid: {
		required: 'User ID is required',
	},

	currentpassword: {
		required: 'Please enter your current password',
	},

	newpassword: {
		required: 'Please enter your new password',
		notValid: 'Your new password is not valid. Passwords must be at least 6 characters long in length, and include an upper case letter, a lower case letter and a number.',
	},

	confirmpassword: {
		required: 'Please confirm your new password',
		notValid: 'The confirmation password you entered does not match the new password you entered.',
	},

	companyName: {
		required: 'Company Name is required',
	},
	positionTitle: {
		required: 'Position Title is required',
	},
	specialisation: {
		required: 'Specialisation is required',
	},
	primaryJobFunction: {
		required: 'Primary Job Function is required',
	},
	interests: {
		required: 'Interest is required',
	},
	prefix: {
		required: 'Title is required',
	},
	firstName: {
		required: 'First Name is required',
	},
	lastName: {
		required: 'Last Name is required',
	},
	preferredName: {
		required: 'Preferred Name is required',
	},
	gender: {
		required: 'Gender is required',
	},
	dob_day: {
		required: 'Day is required',
	},
	dob_month: {
		required: 'Month is required',
	},
	dob_year: {
		required: 'Year is required',
	},
	facebook: {
		required: 'Facebook URL is required',
	},
	twitter: {
		required: 'Twitter URL is required',
	},
	linkedIn: {
		required: 'LinkedIn URL is required',
	},
	about: {
		required: 'About description is required',
	},
	address: {
		required: 'Address is required',
	},
	city: {
		required: 'City is required',
	},
	state: {
		required: 'State is required',
	},
	postcode: {
		required: 'Postcode is required',
	},
	country: {
		required: 'Country is required',
	},
	phone: {
		required: 'Phone is required',
	},
	fax: {
		required: 'Fax is required',
	},
	segmentationDecision: {
		required: 'This field is required',
	},
	membershipOtherOrg: {
		required: 'This field is required',
	},
	clientIndustry: {
		required: 'This field is required',
	},
	corporateIndustry: {
		required: 'This field is required',
	},
	stageInCareer: {
		required: 'This field is required',
	},
	startedCareerInTaxYear: {
		required: 'This field is required',
	},
	interestVolunteering: {
		required: 'This field is required',
	},
	url: {
		required: 'URL is required',
		notValid: 'Please enter a valid URL',
	},
	trainingType: {
		required: 'This field is required',
	},
	deliveryType: {
		required: 'This field is required',
	},
	trainingLocation: {
		required: 'This field is required',
	},
	productTitle: {
		required: 'This field is required',
	},
	dateEarned: {
		required: 'This field is required',
	},
	cpdHours: {
		required: 'This field is required',
		integer: 'Please enter a valid number'
	},
	rta_expiry_day: {
		required: 'Please select a day',
	},
	rta_expiry_month: {
		required: 'Please select a month',
	},
	rta_expiry_year: {
		required: 'Please select a year',
	},
	pi_ccCard: {
		required: 'Please enter card number',
		formatted: "Please enter a 16 digit card number"
	},
	pi_expiry_month: {
		required: 'Please select a month',
	},
	pi_expiry_year: {
		required: "Please select a year"
	},
	pi_cvv: {
		required: 'Please enter your cvv',
	},

}