import React from 'react';
import { Link } from 'react-router-dom';

import * as r from '../../../../common/_routes';
import { handleGaTracking } from '../../../../common/_utility';

const Footer = () => {
	const print = () => {
		handleGaTracking('CPD Record page - Print Records', 'Print Records');
		window.print();
	}
	return (
		<div className="print-actions">
			<div className="row ">
				<div className="col-md-12 text-right">
					<Link
						to={r.URL_PROFILE_CPD_RECORD_STRUCTURED}
						className="btn btn-link-secondary"
					>
						Cancel
					</Link>
					<button
						className="btn btn-link"
						onClick={print}
					>
						Print
					</button>
				</div>
			</div>
		</div>
	);
}

export default Footer;