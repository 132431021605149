import React, { Component } from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string';

import { actionGetApiDataKey } from '../../../../actions/apiData/index';
import * as c from '../../../../common/_constants';
import * as t from '../../../../common/_titles';

import Title from './Title';
import CustomerInfo from './CustomerInfo';
import Sections from './Sections';
import Footer from './Footer';
import Actions from './Actions';
import Loading from '../../../common/Loading';

class Main extends Component {

	componentDidMount() {

		document.title = t.TITLE_PROFILE_CPD_RECORD_PRINT;

		const params = queryString.parse(this.props.location.search);

		//remove parameter type as it is only internal
		delete params.query_type;

		this.props.dispatch(actionGetApiDataKey(c.API_DATA.cpd_record_structured_print.key, params));
		this.props.dispatch(actionGetApiDataKey(c.API_DATA.cpd_record_unstructured_print.key, params));

	}
	
	render() {
		const {
			cpdRecordStructured, 
			cpdRecordUnstructured, 
			user,
			userChoices,
			history
		} = this.props;
		return (
			<div id="content-profile-cpd-record-print">
				<div className="row">
					<div className="col-md-12">
						<Title search={this.props.location.search} />
					</div>
				</div>
				<div className="row">
					<div className="col-md-12">
						<CustomerInfo user={user} choices={userChoices} />
					</div>
				</div>
				{cpdRecordStructured && cpdRecordUnstructured
					? cpdRecordStructured.error || cpdRecordUnstructured.error
						? <div><p>Sorry, an error occurred</p></div>
						: cpdRecordStructured.loading || cpdRecordUnstructured.loading
							? 	<Loading />
							: 	
								<Sections
									cpdRecordStructured={cpdRecordStructured.data.content}
									cpdRecordUnstructured={cpdRecordUnstructured.data.content}
									choices={cpdRecordStructured.data.choices}
								/>
					: <Loading />
				}
				<div className="row">
					<div className="col-md-12">
						<Footer user={user} />
					</div>
				</div>
				<div className="row">
					<div className="col-md-12">
						<Actions history={history} />
					</div>
				</div>
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		cpdRecordStructured: state.apiData.cpd_record_structured_print,
		cpdRecordUnstructured: state.apiData.cpd_record_unstructured_print,
		user: state.app.global.global_user.data.content,
		userChoices: state.app.global.global_user.data.choices,
	}
}

export default connect(mapStateToProps)(Main);
