import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import * as r from '../../common/_routes';

import EventListItem from './EventListItem';
import Loading from '../common/Loading';

class EventsList extends Component {
	render() {

		const { 
			info,
			rootUrl
		} = this.props;

		return (
			
			<div className="dashboard-events-list card h-100">
				<div className="card-header">
					<h6>{/* <i className="fas fa-calendar-alt"></i>  */}your upcoming events</h6>
				</div>

				<div className="card-body">
					{info
						? info.error
							? <div><p>Sorry, an error occurred</p></div>
							: info.loading
								? <Loading/>
								: info.data.content.items
									? info.data.content.items.length > 0
										? 
											info.data.content.items.map((item) => 
												<EventListItem key={item.id} item={item} choices={info.data.choices} />
											)
										: 
											<div className="h-100">
												<p className="position-absolute">You have no upcoming events.</p>
												<div className="dashboard-events-list-empty h-100">
													<a href={rootUrl + "/professional-development/upcoming-cpd-events"} rel="noopener noreferrer" target="_blank">
														Browse all upcoming events at The Tax Institute
													</a>
												</div>
											</div>
									: <Loading />
						: <Loading />
					}
				</div>

				<div className="card-footer">
					<Link to={r.URL_EVENTS_MY_EVENTS_UPCOMING} className="btn-link">
						All Events
	                </Link>
                </div>
			</div>
			
		);
	}
}

export default EventsList;
