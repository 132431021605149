import React, { Component } from 'react';
import { connect } from 'react-redux';

import { actionGetApiDataKey, actionGetApiDataUrl } from '../../../actions/apiData/index';
import * as c from '../../../common/_constants';
import * as t from '../../../common/_titles';

import Search from './Search';
import Sections from './Sections';
import Loading from '../../common/Loading';
import NonSubscriptionMessage from './NonSubscriptionMessage';

class Main extends Component {
	
	componentDidUpdate(prevProps) {

		if (this.props.subscription && !this.props.subscription.error && !this.props.subscription.loading &&
			this.props.subscription.data.content.items.filter(sub=>sub.product_id===1096).length) {
			if (!this.props.match.params.id || this.props.match.params.id !== prevProps.match.params.id) {
				const id = this.props.match.params.id ;
				
				if(!this.props.taxwiseNewsletterlist) {
					if (id) {

						this.props.dispatch(
							actionGetApiDataUrl(
								c.API_DATA.membership_taxwise_newsletter.key,
								c.API_URL + c.API_DATA.membership_taxwise_newsletter.endpoint + id+"/articles",
								true
							)
						);
					
						this.props.dispatch(actionGetApiDataKey(c.API_DATA.membership_taxwise_newsletterlist.key));
					} else {
						this.props.dispatch(actionGetApiDataKey(c.API_DATA.membership_taxwise_newsletterlist.key));
					}
				}
			}
		}
			
	}
	componentDidMount() {

		document.title = t.TITLE_MEMBERSHIP_TAXWISE_NEWSLETTER;

 		this.props.dispatch(actionGetApiDataKey(c.API_DATA.subscriptions_current.key));	
		
	}
	render() {
		const {
			// taxwiseNewsletter,
			taxwiseNewsletterlist,
			rootLink,
			membership_start,
			// fullMember,
			subscription
		} = this.props;

		const id = this.props.match.params.id;

		return (
			<div id="content-membership-taxwise-newsletter">
				<div className="row">
					<div className="col-md-12">
						<h1>Taxwise Newsletter</h1>
					</div>
				</div>

					
					{
						subscription?
						subscription.error
						? <div><p>Sorry, an error occurred</p></div>
						:subscription.loading? null
						:(subscription.data.content && subscription.data.content.items.filter(sub=>sub.product_id===1096).length)?
					
						<div>
							{taxwiseNewsletterlist
								? taxwiseNewsletterlist.error 
									? <div><p>Sorry, an error occurred</p></div>
									: taxwiseNewsletterlist.loading
										? null
										:taxwiseNewsletterlist.data.content.items[0].volume>=new Date().getFullYear()  
										? <Search data={taxwiseNewsletterlist.data.content} rootLink={rootLink} membership_start={membership_start?membership_start.substr(0,4):(""+new Date().getFullYear())}/>
										:<h3>No Volume Available</h3>
								: null
							}
							{taxwiseNewsletterlist 
								? taxwiseNewsletterlist.error
									? <div><p>Sorry, an error occurred</p></div>
									: taxwiseNewsletterlist.loading
									?	<Loading />
									:id 
									?(subscription.data.content && subscription.data.content.items.filter(sub=>sub.product_id===1096).length)
									?<Sections data={taxwiseNewsletterlist.data.content.items.filter(item=>item.id===id)[0]} />:null
										:taxwiseNewsletterlist.data.content.items[0].volume>=2021
										?(subscription.data.content && subscription.data.content.items.filter(sub=>sub.product_id===1096).length)
											?<Sections data={taxwiseNewsletterlist.data.content.items[0]} />:null
											: null
								:null
							}
						</div>
						:<NonSubscriptionMessage/>
						: null
					}
				
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		taxwiseNewsletter: state.apiData.membership_taxwise_newsletter,
		taxwiseNewsletterlist: state.apiData.membership_taxwise_newsletterlist,
		rootLink: state.app.global.global_user.data.content.root_url,
		membership_start: state.app.global.global_user.data.content.membership_commenced_at,
		fullMember: state.app.global.global_user.data.content.membership_data.full_member,
		subscription: state.apiData.subscriptions_current,
	}
}

export default connect(mapStateToProps)(Main);
