import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import GeneralListItem from './GeneralListItem';
import Loading from '../common/Loading';

class GeneralList extends Component {
	
	render() {

		const {
			// iconClass,
			title,
			info,
			linkResource,
			linkUrl,
		} = this.props;
		return (
			<div className="general-list card h-100">
				<div className="card-header">
					{title
						? <h6>{/*<i className={iconClass}></i>*/}{title}</h6>
						: null
					}
				</div>

				<div className="card-body">
				
					{info
						? info.error
							? <div><p>Sorry, an error occurred</p></div>
							: info.loading
								? <Loading />
								: info.data.content.items
									? info.data.content.items.length > 0
										?
											info.data.content.items.map((item, index) =>
												<GeneralListItem 
													key={index} 
													item={item}
													linkResource={linkResource}
												/>
											)
										: <div><p>No items</p></div>
									: <Loading />
						: <Loading />
					}
				</div>

				<div className="card-footer">
					{linkResource
						?
						<Link className="btn-link" to={linkResource}>
							View All
						</Link>
						: null
					}
					{linkUrl
						?
						<a href={linkUrl} target="_blank" rel="noopener noreferrer">View All</a>
						: null
					}
				</div>
			</div>
		);
	}
}

export default GeneralList;
