import React from 'react';

import ListItem from './ListItem';

const List = ({
	data,
	ePortalLink
}) => {
	return (
		<div className="events-list">
			<div className="row">
				{data.content.items.length > 0
					? data.content.items.map((item, index) => <ListItem key={index} item={item} choices={data.choices} ePortalLink={ePortalLink} />)
					: <div className="col-md-12"><p>No items</p></div>
				}
			</div>
		</div>
	);
}

export default List;