import React, { Component } from 'react';
import { connect } from 'react-redux';

// import {Redirect} from "react-router-dom";

import { actionAuthError } from '../../../actions/app/auth';
import { actionResetApiData, retrieveApiData } from '../../../actions/apiData/index';

// import * as r from '../../../common/_routes';
import * as t from '../../../common/_titles';
import * as c from '../../../common/_constants';
// import Loading from "../../common/Loading";

class Main extends Component {
	componentDidMount() {

		document.title = t.TITLE_PROFILE_LOGOUT;

		let url = c.API_BASE_URL + 'api/v1/logout';
		
		retrieveApiData(this.props.dispatch, (url))
		.then((success) => {
			localStorage.removeItem('authToken');
			this.props.dispatch(actionAuthError());
			this.props.dispatch(actionResetApiData());

			// var oldFrm = document.getElementById("loginform");
			// if (oldFrm)
			// {
			// 	oldFrm.parentNode.removeChild(oldFrm);
			// }

			// var ifrm = document.createElement('iframe');
			// ifrm.setAttribute('id', 'loginform');
			// document.body.appendChild(ifrm);
			// ifrm.setAttribute('src', this.props.rootUrl + '/logout');

			window.location.href = 'https://store.taxinstitute.com.au/customer/account/logout';

		})
	}

	render() {
		return <h3>Logging out</h3>;
	}
}

function mapStateToProps(state) {
	return {
		rootUrl: state.app.global.global_user.data.content.root_url,
	}
}

export default connect(mapStateToProps)(Main);