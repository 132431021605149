import React, { Component } from 'react';
import { connect } from 'react-redux';

import { formatDate, handleGaTracking } from '../../../common/_utility';

import * as t from '../../../common/_titles';

class Main extends Component {

	componentDidMount() {

		document.title = t.TITLE_PROFILE_RENEW_MEMBERSHIP;

	}

	render() {
		const {
			ePortalLink,
			autoRenew,
			renewalDate
		} = this.props;
		return (
			<div id="content-profile-renew-membership">
				<div className="row">
					<div className="col-md-12">
						<h1>Renew Membership</h1>
					</div>
				</div>
				<div className="content">
					<div className="row">
						<div className="col-md-10">
							<p>Your membership is due for renewal on the {formatDate(renewalDate, 'd/m/Y')}.</p>
							{autoRenew
								?
									<p>
											You have opted to auto renew one or more subscription products.
											To manage your renewal preferences please contact the customer 
											service department on +61 2 8223 0000 or email{" "}
											<a href="mailto:membership@taxinstitute.com.au">membership@taxinstitute.com.au</a>
									</p>
								:
									<p>To renew your membership please click {" "}
									<a onClick={() => handleGaTracking('Renew Membership page - Click Renew Membership', 'Renew Membership')} href={ePortalLink + '/customerservice/renewals.aspx'} rel="noopener noreferrer" target="_blank">here</a>
									</p>
							}
						</div>
					</div>
				</div>
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		ePortalLink: state.app.global.global_user.data.content.root_eportalurl,
		renewalDate: state.app.global.global_user.data.content.membership_renewal_at,
		autoRenew: state.app.global.global_user.data.content.membership_data.auto_renew,
	}
}

export default connect(mapStateToProps)(Main);
