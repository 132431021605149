import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

import {isset} from '../../common/_utility';
import * as r from '../../common/_routes';
import VideoListItem from './VideoListItem';
import VideoCounter from './VideoCounter';
import Loading from '../common/Loading';

class EventsList extends Component {
	constructor(props) {
		super(props);
		
		this.state = {
			activeSlide: 0
		}
	}
	render() {
		const { 
			info 
		} = this.props;


		var carouselSettings = {
			dots: false,
			infinite: false,
			speed: 500,
			slidesToShow: 5,
			slidesToScroll: 5,
			afterChange: (activeSlide) => {
				this.setState({ activeSlide: activeSlide });
			},
		};

		return (
			<div className="card h-100">
				<div className="card-header">
					<div className="row">
						<div className="col-md-10">
							<h6>{/* <i className="fas fa-desktop"></i>  */}video library</h6>
						</div>
						<div className="col-md-2 text-right">
							{isset(() => info.data.content.items)
								// ? this.state.currentSlide + "-" + this.state.currentVisibleSlides + " / " + this.state.totalSlides
								? 
									<VideoCounter
										items={info.data.content.items}
										activeSlide={this.state.activeSlide}
										gallerySize={carouselSettings.slidesToShow}
									/>
								: null
							}
						</div>
					</div>
				</div>
				<div className="dashboard-videos-list clear card-body">
					{info
						? info.error
							? <div><p>Sorry, an error occurred</p></div>
							: info.loading
								? <Loading />
								: info.data.content.items
									? info.data.content.items.length > 0
										?
											<Slider {...carouselSettings}>
												{info.data.content.items.map((item, index) => 
													<VideoListItem key={index} item={item} />
												)} 
											</Slider>
										: 
											<div>
												<p>There are no videos in your library yet.</p>
												<div className="dashboard-video-list-empty">Browse videos from past presentations at The Tax Institute.</div>
											</div>
									: <Loading />
						: <Loading />
					}
				</div>
				<div className="card-footer">
					<Link to={r.URL_RESOURCES_VIDEOS} className="btn-link">
						View all Videos
	                </Link>
	            </div>
			</div>
		);
	}
}

export default EventsList;
