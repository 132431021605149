import React, { Component } from 'react';
import { connect } from 'react-redux';

import { actionGetApiDataKey, actionGetApiDataUrl } from '../../../actions/apiData/index';
import * as c from '../../../common/_constants';
import * as t from '../../../common/_titles';

import Search from './Search';
import Sections from './Sections';
import Loading from '../../common/Loading';

class Main extends Component {
	componentDidUpdate(prevProps) {

		if (this.props.theTaxSpecialist && !this.props.theTaxSpecialist.error && !this.props.theTaxSpecialist.loading) {
			if (this.props.match.params.id !== prevProps.match.params.id) {
				const id = this.props.match.params.id ;
				if (id) {
					this.props.dispatch(
						actionGetApiDataUrl(
							c.API_DATA.membership_the_tax_specialist.key,
							c.API_URL + c.API_DATA.membership_the_tax_specialist.base + id + '/articles?count=all',
							true
						)
					);
				} else {
					this.props.dispatch(actionGetApiDataKey(c.API_DATA.membership_the_tax_specialist.key));
				}
			}
		}
	}
	componentDidMount() {

		document.title = t.TITLE_MEMBERSHIP_THE_TAX_SPECIALIST;

		const id = this.props.match.params.id;

		if(id){

			this.props.dispatch(
				actionGetApiDataUrl(
					c.API_DATA.membership_the_tax_specialist.key,
					c.API_URL + c.API_DATA.membership_the_tax_specialist.base + id + '/articles?count=all',
					true
				)
			);
		}else{
			this.props.dispatch(actionGetApiDataKey(c.API_DATA.membership_the_tax_specialist.key));
		}

		this.props.dispatch(actionGetApiDataKey(c.API_DATA.membership_the_tax_specialist_listing.key));
	}
	render() {
		const {
			theTaxSpecialist,
			theTaxSpecialistListing,
			rootLink,
			match
		} = this.props;

		const idNum = match && match.params && match.params.id ? +match.params.id : (theTaxSpecialist && theTaxSpecialist.data && theTaxSpecialist.data.content) ? theTaxSpecialist.data.content.id : 0;
		const listingDetails = (theTaxSpecialistListing && theTaxSpecialistListing.data.content && theTaxSpecialistListing.data.content.items.find(it => it.id === idNum)) || {};
		const {document_image} = listingDetails;
		const {document_uri} = listingDetails;

		if (theTaxSpecialist && theTaxSpecialist.data && theTaxSpecialist.data.content) {
			if (listingDetails.issue && !theTaxSpecialist.data.content.issue) {
				Object.assign(theTaxSpecialist.data.content, {issue: listingDetails.issue, issued_at: listingDetails.issued_at})
			}
		}

		return (
			
			<div id="content-membership-the-tax-specialist">
				<div className="row">
					<div className="col-md-12">
						<h1>The Tax Specialist</h1>
					</div>
				</div>

				{theTaxSpecialistListing
					? theTaxSpecialistListing.error
						? <div><p>Sorry, an error occurred</p></div>
						: theTaxSpecialistListing.loading
							? null
							: <Search data={theTaxSpecialistListing.data.content} rootLink={rootLink} />
					: null
				}
				{theTaxSpecialist
					? theTaxSpecialist.error
						? <div><p>Sorry, an error occurred</p></div>
						: theTaxSpecialist.loading
							? <Loading />
							: <Sections data={theTaxSpecialist.data.content} document_image={document_image} document_uri={document_uri} />
					: <Loading />
				}
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		theTaxSpecialist: state.apiData.membership_the_tax_specialist,
		theTaxSpecialistListing: state.apiData.membership_the_tax_specialist_listing,
		rootLink: state.app.global.global_user.data.content.root_url,
	}
}

export default connect(mapStateToProps)(Main);
