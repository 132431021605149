import React from 'react';

import { formatDate, handleGaTracking } from '../../../common/_utility';

const ListItem = ({
	item,
}) => {
	return (
		<div className="row item">
			<div className="col-md-2">
				<div className="image-holder">
					<img className="img-fluid" src={item.image_uri} alt={item.title} />
				</div>
				<div className="expires text-small text-center mt-3">
					<strong>Expires:</strong> {formatDate(item.access_until, "d/m/Y")}
				</div>
			</div>
			<div className="col-md-6">
				<h4>{item.title}</h4>
				<p>{item.teaser}</p>
			</div>
			<div className="col-md-4">
				{item.is_subscribed
					? 
						<a onClick={() => handleGaTracking('Books page - Click Access subscription', item.title)} href={item.access_info.subscribe_uri} rel="noopener noreferrer" target="_blank" className="btn btn-primary mb-3">
							Access subscription
						</a>
					: 
						<a onClick={() => handleGaTracking('Books page - Click Access Book', item.title)} href={item.access_info.access_uri} rel="noopener noreferrer" target="_blank" className="btn btn-primary mb-3">
							Access Book
						</a>
				}
				<div className="purchased">
					<strong>Purchased:</strong> {formatDate(item.access_from, "d/m/Y")}
				</div>
			</div>
			<div className="col-md-12 mt-4 mb-4">
				<hr />
			</div>
		</div>
		
			
		
	);
}

export default ListItem;