import React from 'react';
import { NavLink } from 'react-router-dom';

import * as r from '../../../../common/_routes';

const IntroListing = ({
	user
}) => {
	return (
		<div className="intro">
			<div className="row">
				<div className="col-md-12">
					<p>{user.content.membership_data.cpd_record_text}</p>
					{user.content.membership_data.permissionto_addcpdrecord 
						? <p>Add hours you received outside of The Tax Institute <NavLink to={r.URL_PROFILE_CPD_RECORD_ADD}>here</NavLink></p>
						: null
					}
					<p><strong>Note: CPD Hours for events will be added once the event is completed.</strong></p>
				</div>
			</div>
		</div>
	);
}

export default IntroListing;