import React from 'react';
import { NavLink } from 'react-router-dom';

import * as r from '../../../../common/_routes';

const Intro = () => {
	return (
		<div className="intro">
			<div className="row">
				<div className="col-md-12">
					<p>
						If you have received CPD hours from sources outside The Tax Institute, 
						you can enter these below.<br />
						These hours will immediately be added to your
						{' '}<NavLink to={r.URL_PROFILE_CPD_RECORD_STRUCTURED}>CPD Record</NavLink>{' '} 
						as Structured CPD Hours.
					</p>
				</div>
			</div>
		</div>
	);
}

export default Intro;