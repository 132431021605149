import React from 'react';

import Table from '../common/Table';
import Total from '../common/Total';

const Sections = ({
	cpdRecordStructured,
	cpdRecordUnstructured,
	user,
	choices
}) => {
	return (
		<div className="sections">
			<div className="row mt-5">
				<div className="col-md-12">
					<table className="table table-striped table-bordered mb-0 cpd-header">
						<tr>
							<td>
								<h4 className="mb-0">Structured CPD Activity</h4>
							</td>
						</tr>
					</table>
					<Table data={cpdRecordStructured} from='print' choices={choices} />
					<Total data={cpdRecordStructured} type='structured' />
				</div>
			</div>
			<div className="row mt-5">
				<div className="col-md-12">
					<table className="table table-striped table-bordered mb-0 cpd-header">
						<tr>
							<td>
							<h4 className="mb-0">Unstructured CPD Activity</h4>
							</td>
						</tr>
					</table>
					<Table data={cpdRecordUnstructured} from='print' choices={choices} />
					<Total data={cpdRecordUnstructured} type='unstructured' />
				</div>
			</div>
		</div>
	);
}

export default Sections;