import React from 'react';

const Loading = ({
	spacing,
	type
}) => {

	let extraClasses = [];

	if(spacing){
		extraClasses.push('mt-4');
	}
	if (type){
		extraClasses.push(type);
	}

	return (
		// <div className="loading"><i className="fas fa-spinner"></i></div>
		<div className={extraClasses.join(' ') + " section-loading-anim"}></div>
	);
}

export default Loading;