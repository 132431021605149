import React, { Component } from 'react';
import { connect } from 'react-redux';
import {Redirect} from "react-router-dom";
import * as m from '../../common/_messages';
import * as r from '../../common/_routes';
// import * as c from '../../common/_constants';
import * as t from '../../common/_titles';

import Loading from '../common/Loading';
// import MenuGeneral from '../header/MenuGeneral';

import { actionGetAuth } from '../../actions/app/auth';
import { handleGaTracking } from '../../common/_utility';
import {URL_FORGOTPASSWORD} from "../../common/_routes";
// import { validEmail } from '../../common/_utility';

class Form extends Component {
	componentDidMount() {

		document.title = t.TITLE_LOGIN;
		window.addEventListener('load', this.checkAutologin);
	}

	componentWillUnmount() {
		window.removeEventListener('load', this.checkAutologin)
	}

	constructor(props) {
		super(props);

		this.state = {
			emailValue: '',
			emailError: false,
			passwordValue: '',
			passwordError: false,
			rememberMeValue: false,
			formLoading: false,
			autologin: false
		}

		// if (typeof window.autologinportal !== 'undefined') {
		// 	this.state.emailValue = "autologin";
		// 	this.state.passwordValue = "autologin";
		// 	this.state.autologin = true;
		// }

		this.handleFieldChange = this.handleFieldChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.isFormValid = this.isFormValid.bind(this);
	}
	handleFieldChange(e) {
		const target = e.target;
		const value = target.type === 'checkbox' ? target.checked : target.value;
		const name = target.name;

		this.setState({ [name + 'Value']: value });
	}

	checkAutologin(e) {

		// if (typeof window.autologinportal !== 'undefined') {
		// 	//this.props.dispatch(actionGetAuth('cookieauth', window.autologinzoolid, false));
		// 	console.log("autologin now");
		// 	document.getElementById('submit').click();
		// }

		// console.log("herrrex");
		// var AuthCookie = 1;// this.props.getCookie('TTIAuth');
		// if (true)//(AuthCookie)
		// {
		// 	// user has logged in via TTI portal
		// 	console.log("despaciot");
		// 	//dispatch(actionGetAuth('authcookie', AuthCookie, 1));
		// 	this.props.dispatch(actionGetAuth('gsiswati123', 'Taxation123', 1));
		// }
		// console.log(AuthCookie);


	}

	handleSubmit(e) {

		e.preventDefault();

		//validation
		if (!this.isFormValid()) {
			return;
		}

		handleGaTracking('Login page - Click Login', 'Login - Remember Me : ' + (this.state.rememberMeValue ? 'yes' : 'no') );

		this.setState({ formLoading: true });

		this.props.dispatch(actionGetAuth(this.state.emailValue, this.state.passwordValue, this.state.rememberMeValue));
	}
	isFormValid() {

		let formValid = true;

		//reset error states
		this.setState({
			emailError: false,
			passwordError: false,
		});

		if (!this.state.emailValue) {
			this.setState({ emailError: m.formError.email.required });
			formValid = false;
		}else{
			//not logging in with an email ATM
			// if (!validEmail(this.state.emailValue)) {
			// 	this.setState({ emailError: m.formError.email.notValid });
			// 	formValid = false;
			// }
		}
		if (!this.state.passwordValue) {
			this.setState({ passwordError: m.formError.password.required });
			formValid = false;
		}

		return formValid;
	}

	componentDidUpdate(prevProps) {
		if (prevProps.auth.error !== this.props.auth.error) {
			if (this.props.auth.error)
			{
//				console.log("error condition");
				this.setState({ 'emailValue': "" , 'passwordValue': ""  });

			}
		}
	}
	render() {

		let state_location = this.props.location.state;
		if (state_location)
		{
			if (state_location.from.pathname.includes("forgot-password"))
			{
				state_location = false;
			}
			if (state_location.from.pathname.includes("login"))
			{
				state_location = false;
			}
			if (state_location.from.pathname.includes("logout"))
			{
				state_location = false;
			}
		}
		
		const { from } = state_location || { from: { pathname: r.URL_DASHBOARD } };

		if (this.props.auth.granted) {
			return <Redirect to={from} />;
		}

		return (
			<div className="container">
				{/* <MenuGeneral /> */}
				<div className="row">
					<div className="offset-md-2 col-md-8 login-form-bg">
						<div id="content-login-form">
							<form onSubmit={this.handleSubmit} onLoad={this.checkAutologin}>
								<div className="header">
									<div className="row">
										<div className="col-md-12">
											<h1>The Tax Institute</h1>
										</div>
									</div>
								</div>
								<div>
									<div className="row">
										<div className="col-md-12">
											<h3>Waiting for login token <Loading type={'login'} />
											</h3>
											<a href="https://www.taxinstitute.com.au" style={{color:'white'}}>Click here to return to The Tax Institute website</a>
										</div>
									</div>
								</div>
								<div style={{display:"none"}} className={"content" + ((this.state.autologin && !this.props.auth.error)?" autologin":"")}>
									<div className="row">
										<div className="col-md-12">
											<h2>Log in</h2>
											<div className={"form-group" + (this.state.emailError ? ' has-error' : '')}>
												<label htmlFor="email">Username</label>
												<input
													type="text"
													id="email"
													name="email"
													value={this.state.emailValue}
													onChange={this.handleFieldChange}
													className="form-control"
												/>
												{this.state.emailError
													? <p className="form-error">{this.state.emailError}</p>
													: null
												}
											</div>
											<div className={"form-group" + (this.state.passwordError ? ' has-error' : '')}>
												<label htmlFor="password">Password</label>
												<input
													type={((this.state.autologin && !this.props.auth.error)?"text":"password")}
													id="password"
													name="password"
													value={this.state.passwordValue}
													onChange={this.handleFieldChange}
													className="form-control"
												/>
												{this.state.passwordError
													? <p className="form-error">{this.state.passwordError}</p>
													: null
												}
											</div>
											<div className="form-group form-check">
												<input 
													type="checkbox"
													className="form-check-input"
													id="rememberMe" 
													name="rememberMe"
													value={this.state.rememberMeValue}
													onChange={this.handleFieldChange}
												/>
												<label className="form-check-label" htmlFor="rememberMe">Remember me</label>
											</div>
										</div>
									</div>
									<div className="row">
										<div className="col-md-6">
											<div className="login-forgot">
												<a href={URL_FORGOTPASSWORD} rel="noopener noreferrer" className="text-grey">Forgot log in details</a>
												{/*<a href={c.URL_EPORTAL_BASE + 'ForgotUID.aspx'} rel="noopener noreferrer" className="text-grey">Forgot log in details</a>*/}
											</div>
										</div>
										<div className="col-md-6 text-right">
											<div className="form-group">
												<button className="btn btn-primary" type="submit" id="submit" value="Login">Log in</button>
												{this.props.auth.isLoading
													? <Loading type={'login'} />
													: null
												}
												{this.props.auth.error
													? <p className="form-error">{this.state.autologin?"Unable to log you in, please re-enter your details above":this.props.auth.error}</p>
													: null
												}
											</div>
										</div>
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

function mapStateToProps(state) {
	return {
		auth: state.app.auth
	}
}

export default connect(mapStateToProps)(Form);