import React from 'react';
import { formatDate } from '../../../common/_utility';

const Title = ({
	data
}) => {
	return (
		<div className="taxvine-title">
			<div className="row">
				<div className="col-md-12">
					<h2>TaxVine</h2>
					<p>Delivered to members weekly, TaxVine provides members with information on current issues affecting them as well as updating members on our Tax Policy and Advocacy activities. It also offers a unique opportunity for members to express their views on these issues.</p>
					<p>Member Newsletter {data.issue_number} | {formatDate(data.issued_at, 'd F Y')}</p>
				</div>
			</div>
		</div>
	);
}

export default Title;