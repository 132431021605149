import React from 'react';

const Footer = () => {
	return (
		<div className="footer mt-5 mb-5">
			<hr />
			<div className="mt-5 row justify-content-md-center">
				<div className="col-md-8">
					<div className="row">
						<div className="col-md-4">
							<p>
								GPO Box 1694<br />
								Sydney NSW 2001
							</p>
						</div>
						<div className="col-md-4">
							<p>
								<strong>Tel:</strong> 02 8223 0000<br />
								<strong>Fax:</strong> 02 8223 0077
							</p>
						</div>
						<div className="col-md-4">
							<p>
								<strong>ABN</strong> 45 008 392 372<br />
								<a href="mailto:taxinstitute.com.au">taxinstitute.com.au</a>
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Footer;