import React from 'react';
import { Provider } from 'react-redux';
import { connect } from 'react-redux';
import { BrowserRouter as Router} from 'react-router-dom';

import * as c from '../common/_constants';

import ErrorLoading from './common/ErrorLoading';
import Main from './Main';
import ReactGA from 'react-ga';


if (c.GA_CODE){
	ReactGA.initialize(c.GA_CODE);
}

const Root = ({
	store, 
	appStatus,
	auth
}) => (
	<Provider store={store}>
		<Router>
			<div className="main-wrapper">
				{appStatus.isLoading
					? appStatus.loadingError
						? <ErrorLoading error={appStatus.error} />
						: <div><div className="page-loading">Loading your portal...</div><div className="page-loading-anim"></div></div> //can change with a loading page
					: <Main auth={auth} />
				}
			</div>
		</Router>
	</Provider>
);

function mapStateToProps(state){
	return {
		appStatus: state.app.appStatus,
		auth: state.app.auth.granted
	}
}

export default connect(mapStateToProps)(Root);