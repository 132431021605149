import React, { Component } from 'react';
import Select from 'react-select';
import { withRouter } from 'react-router';
import { handleGaTracking } from '../../../common/_utility';

import * as r from '../../../common/_routes';
class Search extends Component {
	constructor(props) {
		super(props);

		this.state = {
			issueYearValue: null,
			issueYearError: false,
			issueNumberValue: null,
			issueNumberError: false,
			responseMessage: false,
		}

		this.handleSelectChange = this.handleSelectChange.bind(this);

	}
	handleSelectChange(optionSelected, { name }) {
		optionSelected
			? this.setState({ [name + 'Value']: optionSelected.value })
			: this.setState({ [name + 'Value']: null })

		if (optionSelected && name === 'issueNumber'){

			handleGaTracking('Taxation In Australia - Load Issue', optionSelected.year + ' ' + optionSelected.label);

			this.props.history.push(r.URL_MEMBERSHIP_TAXATION_IN_AUSTRALIA + optionSelected.value );
		}
			
	}
	render() {

		let items = this.props.data.items;
		
		let issueNumberList = [];
		let issueYearList = [];

		items.forEach((item) => {
			issueNumberList.push({
				value: item.id, 
				label: item.issue,
				year: item.volume
			})
			if (!issueYearList.find(year => year.value === item.volume)){
				issueYearList.push({
					value: item.volume,
					label: item.volume
				})
			}
		});

		//filter by year 
		issueNumberList = issueNumberList.filter((item) => item.year === this.state.issueYearValue);

		return (
			<div className="search">
				<form>
					<div className="row">
						<div className="col-md-3">
							<div className={"form-group" + (this.state.issueYearError ? ' has-error' : '')}>
								<label htmlFor="issueYear">Select a Volume</label>
								<Select
									id="issueYear"
									name="issueYear"
									className="custom-react-select"
									value={issueYearList.find(item => item.value === this.state.issueYearValue) || null}
									options={issueYearList}
									onChange={this.handleSelectChange}
									isClearable
								/>
								{this.state.issueYearError
									? <p>{this.state.issueYearError}</p>
									: null
								}
							</div>
						</div>
						<div className="col-md-3">
							{this.state.issueYearValue
								?
									<div className={"form-group" + (this.state.issueNumberError ? ' has-error' : '')}>
										<label htmlFor="issueNumber">Select an Issue</label>
										<Select
											id="issueNumber"
											name="issueNumber"
											className="custom-react-select"
											value={issueNumberList.find(item => item.value === this.state.issueNumberValue) || null}
											options={issueNumberList}
											onChange={this.handleSelectChange}
											isClearable
										/>
										{this.state.issueNumberError
											? <p>{this.state.issueNumberError}</p>
											: null
										}
									</div>
								:	null
							}
							
						</div>
						{/* <div className="col-md-6 text-right">
							<a href={this.props.rootLink + '/resources/books-and-journals/taxation-in-australia'} rel="noopener noreferrer" target="_blank"><i className="fas fa-search"></i> Search Journal</a>
						</div> */}
					</div>
				</form>
			</div>
		);
	}
}

export default withRouter(Search);