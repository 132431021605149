import React, { Component } from 'react';
import Collapse from 'react-css-collapse';

import { formatDate } from '../../../../common/_utility';
import * as c from '../../../../common/_constants';

class ListItem extends Component {
	constructor(props) {
		super(props);

		this.state = {
			showMore: false,
		}

		this.handleCollapse = this.handleCollapse.bind(this);
	}
	handleCollapse(){
		this.setState({ showMore: !this.state.showMore });
	}
	render() {
		const {
			item,
			ePortalLink
		} = this.props;
		return (
			<div className="col-md-12">
				<div className="item">
					<div className="row">
						<div className="col-md-8">
							<h4>{item.product}</h4>
							{item.description
								?
									<div>
										<button 
											onClick={this.handleCollapse} 
											className="btn btn-link"
										>
											{"More "}
											<i className={"fas " + (this.state.showMore ? 'fa-sort-up' : 'fa-sort-down') }></i>
										</button>
										<Collapse isOpen={this.state.showMore} transition={c.COLLAPSE_TRANSITION}>
											<div className="materials-extra p-3">
												<p>{item.description}</p>
											</div>
										</Collapse>
									</div>
								:	null
							}
						</div>
						<div className="col-md-4">
							<div className="rhs-pullout">
								<p className="text-danger"><span className="text-bold">Expired on:</span> {formatDate(item.finish_at, 'd M Y')}</p>
								<a className="btn btn-primary" href={ePortalLink + '/ProductCatalog/Product.aspx?ID=' + item.product_id} rel="noopener noreferrer" target="_blank">Renew now</a>
							</div>
						</div>
					</div>
				</div>
				<div className="mb-4 mt-4">
					<hr />
				</div>
			</div>
		);
	}
}

export default ListItem;