import React from 'react';
import { connect } from 'react-redux';

const UserProfilePicture = ({
	user
}) => {
	let userInitials;

	if (user.preferred_name){
		userInitials = user.preferred_name.charAt(0);
	}else{
		userInitials = user.first_name.charAt(0);
	}
	userInitials += user.last_name.charAt(0);

	if (!userInitials.length){
		userInitials = '?';
	}

	return (
		<div className="profile-picture-wrapper">
			<div className="profile-picture">
				{user.avatar
					? <img src={user.avatar} alt=""/>
					:	
						<div className="default">
							<span>
								{userInitials}
							</span>
						</div>
				}
			</div>
		</div>
	);
}

function mapStateToProps(state) {
	return {
		user: state.app.global.global_user.data.content,
	}
}

export default connect(mapStateToProps)(UserProfilePicture);