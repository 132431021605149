import React from 'react';

const TooltipEditPersonalInfo = () => {
	return (
		<div>
			<p>This will replace your first name in all instances. It is an optional field</p>
		</div>
	);
}

export default TooltipEditPersonalInfo;