import React from 'react';

import ListItem from './ListItem';
import { formatDate } from '../../../../common/_utility';

const List = ({
	data
}) => {
	return (
		<div className="self-paced-learning-list">
			<div className="row">
				<div className="col-md-12">
					<h3>{data.title}</h3>
				</div>
			</div>
			<div className="row mb-3">
				<div className="col-md-6">
					<strong>Expires:</strong> {formatDate(data.expires_at, 'd/m/Y')}
				</div>
				<div className="col-md-6 text-right">
					<strong>Downloaded/Purchased:</strong> {formatDate(data.purchased_at, 'd/m/Y')}
				</div>
			</div>
			<div className="row">
				<div className="col-md-12">
					{data.items.length > 0
						? data.items.map((item, index) => <ListItem key={index} item={item} />)
						: <div className="col-md-12"><p>No items</p></div>
					}
				</div>
			</div>
		</div>
	);
}

export default List;