import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import DatePicker from "react-datepicker";

import queryString from "query-string";
import "react-datepicker/dist/react-datepicker.css";

class FilterView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      year_completed: null,
      enrolment_status: "",
    };
  }

  componentDidMount() {
    let $style = document.querySelector("#enrollment__filterview");
    if (!$style) {
      $style = document.createElement("style");
      $style.textContent = `.enrollment__filterview .data-label {
              line-height: 40px;
          }
          .enrollment__filterview .form-group input, 
          .enrollment__filterview .form-group select {
              height: 40px!important;
          }`;
      document.body.appendChild($style);
    }
    let query = queryString.parse(this.props.location.search);
    const { year_completed, enrolment_status } = query;
    const year = year_completed ? new Date(+year_completed, 1, 1) : null;
    this.setState({
      year_completed: year,
      enrolment_status,
    });
  }

  componentDidUpdate(_prevProps, _prevState) {
    if (_prevProps.location.search !== this.props.location.search) {
      const query = queryString.parse(this.props.location.search);
      const { year_completed, enrolment_status } = query;
      const year = year_completed ? new Date(+year_completed, 1, 1) : null;
      this.setState({
        year_completed: year,
        enrolment_status,
      });
    }
  }

  handleStatusChange = (event) => {
    const value = event.target.value;
    this.setState({
      enrolment_status: value,
    });
  };

  handleYearChange = (newDate) => {
    this.setState({
      year_completed: newDate,
    });
  };

  render() {
    const { statusSet } = this.props;
    const statueObj = {
      Passed: true,
      Cancelled: true,
      Failed: true,
      Withdrawal: true,
    };
    statusSet.forEach((v) => (statueObj[v] = true));
    const queryOrig = queryString.parse(this.props.location.search);
    const queryObj = {
      ...queryOrig,
      enrolment_status: this.state.enrolment_status,
      year_completed: this.state.year_completed
        ? this.state.year_completed.getFullYear()
        : "",
    };
    if (!queryObj.enrolment_status) {
      delete queryObj.enrolment_status;
    }
    if (!queryObj.year_completed) {
      delete queryObj.year_completed;
    }
    const resetQueryObj = {...queryObj};
    delete resetQueryObj.enrolment_status;
    delete resetQueryObj.year_completed;
    const query = queryString.stringify(queryObj);
    const resetQuery = queryString.stringify(resetQueryObj);
    return (
      <div className="view-section enrollment__filterview">
        <div className="row">
          <div className="col-md-12">
            <div className="form-group row col-12 col-lg-11 col-xl-10">
              <div className="data-label col-2 col-lg-1">Filter</div>
              <div className="col-2 col-lg-3">
                {/* Status */}
                <select
                  name="status"
                  className="form-control"
                  value={this.state.enrolment_status}
                  onChange={this.handleStatusChange}
                >
                  <option value="">Status</option>
                  {Object.keys(statueObj).map((st) => (
                    <option key={st} value={st}>
                      {st}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-3">
                {/* Date Completed */}
                <DatePicker
                  selected={this.state.year_completed}
                  onChange={this.handleYearChange}
                  placeholderText="Year Completed"
                  showYearPicker
                  dateFormat="yyyy"
                  className="form-control custom-datepicker"
                />
              </div>
              <div className="col-1">
                <Link
                  className="btn btn-primary"
                  to={this.props.location.pathname + "?" + query}
                >
                  Apply
                </Link>
              </div>
              <div className="col-1" style={{marginLeft: '16px'}}>
                <Link
                  className="btn btn-secondary"
                  to={this.props.location.pathname + "?" + resetQuery}
                >
                  Reset
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    //
  };
}

function mapStateToProps(state) {
  const myEnrolmentDetails =
    state.apiData.education_my_enrollment_details || {};
  const ok =
    !!myEnrolmentDetails.data && myEnrolmentDetails.data.status === 200;
  const rowData = ok ? myEnrolmentDetails.data.content.items : [];
  const statusSet = {};
  for (const rd of rowData) {
    statusSet[rd.enrolment_status] = true;
  }
  return {
    statusSet: Object.keys(statusSet),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(FilterView));
