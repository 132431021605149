import React, { Component } from 'react';
import { connect } from 'react-redux';

import { actionGetApiDataKey } from '../../actions/apiData/index';

import * as r from '../../common/_routes';
import * as c from '../../common/_constants';
import * as t from '../../common/_titles';

import Title from './Title';
import CPDWheel from './CPDWheel';
import DashboardAnnouncement from './DashboardAnnouncement';
import EventsList from './EventsList';
import GeneralList from './GeneralList';
import TaxVineList from './TaxVineList';
import ActivityList from './ActivityList';
import VideosList from './VideosList';
// import TextWidget from './TextWidget';

class Main extends Component {

	componentDidMount(){

		document.title = t.TITLE_DASHBOARD;

		this.props.dispatch(actionGetApiDataKey(c.API_DATA.dashboard_events_upcoming.key));
		this.props.dispatch(actionGetApiDataKey(c.API_DATA.dashboard_taxation_in_australia.key));
		this.props.dispatch(actionGetApiDataKey(c.API_DATA.dashboard_tax_specialist.key));
		this.props.dispatch(actionGetApiDataKey(c.API_DATA.dashboard_australian_tax_forum.key));
		this.props.dispatch(actionGetApiDataKey(c.API_DATA.dashboard_videos.key));
		this.props.dispatch(actionGetApiDataKey(c.API_DATA.dashboard_taxvine.key));
		this.props.dispatch(actionGetApiDataKey(c.API_DATA.dashboard_recent_activity.key));

	}
	render() {
		const { 
			user,
			taxationInAustralia,
			australianTaxForum,
			theTaxSpecialist,
			taxvineNewsletter,
			events,
			videos,
			recentActivity,
			fullMember,
			TKE_access,
			rootUrl,
			TSJ_access,
			ATF_access,
			showCpdWheel,
			// accessLevel
		} = this.props;


		let recentActivityColClass = fullMember ? 'col-md-8' : 'col-md-12';
		let showAnnouncement = user.content.announcement ? true : false;

		let eventListColClass = '';
		if (showCpdWheel){
			if (showAnnouncement){
				eventListColClass = 'col-md-4';
			}else{
				eventListColClass = 'col-md-8';
			}
		}else{
			if (showAnnouncement) {
				eventListColClass = 'col-md-8';
			} else {
				eventListColClass = 'col-md-12';
			}
		}

		return (
			<div id="content-dashboard">
				<div className="row">
					<div className="col-md-12">
						<Title />
					</div>
				</div>
				<div className="row">
					{showCpdWheel
						?
							<div className="col-md-4 pb-4">
								<CPDWheel user={user} />
							</div>
						:	null
					}
					{showAnnouncement
						?
							<div className="col-md-4 pb-4">
								<DashboardAnnouncement content={user.content.announcement} />
							</div>
						:	null
					}
					<div className={eventListColClass + " pb-4"}>
						<EventsList
							info={events}
							rootUrl={rootUrl}
						/>
					</div>
				</div>
				{/* {accessLevel === 'student'
					?
						<div className="row">
							<div className="col-md-12">
								<h3>Benefits of Student Membership</h3>
							</div>
							<div className="col-md-4 pb-4">
								<TextWidget
									type={'online_resource'}
								/>
							</div>
							<div className="col-md-4 pb-4">
								<TextWidget
									type={'taxcareers'}
								/>
							</div>
							<div className="col-md-4 pb-4">
								<TextWidget
									type={'contax'}
								/>
							</div>
						</div>
					: null
				} */}
				<div className="row">
					{fullMember
						?
							<div className="col-md-4 pb-4">
								<GeneralList
									title={'taxation in australia'}
									iconClass={'fas fa-file-alt'}
									info={taxationInAustralia}
									linkUrl={r.URL_MEMBERSHIP_TAXATION_IN_AUSTRALIA}
								/>
							</div>
						:	null
					}
					{TKE_access || TSJ_access
						?
							<div className="col-md-4 pb-4">
								<GeneralList
									title={'the tax specialist'}
									iconClass={'fas fa-file-alt'}
									info={theTaxSpecialist}
									linkResource={r.URL_MEMBERSHIP_THE_TAX_SPECIALIST}
								/>
							</div>
						: null
					}
					{TKE_access || ATF_access
						?
							<div className="col-md-4 pb-4">
								<GeneralList
									title={'australian tax forum'}
									iconClass={'fas fa-file-alt'}
									info={australianTaxForum}
									linkResource={r.URL_MEMBERSHIP_AUSTRALIAN_TAX_FORUM}
								/>
							</div>
						: null
					}
					
				</div>
				<div className="row">
					<div className="col-md-12 pb-4">
						<VideosList
							info={videos}
						/>
					</div>
				</div>
				<div className="row">
					{fullMember
						?
							<div className="col-md-4 pb-4">
								<TaxVineList
									info={taxvineNewsletter}
								/>
							</div>
						: null
					}
					<div className={recentActivityColClass + " pb-4"}>
						<ActivityList
							info={recentActivity}
						/>
					</div>
				</div>
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		user: state.app.global.global_user.data,
		TKE_access: state.app.global.global_user.data.content.membership_data.subscribeto_tke,
		TSJ_access: state.app.global.global_user.data.content.membership_data.subscribeto_taxspecialistjournal,
		ATF_access: state.app.global.global_user.data.content.membership_data.subscribeto_australiantaxforum,
		fullMember: state.app.global.global_user.data.content.membership_data.full_member,
		showCpdWheel: state.app.global.global_user.data.content.membership_data.show_cpd_wheel,
		// accessLevel: state.app.global.global_user.data.content.membership_data.access_level,
		rootUrl: state.app.global.global_user.data.content.root_url,
		events: state.apiData.dashboard_events_upcoming,
		taxationInAustralia: state.apiData.dashboard_taxation_in_australia,
		theTaxSpecialist: state.apiData.dashboard_tax_specialist,
		australianTaxForum: state.apiData.dashboard_australian_tax_forum,
		videos: state.apiData.dashboard_videos,
		taxvineNewsletter: state.apiData.dashboard_taxvine,
		recentActivity: state.apiData.dashboard_recent_activity,
	}
}

export default connect(mapStateToProps)(Main);
