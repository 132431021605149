import * as c from '../../common/_constants';
import { isset } from '../../common/_utility';

function reducerApiData(state = {}, action) {

	let expiryInteval = 0;

	switch (action.type) {
		case c.ACTION_RESET_API_DATA:
			return {};
		case c.ACTION_GET_API_DATA_INIT:
			return {
				...state,
				[action.key]: {
					loading: true,
					saving: false,
					error: false,
					expiry: false,
					data: []
				}
			};
		case c.ACTION_GET_API_DATA_ERROR:
			return {
				...state,
				[action.key]: {
					loading: false,
					saving: false,
					error: true,
					expiry: false,
					data: []
				}
			};
		case c.ACTION_GET_API_DATA_LOAD:
			expiryInteval = 
				isset(() => c.API_DATA[action.key].expiryInterval)
					? c.API_DATA[action.key].expiryInterval 
					: c.EXPIRY_INTERVAL_DEFAULT
			//add 1 second to avoid infinite loop
			expiryInteval += 1000;
			return {
				...state,
				[action.key]: {
					loading: false,
					saving: false,
					error: false,
					expiry: Date.now() + expiryInteval,
					params: action.params,
					data: action.data
				}
			};
		case c.ACTION_API_SEND_DATA_INIT:
			return {
				...state,
				[action.key]: {
					...state[action.key],
					saving: true,
				}
			};
		case c.ACTION_API_SEND_DATA_LOAD:
			
			expiryInteval =
				isset(() => c.API_DATA[action.key].expiryInterval)
					? c.API_DATA[action.key].expiryInterval
					: c.EXPIRY_INTERVAL_DEFAULT
			//add 1 second to avoid infinite loop
			expiryInteval += 1000;
			
			return {
				...state,
				[action.key]: {
					...state[action.key],
					saving: false,
					expiry: Date.now() + expiryInteval,
					data: action.data
				}
			};
		case c.ACTION_API_SEND_DATA_ERROR:
			return {
				...state,
				[action.key]: {
					...state[action.key],
					saving: false,
				}
			};
		default:
			return state;
	}
}

export default reducerApiData;