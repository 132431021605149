import React, { Component } from 'react';
import { connect } from 'react-redux';

import { actionGetApiDataKey, actionGetApiDataUrl } from '../../../actions/apiData/index';
import * as c from '../../../common/_constants';
import * as t from '../../../common/_titles';

import NonMemberMessage from '../../common/NonMemberMessage';
import Search from './Search';
import Sections from './Sections';
import Loading from '../../common/Loading';

class Main extends Component {
	
	componentDidUpdate(prevProps) {

		if (this.props.fullMember){
			if (this.props.taxationInAustralia && !this.props.taxationInAustralia.error && !this.props.taxationInAustralia.loading) {
				if (this.props.match.params.id !== prevProps.match.params.id) {
					const id = this.props.match.params.id ;
					if (id) {
						this.props.dispatch(
							actionGetApiDataUrl(
								c.API_DATA.membership_taxation_in_australia.key,
								c.API_URL + c.API_DATA.membership_taxation_in_australia_listing.endpoint + id,
								true
							)
						);
					} else {
						this.props.dispatch(actionGetApiDataKey(c.API_DATA.membership_taxation_in_australia.key));
					}
				}
			}
		}
	}
	componentDidMount() {

		document.title = t.TITLE_MEMBERSHIP_TAXATION_IN_AUSTRALIA;

		if (this.props.fullMember) {
			const id = this.props.match.params.id;

			if(id){

				this.props.dispatch(
					actionGetApiDataUrl(
						c.API_DATA.membership_taxation_in_australia.key,
						c.API_URL + c.API_DATA.membership_taxation_in_australia_listing.endpoint + id ,
						true
					)
				);
			}else{
				this.props.dispatch(actionGetApiDataKey(c.API_DATA.membership_taxation_in_australia.key));
			}

			this.props.dispatch(actionGetApiDataKey(c.API_DATA.membership_taxation_in_australia_listing.key));
		}
	}
	render() {
		const {
			taxationInAustralia,
			taxationInAustraliaListing,
			rootLink,
			fullMember
		} = this.props;
		return (
			<div id="content-membership-taxation-in-australia">
				<div className="row">
					<div className="col-md-12">
						<h1>Taxation in Australia</h1>
					</div>
				</div>

				{fullMember
					?
						<div>
							{taxationInAustraliaListing
								? taxationInAustraliaListing.error
									? <div><p>Sorry, an error occurred</p></div>
									: taxationInAustraliaListing.loading
										? null
										: <Search data={taxationInAustraliaListing.data.content} rootLink={rootLink} />
								: null
							}
							{taxationInAustralia
								? taxationInAustralia.error
									? <div><p>Sorry, an error occurred</p></div>
									: taxationInAustralia.loading
										? <Loading/>
										: <Sections data={taxationInAustralia.data.content} />
								: <Loading/>
							}
						</div>
					:	<NonMemberMessage />
				}
				
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		taxationInAustralia: state.apiData.membership_taxation_in_australia,
		taxationInAustraliaListing: state.apiData.membership_taxation_in_australia_listing,
		rootLink: state.app.global.global_user.data.content.root_url,
		fullMember: state.app.global.global_user.data.content.membership_data.full_member,
	}
}

export default connect(mapStateToProps)(Main);
