import { combineReducers } from 'redux';

import app from './app/index';
import apiData from './apiData/index';
import { reducer as modal } from 'redux-modal';

const rootReducer = combineReducers({
	app,
	apiData,
	modal
});

export default rootReducer;