import React, { Component } from 'react';
import DatePicker from "react-datepicker";
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import queryString from 'query-string';
import "react-datepicker/dist/react-datepicker.css";

import { getJsonDate } from '../../../../common/_utility';

class Search extends Component {
	constructor(props) {
		super(props);

		let query = queryString.parse(this.props.location.search);

		this.state = {
			dateFrom: query.start_time ? new Date(query.start_time) : null,
			dateTo: query.end_time ? new Date(query.end_time) : null,
		}

		this.handleChangeDateFrom = this.handleChangeDateFrom.bind(this);
		this.handleChangeDateTo = this.handleChangeDateTo.bind(this);
	}
	handleChangeDateFrom(date) {
		this.setState({dateFrom: date});
	}
	handleChangeDateTo(date) {
		this.setState({dateTo: date});
	}
	render() {
		let querySearch = {};
		querySearch.query_type = 'range';
		if (this.state.dateFrom){
			let dateFrom = this.state.dateFrom;
			dateFrom.setHours(0, 0, 0);
			querySearch.start_time = getJsonDate(dateFrom);
		}
		if (this.state.dateTo){
			let dateTo = this.state.dateTo;
			dateTo.setHours(0, 0, 0);
			querySearch.end_time = getJsonDate(dateTo);
		}
		return (
			<div className="search-section">
				<div className="row">
					<div className="col-md-3">
						<div className="form-group">
							<div><label className="data-label">From</label></div>
							<DatePicker
								selected={this.state.dateFrom}
								onChange={this.handleChangeDateFrom}
								dateFormat="dd-MMM-yyyy"
								className="form-control custom-datepicker"
								showMonthDropdown
								showYearDropdown
								dropdownMode="select"
							/>
						</div>
					</div>
					<div className="col-md-3">
						<div className="form-group">
							<div><label className="data-label">To</label></div>
							<DatePicker
								selected={this.state.dateTo}
								onChange={this.handleChangeDateTo}
								dateFormat="dd-MMM-yyyy"
								className="form-control custom-datepicker"
								showMonthDropdown
								showYearDropdown
								dropdownMode="select"
							/>
						</div>
					</div>
					<div className="col-md-6">
						<div className="form-group d-flex align-items-center h-100">
							<label></label>
							<Link
								className="btn btn-primary"
								to={this.props.location.pathname + '?' + queryString.stringify(querySearch)}
							>
								Search
							</Link>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default withRouter(Search);