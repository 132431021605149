import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { formatDate } from '../../common/_utility';

class GeneralListItem extends Component {
	
	render() {
		const {
			item,
			linkResource
		} = this.props;
		return (
			<div className="item">
				<Link to={linkResource + item.id}>
					<div className="row">
						<div className="col-md-6">
							{item.issue.substring(0, 3)} {item.volume}
						</div>
						<div className="col-md-6 text-right">
							{formatDate(item.issued_at, 'd F Y')}
						</div>
					</div>
				</Link>
				<hr className="m-0" />
			</div>
		);
	}
}

export default GeneralListItem;
