import React, { Component } from 'react';
import { connect } from 'react-redux';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { retrieveApiData } from '../../../actions/apiData/index';
import queryString from 'query-string';

import * as c from '../../../common/_constants';
import SearchResult from './SearchResult';

import { getJsonDate, formatDate, handleGaTracking } from '../../../common/_utility';

class Form extends Component {
	constructor(props) {
		super(props);

		this.state = {
			dateFrom: null,
			dateTo: null,
			keyword: '',
			searchResult: false,
			searchLoading: false,
			searchError: false,
		}

		this.handleChangeDateFrom = this.handleChangeDateFrom.bind(this);
		this.handleChangeDateTo = this.handleChangeDateTo.bind(this);
		this.handleChangeKeyword = this.handleChangeKeyword.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
	}
	handleChangeDateFrom(date) {
		this.setState({dateFrom: date});
	}
	handleChangeDateTo(date) {
		this.setState({dateTo: date});
	}
	handleChangeKeyword(e) {
		this.setState({ keyword: e.target.value});
	}
	handleSubmit(e) {
		e.preventDefault();

		this.setState({ searchLoading: true });
		this.setState({ searchError: false });
		this.setState({ searchResult: false });

		let params = {};
		let GAString = [];
		if (this.state.keyword) {
			params.keyword = this.state.keyword;
			GAString.push('Keyword: ' + params.keyword);
		}
		if (this.state.dateFrom){
			let startTime = this.state.dateFrom;
			params.start_time = getJsonDate(startTime);
			GAString.push('Date From: ' + formatDate(startTime, 'd/m/Y'));
		}
		
		if (this.state.dateTo){
			let endTime = this.state.dateTo;
			params.end_time = getJsonDate(endTime);
			GAString.push('Date To: ' + formatDate(endTime, 'd/m/Y'));
		}

		handleGaTracking('Video Library page - Form Search', GAString.join(' - ') );

		let url = c.API_URL + c.API_DATA.resources_videos_search.endpoint + '?' + queryString.stringify(params);
		
		retrieveApiData(this.props.dispatch, url).then(response => {

			this.setState({ searchLoading: false });

			switch (response.status) {
				case 200:
					this.setState({ searchResult: response.content.items[0].resources.search_results });
					break;
				default:
					this.setState({ searchError: true });
					break;
			}

		})
		.catch(err => {
			console.log(err);
			this.setState({ searchLoading: false });
			this.setState({ searchError: true });
		});
	}
	render() {
		return (
			<div className="search-section">
				<h6>Search Videos</h6>
				<div className="search-form">
					<form onSubmit={this.handleSubmit}>
						<div className="row">
							<div className="col-md-3">
								<div className="form-group">
									<input
										type="text"
										id="keyword"
										name="keyword"
										value={this.state.keyword}
										onChange={this.handleChangeKeyword}
										className="form-control"
										placeholder="Keyword"
									/>
								</div>
							</div>
							<div className="col-md-3">
								<div className="form-group">
									<DatePicker
										selected={this.state.dateFrom}
										onChange={this.handleChangeDateFrom}
										dateFormat="dd-MMM-yyyy"
										className="form-control custom-datepicker"
										showMonthDropdown
										showYearDropdown
										dropdownMode="select"
										placeholderText="Date From"
									/>
								</div>
							</div>
							<div className="col-md-3">
								<div className="form-group">
									<DatePicker
										selected={this.state.dateTo}
										onChange={this.handleChangeDateTo}
										dateFormat="dd-MMM-yyyy"
										className="form-control custom-datepicker"
										showMonthDropdown
										showYearDropdown
										dropdownMode="select"
										placeholderText="Date To"
									/>
								</div>
							</div>
							<div className="col-md-3">
								<div className="form-group d-flex align-items-center h-100">
									<button
										className="btn btn-primary"
										type="submit"
										value="Save"
									>
										Search
								</button>
								</div>
							</div>
						</div>
					</form>
				</div>
				<SearchResult 
					loading={this.state.searchLoading}
					error={this.state.searchError}
					result={this.state.searchResult}
					onSelectVideo={this.props.onSelectVideo}
				/>
			</div>
		);
	}
}


export default connect()(Form);