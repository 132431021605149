import React, { Component } from 'react';
import { connect } from 'react-redux';
// import {Redirect} from "react-router-dom";
import * as m from '../../common/_messages';
// import * as r from '../../common/_routes';
// import * as c from '../../common/_constants';
import * as t from '../../common/_titles';

import Loading from '../common/Loading';
// import MenuGeneral from '../header/MenuGeneral';

import { actionResetPassword, actionResetPasswordFinalise } from '../../actions/app/auth';
import {URL_LOGIN} from "../../common/_routes";
import { validEmail, validPassword } from '../../common/_utility';
import queryString from "query-string";

class ForgotPassword extends Component {
	componentDidMount() {

		document.title = t.TITLE_FORGOTPASSWORD;

		let qs = queryString.parse(this.props.location.search);

		if (qs.Token)
		{
			this.setState({ 'token': qs.Token });
		}
	}

	constructor(props) {
		super(props);

		this.state = {
			useridValue: '',
			useridError: false,
			emailaddressValue: '',
			emailaddressError: false,
			passwordError: false,
			formLoading: false,
			token: false
		}

		this.handleFieldChange = this.handleFieldChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.isFormValid = this.isFormValid.bind(this);

	}
	handleFieldChange(e) {
		const target = e.target;
		const value = target.type === 'checkbox' ? target.checked : target.value;
		const name = target.name;

		this.setState({ [name + 'Value']: value });
	}
	handleSubmit(e) {

		e.preventDefault();

		//validation
		if (!this.isFormValid()) {
			return;
		}

		this.setState({ formLoading: true });

		if(this.state.token)
		{
			this.props.dispatch(actionResetPasswordFinalise(this.state.useridValue, this.state.password1Value, this.state.token));
		}
		else
		{
			this.props.dispatch(actionResetPassword(this.state.useridValue, this.state.emailaddressValue));
		}
	}
	isFormValid() {

		let formValid = true;

		//reset error states
		this.setState({
			useridError: false,
			emailaddressError: false,
			passwordError: false
		});

		if (!this.state.useridValue) {
			this.setState({ useridError: m.formError.userid.required });
			formValid = false;
		}

		if (this.state.token)
		{
			if (!this.state.password1Value) {
				this.setState({ passwordError: m.formError.newpassword.required });
				formValid = false;
			}
			else if (!this.state.password2Value) {
				this.setState({ passwordError: m.formError.confirmpassword.required });
				formValid = false;
			}
			else if (!validPassword(this.state.password1Value))
			{
				this.setState({ passwordError: m.formError.newpassword.notValid });
				formValid = false;
			}
			else if (this.state.password1Value !== this.state.password2Value)
			{
				this.setState({ passwordError: m.formError.confirmpassword.notValid });
				formValid = false;
			}
		}
		else
		{
			if (!this.state.emailaddressValue) {
				this.setState({ emailaddressError: m.formError.email.required });
				formValid = false;
			}else{
				if (!validEmail(this.state.emailaddressValue)) {
					this.setState({ emailaddressError: m.formError.email.notValid });
					formValid = false;
				}
			}

		}

		return formValid;
	}
	render() {
		//const { from } = this.props.location.state || { from: { pathname: r.URL_DASHBOARD } };

		return (
			<div className="container">
				{/* <MenuGeneral /> */}
				<div className="row">
					<div className="offset-md-2 col-md-8 login-form-bg">
						<div id="content-login-form">
							<form onSubmit={this.handleSubmit}>
								<div className="header">
									<div className="row">
										<div className="col-md-12">
											<h1>The Tax Institute</h1>
										</div>
									</div>
								</div>
								<div className="content">
									<div id={'forgot-password-form'} className={this.props.auth.sent?"d-none":this.state.token?"d-none":""}>
										<div className="row">
											<div className="col-md-12">
												<h2>Forgot Password</h2>

												<p>Please enter your user ID and your email address below to be sent a link to reset your password.</p>


												<div className={"form-group" + (this.state.useridError ? ' has-error' : '')}>
													<label htmlFor="userid">User ID</label>
													<input
															type="text"
															id="userid"
															name="userid"
															value={this.state.useridValue}
															onChange={this.handleFieldChange}
															className="form-control"
													/>
													{this.state.useridError
															? <p className="form-error">{this.state.useridError}</p>
															: null
													}
												</div>
												<div className={"form-group" + (this.state.emailaddressError ? ' has-error' : '')}>
													<label htmlFor="emailaddress">Email Address</label>
													<input
															type="text"
															id="emailaddress"
															name="emailaddress"
															value={this.state.emailaddressValue}
															onChange={this.handleFieldChange}
															className="form-control"
													/>
													{this.state.emailaddressError
															? <p className="form-error">{this.state.emailaddressError}</p>
															: null
													}
												</div>
											</div>
										</div>
										<div className="row action-buttons">
											<div className="col-md-4">
											</div>
											<div className="col-md-8 text-right">
												<div className="form-group">
													<a href={URL_LOGIN} className="btn btn-secondary">Cancel</a>
													<button className="btn btn-primary" type="submit" value="Reset Password">Reset Password</button>
													{this.props.auth.isLoading
															? <Loading type={'login'} />
															: null
													}
													{this.props.auth.error
															? <p className="form-error">{this.props.auth.error}</p>
															: null
													}
												</div>
											</div>
										</div>
									</div>
									<div id={'forgot-password-finliased'} className={this.props.auth.finalised?"":"d-none"}>
										<div className="row">
											<div className="col-md-12">
												<h2>Forgot Password</h2>
												<p>Your password has been reset.</p>
											</div>
										</div>
										<div className="row action-buttons">
											<div className="col-md-4">
											</div>
											<div className="col-md-8 text-right">
												<div className="form-group">
													<a href={URL_LOGIN} className="btn btn-primary">Login</a>
												</div>
											</div>
										</div>
									</div>
									<div id={'forgot-password-token'} className={this.props.auth.sent?"d-none":this.state.token?(this.props.auth.finalised?"d-none":""):"d-none"}>
										<div className="row">
											<div className="col-md-12">
												<h2>Forgot Password</h2>

												<p>Please enter your user ID, your new password, and enter your password a second time to confirm.</p>

												<div className={"form-group" + (this.state.useridError ? ' has-error' : '')}>
													<label htmlFor="userid">User ID</label>
													<input
															type="text"
															id="userid"
															name="userid"
															value={this.state.useridValue}
															onChange={this.handleFieldChange}
															className="form-control"
													/>
													{this.state.useridError
															? <p className="form-error">{this.state.useridError}</p>
															: null
													}
												</div>
												<div className={"form-group" + (this.state.passwordError ? ' has-error' : '')}>
													<label htmlFor="password1">New Password</label>
													<input
															type="password"
															id="password1"
															name="password1"
															onChange={this.handleFieldChange}
															className="form-control"
													/>
												</div>
												<div className={"form-group" + (this.state.passwordError ? ' has-error' : '')}>
													<label htmlFor="password2">Confirm New Password</label>
													<input
															type="password"
															id="password2"
															name="password2"
															onChange={this.handleFieldChange}
															className="form-control"
													/>
													{this.state.passwordError
															? <p className="form-error">{this.state.passwordError}</p>
															: null
													}
												</div>
											</div>
										</div>
										<div className="row action-buttons">
											<div className="col-md-4">
											</div>
											<div className="col-md-8 text-right">
												<div className="form-group">
													<a href={URL_LOGIN} className="btn btn-secondary">Cancel</a>
													<button className="btn btn-primary" type="submit" value="Reset Password">Set New Password</button>
													{this.props.auth.isLoading
															? <Loading type={'login'} />
															: null
													}
													{this.props.auth.error
															? <p className="form-error">{this.props.auth.error}</p>
															: null
													}
												</div>
											</div>
										</div>
									</div>
									<div id={'forgot-password-note'} className={!this.props.auth.sent?"d-none":""}>
										<div className="row">
											<div className="col-md-12">
												<h2>Forgot Password</h2>
												<p>Please check your email for the link to reset your password.</p>
											</div>
										</div>
									</div>
								</div>
								<div className="footer">
									<div className="row">
										<div className="col-md-12">
											<hr/>
											<h3>Need further assistance?</h3>
											<p>If you have forgotten your user ID or do not receive the
												email to reset your password, please contact <a
														href="mailto:customeradmin@taxinstitute.com.au?subject=I need help signing in to my account">customeradmin@taxinstitute.com.au</a>.
											</p>
											<p>Alternatively, contact our Membership and Customer
												Services Team on +61 02 8223 0060 (Monday to Friday,
												8:30am - 5pm AEDT). If available, please have your member
												number or web user ID handy.</p>

										</div>
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

function mapStateToProps(state) {
	return {
		auth: state.app.auth
	}
}

export default connect(mapStateToProps)(ForgotPassword);