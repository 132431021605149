import React, { Component } from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string';

import { actionGetApiDataKey } from '../../../actions/apiData/index';

import * as c from '../../../common/_constants';
import * as t from '../../../common/_titles';

import Sorting from '../../common/Sorting';
import Sections from './Sections';
import Loading from '../../common/Loading';

class Main extends Component {

	componentDidUpdate(){

		if (this.props.videos && !this.props.videos.error && !this.props.videos.loading){
			const params = queryString.parse(this.props.location.search);
			this.props.dispatch(actionGetApiDataKey(c.API_DATA.resources_videos.key, params));
		}
	}
	
	componentDidMount() {

		document.title = t.TITLE_RESOURCES_VIDEOS;

		const params = queryString.parse(this.props.location.search);
		this.props.dispatch(actionGetApiDataKey(c.API_DATA.resources_videos.key, params));

	}
	render() {
		const {
			videos,
		} = this.props;
		return (
			<div id="content-resources-videos">
				<div className="row">
					<div className="col-md-10">
						<h1>Video Library</h1>
					</div>
					<div className="col-md-2 text-right">
						<ul className="title-actions mb-0">
							<li>
								<Sorting
									options={[
										{ name: "Alphabetical (A - Z)", sortBy: 'title', sortOrder: 'asc' },
										{ name: "Alphabetical (Z - A)", sortBy: 'title', sortOrder: 'desc' },
										{ name: "Newest", sortBy: 'date', sortOrder: 'desc' },
										{ name: "Older", sortBy: 'date', sortOrder: 'asc' },
										// { name: "test asc", sortBy: 'access_uri', sortOrder: 'asc'},
										// { name: "test desc", sortBy: 'access_uri', sortOrder: 'desc'},
									]}
								/>
							</li>
						</ul>
					</div>
				</div>
				{videos
					? videos.error
						? <div><p>Sorry, an error occurred</p></div>
						: videos.loading
							? <Loading />
							: <Sections data={videos.data.content} />
					: <Loading />
				}
				
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		videos: state.apiData.resources_videos,
	}
}

export default connect(mapStateToProps)(Main);
