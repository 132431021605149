import React from 'react';
import { formatDate } from '../../../common/_utility';

const Title = ({
	data
}) => {
	return (
		<div className="title">
			<div className="row">
				<div className="col-md-12">
					<h2>The Tax Specialist</h2>
					<p>Issue {data.issue_number} | {formatDate(data.issued_at, 'd F Y')}</p>
				</div>
			</div>
		</div>
	);
}

export default Title;