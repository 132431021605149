import React from 'react';

import Title from './Title';
import Articles from './Articles';

const Sections = (props) => {
	return (
		<div className="sections mt-4">
			<div className="row">
				<div className="col-md-12">
					<Title {...props} />
				</div>
				<div className="col-md-12">
					{!!props.document_image && <img width="200" src={props.document_image} alt="The Tax Specialist" />}
				</div>
				<div className="col-md-12">
					{!!props.document_uri && <a href={props.document_uri} rel="noopener noreferrer" title="The Tax Specialist PDF" target="_blank">PDF</a>}
					<Articles {...props} />
				</div>
			</div>
		</div>
	);
}

export default Sections;