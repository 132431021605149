import React from 'react';
import { Link } from 'react-router-dom';

import FormDelete from '../delete/FormDelete';

import { formatDate } from '../../../../common/_utility';
import * as r from '../../../../common/_routes';

const TableItem = ({
	choices,
	item,
	from,
	show,
	user
}) => {
	let trainingLocationChoice = choices.training_location.find(location => location.id === item.training_location_id);
	let trainingLocation = trainingLocationChoice ? trainingLocationChoice.name : null;
	return (
		<tr>
			<td>{item.product_title}</td>
			<td>{trainingLocation}</td>
			<td>{formatDate(item.earned_at, 'd-M-Y')}</td>
			<td>
				{item.pending
					? <div className="cpd-pending">Pending</div>
					: item.cpd_hours.toFixed(2)
				}
			</td>
			{from !== 'print' && user.content.membership_data.permissionto_addcpdrecord
				? 
					<td>
						{item.editable
							?
								<div className="dropdown">
									<button className="btn-link text-base-color" type="button" data-toggle="dropdown">
										<i className="fas fa-ellipsis-h"></i>
									</button>
									<div className="dropdown-menu">
										<Link
											className="dropdown-item"
											to={r.URL_PROFILE_CPD_RECORD_EDIT + item.id}
										>
											<i className="fas fa-pencil-alt"></i> Edit
										</Link>
										{/* <Link
											className="dropdown-item"
											to={r.URL_PROFILE_CPD_RECORD_DELETE + item.id}
										>
											<i className="far fa-trash-alt"></i> Delete
										</Link> */}
										<button
											className="dropdown-item"
											onClick={() =>
												show(
													'delete-cpd-record',
													{ content: <FormDelete item={item} /> }
												)
											}
										>
											<i className="far fa-trash-alt"></i> Delete
										</button>
									</div>
								</div>
							:	null
						}
					</td>
				:	null
			}
			
		</tr>
	);
}

export default TableItem;