import React from 'react';

import List from './List';
import Pagination from '../../../common/Pagination';

const Sections = (props) => {
	return (
		<div className="sections">
			<div className="row">
				<div className="col-md-12">
					<List {...props} />
				</div>
				<div className="col-md-12">
					<Pagination
						data={props.data.content.pagination}
					/>
				</div>
			</div>
		</div>
	);
}

export default Sections;