import React from 'react';

import Top from './Top';
import MenuPortal from './MenuPortal';
// import MenuGeneral from './MenuGeneral';

const Main = () => (
	<div className="container">
		{/* <MenuGeneral /> */}
		<Top />
		<MenuPortal />
	</div>
);

export default Main;