import React, { Component } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import queryString from 'query-string';

import * as c from '../../common/_constants';
import ActivityListItem from './ActivityListItem';


class ActivityListScroller extends Component {
	constructor(props) {
		super(props);
		this.state = {
			items: this.props.items.map((x) => x),
			page: 1,
			hasMoreItems: true,
			// nextHref: null
		};

		this.loadItems = this.loadItems.bind(this);

	}
	loadItems(page) {

		//clone 

		let mergedParams = Object.assign({}, c.API_DATA.dashboard_recent_activity.endpointParams);

		mergedParams.page = page;
		
		let paramString = queryString.stringify(mergedParams);

		fetch(c.API_URL + c.API_DATA.dashboard_recent_activity.endpoint + '?' + paramString, {
			method: 'GET',
			headers: {
				"Content-Type": "application/json",
				"Authorization": "Bearer " + localStorage.getItem('authToken'),
				"Accept-Type": "application/json",
			}
		})
		.then(response => response.json())
		.then(response => {

			switch (response.status) {
				case 200:
					let items = this.state.items;
					if(response.content.items.length > 0){
						response.content.items.forEach((item) => {
							items.push(item);
						});
						this.setState({
							items,
							page,
						});
					}else{
						this.setState({
							hasMoreItems: false
						});
					}
					
					break;
				case 401:
					
					break;

				default:
					break;
			}

			return response;
		});
	}
	render() {
		// const { 
			
		// } = this.props;

		const loader = <div key="loader" className="loader">Loading ...</div>;
		return (
			<div className="activity-list-scroller">
				<InfiniteScroll
					pageStart={this.state.page}
					loadMore={this.loadItems}
					hasMore={this.state.hasMoreItems}
					loader={loader}
					useWindow={false}
					initialLoad={false}
					// threshold={0}
				>
					{this.state.items.map((item) =>
						<ActivityListItem key={item.item_id} item={item} />
					)}
					{!this.state.hasMoreItems
						? <div key="finished" className="finished">No more elements</div>
						: null
					}
				</InfiniteScroll>
			</div>
		);
	}
}

export default ActivityListScroller;
