import React from 'react';

import PathNavLink from '../../../common/PathNavLink';
import * as r from '../../../../common/_routes';

const Sections = () => {
	return (
		<ul className="menu-tabs">
			<li>
				<PathNavLink to={r.URL_PROFILE_ORDER_HISTORY_ORDERS}>Orders</PathNavLink>
			</li>
			<li>
				<PathNavLink to={r.URL_PROFILE_ORDER_HISTORY_CANCELLED}>Cancelled</PathNavLink>
			</li>
		</ul>
	);
}

export default Sections;