import React from 'react';
import { NavLink, Link } from 'react-router-dom';

import { isset } from '../../../common/_utility';
import * as r from '../../../common/_routes';
import * as c from '../../../common/_constants';

const SubmenuResources = ({
	counters,
	TKE_access,
	TSJ_access,
  	ATF_access
}) => (
	<div className="submenu" id="submenu-resources">
		<h1>My Resources</h1>
		<div className="row">
			<div className="col-lg-5">
				<ul className="submenu-list">
					<li>
						<NavLink to={r.URL_RESOURCES_BOOKS}>
							Books
							{isset(() => counters.data.content.books)
								? <span className="counter">{counters.data.content.books}</span>
								: null
							}
						</NavLink>
					</li>
					<li>
						<NavLink to={r.URL_RESOURCES_PAPER_AND_PRESENTATIONS_PURCHASED}>
							Papers &amp; Presentations
							{isset(() => counters.data.content.papers_and_presentations)
								? <span className="counter">{counters.data.content.papers_and_presentations}</span>
								: null
							}
						</NavLink>
					</li>
					<li>
						<NavLink to={r.URL_RESOURCES_PODCASTS}>
							Podcasts
							{isset(() => counters.data.content.podcasts)
								? <span className="counter">{counters.data.content.podcasts}</span>
								: null
							}
						</NavLink>
					</li>
					<li>
						<NavLink to={r.URL_RESOURCES_VIDEOS}>
							Videos
							{isset(() => counters.data.content.videos)
								? <span className="counter">{counters.data.content.videos}</span>
								: null
							}
						</NavLink>
					</li>
					<li>
						<NavLink to={r.URL_MEMBERSHIP_TAXWISE_NEWSLETTER}>
						Taxwise Newsletter
							<span className="counter"></span>
						</NavLink>
					</li>
					{/*<li>*/}
					{/*	<NavLink to={r.URL_RESOURCES_CPD_ON_DEMAND_SELF_PACED_LEARNING}>*/}
					{/*		CPD on Demand*/}
					{/*		{isset(() => counters.data.content.cpd_on_demand)*/}
					{/*			? <span className="counter">{counters.data.content.cpd_on_demand}</span>*/}
					{/*			: null*/}
					{/*		}*/}
					{/*	</NavLink>*/}
					{/*</li>*/}
				</ul>
				
			</div>
			
			<div className="col-md-7 d-none d-md-block d-lg-block d-xl-block">
				<div className="journals-list-container">
					<strong>Journal Subscriptions</strong>
					<div className="journals-list mt-3">
						{TKE_access || ATF_access
							?
								<div className="row mb-3">
									<div className="col-md-4">
										<Link to={r.URL_MEMBERSHIP_AUSTRALIAN_TAX_FORUM}>
											<img src={c.URL_IMAGES_BASE + 'thumb_australian_tax_forum.jpg'} alt="Australian Tax Forum" />
										</Link>
									</div>
									<div className="col-md-8">
										<Link to={r.URL_MEMBERSHIP_AUSTRALIAN_TAX_FORUM}>
											Australian Tax Forum
										</Link>
									</div>
								</div>
							:	null
						}
						{TKE_access || TSJ_access
							?
								<div className="row mb-3">
									<div className="col-md-4">
										<Link to={r.URL_MEMBERSHIP_THE_TAX_SPECIALIST}>
											<img src={c.URL_IMAGES_BASE + 'thumb_tax_specialist.jpg'} alt="Tax Specialist" />
										</Link>
									</div>
									<div className="col-md-8">
										<Link to={r.URL_MEMBERSHIP_THE_TAX_SPECIALIST}>
											Tax Specialist
										</Link>
									</div>
								</div>
							:	null
						}
						<div className="row mb-3">
							<div className="col-md-4">
								<a href={r.URL_MEMBERSHIP_TAXATION_IN_AUSTRALIA} target="_blank" rel="noopener noreferrer">
									<img src={c.URL_IMAGES_BASE + 'thumb_taxation_in_australia.jpg'} alt="Taxation in Australia" />
								</a>
							</div>
							<div className="col-md-8">
								<a href={r.URL_MEMBERSHIP_TAXATION_IN_AUSTRALIA} target="_blank" rel="noopener noreferrer">
									Taxation in Australia
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>
			

		</div>
	</div>
);

export default SubmenuResources;