import React from 'react';

import Table from './Table';
import Total from './Total';
import Pagination from '../../../common/Pagination';

const Sections = (props) => {
	return (
		<div className="sections">
			<div className="row">
				<div className="col-md-12">
					<Table {...props} />
					<Total {...props} />
				</div>
			</div>
			<div className="row">
				<div className="col-md-12">
					<Pagination
						data={props.data.pagination}
					/>
				</div>
			</div>
		</div>
	);
}

export default Sections;