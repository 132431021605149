import React from 'react';

import List from './List';

const Sections = (props) => {
	return (
		<div className="sections">
			<div className="row">
				<div className="col-md-12">
					<List {...props} />
				</div>
			</div>
		</div>
	);
}

export default Sections;