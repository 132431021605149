import React, { Component } from 'react';

// import List from './List';
// import Pagination from '../../common/Pagination';
import Form from './Form';
import Lists from './Lists';
import VideoDetails from './VideoDetails';
import { Element, scroller } from 'react-scroll';

class Sections extends Component {
	constructor(props) {
		super(props);

		this.state = {
			videoItem: false,
		}

		this.onSelectVideo = this.onSelectVideo.bind(this);
	}
	onSelectVideo(item) {
		this.setState({ videoItem: item });
		scroller.scrollTo('scrollTarget', {
			duration: 600,
			delay: 100,
			smooth: true,
			offset: -100,
		});
	}
	render() {
		return (
			<div className="sections">
				<div className="row">
					<div className="col-md-12">
						<Element name="scrollTarget">
							<div className="video-play mb-5">
								{this.state.videoItem
									? <VideoDetails video={this.state.videoItem} />
									: null
								}
							</div>
						</Element>
					</div>
				</div>
				<div className="row">
					<div className="col-md-12">
						<div className="video-search">
							<Form onSelectVideo={this.onSelectVideo} />

						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-md-12">
						<Lists {...this.props} onSelectVideo={this.onSelectVideo} />
					</div>
				</div>
			</div>
		);
	}
}

export default Sections;