import React, { Component } from 'react';
import { connect } from 'react-redux';
import { hide } from 'redux-modal';
import { bindActionCreators } from 'redux';
// import Select from 'react-select';

import { handleGaTracking } from '../../../common/_utility';
import { actionApiSendData } from '../../../actions/apiData/index';
import * as m from '../../../common/_messages';
import * as c from '../../../common/_constants';
import * as creditCardType from 'credit-card-type'
import NumberFormat from 'react-number-format'
import moment from 'moment'

class EditPaymentInformation extends Component {
	constructor(props) {
		super(props);

		let profile = this.props.profileUser.data.content;

		this.state = {
			personId: profile.person_id,
			cctypeValue: null,
			cctypeError: false,
			ccvalue: '',
			ccError: false,
			ccExpMonValue: '0',
			ccExpMonError: false,
			ccExpYearValue: '',
			ccExpYearError: false,
			cvvValue: '',
			cvvError: false,
			updateType: !Array.isArray(profile.payment_installment)?'ScheduledPayment':
				!Array.isArray(profile.auto_renewal)?'StandingOrder':'',
			responseMessage: false,
			dataSaved: false,
		}

		this.handleccCardChange = this.handleccCardChange.bind(this);
		this.handleFieldChange = this.handleFieldChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.isFormValid = this.isFormValid.bind(this);

	}
	handleccCardChange(value) {
		const type = value.value.length>=4? creditCardType(value.value.substr(0,4)):[];
		const ambiguous = type.length !== 1;

		const cardType = !ambiguous && ['visa', 'mastercard', 'american-express', 'diners-club'].indexOf(type[0].type)>=0?
							type[0].type : null;

		this.setState({ 'ccvalue': value.value , 'cctypeValue': cardType, 'cctypeError': ambiguous||'Not a valid card'});
	}
	handleFieldChange(e) {
		const target = e.target;
		const value = target.value;
		const name = target.name;

		this.setState({ [name + 'Value']: value });
	}
	handleSubmit(e) {

		e.preventDefault();

		//validation
		if (!this.isFormValid()) {
			return;
		}

		const payent_method = ((key) => {
			switch (key) {
				case "visa":
					return 3;
				case "mastercard":
					return 7;
				case "american-express":
					return 8;
				case "diners-club":
					return 13;
				default:
					return -1;
			} 
	
		})(this.state.cctypeValue);
		
		const card_name = ((key) => {
			switch (key) {
				case "visa":
					return 'Visa';
				case "mastercard":
					return 'Mastercard';
				case "american-express":
					return 'American Express';
				case "diners-club":
					return 'Diners Club';
				default:
					return -1;
			} 
		})
		
		let data = {
			"person_id": this.state.personId,
			"payment_method":payent_method,
			"cc_number":this.state.ccvalue,
			"cc_expiry_month":this.state.ccExpMonValue,
			"cc_expiry_year":this.state.ccExpYearValue,
			"cc_security_number":this.state.cvvValue,
			"cc_update_type":this.state.updateType
		}

		console.log(data);

		handleGaTracking('Profile page - Payment Information Details Save', 'Payment Information Details Save');

		this.props.dispatch(
			actionApiSendData(c.API_DATA.save_card_details.key, data)
		).then((success) => {
			if (success){
				this.setState({ 
					responseMessage: 'Saved' ,
					dataSaved : true
				});
				//close popup after 3 secs
				let hidePopup = this.props.hide;
				let update = this.props.update;
				setTimeout(
					function () { 
						const expDate = moment().set({
							'year':  data.cc_expiry_year,
							'month': data.cc_expiry_month-1,
							'date': 1 
						}).add(1, 'months').subtract(1, 'days').toDate()
						const dateStr  = expDate.getDate().toLocaleString('en-US', {
											minimumIntegerDigits: 2,
											useGrouping: false
										})+"/"+(expDate.getMonth()+1).toLocaleString('en-US', {
											minimumIntegerDigits: 2,
											useGrouping: false
										})+"/"+expDate.getFullYear().toLocaleString('en-US', {
											minimumIntegerDigits: 4,
											useGrouping: false
										})+" 12:00:00 AM"
						let ccDetails = {
							'PaymentType': card_name,
							'CCPartial': data.cc_number[0]+'x'.repeat(11)+data.cc_number.substr(12),
							'CCExpireDate': dateStr,
						}
						update(ccDetails);
						hidePopup('edit-payment-information-details')
					}, 2000
				);
			}else{
				this.setState({ responseMessage: 'something went wrong' });
			}
		});
	}
	isFormValid() {

		let formValid = true;

		//reset error states
		this.setState({
			ccError: false,
			ccExpMonError: false,
			ccExpYearError: false,
			cvvError: false,
		});

		if (!this.state.ccvalue||!this.state.ccExpYearValue||!this.state.ccExpMonValue||!this.state.cvvValue) {
			if (!this.state.ccValue) {
				this.setState({ ccError: m.formError.pi_ccCard.required });
				formValid = false;
			} else if(this.state.ccValue.length<16) {
				this.setState({ ccError: m.formError.pi_ccCard.formatted });
				formValid = false;
			}
			if (!this.state.ccExpMonValue) {
				this.setState({ ccExpMonError: m.formError.pi_expiry_month.required });
				formValid = false;
			}
			if (!this.state.ccExpYearValue) {
				this.setState({ ccExpYearError: m.formError.pi_expiry_year.required });
				formValid = false;
			}
			if (!this.state.cvvValue) {
				this.setState({ cvvError: m.formError.pi_cvv.required });
				formValid = false;
			}
		}

		return formValid;
	}
	render() {
		const _this = this;
		return (
			<div className="edit-modal" id="edit-payment-information-details">
				<form onSubmit={this.handleSubmit}>
					<div className="header">
						<div className="row">
							<div className="col-md-12">
								<h4 className="m-0">Edit Payment Information</h4>
							</div>
						</div>
					</div>
					<div className="content">
						<div className="row">
							<div className="col-md-12">	
								{
									this.state.cctypeValue!=null?
									<img height="80" width="80" src={c.URL_IMAGES_BASE + this.state.cctypeValue+ '.svg'} alt="" />:
									null
								}
							</div>
						</div>
						<div className="row">
							<div className="col-md-12">
								<div className={"form-group" + (this.state.ccError ? ' has-error' : '')}>
									<label htmlFor="status">Card number</label>
									<div className="input-select">
										<NumberFormat 
											format="#### #### #### ####" 
											id="ccCard" 
											name="ccCard"
											size="16"
											onValueChange={this.handleccCardChange}
											className="form-control"
										/>
									</div>
									{this.state.ccError
										? <p className="form-error">{this.state.ccError}</p>
										: null
									}
								</div>
								{/* <div className={"form-group" + (this.state.statusError ? ' has-error' : '')}>
									<label htmlFor="status">Status</label>
									<input
										type="text"
										id="status"
										name="status"
										value={this.state.statusValue}
										onChange={this.handleFieldChange}
										className="form-control"
									/>
									{this.state.statusError
										? <p className="form-error">{this.state.statusError}</p>
										: null
									}
								</div> */}
							</div>
						</div>
						<div className="row">
							<div className="col-md-4">
								<div className={"form-group" + (this.state.ccExpMonError ? ' has-error' : '')}>
									<label htmlFor="rtaNumber">Exp Mon</label>
									<select
										type="month"
										id="ccExpMon"
										name="ccExpMon"
										//value={this.state.ccExpMonValue}
										onChange={this.handleFieldChange}
										className="form-control">
											{
												c.MONTHS_OPTION.map((element, index) =>
													(index === parseInt(_this.state.ccExpMonValue))?
														<option value={index} selected="true">{element}</option>:
														<option value={index}>{element}</option>
												)
											}
									</select>
									{this.state.ccExpMonError
										? <p className="form-error">{this.state.ccExpMonError}</p>
										: null
									}
								</div>
							</div>
							<div className="col-md-4">
								<div className={"form-group" + (this.state.ccExpYearError ? ' has-error' : '')}>
									<label htmlFor="rtaNumber">Exp Year</label>
									<input
										type="number"
										id="ccExpYear"
										name="ccExpYear"
										min={new Date().getFullYear()}
										max={new Date().getFullYear()+50}
										step="1"
										value={this.state.ccExpYearValue}
										onChange={this.handleFieldChange}
										className="form-control"
									/>
									{this.state.ccExpYearError
										? <p className="form-error">{this.state.ccExpYearError}</p>
										: null
									}
								</div>
							</div>
							<div className="col-md-4">
								<div className={"form-group" + (this.state.cvvError ? ' has-error' : '')}>
									<label htmlFor="rtaNumber">Cvv</label>
									<input
										type="password"
										id="cvv"
										name="cvv"
										value={this.state.cvvValue}
										onChange={this.handleFieldChange}
										className="form-control"
									/>
									{this.state.cvvError
										? <p className="form-error">{this.state.cvvError}</p>
										: null
									}
								</div>
							</div>
						</div>
					</div>
					<div className="footer">
						<div className="row">
							<div className="col-md-12">
								{this.props.profileUser.saving
									? 
										<span>Saving <i className="fas fa-spinner fa-spin"></i></span>
									: 
										<span>
											{!this.state.dataSaved
												?
													<span>
														<button
															disabled={this.props.profileUser.saving}
															className="btn btn-link-secondary"
															type="button"
															onClick={() => this.props.hide('edit-payment-information-details')}
														>
															Cancel
														</button>
														<button
															disabled={this.props.profileUser.saving}
															className="btn btn-link"
															type="submit"
															value="Save"
														>
															Save
														</button>
													</span>
												:
													null
											}

											{this.state.responseMessage
												? <p className="response-message">{this.state.responseMessage}</p>
												: null
											}
										</span>
								}
							</div>
						</div>
					</div>
				</form>
			</div>
		)
	}
}
function mapStateToProps(state) {
	return {
		profileUser: state.apiData.profile_user,
	}
}
function mapDispatchToProps(dispatch) {
	return {
		...bindActionCreators({ hide }, dispatch),
		dispatch
	};
}
export default connect(mapStateToProps, mapDispatchToProps)(EditPaymentInformation);