import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { refreshGlobalEndpointData } from '../../../../actions/app/global';
import { actionApiSendData, actionGetApiDataUrl } from '../../../../actions/apiData/index';
import { getJsonDate, handleGaTracking } from '../../../../common/_utility';
import * as m from '../../../../common/_messages';
import * as c from '../../../../common/_constants';
import * as r from '../../../../common/_routes';

class Form extends Component {
	constructor(props) {
		super(props);

		

		var defaultState = {
			trainingTypeValue: '', 
			trainingTypeError: '',
			deliveryTypeValue: '',
			deliveryTypeError: '',
			trainingLocationValue: '',
			trainingLocationError: '',
			productTitleValue: '',
			productTitleError: '',
			dateEarnedValue: null,
			dateEarnedError: '',
			cpdHoursValue: '',
			cpdHoursError: '',
			responseMessage: false,
		}

		if(this.props.type === 'edit'){

			let data = this.props.cpdRecord.data.content;

			defaultState.trainingTypeValue = data.training_type_id;
			defaultState.deliveryTypeValue = data.delivery_type_id;
			defaultState.trainingLocationValue = data.training_location_id;
			defaultState.productTitleValue = data.product_title;
			defaultState.dateEarnedValue = data.earned_at ? new Date(data.earned_at) : null;
			defaultState.cpdHoursValue = data.cpd_hours;
		}

		this.state = defaultState;

		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleSelectChange = this.handleSelectChange.bind(this);
		this.handleFieldChange = this.handleFieldChange.bind(this);
		this.handleChangeDate = this.handleChangeDate.bind(this);
		this.isFormValid = this.isFormValid.bind(this);
	}
	handleSelectChange(optionSelected, { name }) {
		optionSelected
			? this.setState({ [name + 'Value']: optionSelected.value })
			: this.setState({ [name + 'Value']: null })
	}
	handleFieldChange(e) {
		const target = e.target;
		const value = target.type === 'checkbox' ? target.checked : target.value;
		const name = target.name;

		this.setState({ [name + 'Value']: value });
	}
	handleChangeDate(date) {
		this.setState({ dateEarnedValue: date });
	}
	handleSubmit(e) {

		e.preventDefault();

		//validation
		if (!this.isFormValid()) {
			return;
		}

		let data = {
			training_type_id: this.state.trainingTypeValue,
			delivery_type_id: (this.state.trainingTypeValue === 2 ? '' : this.state.deliveryTypeValue),
			training_location_id: (this.state.trainingTypeValue === 2 ? '' : this.state.trainingLocationValue),
			product_title: this.state.productTitleValue,
			earned_at: getJsonDate(this.state.dateEarnedValue),
			cpd_hours: this.state.cpdHoursValue,
		}

		let url;
		let method;
		let key;

		let GAString;

		if (this.props.type === 'edit'){
			url = c.API_URL + c.API_DATA.cpd_record_edit.endpoint + this.props.id;
			method = 'PUT';
			key = c.API_DATA.cpd_record_edit.key;
			GAString = 'Edit';
		}else{
			url = c.API_URL + c.API_DATA.cpd_record_edit.endpoint
			method = 'POST';
			key = c.API_DATA.cpd_record_add.key;
			GAString = 'Add';
		}

		handleGaTracking('CPD Record page - ' + GAString + ' Record', GAString + ' Record');

		this.props.dispatch(
			actionApiSendData(key, data, url, method)
			
		).then((success) => {
			if (success) {
				this.setState({ responseMessage: 'Your data has been saved.' });

				//refresh listing based on the type of record inserted (training type):
				// 1 - structured
				// 2 - unstructured
				if (this.state.trainingTypeValue === 1){

					this.props.dispatch(
						//refresh structured listing
						actionGetApiDataUrl(
							c.API_DATA.cpd_record_structured.key,
							c.API_URL + c.API_DATA.cpd_record_structured.endpoint,
							true
						)
					);
				};
				if (this.state.trainingTypeValue === 2) {
					this.props.dispatch(
						//refresh unstructured listing
						actionGetApiDataUrl(
							c.API_DATA.cpd_record_unstructured.key,
							c.API_URL + c.API_DATA.cpd_record_unstructured.endpoint,
							true
						)
					);
				}

				//also get user data again, to fix cpd Hours
				refreshGlobalEndpointData( 'global_user' , this.props.dispatch);
				// actionLoadGlobal('global_user', this.props.dispatch);
				
				// reset form
				if (this.props.type === 'add') {
					this.setState({
						trainingTypeValue: '',
						deliveryTypeValue: '',
						trainingLocationValue: '',
						productTitleValue: '',
						dateEarnedValue: null,
						cpdHoursValue: ''
					});
				}
			} else {
				this.setState({ responseMessage: 'something went wrong' });
			}
		});
	}
	isFormValid() {

		let formValid = true;

		//reset error states
		this.setState({
			trainingTypeError: false,
			deliveryTypeError: false,
			trainingLocationError: false,
			productTitleError: false,
			dateEarnedError: false,
			cpdHoursError: false,
			responseMessage: false
		});

		if (!this.state.trainingTypeValue) {
			this.setState({ trainingTypeError: m.formError.trainingType.required });
			formValid = false;
		}
		if (this.state.trainingTypeValue !== 2){
			if (!this.state.deliveryTypeValue) {
				this.setState({ deliveryTypeError: m.formError.deliveryType.required });
				formValid = false;
			}
			if (!this.state.trainingLocationValue) {
				this.setState({ trainingLocationError: m.formError.trainingLocation.required });
				formValid = false;
			}
		}
		if (!this.state.productTitleValue) {
			this.setState({ productTitleError: m.formError.productTitle.required });
			formValid = false;
		}
		if (!this.state.dateEarnedValue) {
			this.setState({ dateEarnedError: m.formError.dateEarned.required });
			formValid = false;
		}
		if (isNaN(this.state.cpdHoursValue)) {
			this.setState({ cpdHoursError: m.formError.cpdHours.integer });
			formValid = false;
		}

		return formValid;
	}
	render() {
		let choices = this.props.cpdRecord.data.choices;
		const trainingTypeList = choices.training_type.map((item) => {
			return { value: parseInt(item.id, 10), label: item.name }
		});
		const deliveryTypeList = choices.delivery_type.map((item) => {
			return { value: item.id, label: item.name }
		});
		const trainingLocationList = choices.training_location.map((item) => {
			return { value: item.id, label: item.name }
		});
		return (
			<div className="form-edit-add">
				<form onSubmit={this.handleSubmit}>
					<div className="content">
						<div className="row">
							<div className="col-md-3">
								<div className={"form-group" + (this.state.trainingTypeError ? ' has-error' : '')}>
									<label htmlFor="trainingType">Training Type</label>
									<Select
										id="trainingType"
										name="trainingType"
										className="custom-react-select"
										value={trainingTypeList.find(item => item.value === this.state.trainingTypeValue) || null}
										options={trainingTypeList}
										onChange={this.handleSelectChange}
										isClearable
									/>
									{this.state.trainingTypeError
										? <p className="form-error">{this.state.trainingTypeError}</p>
										: null
									}
								</div>
							</div>
						</div>
						{this.state.trainingTypeValue !== 2
							?
								<div>
									<div className="row">
										<div className="col-md-3">
											<div className={"form-group" + (this.state.deliveryTypeError ? ' has-error' : '')}>
												<label htmlFor="deliveryType">Delivery Type</label>
												<Select
													id="deliveryType"
													name="deliveryType"
													className="custom-react-select"
													value={deliveryTypeList.find(item => item.value === this.state.deliveryTypeValue) || null}
													options={deliveryTypeList}
													onChange={this.handleSelectChange}
													isClearable
												/>
												{this.state.deliveryTypeError
													? <p className="form-error">{this.state.deliveryTypeError}</p>
													: null
												}
											</div>
										</div>
									</div>
									<div className="row">
										<div className="col-md-3">
											<div className={"form-group" + (this.state.trainingLocationError ? ' has-error' : '')}>
												<label htmlFor="trainingLocation">Training Provider</label>
												<Select
													id="trainingLocation"
													name="trainingLocation"
													className="custom-react-select"
													value={trainingLocationList.find(item => item.value === this.state.trainingLocationValue) || null}
													options={trainingLocationList}
													onChange={this.handleSelectChange}
													isClearable
												/>
												{this.state.trainingLocationError
													? <p className="form-error">{this.state.trainingLocationError}</p>
													: null
												}
											</div>
										</div>
									</div>
								</div>
							:	null
						}
						
						<div className="row">
							<div className="col-md-7">
								<div className={"form-group" + (this.state.productTitleError ? ' has-error' : '')}>
									<label htmlFor="productTitle">Product Type or Description (maximum 50 characters)</label>
									<input
										type="text"
										id="productTitle"
										name="productTitle"
										value={this.state.productTitleValue}
										onChange={this.handleFieldChange}
										className="form-control"
										maxlength="50"
									/>
									{this.state.productTitleError
										? <p className="form-error">{this.state.productTitleError}</p>
										: null
									}
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col-md-3">
								<div className={"form-group" + (this.state.dateEarnedError ? ' has-error' : '')}>
									<label htmlFor="dateEarned">Date CPD Hours Earned</label>
									<DatePicker
										selected={this.state.dateEarnedValue}
										onChange={this.handleChangeDate}
										dateFormat="dd-MMM-yyyy"
										className="form-control custom-datepicker"
										showMonthDropdown
										showYearDropdown
										dropdownMode="select"
									/>
									{this.state.dateEarnedError
										? <p className="form-error">{this.state.dateEarnedError}</p>
										: null
									}
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col-md-3">
								<div className={"form-group" + (this.state.cpdHoursError ? ' has-error' : '')}>
									<label htmlFor="cpdHours">CPD Hours</label>
									<input
										type="text"
										id="cpdHours"
										name="cpdHours"
										value={this.state.cpdHoursValue}
										onChange={this.handleFieldChange}
										className="form-control"
									/>
									{this.state.cpdHoursError
										? <p className="form-error">{this.state.cpdHoursError}</p>
										: null
									}
								</div>
							</div>
						</div>
					</div>
					<p><strong>Note:</strong> If you are subject to an audit, documented proof will be required</p>
					<div className="footer">
						<div className="row">
							<div className="col-md-12">
								{this.props.cpdRecord.saving
									? <i className="fas fa-spinner fa-spin"></i>
									: 
										<div>
											<Link
												to={r.URL_PROFILE_CPD_RECORD_STRUCTURED}
												className="btn btn-link-secondary"
											>
												Back
											</Link>
											<button
												disabled={this.props.cpdRecord.saving}
												className="btn btn-link"
												type="submit"
												value="Save"
											>
												Submit CPD Hours
											</button>
										</div>
								}
								{this.state.responseMessage
									? <p className="response-message"><i className="fas fa-check"></i> {this.state.responseMessage}</p>
									: null
								}
							</div>
						</div>
					</div>
				</form>
			</div>
		);
	}
}

export default connect()(Form);