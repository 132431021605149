import React from 'react';

const NonSubscriptionMessage = (props) => {
	return (
		<div className="content">
			<div className="row">
				<div className="col-md-9">
					<p>Access to this content is for subscribers only. Please visit 
						<a rel="noopener noreferrer" target="_blank" href="https://www.taxinstitute.com.au/resources/books-and-journals/taxwise-news"> Taxwise® News </a>
					 to find out more or subscribe.</p>
				</div>
			</div>
		</div>
	);
}

export default NonSubscriptionMessage;