import React, { Component } from 'react';
import { handleGaTracking } from '../../common/_utility';

class DashboardAnnouncement extends Component {

	// constructor(props) {
	// 	super(props);

	// 	this.contentClickHandler = this.contentClickHandler.bind(this);
	// }

	contentClickHandler = (e) => {
		const targetLink = e.target.closest('a');
		if (!targetLink) return;

		handleGaTracking('Dashboard - Click Announcement', targetLink.href);

	};

	render() {
		return (
			<div className="dashboard-announcement card h-100">
				<div className="card-header">
					<h6>{/* <i className="fas fa-bullhorn"></i> */}ANNOUNCEMENTS</h6>
				</div>

				<div className="card-body">
					
					<div onClick={this.contentClickHandler} dangerouslySetInnerHTML={{ __html: this.props.content }}></div>
				</div>

			</div>
		);
	}
}

export default DashboardAnnouncement;
