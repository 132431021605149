import React, { Component } from 'react';
import { withRouter } from 'react-router';
import queryString from 'query-string';
import { Link } from 'react-router-dom';

import Search from './Search';

class View extends Component {
	constructor(props) {
		super(props);

		let query = queryString.parse(this.props.location.search);

		this.state = {
			viewModeValue: query.query_type ? query.query_type : 'all',
		}

		this.handleViewModeChange = this.handleViewModeChange.bind(this);
		this.setStateAll = this.setStateAll.bind(this);
	}
	handleViewModeChange(e) {
		
		const target = e.target;
		const value = target.value;
		const name = target.name;
		
		this.setState({ [name + 'Value']: value });
	}
	setStateAll() {
		
		this.setState({ viewModeValue: 'all' });
	}
	render() {
		return (
			<div className="view-section">
				<div className="row">
					<div className="col-md-12">
						<div className="form-group">
							<div className="btn-group btn-group-toggle">
								<Link
									className={"btn btn-secondary" + (this.state.viewModeValue === "all" ? ' active' : '')}
									to={this.props.location.pathname}
									onClick={this.setStateAll}
								>
									All
								</Link>
								<label 
									className={"btn btn-secondary" + (this.state.viewModeValue === "range" ? ' active' : '')}
									>
									<input
										onClick={this.handleViewModeChange}
										type="radio"
										name="viewMode"
										readOnly
										id="viewMode-range"
										autoComplete="off"
										value="range"
										checked={this.state.viewModeValue === "range"}
									/>
									Date Range
								</label>
							</div>
						</div>
					</div>
				</div>
				{this.state.viewModeValue === 'range'
					? <Search />
					: null
				}
			</div>
		);
	}
}

export default withRouter(View);