import React, { Component } from 'react';
import { connect } from 'react-redux';
import { hide } from 'redux-modal';
import { bindActionCreators } from 'redux';

import { actionApiSendData } from '../../../actions/apiData/index' ;
import * as m from '../../../common/_messages';
import * as c from '../../../common/_constants';
import { handleGaTracking } from '../../../common/_utility';
class EditAbout extends Component {
	constructor(props) {
		super(props);

		let profile = this.props.taxSpecialistData.data.content;

		this.state = {
			aboutValue: profile.about,
			aboutError: false,
			responseMessage: false,
			dataSaved: false,
		}

		this.handleFieldChange = this.handleFieldChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.isFormValid = this.isFormValid.bind(this);

	}
	handleFieldChange(e) {
		const target = e.target;
		const value = target.type === 'checkbox' ? target.checked : target.value;
		const name = target.name;

		this.setState({ [name + 'Value']: value });
	}
	handleSubmit(e) {

		e.preventDefault();

		//validation
		if (!this.isFormValid()) {
			return;
		}

		let data = {
			about: this.state.aboutValue
		}

		handleGaTracking('Tax Specialist Profile page - About Save', 'About Save');

		this.props.dispatch(
			actionApiSendData(c.API_DATA.tax_specialist_profile.key, data)
		).then((success) => {
			if (success) {
				this.setState({
					responseMessage: 'Saved',
					dataSaved: true
				});
				//close popup after 3 secs
				let hidePopup = this.props.hide;
				setTimeout(
					function () {
						hidePopup('edit-tax-specialist-profile-about')
					}, 2000
				);
			} else {
				this.setState({ responseMessage: 'something went wrong' });
			}
		});

		
	}
	isFormValid() {

		let formValid = true;

		//reset error states
		this.setState({
			aboutError: false,
			responseMessage: false
		});

		if (!this.state.aboutValue) {
			this.setState({ aboutError: m.formError.about.required });
			formValid = false;
		}

		return formValid;
	}
	render() {
		return (
			<div className="edit-modal" id="edit-tax-specialist-profile-about">
				<form onSubmit={this.handleSubmit}>
					<div className="header">
						<div className="row">
							<div className="col-md-12">
								<h4>Edit Tax Specialist Profile Information</h4>
							</div>
						</div>
					</div>
					<div className="content">
						<div className="row">
							<div className="col-md-12">
								<div className={"form-group" + (this.state.aboutError ? ' has-error' : '')}>
									<label htmlFor="about">About</label>
									{/* <input
										type="text"
										id="about"
										name="about"
										value={this.state.aboutValue}
										onChange={this.handleFieldChange}
										className="form-control"
									/> */}
									<textarea
										id="about"
										name="about"
										onChange={this.handleFieldChange}
										className="form-control"
									>
										{this.state.aboutValue}
									</textarea>
									{this.state.aboutError
										? <p className="form-error">{this.state.aboutError}</p>
										: null
									}
								</div>
							</div>
						</div>
					</div>
					<div className="footer">
						<div className="row">
							<div className="col-md-12">
								{this.props.taxSpecialistData.saving
									?
										<span>Saving <i className="fas fa-spinner fa-spin"></i></span>
									:
										<span>
											{!this.state.dataSaved
												?
													<span>
														<button
														disabled={this.props.taxSpecialistData.saving}
															type="button"
															className="btn btn-link-secondary"
															onClick={() => this.props.hide('edit-tax-specialist-profile-about')}
														>
															Cancel
														</button>
														<button
															disabled={this.props.taxSpecialistData.saving}
															className="btn btn-link"
															type="submit"
															value="Save"
														>
															Save
														</button>
													</span>
												: null
											}
											{this.state.responseMessage
												? <p className="response-message">{this.state.responseMessage}</p>
												: null
											}
										</span>
								}
							</div>
						</div>
					</div>
				</form>
			</div>
		)
	}
}
function mapStateToProps(state) {
	return {
		taxSpecialistData: state.apiData.tax_specialist_profile,
	}
}
function mapDispatchToProps(dispatch) {
	return {
		...bindActionCreators({ hide }, dispatch),
		dispatch
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(EditAbout);