import React from 'react';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import queryString from 'query-string';

const Sorting = ({
	location,
	options,
	size
}) => {
	const style = {};
	let query = queryString.parse(location.search);
	const currentSortBy = query.sort_by ? query.sort_by : false;
	const currentSortOrder = query.sort_order ? query.sort_order : false;

	//base existing query for sort
	let querySort = queryString.parse(location.search);

	if (size === 'large') {
		style.fontSize = '18px';
	}

	//remove page parameter because on sorting we always start from first page
	delete querySort.page;

	//also remove sorting parameters
	delete querySort.sort_by;
	delete querySort.sort_order;

	return (
		<div className="sorting-section">
			<button className="btn btn-link dropdown-toggle" style={style} data-toggle="dropdown" >
				<i className="fas fa-sort-amount-down"></i>
			</button>
			<div className="dropdown-menu pagination-items-counter-list">
				{options.map((item, index) => {
					querySort.sort_by = item.sortBy;
					querySort.sort_order = item.sortOrder;

					let current = item.sortBy === currentSortBy && item.sortOrder === currentSortOrder;
					return (
						<Link
							key={index}
							className={"dropdown-item" + (current ? ' active' : '')}
							to={location.pathname + '?' + queryString.stringify(querySort)}
						>
							{item.name}
						</Link>
					)
				})}
			</div>
		</div>
	);
}

export default withRouter(Sorting);