import React from 'react';

import { formatDate, handleGaTracking } from '../../../common/_utility';

import InformationMissing from '../../common/InformationMissing';
import EditPersonalInfo from './EditPersonalInfo';
import ChangePassword from './ChangePassword';

const PersonalInfo = ({
	profile,
	choices,
	ePortalLink,
	show,
}) => {
	let prefixChoice = choices.prefix.find(item => parseInt(item.id, 10) === profile.prefix_id);
	let prefix = prefixChoice ? prefixChoice.prefix : null;
	
	let homeStateChoice = choices.state.find(item => parseInt(item.id, 10) === profile.home_state_id);
	let homeState = homeStateChoice ? homeStateChoice.abbreviation : null;

	let homeCountryChoice = choices.country.find(item => parseInt(item.id, 10) === profile.home_country_id);
	let homeCountry = homeCountryChoice ? homeCountryChoice.country : null;

	let fullAddressHome;
	let tempFullAddressHome = [];

	if (homeState){
		tempFullAddressHome.push(homeState);
	}
	if (homeCountry){
		tempFullAddressHome.push(homeCountry);
	}
	fullAddressHome = tempFullAddressHome.join(', ');


	tempFullAddressHome = [];
	if (fullAddressHome) {
		tempFullAddressHome.push(fullAddressHome);
	}
	if (profile.home_postcode) {
		tempFullAddressHome.push(profile.home_postcode);
	}
	fullAddressHome = tempFullAddressHome.join(' ');

	tempFullAddressHome = [];
	if (profile.home_street_address_1) {
		tempFullAddressHome.push(profile.home_street_address_1);
	}
	if (profile.home_street_address_2) {
		tempFullAddressHome.push(profile.home_street_address_2);
	}
	if (profile.home_city) {
		tempFullAddressHome.push(profile.home_city);
	}
	if (fullAddressHome) {
		tempFullAddressHome.push(fullAddressHome);
	}
	fullAddressHome = tempFullAddressHome.join('<br />');
	
	
	let businessStateChoice = choices.state.find(item => parseInt(item.id, 10) === profile.state_id);
	let businessState = businessStateChoice ? businessStateChoice.abbreviation : null;

	let businessCountryChoice = choices.country.find(item => parseInt(item.id, 10) === profile.country_id);
	let businessCountry = businessCountryChoice ? businessCountryChoice.country : null;

	let fullAddressBusiness;
	let tempFullAddressBusiness = [];

	if (businessState) {
		tempFullAddressBusiness.push(businessState);
	}
	if (businessCountry) {
		tempFullAddressBusiness.push(businessCountry);
	}
	fullAddressBusiness = tempFullAddressBusiness.join(', ');


	tempFullAddressBusiness = [];
	if (fullAddressBusiness) {
		tempFullAddressBusiness.push(fullAddressBusiness);
	}
	if (profile.postcode) {
		tempFullAddressBusiness.push(profile.postcode);
	}
	fullAddressBusiness = tempFullAddressBusiness.join(' ');

	tempFullAddressBusiness = [];
	if (profile.street_address) {
		tempFullAddressBusiness.push(profile.street_address);
	}
	if (profile.city) {
		tempFullAddressBusiness.push(profile.city);
	}
	if (fullAddressBusiness) {
		tempFullAddressBusiness.push(fullAddressBusiness);
	}
	fullAddressBusiness = tempFullAddressBusiness.join('<br />');
	return (
		<div className="personal-info-privacy card mb-4">
			<div className="row">
				<div className="col-md-12">
					<div className="card-header">
						<h6><i className="fas fa-user"></i> Personal Information &amp; Privacy</h6>
						<div className="card-header-icon-team">
							<button
								type="button"
								className="btn trigger-edit btn-link"
								onClick={() => {
									handleGaTracking('Profile page - Edit Personal Information', 'Edit Personal Information');
									show(
										'edit-account-details-personal-info',
										{ content: <EditPersonalInfo />,  size:"lg" }
									)
								}}
							>
								<i className="fas fa-pen"></i>
							</button>
						</div>
					</div>

					<div className="card-body">
					
						<div className="info-detail">
							<div className="data-label">
								{[prefix, profile.full_name].join(' ')}
							</div>
							<p>{profile.email}</p>
						</div>
						<div className="info-detail">
							<div className="data-label">Preferred Name</div>
							<p>
								{profile.preferred_name
									? profile.preferred_name
									: <InformationMissing />
								}
							</p>
						</div>
						<div className="info-detail">
							{/*<a href={ePortalLink + "/ChangePassword.aspx"} rel="noopener noreferrer" target="_blank" className="btn btn-link">*/}
							{/*	Change Password*/}
							{/*</a>*/}
							<button
									type="button"
									className="btn trigger-edit btn-link"
									onClick={() => {
										handleGaTracking('Profile page - Change Password', 'Change Password');
										show(
												'edit-change-password',
												{ content: <ChangePassword />,  size:"lg" }
										)
									}}
							>Change Password
							</button>
						</div>
						<div className="info-detail">
							<div className="data-label">Birthday</div>
							<p>
								{profile.date_of_birth_at
									? formatDate(profile.date_of_birth_at, 'd/m/Y')
									: <InformationMissing />
								}
							</p>
						</div>
						<div className="bottom">
							<div className="row">
								<div className="col-md-6">
									<div className="info-detail">
										<div className="data-label">Home address</div>
										{fullAddressHome
											? <p dangerouslySetInnerHTML={{ __html: fullAddressHome }}></p>
											: <InformationMissing />
										}
									</div>
								</div>
								<div className="col-md-6">
									<div className="info-detail">
										<div className="data-label">Business address</div>
										{fullAddressBusiness
											? <p dangerouslySetInnerHTML={{ __html: fullAddressBusiness }}></p>
											: <InformationMissing />
										}
									</div>
								</div>
								
							</div>
							<div className="row">
								<div className="col-md-6">
									<div className="info-detail">
										<div className="data-label">Personal Number</div>
										<p>
											{profile.phone
												? profile.phone
												: <InformationMissing />
											}
										</p>
									</div>
								</div>
								<div className="col-md-6">
									<div className="info-detail">
										<div className="data-label">Fax Number</div>
										<p>
											{profile.fax
												? profile.fax
												: <InformationMissing />
											}
										</p>
									</div>
								</div>
							</div>
						</div>
						<small>
							Have you changed your name recently? To update your name please 
							contact the customer service department on <a href="tel:1300829338">1300 829 338</a> or email{" "}
							<a href="mailto:membership@taxinstitute.com.au">membership@taxinstitute.com.au</a></small>
					</div>
				</div>
			</div>
		</div>
	);
}

export default PersonalInfo;