import React from 'react';

const TooltipInterests = () => {
	return (
		<div>
			<div className="text-bold">Topics of Interest</div>
			<p>Content can be tailored to your specific interests that are listed here</p>
		</div>
	);
}

export default TooltipInterests;