import React, { Component } from 'react';
import { Link } from 'react-router-dom';

// import * as r from '../../common/_routes';
import * as c from '../../common/_constants';
import { handleGaTracking} from '../../common/_utility';

import Profile from './top/Profile';

class Top extends Component {
    constructor(props) {
        super(props);

        this.state = {
            searchValue: '',
        }

        this.handleFieldChange = this.handleFieldChange.bind(this);

    }
    handleFieldChange(e) {
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({ [name + 'Value']: value });
    }
    render() {
        return (
            <div className="header-top mt-3 mb-3">
                <div className="row">
                    <div className="col-md-5 d-flex align-items-center">
                        <Link to={{ pathname: c.WWW_BASE_URL}} className="logo" target="_self">
                            <img src={c.URL_IMAGES_BASE + 'logo.png'} alt="Tax Institute Home" />
                        </Link>
                    </div>
                    <div className="col-md-7">
                        <div className="header-top-search">
                            <div className="d-inline-block">
                                <div className="form-group pr-3">
                                    <input
                                        type="text"
                                        id="search"
                                        name="search"
                                        placeholder="Search The Tax Institute"
                                        value={this.state.searchValue}
                                        onChange={this.handleFieldChange}
                                        className="mr-1"
                                    />
                                    <a onClick={() => handleGaTracking('Global Search', this.state.searchValue)} href={"https://resources.taxinstitute.com.au/search?criteriaOr=" + encodeURIComponent(this.state.searchValue)} rel="noopener noreferrer" target="_blank" className="btn btn-search">
                                        <i className="fas fa-search"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <Profile />
                    </div>
                </div>
            </div>
        );
    }
}

export default Top;