import React, { Component } from 'react';
import { connect } from 'react-redux';

import * as t from '../../../common/_titles';

class Main extends Component {

	componentDidMount() {

		document.title = t.TITLE_PROFILE_LOGOS;

	}

	render() {
		const {
			logos
		} = this.props;
		return (
			<div id="content-profile-renew-membership">
				<div className="row">
					<div className="col-md-12">
						<h1>Logos</h1>
					</div>
				</div>
				<div className="content">
					<div className="row">
						<div className="col-md-12">
							<p>paragraph of information about how to use the logos (to be supplied by TTI).</p>
							<h4>Resources</h4>
							<div className="row">
								{logos.resources.map((item, index) =>
									<div className="col-md-6">
										<img style={{width: 'auto',maxWidth: '100%'}} src={item.path} alt=""/>
										{/* <a href={item.path} rel="noopener noreferrer" target="_blank">Download</a> */}
									</div>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		logos: state.app.global.global_user.data.content.membership_data.logos,
	}
}

export default connect(mapStateToProps)(Main);
