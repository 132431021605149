import React, { Component } from 'react';
import { connect } from 'react-redux';

import { actionGetApiDataUrl } from '../../../../actions/apiData/index';
import * as c from '../../../../common/_constants';
import * as t from '../../../../common/_titles';

import Title from '../common/Title';
import InfoBar from '../../../dashboard/Title';
import Intro from './Intro';
import Form from '../common/Form';
import PageNotFound from '../../../common/PageNotFound';
import Loading from '../../../common/Loading';

class Main extends Component {

	componentDidMount() {

		document.title = t.TITLE_PROFILE_CPD_RECORD_EDIT;

		const id = this.props.match.params.id;
		this.props.dispatch(
			actionGetApiDataUrl(
				c.API_DATA.cpd_record_edit.key,
				c.API_URL + c.API_DATA.cpd_record_edit.endpoint + id,
				true
			)
		);
	}
	render() {
		const {
			cpdRecord,
			user
		} = this.props;

		/* 404 if no permission */
		if (!user.content.membership_data.permissionto_addcpdrecord) {
			return <PageNotFound />;
		}
		
		return (
			<div id="content-profile-cpd-record-edit">
				<div className="row">
					<div className="col-md-12">
						<Title user={user} />
					</div>
				</div>
				<div className="row">
					<div className="col-md-12">
						<InfoBar />
					</div>
				</div>
				<div className="row">
					<div className="col-md-12">
						<Intro />
					</div>
				</div>
				{cpdRecord
					? cpdRecord.error
						? <div><p>Sorry, an error occurred</p></div>
						: cpdRecord.loading
							? <Loading />
							: 
								<Form
									cpdRecord={cpdRecord}
									type="edit"
									id={this.props.match.params.id}
								/>
					: <Loading />
				} 
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		cpdRecord: state.apiData.cpd_record_edit,
		user: state.app.global.global_user.data,
	}
}

export default connect(mapStateToProps)(Main);
