import * as c from '../../common/_constants';

function reducerAuth(state = {}, action) {

	switch (action.type) {

		case c.ACTION_AUTH_REQUEST:
			return {
				...state,
				isLoading: true,
				error: false,
				granted:false
			};
		case c.ACTION_AUTH_SUCCESS:
			return {
				...state,
				isLoading: false,
				error: false,
				granted: true
			};
		case c.ACTION_FORGOTPASSWORD_SUCCESS:
			return {
				...state,
				isLoading: false,
				error: false,
				sent: true
			};
		case c.ACTION_FORGOTPASSWORDFINALISE_SUCCESS:
			return {
				...state,
				isLoading: false,
				error: false,
				finalised: true
			};
		case c.ACTION_AUTH_ERROR:
			return {
				...state,
				isLoading: false,
				error: action.message,
				granted: false
			};

		case c.ACTION_SET_AUTH:
			return action.status
		default:
			return state;
	}
}

export default reducerAuth;